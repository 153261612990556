import React from 'react'
import TextField from '@material-ui/core/TextField'
import { useController } from 'react-hook-form'

export default function MuiTextfield({
  control,
  name,
  rules, // react-hook-form prop

  variant = 'filled',
  size = 'small',
  ...muiProps // material-ui prop
}) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: '',
  })

  return (
    <TextField
      error={error}
      variant={variant}
      size={size}
      helperText={error?.message}
      {...muiProps}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      inputRef={ref}
    />
  )
}
