import { fetchLocale } from '@/src/requests'
const { moco, client, bucket, StorageURL } = require('../../MbaasClient')

export const funcCreateAdvertisements = async (resource, params) => {
  if (resource === 'advertisements') params.data.ads_type = 'banner'
  if (resource === 'popupads') params.data.ads_type = 'popup'
  if (resource === 'couponads') params.data.ads_type = 'coupon'
  if (resource === 'runningText') params.data.ads_type = 'running_text'
  resource = 'advertisements'

  const { start, end, country, ads_type, coupon_setting_id, user_type, textColor } = params.data

  // Reset Priority:
  // 1. Get Data Ads
  // 2. listing priority
  // 3. sorting priority
  // 4. reset priority
  const { data: getDataAds, total: getTotalAds } = await client
    .getTable('advertisements')
    .filter(moco.composeFilter().in('ads_type', ads_type).toString())
    .read()
  let listPriority = []
  for (let i = 0; i < getTotalAds.length; i++) {
    const { id, priority } = getDataAds[i]
    listPriority.push({ id, priority })
  }
  listPriority.sort((a, b) => a.priority - b.priority)
  for (let i = 0; i < listPriority.length; i++) {
    const { id, priority } = listPriority[i]
    if (priority !== i + 1) {
      await client.getTable(resource).update(
        Object.assign({
          id: id,
          data: {
            priority: i + 1,
          },
        })
      )
    }
  }
  // upload image
  const { url, description, max_shown, ini_url } = params.data
  const { data: uploadedData } = await client.storage.upload({
    params: {
      filename: `${Date.now()}-${url?.rawFile?.name}`,
      bucket: bucket['advertisements'],
    },
    file: url?.rawFile,
  })

  const parseUrl = new URL(uploadedData?.attributes?.url)
  const imageUrl = `${StorageURL}${parseUrl.pathname}`

  let userTypeInserted
  if (user_type) {
    userTypeInserted = user_type.join(',')
  } else {
    userTypeInserted = 'GUEST'
  }

  let runningTextStyleProps
  if (textColor) {
    runningTextStyleProps = {
      css: {
        color: textColor,
      },
    }
  }

  // running text
  const [, data] = await fetchLocale()

  function extractRunText(data, locale) {
    const text = {}
    for (const item of locale) {
      const prop = `runText_locale_${item.locale}`
      if (data.hasOwnProperty(prop)) {
        text[item.locale] = data[prop]
      }
    }
    return { text }
  }

  const result = extractRunText(params.data, data?.rows)
  if (runningTextStyleProps) {
    Object.assign(result, runningTextStyleProps)
  }

  // create row
  const { data: resultData } = await client.getTable(resource).insert({
    description: !description ? '#' : description === 'custom_url' ? ini_url : description,
    filename: url ? uploadedData.id : '',
    url: url ? imageUrl : '',
    priority: getTotalAds + 1,
    start,
    end,
    country,
    ads_type,
    max_shown,
    user_type: userTypeInserted,
    coupon_setting_id,
    running_text_config: result,
  })
  return { data: resultData }
}
