import { cloneElement } from 'react'
import {
  List,
  FunctionField,
  DateField,
  TextField,
  SelectInput,
  NumberField,
  Link,
  linkToRecord,
  sanitizeListRestProps,
  useListContext,
  Datagrid,
  Filter,
  TopToolbar,
} from 'react-admin'
import ReactJson from 'react-json-view'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

const PoinLedgersFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Credit Type"
      source="credit_type"
      choices={[
        { id: 'REFUND', name: 'REFUND' },
        { id: 'GIMMICK', name: 'GIMMICK' },
      ]}
      alwaysOn
    />
    <SelectInput
      label="category"
      source="credit_category"
      choices={[
        { id: 'REFUND', name: 'REFUND' },
        { id: 'GIMMICK-OF-TRX', name: 'GIMMICK-OF-TRX' },
        { id: 'REFERRAL-FIRST', name: 'REFERRAL-FIRST' },
        { id: 'REFERRAL-NEXT', name: 'REFERRAL-NEXT' },
        { id: 'CONVERSION', name: 'CONVERSION' },
        { id: 'CREDIT-GIMMICK', name: 'CREDIT-GIMMICK' },
      ]}
      alwaysOn
    />

    <SelectInput
      label="Type"
      source="type"
      choices={[
        { id: 'POIN', name: 'POIN' },
        { id: 'CREDIT', name: 'CREDIT' },
      ]}
      alwaysOn
    />
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      <Typography
        variant="h2"
        component="h2"
        style={{ color: 'black', fontSize: '17px', fontWeight: '700' }}
      >
        Customer Ledger
      </Typography>
    </TopToolbar>
  )
}

const CustomerLedger = (props) => {
  const { record } = props
  const { filterValues } = useListContext()

  console.log(73013213, 'filterValues', filterValues)

  return (
    <>
      <List
        {...props}
        filters={<PoinLedgersFilter />}
        filter={{
          customer_id: record?.customer_id,
          dateGte: filterValues?.dateGte,
          dateLte: filterValues?.dateLte,
        }}
        actions={<ListActions />}
        exporter={false}
        sort={{ field: 'created_at', order: 'DESC' }}
        resource="poin_ledgers"
        filterDefaultValues={{
          customer_id: record?.customer_id,
          type: 'CREDIT',
          dateGte: moment().startOf('month').format('YYYY/MM/DD'),
          dateLte: moment().endOf('month').format('YYYY/MM/DD'),
        }}
        title={<></>}
      >
        <Datagrid>
          <DateField source="created_at" label="Created" showTime />
          <FunctionField
            label="Users"
            render={(record) => {
              if (record.user_id) {
                const linkToUser = linkToRecord(`/users`, record.user_id)
                return <Link to={linkToUser}>{record.users.name}</Link>
              }
            }}
          />
          <FunctionField
            label="Customers"
            render={(record) => {
              const linkToCust = linkToRecord(`/customers`, record.customer_id)
              return <Link to={linkToCust}>{record.customers.name}</Link>
            }}
          />
          <FunctionField
            label="Reference"
            render={(record) => {
              if (record.transaction_ref) {
                const linkToRef = linkToRecord(`/transactions`, record.transaction_ref, 'show')
                return <Link to={linkToRef}>{record.transactions.order_id}</Link>
              }
            }}
          />
          <NumberField source="credit" />
          <NumberField source="debit" />
          <NumberField source="ending_balance" label="Ending balance" />
          <TextField source="remark" />
          <DateField source="expiry" label="Expired at" />
          <TextField source="type" label="Loyalty Type" />
          <TextField source="credit_type" label="Credit Type" />
          <FunctionField
            label="Data"
            render={(record) => (
              <ReactJson
                src={record.data || {}}
                shouldCollapse={() => {}}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={true}
                name={false}
              />
            )}
          />
        </Datagrid>
      </List>
    </>
  )
}

export { CustomerLedger }
