const currencyList = [
  { id: 'AUD', name: 'AUD (Australian Dollar)' },
  {
    id: 'BND',
    name: 'BND (Brunei Dollar - BRUNEI DARUSSALAM)',
  },
  { id: 'EUR', name: 'EUR (Euro)' },
  { id: 'GBP', name: 'GBP (Pound Sterling)' },
  { id: 'HKD', name: 'HKD (Hong Kong Dollar - HONG KONG)' },
  { id: 'IDR', name: 'IDR (Rupiah - INDONESIA)' },
  { id: 'KHR', name: 'KHR (Riel - CAMBODIA)' },
  { id: 'MMK', name: 'MMK (Kyat - MYANMAR)' },
  { id: 'MYR', name: 'MYR (Malaysian Ringgit - MALAYSIA)' },
  { id: 'NPR', name: 'NPR (Nepalese Rupee - NEPAL)' },
  { id: 'NZD', name: 'NZD (New Zealand Dollar)' },
  {
    id: 'PHP',
    name: 'PHP (Philippine Peso - PHILIPPINES (THE))',
  },
  { id: 'SGD', name: 'SGD (Singapore Dollar - SINGAPORE)' },
  { id: 'THB', name: 'THB (Baht - THAILAND)' },
  {
    id: 'TWD',
    name: 'TWD (New Taiwan Dollar - TAIWAN (PROVINCE OF CHINA))',
  },
  { id: 'USD', name: 'USD (US Dollar)' },
  { id: 'VND', name: 'VND (Dong - VIET NAM)' },
]

export default currencyList
