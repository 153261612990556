import { client } from '../../MbaasClient'

// @ts-ignore
export const funcUpdateDenomPricings = async (resource, params) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  const { data } = await client.getTable('denom_pricings').update({
    id: params.id,
    data: {
      denom: params.data.denom,
      rrp: params.data.rrp,
      currency: `${params.data.currency_from}-${params.data.currency_to}`,
    },
  })

  return { data: data }
}
