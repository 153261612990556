import gql from 'graphql-tag'
import { client } from '../provider/MbaasClient'

/* MAIN FUNCTION */
const fetchLocale = async () => {
  try {
    const result = await client.gql.query({
      query,
    })

    return [
      null,
      {
        rows: result?.allLocalesList?.data || [],
        count: result?.allLocalesList?.count || 0,
      },
    ]
  } catch (error) {
    return [error, { rows: [], count: 0 }]
  }
}

export default fetchLocale

/* QUERY GRAPHQL */
const query = gql`
  query getLocale {
    allLocalesList {
      data {
        locale
      }
      count
    }
  }
`
