const { moco, client } = require('../../MbaasClient')

export async function isKnowledgeBase(params, resource) {
  console.log(623495, { method: 'getList', resource: resource, params: params })

  const { page, perPage } = params.pagination
  let { field, order } = params.sort
  let filterObject = moco.composeFilter()

  if (params.filter.q) {
    filterObject = filterObject
      .contains('tolower(question)', params.filter.q.toLowerCase())
      .or((x) => x.contains('tolower(answer)', params.filter.q.toLowerCase()))
      .or((y) => y.contains('tolower(category)', params.filter.q.toLowerCase()))
      .or((z) => z.contains('tolower(tag)', params.filter.q.toLowerCase()))
  }
  if (params.filter.category) {
    filterObject = filterObject.eq('category', params.filter.category)
  }
  if (params.filter.tag) {
    filterObject = filterObject.eq('tag', params.filter.tag)
  }
  if (filterObject !== '') filterObject = filterObject.toString()

  const { data, total } = await client
    .getTable(resource)
    .filter(filterObject || 'true')
    .sort(order === 'DESC' ? `-${field}` : field)
    .limit(perPage)
    .offset((page - 1) * perPage)
    .read()

  return {
    data: data,
    total: total,
  }
}
