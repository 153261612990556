export default function buttonText(isPriorityChange, check) {
  if (isPriorityChange && Object.keys(check).length > 0) {
    return <span>Delete and save order</span>
  }
  if (isPriorityChange) {
    return <span>Save order</span>
  }
  if (Object.keys(check).length > 0) {
    return <span>Delete</span>
  }
}
