import React, { createElement, useState } from 'react'
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash'
import { withRouter, useHistory } from 'react-router-dom'
import { MenuItemLink, usePermissions } from 'react-admin'
import { Box } from '@material-ui/core'
import LoadingIndicator from '../component/LoadingIndicator'

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import SettingsIcon from '@material-ui/icons/Settings'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import StoreIcon from '@material-ui/icons/Store'
import UserIcon from '@material-ui/icons/Group'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import PromotionIcon from '@material-ui/icons/ConfirmationNumber'
import GetAppIcon from '@material-ui/icons/GetApp'
import DescriptionIcon from '@material-ui/icons/Description'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import BusinessIcon from '@material-ui/icons/Business'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import PhotoIcon from '@material-ui/icons/Photo'
import PersonIcon from '@material-ui/icons/Person'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import LanguageIcon from '@material-ui/icons/Language'
import PublicIcon from '@material-ui/icons/Public'
import PlnIcon from '@material-ui/icons/OfflineBolt'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DormancyIcon from '@material-ui/icons/LocalHotel'
import MonitoringIcon from '@material-ui/icons/Assessment'
import CLTVIcon from '@material-ui/icons/Timer'
import MapIcon from '@material-ui/icons/MapOutlined'
import CouponIcon from '@material-ui/icons/Loyalty'
import CouponSettingsIcon from '@material-ui/icons/SettingsApplications'
import PoinTopIcon from '@material-ui/icons/RedeemSharp'
import PoinIcon from '@material-ui/icons/Redeem'
import PoinLedgersIcon from '@material-ui/icons/PlaylistAddCheck'
import PoinAddIcon from '@material-ui/icons/PlusOne'
import AdsIcon from '@material-ui/icons/LocalActivity'
import BannerAdsIcon from '@material-ui/icons/Slideshow'
import PopupAdsIcon from '@material-ui/icons/ViewCarousel'
import ReferralBenefitIcon from '@material-ui/icons/GroupAdd'
import ViewListIcon from '@material-ui/icons/ViewList'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate'
import ImageRoundedIcon from '@material-ui/icons/ImageRounded'
import BlacklistedTargetIcon from '@material-ui/icons/Block'
import BonusIcon from '@material-ui/icons/Star'
import TodayIcon from '@material-ui/icons/Today'
import TextFieldsIcon from '@material-ui/icons/TextFields'
// import CreditCardIcon from '@material-ui/icons/CreditCard'

import SubMenu from './SubMenu'

const Menu = (props) => {
  const { loading, permissions } = usePermissions()
  let history = useHistory()

  const [state, setState] = useState({
    menuUtilities: false,
    menuProducts: false,
    menuAccount: false,
    menuDenomLookup: false,
    menulanguage: false,
    menuDashboard: false,
    menuCoupon: false,
    menuPoin: false,
    menuAds: false,
    menuPromotions: false,
  })

  const handleToggle = (menu) => {
    if (menu === 'menuDashboard') {
      history.push('/gonilai_today_dashboard')
    }
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }
  const { onMenuClick, open, resources } = props

  if (loading) {
    return <LoadingIndicator />
  }
  if ((!loading && permissions && permissions.includes('Ads_admin')) || !permissions) {
    return (
      <div>
        <Box pt={2} />
        <SubMenu
          handleToggle={() => handleToggle('menuAds')}
          isOpen={state.menuAds}
          sidebarIsOpen={open}
          name="Advertisement"
          icon={<AdsIcon />}
        >
          <MenuItemLink
            to={`/advertisements`}
            primaryText="Banner Ads"
            onClick={onMenuClick}
            leftIcon={<BannerAdsIcon />}
          />
          <MenuItemLink
            to={`/popupads`}
            primaryText="Pop-Up Ads"
            onClick={onMenuClick}
            leftIcon={<PopupAdsIcon />}
          />
          <MenuItemLink
            to={`/couponads`}
            primaryText="Coupon Ads"
            onClick={onMenuClick}
            leftIcon={<BannerAdsIcon />}
          />
          <MenuItemLink
            to={`/runningText`}
            primaryText="Running Text"
            onClick={onMenuClick}
            leftIcon={<BannerAdsIcon />}
          />
        </SubMenu>
      </div>
    )
  }
  if (!loading && permissions && !permissions.includes('Ads_admin')) {
    return (
      <div>
        <Box pt={2} />
        <SubMenu
          handleToggle={() => handleToggle('menuDashboard')}
          isOpen={state.menuDashboard}
          sidebarIsOpen={open}
          name="Dashboard"
          icon={<DashboardIcon />}
        >
          <MenuItemLink
            to={`/gonilai_today_dashboard`}
            primaryText="Today"
            onClick={onMenuClick}
            leftIcon={<TodayIcon />}
          />
          <MenuItemLink
            to={`/transaction_monitoring_dashboard`}
            primaryText="Transaction Monitoring"
            onClick={onMenuClick}
            leftIcon={<MonitoringIcon />}
          />
          <MenuItemLink
            to={`/dormancy_rate_dashboard`}
            primaryText="Dormancy Rate"
            onClick={onMenuClick}
            leftIcon={<DormancyIcon />}
          />
          <MenuItemLink
            to={`/cltv_reactivation_dashboard`}
            primaryText="CLTV and Reactivation"
            onClick={onMenuClick}
            leftIcon={<CLTVIcon />}
          />
          <MenuItemLink
            to={`/store_profit_dashboard`}
            primaryText="Store Profit"
            onClick={onMenuClick}
            leftIcon={<AttachMoneyIcon />}
          />
          <MenuItemLink
            to={`/heat_map`}
            primaryText="Heat Map"
            onClick={onMenuClick}
            leftIcon={<MapIcon />}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => handleToggle('menuUtilities')}
          isOpen={state.menuUtilities}
          sidebarIsOpen={open}
          name="Utilities"
          icon={<SettingsIcon />}
        >
          {/* <MenuItemLink
            to={`/announcements`}
            primaryText="Announcements"
            onClick={onMenuClick}
            leftIcon={<AnnouncementIcon />}
              /> */}
          <MenuItemLink
            to={`/blast_sms`}
            primaryText="Blast SMS"
            onClick={onMenuClick}
            leftIcon={<AnnouncementIcon />}
          />
          <MenuItemLink
            to={`/gonilai_draw`}
            primaryText="GoNilai Draw"
            onClick={onMenuClick}
            leftIcon={<FavoriteBorderIcon />}
          />
          <MenuItemLink
            to={`/downloads`}
            primaryText="Downloads"
            onClick={onMenuClick}
            leftIcon={<GetAppIcon />}
          />
          <MenuItemLink
            to={`/documents`}
            primaryText="Documents"
            onClick={onMenuClick}
            leftIcon={<DescriptionIcon />}
          />
          <SubMenu
            handleToggle={() => handleToggle('menulanguage')}
            isOpen={state.menulanguage}
            sidebarIsOpen={open}
            name="Languages"
            icon={<PublicIcon />}
          >
            <MenuItemLink
              to={`/locales`}
              primaryText="Gonilai"
              onClick={onMenuClick}
              leftIcon={<LanguageIcon />}
            />
            <MenuItemLink
              to={`/messenger_i18n`}
              primaryText="Messenger"
              onClick={onMenuClick}
              leftIcon={<LanguageIcon />}
            />
          </SubMenu>
          <MenuItemLink
            to={`/header-image`}
            primaryText="Header Image"
            onClick={onMenuClick}
            leftIcon={<ImageRoundedIcon />}
          />
          <MenuItemLink
            to={`/prod_prefs`}
            primaryText="Product Preference"
            onClick={onMenuClick}
            leftIcon={<LanguageIcon />}
          />
          <MenuItemLink
            to={`/blacklisted_targets`}
            primaryText="Blacklisted Targets"
            onClick={onMenuClick}
            leftIcon={<BlacklistedTargetIcon />}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => handleToggle('menuProducts')}
          isOpen={state.menuProducts}
          sidebarIsOpen={open}
          name="Products"
          icon={<StoreIcon />}
        >
          <MenuItemLink
            to={`/products`}
            primaryText="Products"
            onClick={onMenuClick}
            leftIcon={<ShoppingCartIcon />}
          />
          <MenuItemLink
            to={`/organizations`}
            primaryText="Organizations"
            onClick={onMenuClick}
            leftIcon={<BusinessIcon />}
          />
          <MenuItemLink
            to={`/pricings`}
            primaryText="Pricings"
            onClick={onMenuClick}
            leftIcon={<AttachMoneyIcon />}
          />
          <MenuItemLink
            to={`/reference_snapshots`}
            primaryText="Reference Snapshots"
            onClick={onMenuClick}
            leftIcon={<PhotoIcon />}
          />
          <MenuItemLink
            to={`/pln_rates`}
            primaryText="PLN Rates"
            onClick={onMenuClick}
            leftIcon={<PlnIcon />}
          />
          <MenuItemLink
            to={`/failed_buttons`}
            primaryText="FAILED buttons"
            onClick={onMenuClick}
            leftIcon={<CouponSettingsIcon />}
          />
          <MenuItemLink
            to={`/purchase-limit`}
            primaryText="Purchase Limit"
            onClick={onMenuClick}
            leftIcon={<CLTVIcon />}
          />
          <SubMenu
            handleToggle={() => handleToggle('menuDenomLookup')}
            isOpen={state.menuDenomLookup}
            sidebarIsOpen={open}
            name="Denom Lookup"
            icon={<LocalAtmIcon />}
          >
            <MenuItemLink
              to={`/denom_pricings`}
              primaryText="View & Edit"
              onClick={onMenuClick}
              leftIcon={<MonetizationOnIcon />}
            />
            <MenuItemLink
              to={`/denom_pricings_setup`}
              primaryText="Product Setup"
              onClick={onMenuClick}
              leftIcon={<CouponSettingsIcon />}
            />
          </SubMenu>
        </SubMenu>
        <SubMenu
          handleToggle={() => handleToggle('menuAccount')}
          isOpen={state.menuAccount}
          sidebarIsOpen={open}
          name="Account"
          icon={<AccountBoxIcon />}
        >
          <MenuItemLink
            to={`/customers`}
            primaryText="Customers"
            onClick={onMenuClick}
            leftIcon={<PersonIcon />}
          />
          <MenuItemLink
            to={`/users`}
            primaryText="Users"
            onClick={onMenuClick}
            leftIcon={<UserIcon />}
          />
          <MenuItemLink
            to={`/kyc_levels`}
            primaryText="KYC Levels"
            onClick={onMenuClick}
            leftIcon={<PhotoIcon />}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => handleToggle('menuAds')}
          isOpen={state.menuAds}
          sidebarIsOpen={open}
          name="Advertisement"
          icon={<AdsIcon />}
        >
          <MenuItemLink
            to={`/advertisements`}
            primaryText="Banner Ads"
            onClick={onMenuClick}
            leftIcon={<BannerAdsIcon />}
          />
          <MenuItemLink
            to={`/popupads`}
            primaryText="Pop-Up Ads"
            onClick={onMenuClick}
            leftIcon={<PopupAdsIcon />}
          />
          <MenuItemLink
            to={`/couponads`}
            primaryText="Coupon Ads"
            onClick={onMenuClick}
            leftIcon={<BannerAdsIcon />}
          />
          <MenuItemLink
            to={`/runningText`}
            primaryText="Running Text"
            onClick={onMenuClick}
            leftIcon={<TextFieldsIcon />}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => handleToggle('menuPromotions')}
          isOpen={state.menuPromotions}
          sidebarIsOpen={open}
          name="Promotions"
          icon={<ReceiptIcon />}
        >
          <MenuItemLink
            to={`/promotions`}
            primaryText="Promotion Code"
            onClick={onMenuClick}
            leftIcon={<PromotionIcon />}
          />
          <SubMenu
            handleToggle={() => handleToggle('menuPoin')}
            isOpen={state.menuPoin}
            sidebarIsOpen={open}
            name="Loyalty"
            icon={<PoinTopIcon />}
          >
            <MenuItemLink
              to={`/poin`}
              primaryText="Point"
              onClick={onMenuClick}
              leftIcon={<PoinIcon />}
            />
            {/* <MenuItemLink
              to={`/poin_ledgers`}
              primaryText="Loyalty Reward Logs"
              onClick={onMenuClick}
              leftIcon={<PoinLedgersIcon />}
            /> */}
            <MenuItemLink
              to={`/wallets`}
              primaryText="Loyalty Reward Logs"
              onClick={onMenuClick}
              leftIcon={<PoinLedgersIcon />}
            />
            <MenuItemLink
              to={`/poin_add_manually`}
              primaryText="Add Manually"
              onClick={onMenuClick}
              leftIcon={<PoinAddIcon />}
            />
            <MenuItemLink
              to={`/promo_double_poin`}
              primaryText="Multiplier"
              onClick={onMenuClick}
              leftIcon={<ControlPointDuplicateIcon />}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle('menuCoupon')}
            isOpen={state.menuCoupon}
            sidebarIsOpen={open}
            name="Coupons"
            icon={<CouponIcon />}
          >
            <MenuItemLink
              to={`/coupon_settings`}
              primaryText="Coupon Settings"
              onClick={onMenuClick}
              leftIcon={<CouponSettingsIcon />}
            />
            <MenuItemLink
              to={`/coupons`}
              primaryText="Coupons"
              onClick={onMenuClick}
              leftIcon={<PromotionIcon />}
            />
          </SubMenu>
          <MenuItemLink
            to={`/referral_benefits`}
            primaryText="Referral Benefits"
            onClick={onMenuClick}
            leftIcon={<ReferralBenefitIcon />}
          />
          <MenuItemLink
            to={`/flashsale`}
            primaryText="Sale"
            onClick={onMenuClick}
            leftIcon={<ViewListIcon />}
          />
          <MenuItemLink
            to={`/bonuses`}
            primaryText="Bonuses"
            onClick={onMenuClick}
            leftIcon={<BonusIcon />}
          />
        </SubMenu>
        {resources
          .filter((x) => !x.options.menu)
          .map((resource, index) => (
            <MenuItemLink
              key={index}
              to={`/${resource.name}`}
              primaryText={resource.options.label || resource.name}
              leftIcon={resource.icon ? createElement(resource.icon) : undefined}
              onClick={onMenuClick}
            />
          ))}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
})

const enhance = compose(withRouter, connect(mapStateToProps, {}))

export default enhance(Menu)
