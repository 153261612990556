const uriProd = 'https://rpx.telin.com.my'
const uriTest = 'https://sandbox.rpx.telin.com.my'
const uriDev = 'http://localhost:3000'
const URL =
  window.location.host === 'portal.rpx.telin.com.my'
    ? uriProd
    : window.location.host === 'develop.rpx-web-console.pages.dev'
    ? uriTest
    : uriDev
const storageDevURL = 'http://127.0.0.1:9000'
const storageTestURL = 'https://testing.tmy-storage.telin.com.my'
const storageProdURL = 'https://gonilai.b-cdn.net' // old -> 'https://live.tmy-storage.telin.com.my'
export const StorageURL =
  window.location.host === 'portal.rpx.telin.com.my'
    ? storageProdURL
    : window.location.host === 'develop.rpx-web-console.pages.dev'
    ? storageTestURL
    : storageDevURL
export const bucket = {
  advertisements: 'advertisements',
  documents: 'documents',
}
//
export const appUrl = URL
export const moco = require('@mocobaas/client-js')
const clientProd = new moco.init(URL, {
  payloadEncryption: {
    enforcement: ['COMMON', 'FUNCTION', 'TABLE', 'GRAPHQL'],
  },
})
const clientTest = new moco.init(URL, {
  payloadEncryption: {
    enforcement: ['COMMON', 'FUNCTION', 'TABLE', 'GRAPHQL'],
    // enforcement: [],
  },
})
const clientDev = new moco.init(URL, {
  payloadEncryption: {
    enforcement: [],
  },
})
export const client =
  window.location.host === 'portal.rpx.telin.com.my'
    ? clientProd
    : window.location.host === 'develop.rpx-web-console.pages.dev'
    ? clientTest
    : clientDev
