const countryList = [
  { id: 'AU', name: 'AU (Australia)' },
  { id: 'BD', name: 'BD (Bangladesh)' },
  { id: 'BN', name: 'BN (Brunei)' },
  { id: 'KH', name: 'KH (Cambodia)' },
  { id: 'TL', name: 'TL (East Timor)' },
  { id: 'HK', name: 'HK (Hong Kong)' },
  { id: 'ID', name: 'ID (Indonesia)' },
  { id: 'LA', name: 'LA (Laos)' },
  { id: 'MO', name: 'MO (Macao)' },
  { id: 'MY', name: 'MY (Malaysia)' },
  { id: 'MM', name: 'MM (Myanmar)' },
  { id: 'NP', name: 'NP (Nepal)' },
  { id: 'NZ', name: 'NZ (New Zealand)' },
  { id: 'PH', name: 'PH (Philippines)' },
  { id: 'SG', name: 'SG (Singapore)' },
  { id: 'TW', name: 'TW (Taiwan)' },
  { id: 'TH', name: 'TH (Thailand)' },
  { id: 'US', name: 'US (United States)' },
  { id: 'VN', name: 'VN (Vietnam)' },
]

export default countryList
