import moment from 'moment'
import { client } from '../../MbaasClient'

export const funcUpdateCouponSettings = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  let updatedData = params.data
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  let couponSettingData = Object.assign({
    ...updatedData,
    end: moment(updatedData.end).add(59, 'seconds'),
    product_origin: 'IDN',
  })
  const { data } = await client.getTable(resource).update(
    Object.assign({
      id: params.id,
      data: couponSettingData,
    })
  )
  return { data: data }
}
