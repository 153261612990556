import gql from 'graphql-tag'
import { client } from '@/src/provider/MbaasClient'

export const updateOne = async (
  id: string,
  variables: {
    product_origin: any
    category: any
    product_code: any
    specific_customer: any
    customer_id: any
    transactions_aggregate: any
    total_usage: any
    value: any
    denomination: any
  }
) => {
  delete variables.transactions_aggregate

  const isProdOriginString = typeof variables?.product_origin[0] === 'string'
  const isCategoryString = typeof variables?.category[0] === 'string'
  const isProdCodeString = typeof variables?.product_code[0] === 'string'
  const isTotalUsageString = typeof variables?.total_usage[0] === 'string'
  const isValueString = typeof variables?.value[0] === 'string'
  const isDenominationString = typeof variables?.denomination[0] === 'string'

  const modifyVariable = {
    ...variables,
    customer_id: variables?.specific_customer ? variables?.customer_id : null,
    total_usage: isTotalUsageString ? parseInt(variables?.total_usage) : variables?.total_usage,
    value: isValueString ? parseInt(variables?.value) : variables?.value,
    denomination: isDenominationString
      ? parseInt(variables?.denomination)
      : variables?.denomination,
    product_origin: isProdOriginString
      ? variables?.product_origin.toString()
      : variables?.product_origin.map((v: any) => v.id).toString(),
    category: isCategoryString
      ? variables?.category.toString()
      : variables?.category.map((v: any) => v.id).toString(),
    product_code: isProdCodeString
      ? variables?.product_code.toString()
      : variables?.product_code.map((v: any) => v.id).toString(),
  }

  const res = await client.gql
    .mutation({
      mutation,
      variables: { ids: [id], input: modifyVariable },
    })
    .then((v: any) => {
      console.log(31243, 'v', v)
      return v?.response?.[0]
    })

  const dataModify = {
    ...(res || {}),
    id: id,
    product_origin: res?.product_origin.split(','),
    category: res?.category.split(','),
    product_code: res?.product_code.split(','),
  }

  return { data: dataModify }
}

const mutation = gql`
  mutation ($ids: [UUID!]!, $input: PromoFlashsalesUpdateInput!) {
    response: updatePromoFlashsalesById(ids: $ids, input: $input) {
      id
      created_at
      updated_at
      user_id
      start
      end
      value
      country
      product_origin
      denomination
      product_code
      category
      total_usage
      apply_max_usage_per_customer
      max_usage_per_customer
      specific_target
      new_customer_only
      specific_customer
      customer_id
      transactions_aggregate: transactions_flashsale_id_list_aggregate {
        count
      }
    }
  }
`
