import gql from 'graphql-tag'
import { client } from '@/src/provider/MbaasClient'
import moment from 'moment/moment'
export const getList = async ({
  page,
  limit,
  orderBy,
  filter,
}: {
  page: number
  limit: number
  orderBy: { [key: string]: 'ASC' | 'DESC' }
  filter: { [key: string]: string }
}) => {
  const { dateGte, dateLte } = filter
  const whereBuilder = {
    ...(dateGte &&
      dateLte && {
        created_at: {
          _gte: moment(dateGte).startOf('day').toISOString(),
          _lte: moment(dateLte).endOf('day').toISOString(),
        },
      }),
  }

  const res = await client.gql.query({
    query,
    variables: {
      offset: (page - 1) * limit,
      limit,
      orderBy,
      whereBuilder,
    },
  })

  const dataModify = res.response.data?.map((v: any) => {
    return {
      ...(v || {}),
      items: {
        ...(v?.items || {}),
        channel: v?.items?.channel?.map((v: any) => ({ id: v, name: v })) || [],
        category: v?.items?.category?.map((v: any) => ({ id: v, name: v })) || [],
        denomination: v?.items?.denomination?.map((v: any) => ({ id: v, name: v })) || [],
        product_code: v?.items?.product_code?.map((v: any) => ({ id: v, name: v })) || [],
        product_origin: v?.items?.product_origin?.map((v: any) => ({ id: v, name: v })) || [],
      },
    }
  })

  return { data: dataModify, count: res.response.count }
}

const query = gql`
  query (
    $offset: Int
    $limit: Int
    $orderBy: PromoDoublePoinOrderByInput
    $whereBuilder: PromoDoublePoinWhereInput
  ) {
    response: allPromoDoublePoinList(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      where: $whereBuilder
    ) {
      data {
        id
        user_id
        created_at
        updated_at
        end
        start
        country
        value
        configs
        items
        transactions_aggregate: transactions_promo_double_poin_id_list_aggregate {
          count
        }
      }
      count
    }
  }
`
