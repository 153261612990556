const { client, moco } = require('../MbaasClient')

const funcGetManyReference = async (resource, params) => {
  const { data } = await client
    .getTable(resource)
    .filter(moco.composeFilter().eq(params.target, params.id).toString())
    .read()
  return { data: data, total: data.length }
}
export default funcGetManyReference
