import gql from 'graphql-tag'
import { client } from '../../MbaasClient'

export const getManyCustomer: any = async () => {
  try {
    const gqlResult = await client.gql.query({ query })
    return gqlResult?.allCustomersList?.data || []
  } catch (e) {
    return []
  }
}

const query = gql`
  query getManyCustomers {
    allCustomersList(limit: 999999) {
      data {
        id
        name
        channel
        metadata
        channel_id
        users_customer_id_list {
          data {
            id
            msisdn
          }
        }
        organizations: organization_id_data {
          name
        }
      }
    }
  }
`
