const { client } = require('./../provider/MbaasClient')

const deleteManyHeaderImage = async (Ids) => {
  try {
    for (const id of Ids) {
      let { data } = await client.getTable('webview_headers').lookup(id)
      const { bucket, filepath } = JSON.parse(data?.url)
      await client.storage.removeFile({ filePath: filepath, bucket })
      await client.getTable('webview_headers').del({ id })
    }

    return ['result', null]
  } catch (e) {
    return [null, e]
  }
}
export default deleteManyHeaderImage
