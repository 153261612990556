import reqFlashsale from '@/src/requests/flashsale'

export const funcUpdateFlashsale = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  const { data } = await reqFlashsale.updateOne(params.id, params.data)
  return { data }
}
