import moment from 'moment'
import { fetchDataInitTable } from '../shared-script/fetchDataInitTable'
import defaultSortParams from '../shared-script/defaultSortParams'
const { moco, client } = require('../../MbaasClient')

export async function isCustomers(params, resource) {
  let { page, perPage } = params.pagination
  const { dateGte, dateLte } = params.filter || {}
  let { field: sortField, order: sortOrder } = params.sort

  // adjust sorting params, default sort DESC
  let [field, order] = defaultSortParams(sortField, sortOrder)

  if (params.filter.q) {
    perPage = 50
  }

  let filterObject = moco.composeFilter()
  let includedTables = []

  switch (true) {
    case Boolean(params.filter.filter_channel):
      filterObject = filterObject.contains((x) => {
        if (isNaN(params.filter.filter_channel)) {
          return x.toLower('channel')
        }
        return x.toLower('channel_id')
      }, params.filter.filter_channel.toLowerCase())
      break

    case Boolean(params.filter.custName):
      filterObject = filterObject.contains((x) => {
        if (isNaN(params.filter.custName)) {
          return x.toLower('name')
        }
        return x.toLower('channel_id')
      }, params.filter.custName.toLowerCase())
      break

    case Boolean(params.filter.q):
      filterObject = filterObject.contains((x) => {
        if (isNaN(params.filter.q)) {
          return x.toLower('name')
        }
        return x.toLower('channel_id')
      }, params.filter.q.toLowerCase())
      break

    case Boolean(params.filter.customer_type):
      const { page, perPage } = params.pagination
      let { field, order } = params.sort
      const {
        data: { data, total },
      } = await client.invokeApi('customer-referral-type', {
        field: field,
        order: order,
        filter: params.filter,
        limit: perPage,
        offset: (page - 1) * perPage,
      })
      return { data: data, total: total }

    default:
      console.log('default')
      break
  }

  if (dateGte && dateLte) {
    filterObject = filterObject.and((x) =>
      x
        .ge('created_at', moment(dateGte).startOf('day').toISOString())
        .le('created_at', moment(dateLte).endOf('day').toISOString())
    )
  }

  if (filterObject !== '') filterObject = filterObject.toString()

  const [data, total] = await fetchDataInitTable(
    includedTables,
    resource,
    filterObject,
    order,
    field,
    page,
    perPage
  )

  return { data: data, total: total }
}
