import moment from 'moment'
import { mapCountryCurrency } from '../../../constants'
import defaultSortParams from '../shared-script/defaultSortParams'
const { moco, client } = require('../../MbaasClient')

export async function isDenomPricings(params, resource) {
  console.log(6234958776, { method: 'getList', resource: resource, params: params })
  const { product_origin, dateGte, dateLte } = params.filter || {}

  const { page, perPage } = params.pagination
  let { field: sortField, order: sortOrder } = params.sort

  // adjust sorting params, default sort DESC
  const [, order] = defaultSortParams(sortField, sortOrder)

  let filterObject = moco.composeFilter()
  const currencyBasedonCountry = mapCountryCurrency.filter((val) => val.country === product_origin)

  if (product_origin) {
    filterObject = filterObject.startsWith('currency', currencyBasedonCountry[0].currency)
  }

  if (dateGte && dateLte) {
    filterObject = filterObject.and((x) =>
      x
        .ge('created_at', moment(dateGte).startOf('day').toISOString())
        .le('created_at', moment(dateLte).endOf('day').toISOString())
    )
  }

  if (filterObject !== '') filterObject = filterObject.toString()
  const { data, total } = await client
    .getTable('denom_pricings')
    .filter(filterObject || 'true')
    .sort(order === 'ASC' ? `-denom` : 'denom')
    .limit(perPage)
    .offset((page - 1) * perPage)
    .read()

  return { data, total }
}
