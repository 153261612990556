import fetchFindActiveDefaultHeaderImage from './fetch-find-active-default-header-image'

const { client } = require('./../provider/MbaasClient')

const storeWebviewBackgroundHeader = async ({
  url,
  start,
  end,
  defaultImage,
  created_by,
  updated_by = [],
}) => {
  try {
    if (defaultImage) {
      const [, { hasActiveDefaultImage }] = await fetchFindActiveDefaultHeaderImage()
      if (hasActiveDefaultImage) return Promise.reject('Error: has active default image')
    }
    const filename = `${Date.now()}-${url?.rawFile?.name}`
    const { data: uploadedData } = await client.storage.upload({
      params: {
        filename,
        bucket: 'webview-configs',
      },
      file: url?.rawFile,
    })

    const { data } = await client.getTable('webview_headers').insert({
      start,
      end,
      default: defaultImage,
      url: JSON.stringify({
        imageUrl: uploadedData.attributes.url.split('?')[0],
        filepath: filename,
        type: url?.rawFile?.type,
        bucket: 'webview-configs',
      }),
      created_by,
      updated_by,
    })

    return [null, data]
  } catch (error) {
    return [error, null]
  }
}
export default storeWebviewBackgroundHeader
