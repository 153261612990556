import { client } from '../../MbaasClient'

export const funcUpdateTransactions = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  try {
    let store_data = params.data.store_data

    /**
     * status:
     *  - 'skipped' (ada 'store_id' nya)
     *  - 'mapped' (ada 'prune_address' nya)
     *  - 'not found'
     */
    let status = 'not found'
    if (store_data.store_id) {
      status = 'skipped'
    } else if (store_data.pruned_address) {
      status = 'mapped'
    }

    const newStoreData = { ...store_data, status }

    await client.getTable('store_points').update({
      id: store_data.id,
      data: {
        store_id: store_data.store_id || null,
        pruned_address: store_data.store_address.replace(/[^a-zA-Z0-9_]+/gm, ''),
      },
    })

    const { data } = await client.getTable('transactions').update({
      id: params.id,
      data: { store_data: newStoreData },
    })

    return { data: data }
  } catch (e) {
    console.error(e)
    throw e
  }
}
