import dataProvider from '../provider/DataProvider'

export default async function onClick_deleteRowsAndUpdatePriority_Test(
  rows,
  check,
  isPriorityChange,
  pagination,
  setIsLoading
) {
  setIsLoading(true)
  // DELETE RECORDS AND UPDATE PRIORITY
  const ids = []
  Object.keys(check).forEach((v) => {
    if (check[v]) ids.push(v)
  })
  if (Object.keys(check).length > 0 && isPriorityChange) {
    await dataProvider.deleteMany('advertisements', { ids: [...ids] })
    const rowAfterDelete = rows.filter((v) => !ids.includes(v.id))
    if (rowAfterDelete.length > 0) {
      const index = (pagination.page - 1) * pagination.perPage
      await dataProvider.updateMany('advertisements', { data: [...rowAfterDelete], index })
    }
    // DELETE RECORDS
  } else if (Object.keys(check).length > 0) {
    await dataProvider.deleteMany('advertisements', { ids: [...ids] })
    // UPDATE PRIORITY
  } else if (isPriorityChange) {
    const index = (pagination.page - 1) * pagination.perPage
    await dataProvider.updateMany('advertisements', { data: [...rows], index })
  }

  window.location.reload()
}
