import reqDoublePoin from '@/src/requests/double-poin'

export async function isPromoDoublePoin(params, resource) {
  console.log(623495, { method: 'getList', resource: resource, params: params })

  const { page, perPage } = params.pagination
  let { field, order } = params.sort

  const { data, count } = await reqDoublePoin.getList({
    page,
    limit: perPage,
    orderBy: { [field]: order },
    filter: params.filter,
  })

  return { data: data, total: count }
}
