const { client } = require('./../provider/MbaasClient')

const storePricings = async ({
  organization_id,
  product_id,
  method,
  value,
  poin,
  available,
  reference,
  auto_update,
  channel_code,
  currency,
  country,
  purchase_limit = {
    period: 'daily',
    limit: 'unlimited',
  },
}) => {
  try {
    const { data } = await client.getTable('pricings').insert({
      organization_id,
      product_id,
      method,
      value,
      poin,
      available,
      reference,
      auto_update,
      channel_code,
      currency,
      country,
      purchase_limit,
    })
    return [null, data]
  } catch (error) {
    return [error, null]
  }
}

export default storePricings
