import { create } from 'zustand'

export const useCustomerId = create((set) => ({
  customer_data: {
    id: '',
    name: '',
  },
  updateCustData: (customer_data: any) =>
    set(() => ({ customer_data: { id: customer_data?.id, name: customer_data?.name } })),
}))
