export const paymentChannelDesc = {
  SEVENTW: '711',
  KKMART: 'KK',
  SPEEDMART: '99',
  HAPPYMART: 'HM',
  BIGC: 'BigC',
  TESCO: 'Tesco',
  BIMB: 'FPX',
  MB2U: 'FPX',
  PBB: 'FPX',
  CIMBCLICKS: 'FPX',
  AMOL: 'FPX',
  HLB: 'FPX',
  RHB: 'FPX',
  FPX_OCBC: 'FPX',
  FPX_SCB: 'FPX',
  FPX_ABB: 'FPX',
  BANKRAKYAT: 'FPX',
  BANKMUAMALAT: 'FPX',
  FPX_KFH: 'FPX',
  FPX_BSN: 'FPX',
  FPX_ABMB: 'FPX',
  FPX_UOB: 'FPX',
  FPX_HSBC: 'FPX',
  FPX: 'FPX',
  TNG: 'TnG',
  GRABPAY: 'GrabPay',
  SHOPEEPAY: 'ShopeePay',
  BOOST: 'BOOST',
  SG_SOCASH_CASH: 'Socash',
  NOPAY: 'NOPAY',
  IPAY88_BOOST: 'BOOST',
  IPAY88_BIMB: 'FPX',
  IPAY88_MB2U: 'FPX',
  IPAY88_PBOL: 'FPX',
  IPAY88_CIMBCLICK: 'FPX',
  IPAY88_AMOL: 'FPX',
  IPAY88_HLOL: 'FPX',
  IPAY88_RHBO: 'FPX',
  IPAY88_OCBC: 'FPX',
  IPAY88_SCB: 'FPX',
  IPAY88_AFOL: 'FPX',
  IPAY88_BRRB: 'FPX',
  IPAY88_BMMB: 'FPX',
  IPAY88_KFH: 'FPX',
  IPAY88_BSNO: 'FPX',
  IPAY88_ALOL: 'FPX',
  IPAY88_UOB: 'FPX',
  IPA88_HSBC: 'FPX',
  SG_ENETS: 'eNETS Debit',
}
