import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ReferenceField,
  FunctionField,
  TopToolbar,
  CreateButton,
} from 'react-admin'
import ReactJson from 'react-json-view'
import moment from 'moment'

const CustomActions = ({ basePath }) => (
  <TopToolbar>
    {/* <FilterButton /> */}
    <CreateButton basePath={basePath} />
    {/* <ExportButton /> */}
    {/* Add your custom actions */}
  </TopToolbar>
)

export default function CustomersList(props) {
  return (
    <List {...props} actions={<CustomActions />}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="country" />
        <DateField source="start" />
        <DateField source="end" />
        <FunctionField
          label="Config"
          render={({ config }) => {
            return (
              <ReactJson
                src={config || []}
                shouldCollapse={() => {}}
                name={false}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            )
          }}
        />
        <ReferenceField label="Created by" source="user_id" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Status"
          render={({ start, end }) => {
            let status = 'EXPIRED'
            if (moment(moment().toISOString()).isBetween(start, end)) {
              status = 'ACTIVE'
            } else if (moment(moment().toISOString()).isBefore(end)) {
              status = 'INACTIVE'
            }
            return status
          }}
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}
