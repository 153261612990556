import gql from 'graphql-tag'
const { client } = require('../../MbaasClient')

export async function isBlastSms(params, resource) {
  console.log(6234958776, { method: 'getList', resource: resource, params: params })
  const { page, perPage } = params.pagination
  let { order } = params.sort
  const { is_sent } = params.filter || {}

  let orderByBlastSms = order === 'DESC' ? `{ created_at: DESC }` : `{ created_at: ${order} }`

  const where = `{
        ${is_sent ? `is_sent: { _eq: "${is_sent}" }` : ''}
      }`

  const gqlResult = await client.gql.query({
    query: gql`query{
          allSmsBlastsList(
            where: ${where}
            orderBy: ${orderByBlastSms}
            offset: ${(page - 1) * perPage}
            limit: ${perPage}
          ){
            data{
              id
              created_at
              updated_at
              creator_id
              content
              target
              is_sent
            }
          }
          aggregateSmsBlasts(
            where: ${where}
          ){
            count
          }
        }`,
  })

  return {
    data: gqlResult?.allSmsBlastsList?.data || [],
    total: gqlResult?.aggregateSmsBlasts?.count || 0,
  }
}
