const { client } = require('../../MbaasClient')

export const funcCreateDenomPricings = async (resource, params) => {
  const { data } = await client.getTable('denom_pricings').insert({
    denom: params.data.denom,
    rrp: params.data.rrp,
    currency: `${params.data.currency_from}-${params.data.currency_to}`,
  })

  return { data: data }
}
