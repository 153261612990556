import React from 'react'
import {
  Toolbar,
  Button,
  useNotify,
  useRedirect,
  useDataProvider,
  useAuthProvider,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Button as MuiButton } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const SaveButton = ({ dataForm }) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const authP = useAuthProvider()

  const [isLoading, setIsLoading] = React.useState(false)

  let startIcon = <SaveIcon />
  if (isLoading) {
    startIcon = <CircularProgress size={20} />
  }

  const saveData = async () => {
    setIsLoading(true)
    delete dataForm.type
    await dataProvider
      .create('denom_pricings', {
        data: { ...dataForm, user_id: authP.id() },
      })
      .then((response) => {
        redirect('/denom_pricings')
        notify('denom_pricings created')
      })
      .catch((error) => {
        notify(`denom_pricings create error: ${error.message}`, 'warning')
      })
    setIsLoading(false)
  }

  return (
    <Button
      startIcon={startIcon}
      label="Save"
      variant="contained"
      disabled={
        !dataForm.currency_from || !dataForm.currency_to || !dataForm.denom || !dataForm.rrp
      }
      onClick={saveData}
    />
  )
}

export const CustomToolbar = ({ dataForm, onCancel, ...props }) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton dataForm={dataForm} />
      <MuiButton onClick={onCancel}>cancel</MuiButton>
    </Toolbar>
  )
}
