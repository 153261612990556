import gql from 'graphql-tag'
import { client } from '@/src/provider/MbaasClient'

export const getOne = async (id: any) => {
  const res = await client.gql.query({ query, variables: { id } })
  return {
    ...(res?.response || {}),
    items: {
      ...(res?.response?.items || {}),
      channel: res?.response?.items?.channel?.map((v: any) => ({ id: v, name: v })) || [],
      category: res?.response?.items?.category?.map((v: any) => ({ id: v, name: v })) || [],
      denomination: res?.response?.items?.denomination?.map((v: any) => ({ id: v, name: v })) || [],
      product_code: res?.response?.items?.product_code?.map((v: any) => ({ id: v, name: v })) || [],
      product_origin:
        res?.response?.items?.product_origin?.map((v: any) => ({ id: v, name: v })) || [],
    },
  }
}

const query = gql`
  query ($id: UUID!) {
    response: getPromoDoublePoinById(id: $id) {
      id
      user_id
      created_at
      updated_at
      end
      start
      country
      value
      configs
      items
      transactions_aggregate: transactions_promo_double_poin_id_list_aggregate {
        count
      }
    }
  }
`
