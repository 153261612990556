import englishMessages from 'ra-language-english'

export const en = {
  ...englishMessages,
  myroot: {
    valid_from: 'Valid From',
  },
  resources: {
    promo_double_poin: {
      fields: {
        start: 'Model33',
      },
    },
    shoe: {
      name: 'Shoe |||| Shoes',
      fields: {
        model: 'Model',
        stock: 'Nb in stock',
        color: 'Color',
      },
    },
    customer: {
      name: 'Customer |||| Customers',
      fields: {
        first_name: 'First name',
        last_name: 'Last name',
        dob: 'Date of birth',
      },
    },
  },
}
