import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin'
import ReactJson from 'react-json-view'

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  )
}

export const ProductPrefList = (props) => (
  <List {...props} bulkActionButtons={false} actions={<ListActions />} title="Product Preference">
    <Datagrid>
      <TextField source="name" />
      <FunctionField
        label="Layout"
        render={({ layout }) => (
          <div>
            <label>
              <ReactJson
                src={layout || []}
                shouldCollapse={() => {}}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                name={false}
              />
            </label>
          </div>
        )}
      />
      <TextField source="country" />
      <EditButton />
    </Datagrid>
  </List>
)
