import React from 'react'
import {
  DateTimeInput,
  SimpleForm,
  TextInput,
  Edit,
  required,
  SelectInput,
  useInput,
  useGetOne,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { client } from './../../provider/MbaasClient'
import productOrigin from './../list-product-origin'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextFieldMui from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import { RaNumberFormatInput } from './../../shared-components'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: 'auto',
    },
  },
}))

const EditTitle = ({ record }) => {
  return `Edit Flashsale: ${record.id}`
}

export const FlashsaleEdit = (props) => {
  const classes = useStyles()

  const { data } = useGetOne('flashsale', props.id)

  const [optionCategory, setOptionCategory] = React.useState([])
  const [optionProdCode, setOptionProdCode] = React.useState([])

  const [selectedProdOrigin, setSelectedProdOrigin] = React.useState([])
  const [selectedCategory, setSelectedCategory] = React.useState([])
  const [selectedProdCode, setSelectedProdCode] = React.useState([])

  const [firstRender, setFirstRender] = React.useState(true)

  React.useEffect(() => {
    let haloTimeout = setTimeout(() => {
      client
        .invokeApi('promo-flashsales-products', {
          product_origin: selectedProdOrigin?.map((v) => v.id), //[String],
          // product_origin: ['MYS'], //[String],
          // category: formData.category || ['ALL'], //[String],
          // product_code: '', //[String],
          // denomination: '', //Int,
          invoked: 'category', //String! product_origin, category, product_code, denomination
        })
        .then((v) => {
          if (v.length !== 0) {
            const category = v.data?.data?.map((v) => ({ id: v.category, name: v.category }))
            setOptionCategory(category)
            !firstRender && setSelectedCategory(category)
          }
        })
    }, 2000)

    return () => clearTimeout(haloTimeout)
  }, [selectedProdOrigin])

  React.useEffect(() => {
    let haloTimeout = setTimeout(() => {
      client
        .invokeApi('promo-flashsales-products', {
          product_origin: selectedProdOrigin?.map((v) => v.id), //[String],
          category: selectedCategory?.map((v) => v.id), //[String],
          // product_code: '', //[String],
          // denomination: '', //Int,
          invoked: 'product_code', //String! product_origin, category, product_code, denomination
        })
        .then((v) => {
          if (v.length !== 0) {
            const prodCode = v.data?.data?.map((v) => ({
              id: v.product_code,
              name: v.product_code,
            }))
            setOptionProdCode(prodCode)
            !firstRender && setSelectedProdCode(prodCode)
          }
        })
    }, 2000)

    return () => clearTimeout(haloTimeout)
  }, [selectedCategory, selectedProdOrigin])

  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm>
        <SelectInput
          label="Country"
          source="country"
          fullWidth
          validate={required()}
          choices={[
            // { id: 'ALL', name: 'All Country' },
            { id: 'MY', name: 'Malaysia' },
            { id: 'ID', name: 'Indonesia' },
          ]}
          disabled={data?.transactions_aggregate?.count >= 1}
        />
        <CustomAutocompleteProductOrigin
          label="Product Origin"
          source="product_origin"
          validate={required()}
          selectedProdOrigin={selectedProdOrigin}
          setSelectedProdOrigin={setSelectedProdOrigin}
          setSelectedCategory={setSelectedCategory}
          setSelectedProdCode={setSelectedProdCode}
          firstRender={firstRender}
          setFirstRender={setFirstRender}
          disabled={data?.transactions_aggregate?.count >= 1}
          //
        />
        <CustomAutocompleteCategory
          label="Category"
          source="category"
          validate={required()}
          option={optionCategory}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setSelectedProdCode={setSelectedProdCode}
          firstRender={firstRender}
          setFirstRender={setFirstRender}
          disabled={data?.transactions_aggregate?.count >= 1}
          //
        />
        <CustomAutocompleteProductCode
          label="Product Code"
          source="product_code"
          validate={required()}
          option={optionProdCode}
          selectedProdCode={selectedProdCode}
          setSelectedProdCode={setSelectedProdCode}
          firstRender={firstRender}
          setFirstRender={setFirstRender}
          disabled={data?.transactions_aggregate?.count >= 1}
          //
        />
        <RaNumberFormatInput
          disabled={data?.transactions_aggregate?.count >= 1}
          label="Denomination"
          source="denomination"
          fullWidth
          style={{ marginBottom: '28px' }}
        />
        <TextInput
          disabled={data?.transactions_aggregate?.count >= 1}
          label="Value"
          source="value"
          placeholder=""
          fullWidth
        />
        <div style={{ display: 'flex', width: '100%', margin: '6px 0' }}>
          <BooleanInput
            disabled={data?.transactions_aggregate?.count >= 1}
            fullWidth
            source="apply_max_usage_per_customer"
            label="Apply Cust. Max Usage?"
            title="Is Max Usage per Customer applied?"
            style={{ flexGrow: 1 }}
          />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => {
              if (formData.apply_max_usage_per_customer) {
                return (
                  <NumberInput
                    disabled={data?.transactions_aggregate?.count >= 1}
                    style={{ flexGrow: 1 }}
                    // fullWidth
                    className={classes.input}
                    source="max_usage_per_customer"
                    label="Max Usage per Customer"
                  />
                )
              }
            }}
          </FormDataConsumer>
        </div>
        <div style={{ display: 'flex', width: '100%', margin: '6px 0' }}>
          <BooleanInput
            disabled={data?.transactions_aggregate?.count >= 1}
            fullWidth
            source="new_customer_only"
            label="For New Customer / Target Only?"
            title="Customer / Target without success transactions before this promo is created"
            style={{ flexGrow: 1 }}
          />

          <BooleanInput
            disabled={data?.transactions_aggregate?.count >= 1}
            fullWidth
            source="specific_target"
            label="Is Specific Target?"
            title="Is it can only be used once per target?"
            style={{ flexGrow: 1 }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', margin: '6px 0' }}>
          <BooleanInput
            disabled={data?.transactions_aggregate?.count >= 1}
            style={{ flexGrow: 1 }}
            fullWidth
            source="specific_customer"
            label="Is for Specific Customer?"
            title="Is applied for Specific Customer?"
          />

          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => {
              if (formData?.specific_customer) {
                return (
                  <ReferenceInput
                    style={{ flexGrow: 1 }}
                    source="customer_id"
                    reference="customers"
                    fullWidth
                    className={classes.input}
                    sort={{ field: 'name', order: 'ASC' }}
                    label="Specific for"
                  >
                    <AutocompleteInput
                      disabled={data?.transactions_aggregate?.count >= 1}
                      source="name"
                      optionText={(choice) => `${choice?.name} - ${choice?.channel_id}`}
                    />
                  </ReferenceInput>
                )
              }
            }}
          </FormDataConsumer>
        </div>
        <RaNumberFormatInput
          disabled={data?.transactions_aggregate?.count >= 1}
          label="Total Usage"
          source="total_usage"
          fullWidth
          style={{ marginBottom: '28px' }}
        />
        <div style={{ width: '100%', display: 'flex', gap: 16 }}>
          <DateTimeInput
            disabled={data?.transactions_aggregate?.count >= 1}
            label="Valid From"
            source="start"
            fullWidth
            className={classes.input}
            validate={[required()]}
          />
          <DateTimeInput
            label="Valid Until"
            source="end"
            fullWidth
            className={classes.input}
            validate={[required()]}
          />
        </div>
      </SimpleForm>
    </Edit>
  )
}

const CustomAutocompleteProductCode = (props) => {
  const classes = useStyles()
  const [tes, setTes] = React.useState(true)
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const record = props.record[name]

  React.useEffect(() => {
    !props.firstRender && onChange(props.selectedProdCode)
  }, [props.firstRender, props.selectedProdCode])

  React.useEffect(() => {
    const tes = record?.map((v) => ({
      id: v,
      name: v,
    }))
    props.setSelectedProdCode(tes)
  }, [])

  return (
    <Autocomplete
      disabled={props.disabled}
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option}
      getOptionLabel={(option) => option.name}
      value={
        props.firstRender && tes
          ? props.option.filter((item) => record.includes(item.id))
          : props.selectedProdCode
      }
      style={{ marginBottom: '32px' }}
      onChange={(_, v) => {
        onChange(v)
        setTes(false)
        // props.setFirstRender(false)
        props.setSelectedProdCode(v)
      }}
      required={isRequired}
      PaperComponent={({ children }) => <Paper className={classes.test}>{children}</Paper>}
      renderInput={(params) => (
        <TextFieldMui
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select ${name.toLowerCase()}`}
          error={!!(touched && error)}
          helperText={touched && error && 'required'}
          {...rest}
        />
      )}
    />
  )
}

const CustomAutocompleteCategory = (props) => {
  const classes = useStyles()
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const record = props.record[name]

  React.useEffect(() => {
    const tes = record?.map((v) => ({
      id: v,
      name: v,
    }))
    props.setSelectedCategory(tes)
  }, [])

  React.useEffect(() => {
    !props.firstRender && onChange(props.selectedCategory)
  }, [props.firstRender, props.selectedCategory])

  return (
    <Autocomplete
      disabled={props.disabled}
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option}
      getOptionLabel={(option) => option.name}
      value={
        props.firstRender
          ? props.option.filter((item) => {
              return record.includes('ALL') ? true : record.includes(item.id)
            })
          : props.selectedCategory
      }
      style={{ marginBottom: '32px' }}
      onChange={(_, v) => {
        props.setFirstRender(false)
        props.setSelectedCategory(v)
        props.setSelectedProdCode([])
      }}
      required={isRequired}
      PaperComponent={({ children }) => <Paper className={classes.test}>{children}</Paper>}
      renderInput={(params) => (
        <TextFieldMui
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select ${name.toLowerCase()}`}
          error={!!(touched && error)}
          helperText={touched && error && 'required'}
          {...rest}
        />
      )}
    />
  )
}

const CustomAutocompleteProductOrigin = (props) => {
  const classes = useStyles()
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const record = props.record[name]

  React.useEffect(() => {
    !props.firstRender && onChange(props.selectedProdOrigin)
  }, [props.firstRender, props.selectedProdOrigin])

  React.useEffect(() => {
    const tes = productOrigin.filter((item) => record.includes(item.id))
    props.setSelectedProdOrigin(tes)
  }, [])

  return (
    <Autocomplete
      disabled={props.disabled}
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={productOrigin}
      getOptionLabel={(option) => option.name}
      value={props.selectedProdOrigin}
      style={{ marginBottom: '32px' }}
      onChange={(_, v) => {
        // onChange(v)
        props.setSelectedProdOrigin(v)
        props.setFirstRender(false)
        props.setSelectedCategory([])
        props.setSelectedProdCode([])
      }}
      required={isRequired}
      PaperComponent={({ children }) => <Paper className={classes.test}>{children}</Paper>}
      renderInput={(params) => (
        <TextFieldMui
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select ${name.toLowerCase()}`}
          error={!!(touched && error)}
          helperText={touched && error && 'required'}
          {...rest}
        />
      )}
    />
  )
}
