const { client, bucket, StorageURL } = require('../../MbaasClient')

export const funcCreateMessengerMsgSeries = async (resource, params) => {
  let { type, delay, content, imgUpload, start, end } = params.data
  if (type === 'image') {
    const uploadImage = await client.storage.upload({
      params: {
        filename: imgUpload.rawFile.name,
        bucket: bucket['advertisements'],
        expire: '60',
      },
      file: imgUpload.rawFile,
    })
    content = {
      url: `${StorageURL}/${bucket['advertisements']}/${uploadImage.data.id}`,
      image_id: uploadImage.data.id,
    }
  } else if (type === 'video') {
    content = {
      url: content.url,
      title: content.title,
      subtitle: content.subtitle,
    }
  } else if (type === 'text') {
    content = {
      text: content.text,
    }
  }

  const { data } = await client.getTable('messenger_msg_series').insert({
    type: type,
    delay: delay,
    content: content,
    start: start,
    end: end,
  })
  return { data: data }
}
