import React, { useState, cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  DateField,
  ReferenceField,
  ExportButton,
  downloadCSV,
  Confirm,
  FunctionField,
  useUpdate,
} from 'react-admin'
import jsonExport from 'jsonexport/dist'
import ReactJson from 'react-json-view'
import Button from '@material-ui/core/Button'
import SendIcon from '@material-ui/icons/Send'

const BlastSmsFilter = (props) => {
  return <Filter {...props}></Filter>
}

const DownloadMaster = async () => {
  jsonExport(
    [{}],
    {
      headers: ['target'],
    },
    (err, csv) => {
      downloadCSV(csv, 'target-list-template')
    }
  )
}

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton label="Target List template" exporter={DownloadMaster} />
    </TopToolbar>
  )
}

const ListTitle = () => {
  return <span>Blast SMS</span>
}

const ResendButton = (props) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const [update, { isLoading }] = useUpdate(
    'blast_sms',
    props.record.id,
    {
      is_sent: true,
    },
    props.record
  )

  const myFunction = () => {
    update()
    setOpen(false)
  }

  // -- disable resend button if all target is successfully sent
  const isButtonDisabled =
    props?.record?.content?.status?.success.length === props?.record?.target.length

  // -- disable resend button if all target is not_registered
  const isButtonDisabled2 =
    props?.record?.content?.status?.not_registered.length === props?.record?.target.length

  const isButtonDisabled3 = props?.record?.content?.status?.failed.length === 0

  return (
    <>
      <Button
        variant="contained"
        disabled={isLoading || isButtonDisabled || isButtonDisabled2 || isButtonDisabled3}
        onClick={handleClick}
        color="primary"
        startIcon={<SendIcon />}
      >
        Resend
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Important"
        content="By confirming this, it will automatically send SMS message to the target list, are you sure want to proceed?"
        onConfirm={myFunction}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default function PageList(props) {
  return (
    <List
      {...props}
      filters={<BlastSmsFilter />}
      actions={<ListActions />}
      title={<ListTitle />}
      sort={{ order: 'DESC' }}
    >
      <Datagrid>
        <DateField source="created_at" label="Created at" showTime />
        <TextField source="content.title" label="Title" />
        <TextField source="content.content" label="Content" />
        <ReferenceField source="creator_id" label="Creator" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Success Rate"
          render={(record) => {
            let success = record?.content?.status?.success.length
            let failed = record?.content?.status?.failed.length
            let notRegistered = record?.content?.status?.not_registered.length

            let totalTarget = failed + notRegistered + success

            if (success === undefined || isNaN(success)) success = 0

            if (totalTarget === undefined || isNaN(totalTarget)) totalTarget = 0

            return (
              <div>
                <label>{`${success}/${totalTarget}`}</label>
              </div>
            )
          }}
        />
        <FunctionField
          label="Details"
          render={(record) => (
            <div>
              <label>
                <ReactJson
                  src={record.content.status ? record.content.status : {}}
                  name={false}
                  shouldCollapse={() => {}}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
              </label>
            </div>
          )}
        />
        <ResendButton />
      </Datagrid>
    </List>
  )
}
