import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  DateField,
  TextField,
  NumberField,
  Filter,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  useRedirect,
  SearchInput,
} from 'react-admin'
import { Button as MuiButton } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import moment from 'moment'
import { DateRange } from '../DateRange'

export default function PageList(props) {
  return (
    <List
      {...props}
      filters={<CustomFilter />}
      actions={<CustomAction />}
      title="Add Poin Manually"
      bulkActionButtons={false}
    >
      <Datagrid>
        <DateField source="created_at" />
        <TextField source="code" label="Add-Code" />
        <TextField source="remark" label="Remark" />
        <NumberField source="credit" />
        <NumberField source="customer" />
        <CustomDetailsButton />
      </Datagrid>
    </List>
  )
}

const CustomFilter = (props) => {
  return (
    <Filter
      {...props}
      filterDefaultValues={{
        dateGte: moment().subtract(1, 'month').calendar(),
        dateLte: moment().format('YYYY/MM/DD'),
      }}
    >
      <DateRange alwaysOn />
      <SearchInput source="remark" />
    </Filter>
  )
}

const CustomAction = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  )
}

const CustomDetailsButton = ({ record }) => {
  const redirect = useRedirect()
  return (
    <MuiButton
      startIcon={<EditIcon />}
      color="primary"
      onClick={() => redirect(`/poin_add_manually/${record.id}`)}
    >
      Details
    </MuiButton>
  )
}
