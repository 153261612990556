import { client } from '../../MbaasClient'

export const funcUpdateKnowledgeBase = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  let updatedData = params.data
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  if (updatedData.create_category) delete updatedData.create_category
  if (updatedData.create_tag) delete updatedData.create_tag
  const { data } = await client.getTable(resource).update({
    id: params.id,
    data: updatedData,
  })

  return { data: data }
}
