const { moco, client } = require('../../MbaasClient')

export async function isLedgers(params, resource) {
  console.log(623495, { method: 'getList', resource: resource, params: params })

  const { page, perPage } = params.pagination
  let { field, order } = params.sort

  // adjust sorting params
  if (field === 'id') {
    field = 'created_at'
    order = 'DESC'
  }

  const result = await client
    .getTable(resource)
    .include(['deposit_logs', 'transaction_histories'])
    .sort(order === 'DESC' ? `-${field}` : field)
    .limit(perPage)
    .offset((page - 1) * perPage)
    .read()
  //
  const listOfTransactionId = result.data
    .filter((x) => x.transaction_histories)
    .map((x) => x.transaction_histories.transaction_id)
  //
  const fetchOrderId =
    listOfTransactionId.length > 0
      ? await client
          .getTable('transactions')
          .filter(moco.composeFilter().in('id', listOfTransactionId).toOData())
          .read()
      : []
  //
  const mappedTransactions = result.data.map((data) =>
    Object.assign({
      ...data,
      reference: data.transaction_histories
        ? fetchOrderId.data.find((x) => x.id === data.transaction_histories.transaction_id).order_id
        : data.deposit_logs.payment_id,
    })
  )

  return Object.assign({ ...result, data: mappedTransactions })
}
