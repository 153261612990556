const { client } = require('../../MbaasClient')

export const funcCreateBonuses = async (resource, params) => {
  const authUserId = (await client.user.get())?.data?.id
  delete params.data.user_id
  const tobeSavedData = {
    ...params.data,
    items: {
      ...params.data.items,
      product_origin: params.data.items.product_origin.map((ori) => ori.id),
      category: params.data.items.category.map((cat) => cat.id),
      product_code: params.data.items.product_code.map((prod) => prod.id),
      denomination: params.data.items.denomination.map((denom) => denom.id),
      channel: params.data.items.channel.map((cha) => cha.id),
    },
    configs: {
      ...params.data.configs,
      once_per_target: params.data.configs.once_per_target
        ? params.data.configs.once_per_target
        : false,
      new_customer_only: params.data.configs.new_customer_only
        ? params.data.configs.new_customer_only
        : false,
      specific_customer: params.data.configs.specific_customer
        ? params.data.configs.specific_customer
        : {
            status: false,
          },
      max_usage_per_customer: Number(params.data.configs.max_usage_per_customer),
      total_usage: Number(params.data.configs.total_usage),
    },
    author_id: authUserId,
  }
  const { data } = await client.getTable('bonuses').insert(tobeSavedData)
  return { data }
}
