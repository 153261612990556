import React from 'react'
import { Toolbar, Button, useNotify, useRedirect, useDataProvider } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { useFormState } from 'react-final-form'

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const SaveButton = ({ dataForm, option }) => {
  const formState = useFormState()
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()

  const [isLoading, setIsLoading] = React.useState(false)

  let startIcon = <SaveIcon />
  if (isLoading) {
    startIcon = <CircularProgress size={20} />
  }

  const saveData = async () => {
    setIsLoading(true)
    delete dataForm.type
    await dataProvider
      .update('prod_prefs', {
        id: dataForm.id,
        data: {
          ...dataForm,
          layout: option,
        },
      })
      .then((response) => {
        redirect(`/prod_prefs/${dataForm.id}`)
        notify('Product Edited')
      })
      .catch((error) => {
        notify(`Product Edit Error: ${error.message}`, 'warning')
      })
    setIsLoading(false)
  }

  return (
    <Button
      startIcon={startIcon}
      label="Save"
      variant="contained"
      disabled={!formState.valid}
      onClick={() => {
        saveData()
      }}
    />
  )
}

export const CustomToolbar = ({ dataForm, option, ...props }) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton dataForm={dataForm} option={option} />
      {/* <DeleteButton undoable={false} /> */}
    </Toolbar>
  )
}
