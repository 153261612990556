import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  useQuery,
  Loading,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import ExportButton from '../shared-components/export-button'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
    height: 350,
  },
  input: {
    marginBottom: '-20px',
  },
  text: {
    margin: 0,
  },
  detailsBox: {
    borderBottom: 'solid thin rgba(0, 0, 0, 0.12)',
    marginBottom: '20px',
  },
})

const PoinDetail = ({ record }) => {
  const classes = useStyles()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)

  const { data, total, loading, error } = useQuery({
    type: 'getList',
    resource: 'poin',
    payload: {
      pagination: { page, perPage },
      sort: { field: 'id', order: 'ASC' },
      filter: { customer_id: record.id },
    },
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangePerPage = (event) => {
    setPerPage(event.target.value)
    setPage(1)
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <p>ERROR: {error}</p>
  }

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Year Month</TableCell>
            <TableCell>Poin</TableCell>
            <TableCell>Consumed</TableCell>
            <TableCell>Expiry</TableCell>
            <TableCell>Remark</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((data) => (
            <TableRow key={data.id}>
              <TableCell>{data.year_month}</TableCell>
              <TableCell>{data.poin}</TableCell>
              <TableCell>{data.consumed}</TableCell>
              <TableCell>{moment(data.expiry).format('MM/DD/YYYY, hh:mm:ss A')}</TableCell>
              <TableCell>{data.remark}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 15, 20]}
        rowsPerPage={perPage}
        count={total}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangePerPage}
      />
    </>
  )
}

const PoinFilter = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="id" reference="customers" alwaysOn label="Customer">
        <AutocompleteInput source="name" />
      </ReferenceInput>
    </Filter>
  )
}

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton filter={filterValues} resourceType="POIN" />
    </TopToolbar>
  )
}

export const PoinList = (props) => {
  return (
    <List {...props} filters={<PoinFilter />} actions={<ListActions />}>
      <Datagrid rowClick="expand" expand={<PoinDetail />}>
        <ReferenceField source="id" reference="customers" label="customer">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="poin" />
        <NumberField source="consumed" />
      </Datagrid>
    </List>
  )
}
