export const schema = {
  type: 'object',
  properties: {
    country: { type: 'object', minProperties: 1 },
    default: { type: 'object', minProperties: 1 },
    additional_configuration: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          productOrigin: { type: 'array', minItems: 1 },
          category: { type: 'array', minItems: 1 },
          productCode: { type: 'array', minItems: 1 },
          denom: { type: 'array', minItems: 1 },
          paymentChannel: { type: 'array', minItems: 1 },
          paymentGatewayChannel: { type: 'object', minProperties: 1 },
        },
        required: [
          'productOrigin',
          'category',
          'productCode',
          'denom',
          'paymentChannel',
          'paymentGatewayChannel',
        ],
      },
    },
  },
  required: ['country', 'default', 'additional_configuration'],
  // additionalProperties: false,
}
