const { client, bucket } = require('../../MbaasClient')

export const funcCreateCSVPricing = async (resource, params) => {
  const file = params.data.files

  const convertFileToText = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsText(file.rawFile)

      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
    })

  const newFile = await convertFileToText(file)

  const uploadFile = await client.storage.upload({
    params: {
      filename: file.rawFile.name,
      bucket: bucket['documents'],
    },
    file: newFile,
  })

  const { data } = await client.invokeApi('update-pricings', {
    fileName: uploadFile.data.id,
  })

  return { data: data }
}
