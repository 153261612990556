import gql from 'graphql-tag'
const { client } = require('../../MbaasClient')

export async function isCoupons(params, resource) {
  console.log(623495, { method: 'getList', resource: resource, params: params })

  const { page, perPage } = params.pagination
  const { id, couponId } = params.filter || {}
  let { field, order } = params.sort

  let orderByCoupons = `{ coupon_id_data: { start: DESC } }`
  if (field === 'coupon_setting_id') {
    orderByCoupons = `{ coupon_id_data: { start: ${order} } }`
  }
  //
  const where = `{
        ${id ? `customer_id: { _eq: "${id}" }` : ''}
        ${couponId ? `coupon_setting_id: { _eq: "${couponId}" }` : ''}
      }`
  const gqlResult = await client.gql.query({
    query: gql`query{
          allCouponsList(
            where: ${where}
            orderBy: ${orderByCoupons}
            offset: ${(page - 1) * perPage}
            limit: ${perPage}
          ){
            data{
              id
              created_at
              updated_at
              customer_id
              coupon_setting_id
              coupon
            }
          }
          aggregateCoupons(
            where: ${where}
          ){
            count
          }
        }`,
  })

  return {
    data: gqlResult.allCouponsList.data,
    total: gqlResult.aggregateCoupons.count || 0,
  }
}
