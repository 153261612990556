import React from 'react'
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  DateTimeInput,
  required,
  AutocompleteInput,
  NumberInput,
  Toolbar,
  SaveButton,
  FileInput,
  FileField,
  TextInput,
  FormDataConsumer,
  CheckboxGroupInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { countryList, linkToList } from './../../constants'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  inputHidden: {
    display: 'none',
  },
  text: {
    color: '#0000008A',
    fontSize: '12px',
  },
  autocomplete1: {
    width: 200,
    display: 'inline-block',
    transform: 'translateY(-4px)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  button1: {
    marginRight: '10px',
  },
  w_300: {
    width: 300,
  },
}))

const CustomToolbars = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.invalid || props.error} />
    </Toolbar>
  )
}

// export
export default function PopupadsCreate(props) {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/popupads')
  }

  return (
    <Create {...props} onSuccess={onSuccess} title="Create Pop-Up Ads">
      <SimpleForm toolbar={<CustomToolbars />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FileInput
                source="url"
                label="Image / Video (mp4)"
                accept="video/mp4, image/*"
                validate={required()}
              >
                <FileField source="src" title="title" />
              </FileInput>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="h6"
                style={{ color: 'black', marginBottom: '10px', fontSize: '14px' }}
              >
                Apply Popup Ads For?
              </Typography>
              <CheckboxGroupInput
                source="user_type"
                label={false}
                choices={[
                  { id: 'GUEST', name: 'GUEST' },
                  { id: 'UNPAYING', name: 'NEW USER' },
                  { id: 'PAYING', name: 'PAYING' },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteInput
                source="description"
                label="Link to"
                choices={linkToList}
                className={classes.input}
                fullWidth
                validate={required()}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                fullWidth
                source="max_shown"
                label="Max. Shown / Customer"
                className={classes.input}
                validate={required()}
              />
            </Grid>

            <FormDataConsumer>
              {({ formData }) =>
                formData.description === 'custom_url' && (
                  <Grid item xs={12}>
                    <TextInput
                      fullWidth
                      source="ini_url"
                      label="Custom Url"
                      className={classes.input}
                      validate={required()}
                    />
                  </Grid>
                )
              }
            </FormDataConsumer>

            <Grid item xs={6}>
              <DateTimeInput
                fullWidth
                className={classes.input}
                source="start"
                label="Valid From"
                defaultValue={new Date()}
                parse={(v) => new Date(v)}
                validate={required()}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimeInput
                fullWidth
                className={classes.input}
                source="end"
                label="Valid Until"
                defaultValue={new Date()}
                parse={(v) => new Date(v)}
                validate={required()}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteInput
                fullWidth
                label="Country"
                source="country"
                className={classes.input}
                choices={countryList}
                validate={required()}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}
