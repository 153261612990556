import React from 'react'
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  DateInput,
  AutocompleteInput,
  CheckboxGroupInput,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { countryList } from './../../constants'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  inputHidden: {
    display: 'none',
  },
  text: {
    color: '#0000008A',
    fontSize: '12px',
  },
  autocomplete1: {
    width: 200,
    display: 'inline-block',
    transform: 'translateY(-4px)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  button1: {
    marginRight: '10px',
  },
  w_300: {
    width: 300,
  },
}))

// export
export default function AdvertisementCreate(props) {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/advertisements')
  }

  return (
    <Create {...props} onSuccess={onSuccess} title="Create Banner Ads">
      <SimpleForm>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ImageInput source="url" label="Image" accept="image/*">
                <ImageField source="src" title="title" />
              </ImageInput>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="h6"
                style={{ color: 'black', marginBottom: '10px', fontSize: '14px' }}
              >
                Apply Popup Ads For?
              </Typography>
              <CheckboxGroupInput
                source="user_type"
                label={false}
                choices={[
                  { id: 'GUEST', name: 'GUEST' },
                  { id: 'UNPAYING', name: 'NEW USER' },
                  { id: 'PAYING', name: 'PAYING' },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextInput source="description" />
            </Grid>
            <Grid item xs={12}>
              <DateInput
                label="Start Date"
                source="start"
                defaultValue={new Date()}
                parse={(v) => new Date(v)}
              />
              <DateInput
                label="End Date"
                source="end"
                defaultValue={new Date()}
                parse={(v) => new Date(v)}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteInput
                fullWidth
                label="Country"
                source="country"
                className={classes.input}
                choices={countryList}
              />
              <TextInput source="ads_type" className={classes.inputHidden} value="banner" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}
