import moment from 'moment'

const { client } = require('../../MbaasClient')

export const funcCreateBlacklistedTargets = async (resource, params) => {
  const duration = params.data.duration
  const end = moment().add(Number(duration), 'months').endOf('day').toISOString()
  const { data } = await client.getTable('blacklisted_targets').insert({
    target: params.data.target,
    status: true,
    start: moment().toISOString(),
    end: end,
  })

  return { data: data }
}
