import moment from 'moment'
import { fetchDataInitTable } from '../shared-script/fetchDataInitTable'
import defaultSortParams from '../shared-script/defaultSortParams'
const { moco, client } = require('../../MbaasClient')

export async function isPoinAddManually(params, resource) {
  const { page, perPage } = params.pagination
  const { dateGte, dateLte } = params.filter || {}
  let { field: sortField, order: sortOrder } = params.sort

  // adjust sorting params, default sort DESC
  const [field, order] = defaultSortParams(sortField, sortOrder)
  let filterObject = moco.composeFilter()
  let includedTables = []
  //poin list group by customer
  if (!params.filter.code) {
    const { data } = await client.invokeApi('credit-add-manually', {
      offset: (page - 1) * perPage,
      limit: perPage,
      filter: params.filter,
      field: field,
      order: order,
      dpAction: 'getList',
    })
    return data
  }

  if (params.filter.code) {
    filterObject = filterObject.eq('code', params.filter.code)
  }

  if (dateGte && dateLte) {
    filterObject = filterObject.and((x) =>
      x
        .ge('created_at', moment(dateGte).startOf('day').toISOString())
        .le('created_at', moment(dateLte).endOf('day').toISOString())
    )
  }

  if (filterObject !== '') filterObject = filterObject.toString()

  const [data, total] = await fetchDataInitTable(
    includedTables,
    resource,
    filterObject,
    order,
    field,
    page,
    perPage
  )

  return { data: data, total: total }
}
