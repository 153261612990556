import moment from 'moment'
import { fetchDataInitTable } from '../shared-script/fetchDataInitTable'
import defaultSortParams from '../shared-script/defaultSortParams'
const { moco } = require('../../MbaasClient')

export async function isDepositLogs(params, resource) {
  console.log(623495, { method: 'getList', resource: resource, params: params })
  const { status, user_id, payment_id, dateGte, dateLte } = params.filter || {}
  const { page, perPage } = params.pagination

  let { field: sortField, order: sortOrder } = params.sort

  // adjust sorting params, default sort DESC
  const [field, order] = defaultSortParams(sortField, sortOrder)

  let filterObject = moco.composeFilter()
  let includedTables = []

  switch (true) {
    case Boolean(status):
      filterObject = filterObject.eq('status', status)
      break
    case Boolean(user_id):
      filterObject = filterObject.eq('user_ID', user_id)
      break
    case Boolean(payment_id):
      filterObject = filterObject.contains((x) => x.toLower('payment_ID'), payment_id.toLowerCase())
      break
    default:
      console.log('default')
      break
  }

  if (dateGte && dateLte) {
    filterObject = filterObject.and((x) =>
      x
        .ge('created_at', moment(dateGte).startOf('day').toISOString())
        .le('created_at', moment(dateLte).endOf('day').toISOString())
    )
  }

  if (filterObject !== '') filterObject = filterObject.toString()

  const [data, total] = await fetchDataInitTable(
    includedTables,
    resource,
    filterObject,
    order,
    field,
    page,
    perPage
  )

  return { data: data, total: total }
}
