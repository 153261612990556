import { funcGetOneTransactions } from '@/src/provider/DataProvider/populate-getOne-resource/transactions'
import { funcGetOneTransactionMonitoringDashboard } from '@/src/provider/DataProvider/populate-getOne-resource/transaction-monitoring-dashboard'
import { funcGetOneGoNilaiTodayDashboard } from '@/src/provider/DataProvider/populate-getOne-resource/gonilai-today-dashboard'
import { funcGetOneSurveys } from '@/src/provider/DataProvider/populate-getOne-resource/surveys'
import { funcGetOneRedudancyPaymentGateway } from '@/src/provider/DataProvider/populate-getOne-resource/redundancy-payment-gateway'
import { funcGetOnePromotions } from '@/src/provider/DataProvider/populate-getOne-resource/promotions'
import { funcGetOnePromoDoublePoin } from '@/src/provider/DataProvider/populate-getOne-resource/promo-double-poin'
import { funcGetOnePricings } from '@/src/provider/DataProvider/populate-getOne-resource/pricings'
import { funcGetOnePoinAddManually } from '@/src/provider/DataProvider/populate-getOne-resource/poin-add-manually'
import { funcGetOneHeaderImage } from '@/src/provider/DataProvider/populate-getOne-resource/header-image'
import { funcGetOneFlashsale } from '@/src/provider/DataProvider/populate-getOne-resource/flashsale'
import { funcGetOneFailedButtons } from '@/src/provider/DataProvider/populate-getOne-resource/failed-buttons'
import { funcGetOneDormancyDashboard } from '@/src/provider/DataProvider/populate-getOne-resource/dormancy-dashboard'
import { funcGetOneDenomPricingsSetup } from '@/src/provider/DataProvider/populate-getOne-resource/denom-pricings-setup'
import { funcGetOneDenomPricings } from '@/src/provider/DataProvider/populate-getOne-resource/denom-pricings'
import { funcGetOneDefault } from '@/src/provider/DataProvider/populate-getOne-resource/default'
import { funcGetOneDashboard } from '@/src/provider/DataProvider/populate-getOne-resource/dashboard'
import { funcGetOneCltvDashboard } from '@/src/provider/DataProvider/populate-getOne-resource/cltv-dashboard'
import { funcGetOneBonuses } from '@/src/provider/DataProvider/populate-getOne-resource/bonuses'
import { funcGetOneBlastSms } from '@/src/provider/DataProvider/populate-getOne-resource/blast-sms'
import { funcGetOneAdvertisements } from '@/src/provider/DataProvider/populate-getOne-resource/advertisements'

const funcGetOne = async (resource, params) => {
  console.log('get-one', { resource, params })

  if (resource === 'promo_double_poin') {
    return await funcGetOnePromoDoublePoin(resource, params)
  }

  if (
    resource === 'advertisements' ||
    resource === 'popupads' ||
    resource === 'couponads' ||
    resource === 'runningText'
  ) {
    return await funcGetOneAdvertisements(resource, params)
  }

  if (resource === 'transactions') {
    return await funcGetOneTransactions(resource, params)
  }

  if (resource === 'pricings') {
    return await funcGetOnePricings(resource, params)
  }

  if (resource === 'dashboard') {
    return await funcGetOneDashboard(resource, params)
  }

  if (resource === 'cltv_dashboard') {
    return await funcGetOneCltvDashboard(resource, params)
  }

  if (resource === 'dormancy_dashboard') {
    return await funcGetOneDormancyDashboard(resource, params)
  }

  if (resource === 'transaction_monitoring_dashboard') {
    return await funcGetOneTransactionMonitoringDashboard(resource, params)
  }

  if (resource === 'gonilai_today_dashboard') {
    return await funcGetOneGoNilaiTodayDashboard(resource, params)
  }

  if (resource === 'poin_add_manually') {
    return await funcGetOnePoinAddManually(resource, params)
  }

  if (resource === 'failed_buttons') {
    return await funcGetOneFailedButtons(resource, params)
  }

  if (resource === 'blast_sms') {
    return await funcGetOneBlastSms(resource, params)
  }

  if (resource === 'surveys') {
    return await funcGetOneSurveys(resource, params)
  }

  if (resource === 'flashsale') {
    return await funcGetOneFlashsale(resource, params)
  }

  if (resource === 'promotions') {
    return await funcGetOnePromotions(resource, params)
  }

  if (resource === 'redundancy_payment_gateway') {
    return await funcGetOneRedudancyPaymentGateway(resource, params)
  }

  if (resource === 'header-image') {
    return await funcGetOneHeaderImage(resource, params)
  }

  if (resource === 'denom_pricings') {
    return await funcGetOneDenomPricings(resource, params)
  }

  if (resource === 'denom_pricings_setup') {
    return await funcGetOneDenomPricingsSetup(resource, params)
  }

  if (resource === 'bonuses') {
    return await funcGetOneBonuses(resource, params)
  }

  return await funcGetOneDefault(resource, params)
}

export default funcGetOne
