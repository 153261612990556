export const LIST_MAP_CHANNEL = [
  {
    id: 'PWA',
    label: 'PWA',
  },
  {
    id: 'FACEBOOK',
    label: 'Facebook',
  },
]
