import React from 'react'
import { Edit, FormWithRedirect } from 'react-admin'
import { FormInput } from '@/src/component/Bonuses/form-input'

const EditTitle = ({ record, pageName }: any): any => {
  return `Edit ${pageName}: ${record.id}`
}

export const BonusesEdit = (props: any) => {
  return (
    <Edit title={<EditTitle pageName="Bonus" />} {...props}>
      <FormWithRedirect
        mutators={{
          setValue: ([field, value]: any, state: any, { changeValue }: any) => {
            changeValue(state, field, () => value)
          },
        }}
        render={(renderProps) => {
          return <FormInput {...renderProps} />
        }}
      />
    </Edit>
  )
}
