import { fetchListPurchaseLimit } from '@/src/requests'

export async function isPurchaseLimit(params, resource) {
  console.log(623495, { method: 'getList', resource: resource, params: params })

  const [, data] = await fetchListPurchaseLimit({
    product_code: params?.filter?.product_code,
    country: params?.filter?.country,
    channel_code: params?.filter?.channel_code,
    order_by: {
      column: params?.sort.field === 'id' ? 'country' : params?.sort.field,
      order: params?.sort.order,
    },
    page: params?.pagination?.page,
    per_page: params?.pagination.perPage,
  })

  return {
    data:
      data?.rows?.map((row) => ({
        ...row,
        id: row.product_code + row.country + row.channel_code + JSON.stringify(row.purchase_limit),
      })) || [],
    total: data.count || 0,
  }
}
