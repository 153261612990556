const { client } = require('../../MbaasClient')

export const funcUpdateManyPoinAddManually = async (resource, params) => {
  delete params.data['created_at']
  delete params.data['updated_at']

  for (let i = 0; i < params.ids.length; i++) {
    await client.getTable(resource).update({
      id: params.ids[i],
      data: params.data,
    })
  }
}
