import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

const filter = createFilterOptions()

export default function CustomAutocompleteInput({
  limitTags = 5,
  options,
  disableCloseOnSelect = false,
  PaperComponent,
  value,
  onBlur,
  onChange,
  inputRef,
  variant = 'filled',
  label,
  size = 'small',
  multiple,
  error,
  helperText,
  style,
  disabled,
  ...props
}) {
  const [tes1, setSelectedOptions] = React.useState(multiple ? [] : {})

  React.useEffect(() => {
    let v1 = multiple ? [] : {}
    if (value) v1 = value
    setSelectedOptions(v1)
  }, [JSON.stringify(value)])

  return (
    <Autocomplete
      limitTags={limitTags}
      multiple={multiple}
      options={options}
      getOptionLabel={(option) => {
        return option.name
      }}
      getOptionSelected={(option, value) => {
        return option.id === value.id
      }}
      disableCloseOnSelect={disableCloseOnSelect}
      PaperComponent={PaperComponent}
      value={tes1}
      onBlur={onBlur}
      onChange={(event, selectedOptions, reason) => {
        if (multiple) {
          if (reason === 'select-option' || reason === 'remove-option') {
            if (selectedOptions.find((option) => option.id === 'ALL')) {
              let result = options.filter((el) => el.id !== 'ALL')
              return onChange(event, result, reason)
            } else {
              return onChange(event, selectedOptions, reason)
            }
          } else if (reason === 'clear') {
            return onChange(event, selectedOptions, reason)
          } else {
            return onChange(event, selectedOptions, reason)
          }
        } else {
          return onChange(event, selectedOptions, reason)
        }
      }}
      style={style}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        if (multiple) {
          return [{ id: 'ALL', name: 'Select All' }, ...filtered]
        }
        return filtered
      }}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          size={size}
          error={error}
          helperText={helperText}
          inputRef={inputRef}
          {...props}
        />
      )}
    />
  )
}
