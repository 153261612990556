import { cacheDataProviderProxy } from 'react-admin'
import getList from './func-get-list'
import getOne from './func-get-one'
import update from './func-update'
import getMany from './func-get-many'
import getManyReference from './func-get-many-reference'
import updateMany from './func-update-many'
import create from './func-create'
import delete_ from './func-delete'
import deleteMany from './func-delete-many'

export default cacheDataProviderProxy({
  getList,
  //@ts-ignore
  getOne,
  //@ts-ignore
  getMany,
  //@ts-ignore
  getManyReference,
  //@ts-ignore
  update,
  updateMany,
  create,
  //@ts-ignore
  delete: delete_,
  deleteMany,
})
