const { client } = require('../../MbaasClient')

export const funcGetOnePoinAddManually = async (resource, params) => {
  const { data } = await client.invokeApi('credit-add-manually', {
    code: params.id,
    dpAction: 'getOne',
  })

  console.log(79312837123, 'data', data)

  return { data }
}
