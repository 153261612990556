import React from 'react'
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  DateInput,
  AutocompleteInput,
  ReferenceInput,
  required,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { countryList } from './../../constants'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  inputHidden: {
    display: 'none',
  },
}))

// export
export default function AdvertisementCreate(props) {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/couponads')
  }

  return (
    <Create {...props} onSuccess={onSuccess} title="Create Banner Ads">
      <SimpleForm>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ImageInput source="url" label="Image" accept="image/*" validate={required()}>
                <ImageField source="src" title="title" />
              </ImageInput>
            </Grid>
            <Grid item xs={12}>
              <RichTextInput source="description" validate={required()} />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', gap: 16 }}>
              <DateInput
                label="Start Date"
                source="start"
                defaultValue={new Date()}
                parse={(v) => new Date(v)}
                fullWidth
                validate={required()}
              />
              <DateInput
                label="End Date"
                source="end"
                defaultValue={new Date()}
                parse={(v) => new Date(v)}
                fullWidth
                validate={required()}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteInput
                fullWidth
                label="Country"
                source="country"
                className={classes.input}
                choices={countryList}
                validate={required()}
              />
              <TextInput
                source="ads_type"
                className={classes.inputHidden}
                value="coupon"
                // validate={required()}
              />
            </Grid>
            <Grid item xs={12}>
              <ReferenceInput
                fullWidth
                label="Link Coupon"
                source="coupon_setting_id"
                reference="coupon_settings"
                validate={required()}
              >
                <AutocompleteInput fullWidth optionText="name" validate={required()} />
              </ReferenceInput>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}
