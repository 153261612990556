import gql from 'graphql-tag'

const { client } = require('../../MbaasClient')

export const funcGetOneBonuses = async (resource, params) => {
  const { id: bonus_id } = params
  const { getBonusesById: data } = await client.gql.query({
    query: gql`
      query ($id: UUID) {
        getBonusesById(id: $id) {
          id
          created_at
          updated_at
          author_id_data {
            name
          }
          start
          end
          country
          items
          configs
        }
      }
    `,
    variables: {
      id: bonus_id,
    },
  })
  const returnedData = {
    ...data,
    items_main: {
      category: data.items.category,
      product_origin: data.items.product_origin,
      product_code: data.items.product_code,
      denomination: data.items.denomination,
    },
    items: {
      ...data.items,
      category: data.items.category.map((cat) => ({ id: cat, name: cat })),
      product_origin: data.items.product_origin.map((prod) => ({ id: prod, name: prod })),
      product_code: data.items.product_code.map((prod) => ({ id: prod, name: prod })),
      denomination: data.items.denomination.map((denom) => ({ id: denom, name: denom })),
      channel: data.items.channel.map((cha) => ({ id: cha, name: cha })),
    },
  }
  return {
    data: returnedData,
  }
}
