import React from 'react'
import {
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  Edit,
  DateInput,
  AutocompleteInput,
  FormDataConsumer,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { makeStyles } from '@material-ui/core/styles'
import { CustomToolbar } from '../CustomToolbarAdvertisements'
import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { countryList } from './../../constants'
import CheckboxLabels from './CheckBox'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  inputHidden: {
    display: 'none',
  },
  text: {
    color: '#0000008A',
    fontSize: '12px',
  },
  autocomplete1: {
    width: 200,
    display: 'inline-block',
    transform: 'translateY(-4px)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  button1: {
    marginRight: '10px',
  },
  w_300: {
    width: 300,
  },
}))

export default function AdvertisementEdit(props) {
  const { source, ...rest } = props
  const classes = useStyles()
  const [dataForm, setDataForm] = React.useState({})

  const AdvertisementTitle = ({ record }) => {
    return <span>Edit Banner Ads: {record ? `"${record.id}"` : ''}</span>
  }

  const PreviousImage = ({ record }) => {
    return <img src={record.url} alt="Previous IMG" width="auto" />
  }

  return (
    <Edit title={<AdvertisementTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar dataForm={dataForm} />}>
        <TextInput source="id" disabled fullWidth />
        <p className={classes.text}>Previous Image</p>
        <PreviousImage />
        <ImageInput source="url" label="Image" accept="image/*">
          <ImageField source="src" />
        </ImageInput>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="h6"
            style={{ color: 'black', marginBottom: '10px', fontSize: '14px' }}
          >
            Apply Popup Ads For?
          </Typography>
          <div>
            <CheckboxLabels type="checkbox" source="user_type" {...rest} />
          </div>
        </Grid>

        <RichTextInput source="description" />
        <DateInput label="Start Date" source="start" parse={(v) => new Date(v)} />
        <DateInput label="End Date" source="end" parse={(v) => new Date(v)} />
        <TextInput source="ads_type" className={classes.inputHidden} />
        <AutocompleteInput
          fullWidth
          label="Country"
          source="country"
          className={classes.input}
          choices={countryList}
        />
        <FormDataConsumer>
          {({ formData }) => {
            setDataForm(formData)
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}
