import React from 'react'
import { Toolbar, SaveButton, DeleteButton } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
)
