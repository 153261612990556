import React, { Suspense, lazy } from 'react'
import { Admin, Resource, Layout, getResources, defaultTheme } from 'react-admin'
import { UserList, UserCreate, UserEdit } from './component/Users'
import { CustomerList, CustomerEdit } from './component/Customers'
import { LocaleList, LocaleCreate, LocaleEdit } from './component/Locales'
import {
  MessengerI18nList,
  MessengerI18nCreate,
  MessengerI18nEdit,
} from './component/MessengerI18n'
import { OrganizationList, OrganizationCreate, OrganizationEdit } from './component/Organizations'
import { KycLevelList, KycLevelCreate, KycLevelEdit } from './component/KycLevels'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import authProvider from './provider/AuthProvider'
import DataProvider from './provider/DataProvider'

import { SocialMediaList, SocialMediaCreate, SocialMediaEdit } from './component/SocialMedia'
import { PoinList } from './component/Poins'
import { ProductList, ProductCreate, ProductEdit } from './component/product'
import { PromotionCreate, PromotionEdit } from './component/promotion-code'
import { PricingList, PricingCreate, PricingEdit } from './component/pricings'
import { DepositLogsList } from './component/DepositLogs'
import { LogsList } from './component/Logs'
import { LedgersList } from './component/Ledgers'
import { DownloadList } from './component/Downloads'
import { DocumentList, DocumentCreate, DocumentEdit } from './component/Documents'
import Transactions from './component/Transactions'
import { ReferenceSnapshotList } from './component/ReferenceSnapshots'
import { ProfileEdit } from './component/ProfileEdit'
import {
  PageList as AdvertisementList,
  PageCreate as AdvertisementCreate,
  PageEdit as AdvertisementEdit,
} from './component/Advertisements'
import {
  PageList as PopupadsList,
  PageCreate as PopupadsCreate,
  PageEdit as PopupadsEdit,
} from './component/PopupAds'
import {
  PageList as CouponAdsList,
  PageCreate as CouponAdsCreate,
  PageEdit as CouponAdsEdit,
} from './component/CouponAds'
import {
  PageList as RunningTextList,
  PageCreate as RunningTextCreate,
  PageEdit as RunningTextEdit,
} from './component/runningText'
import { PageList as DenomLookupListSetup } from './component/DenomLookupSetup'
import {
  MessengerMsgSeriesList,
  MessengerMsgSeriesCreate,
  MessengerMsgSeriesEdit,
} from './component/MessengerMsgSeries'
import { StorePointList, StorePointCreate, StorePointEdit } from './component/StorePoints'
import { PoinLedgersList } from './component/PoinLedgers'
import { SurveysList, SurveysCreate, SurveysEdit } from './component/surveys'
import {
  PageList as WebviewBackgroundHeaderList,
  PageCreate as WebviewBackgroundHeaderCreate,
  PageEdit as WebviewBackgroundHeaderEdit,
} from './component/webview-background-header'

// PAGE PRODUCTS -> FAILED BUTTONS
import ProductCategoriesList from './component/Products/Categories/PageList'
import ProductCategoriesEdit from './component/Products/Categories/PageEdit'

// PAGE POIN -> ADD MANUALLY
import PoinAddManuallyList from './component/PoinAddManually/PageList'
import PoinAddManuallyCreate from './component/PoinAddManually/PageCreate'
import PoinAddManuallyEdit from './component/PoinAddManually/PageEdit'

// PAGE FLASHSALE
import { FlashsaleCreate } from './component/Flashsale/PageCreate'
import { FlashsaleEdit } from './component/Flashsale/PageEdit'

// PAGE DENOM LOOKUP PRICING
import { FlashsaleCreate as FlashsaleCreate3 } from './component/DenomLookup/PageCreate'
import { FlashsaleEdit as FlashsaleEdit3 } from './component/DenomLookup/PageEdit'

// PAGE WALLET
import { PageList as WalletList } from './component/wallet'

import * as pageDoublePoin from './component/doule-poin'

import { PageList, PageCreate, PageUpdate } from './component/redundancy-payment-gateway'

import { PageList as PurchaseLimitList } from './component/purchase-limit'
import { KBaseCreate, KBaseEdit, KBaseList } from './component/KnowledgeBase'
import { BlastSmsCreate, BlastSmsEdit, BlastSmsList } from './component/BlastSms'
import { BlacklistedTargetsCreate, BlacklistedTargetsEdit } from './component/BlacklistedTargets'

import { ProductPrefList, ProductPrefCreate, ProductPrefEdit } from './component/ProductPreference'

import { BonusesList } from './component/Bonuses/List'
import { BonusesEdit } from './component/Bonuses/Edit'
import { BonusesCreate } from './component/Bonuses/Create'

import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import RedeemIcon from '@material-ui/icons/Redeem'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import CheckIcon from '@material-ui/icons/Check'
import ViewListIcon from '@material-ui/icons/ViewList'
import PublicIcon from '@material-ui/icons/Public'
import SendIcon from '@material-ui/icons/Send'
import StorefrontIcon from '@material-ui/icons/Storefront'
import ReferralBenefitIcon from '@material-ui/icons/GroupAdd'
import PoinLedgersIcon from '@material-ui/icons/Beenhere'
import CouponSettingsIcon from '@material-ui/icons/SettingsApplications'
import CouponsIcon from '@material-ui/icons/ConfirmationNumber'
import PopupadsIcon from '@material-ui/icons/ViewCarousel'
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate'
import PollIcon from '@material-ui/icons/Poll'
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto'
import TimerIcon from '@material-ui/icons/Timer'
import KBaseIcon from '@material-ui/icons/School'

import Menu from './Menu/Menu'
import MyAppbar from './Menu/MyAppbar'

import LoadingIndicator from './component/LoadingIndicator'
import LoginPage from './component/login-page'
import { i18nProvider } from './i18n/provider'

const FlashsaleList = lazy(() => import('./component/Flashsale/PageList'))
const DenomLookupList = lazy(() => import('./component/DenomLookup/PageList'))
const PromotionList = lazy(() => import('./component/Promotions'))
const PlnRatesList = lazy(() => import('./component/PlnRates'))
const ReferralBenefitsList = lazy(() => import('./component/ReferralBenefits'))
const CouponSettingsList = lazy(() => import('./component/CouponSettings'))
const CouponList = lazy(() => import('./component/Coupons'))
const Dashboard = lazy(() => import('./component/dashboard'))
const Dormancy_Dashboard = lazy(() => import('./component/dormancy_dashboard'))
const CLTV_Dashboard = lazy(() => import('./component/cltv_dashboard'))
const Transaction_Monitoring_Dashboard = lazy(() =>
  import('./component/transaction_monitoring_dashboard')
)
const Gonilai_Today_Dashboard = lazy(() => import('./component/gonilai_today_dashboard'))
const HeatMap = lazy(() => import('./component/heat-map/index.js'))
const BlacklistedTargetsList = lazy(() => import('./component/BlacklistedTargets'))
const GoNilaiDraw = lazy(() => import('./component/GoNilaiDraw/GoNilai-Draw'))

const MyMenu = withRouter(
  connect((state) => ({
    resources: getResources(state),
  }))(Menu)
)

const theme = {
  ...defaultTheme,
  sidebar: {
    width: 275,
  },
}

const MyLayout = (props) => <Layout {...props} menu={MyMenu} appBar={MyAppbar} />

const App = () => {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Admin
        theme={theme}
        authProvider={authProvider}
        dataProvider={DataProvider}
        i18nProvider={i18nProvider}
        layout={MyLayout}
        loginPage={LoginPage}
        customRoutes={[
          <Route key="my-profile" path="/my-profile" component={ProfileEdit} />,
          <Route
            key="dormancy_rate_dashboard"
            exact
            path="/dormancy_rate_dashboard"
            component={Dormancy_Dashboard}
          />,
          <Route
            key="cltv_reactivation_dashboard"
            exact
            path="/cltv_reactivation_dashboard"
            component={CLTV_Dashboard}
          />,
          <Route
            key="transaction_monitoring_dashboard"
            exact
            path="/transaction_monitoring_dashboard"
            component={Transaction_Monitoring_Dashboard}
          />,
          <Route
            key="gonilai_today_dashboard"
            exact
            path="/gonilai_today_dashboard"
            component={Gonilai_Today_Dashboard}
          />,
          <Route
            key="store_profit_dashboard"
            exact
            path="/store_profit_dashboard"
            component={Dashboard}
          />,
          <Route exact path="/gonilai_draw" component={GoNilaiDraw} />,
          <Route key="heat_map" exact path="/heat_map" component={HeatMap} />,
        ]}
      >
        {/* ACCOUNT */}
        <Resource
          name="users"
          list={UserList}
          edit={UserEdit}
          create={UserCreate}
          options={{ menu: 'account' }}
        />
        <Resource
          name="kyc_levels"
          list={KycLevelList}
          create={KycLevelCreate}
          edit={KycLevelEdit}
          options={{ menu: 'account' }}
        />
        <Resource
          name="customers"
          list={CustomerList}
          edit={CustomerEdit}
          options={{ menu: 'account' }}
        />
        {/* PRODUCTS */}
        <Resource
          name="organizations"
          list={OrganizationList}
          create={OrganizationCreate}
          edit={OrganizationEdit}
          options={{ menu: 'products' }}
        />
        <Resource
          name="products"
          list={ProductList}
          create={ProductCreate}
          edit={ProductEdit}
          options={{ menu: 'products' }}
        />
        <Resource
          name="pricings"
          list={PricingList}
          create={PricingCreate}
          edit={PricingEdit}
          options={{ menu: 'products' }}
        />
        <Resource
          name="reference_snapshots"
          list={ReferenceSnapshotList}
          options={{ menu: 'products', label: 'references snapshots' }}
        />
        <Resource name="pln_rates" list={PlnRatesList} options={{ menu: 'products' }} />
        <Resource
          name="failed_buttons"
          list={ProductCategoriesList}
          edit={ProductCategoriesEdit}
          options={{ menu: 'products' }}
        />
        <Resource
          name="denom_pricings"
          list={DenomLookupList}
          create={FlashsaleCreate3}
          edit={FlashsaleEdit3}
          options={{ menu: 'products' }}
        />
        <Resource
          name="denom_pricings_setup"
          list={DenomLookupListSetup}
          options={{ menu: 'products' }}
        />
        <Resource
          name="blast_sms"
          list={BlastSmsList}
          create={BlastSmsCreate}
          edit={BlastSmsEdit}
          options={{ menu: 'utilities' }}
        />
        <Resource name="downloads" list={DownloadList} options={{ menu: 'utilities' }} />
        <Resource
          name="documents"
          list={DocumentList}
          create={DocumentCreate}
          edit={DocumentEdit}
          options={{ menu: 'utilities' }}
        />
        <Resource
          name="locales"
          list={LocaleList}
          create={LocaleCreate}
          edit={LocaleEdit}
          options={{ menu: 'utilities' }}
          label="Gonilai"
        />
        <Resource
          name="messenger_i18n"
          list={MessengerI18nList}
          create={MessengerI18nCreate}
          edit={MessengerI18nEdit}
          options={{ menu: 'utilities' }}
          label="FB Messanger"
        />
        <Resource
          name="header-image"
          list={WebviewBackgroundHeaderList}
          create={WebviewBackgroundHeaderCreate}
          edit={WebviewBackgroundHeaderEdit}
          options={{ menu: 'utilities' }}
        />
        <Resource
          name="prod_prefs"
          list={ProductPrefList}
          create={ProductPrefCreate}
          edit={ProductPrefEdit}
          options={{ menu: 'utilities' }}
          label="Product Preference"
        />
        <Resource
          name="blacklisted_targets"
          list={BlacklistedTargetsList}
          create={BlacklistedTargetsCreate}
          edit={BlacklistedTargetsEdit}
          options={{ menu: 'utilities' }}
        />

        <Resource
          name="logs"
          list={LogsList}
          options={{ label: 'Activity Logs' }}
          icon={ViewListIcon}
        />
        <Resource name="poin" list={PoinList} icon={RedeemIcon} options={{ menu: 'poin' }} />
        <Resource
          name="poin_ledgers"
          list={PoinLedgersList}
          icon={PoinLedgersIcon}
          options={{ menu: 'poin' }}
        />
        <Resource
          name="poin_add_manually"
          list={PoinAddManuallyList}
          create={PoinAddManuallyCreate}
          edit={PoinAddManuallyEdit}
          options={{ menu: 'poin' }}
        />
        <Resource
          name="deposit_logs"
          list={DepositLogsList}
          icon={AccountBalanceWalletIcon}
          options={{ label: 'Deposit Logs' }}
        />
        <Resource
          name="ledgers"
          list={LedgersList}
          icon={CheckIcon}
          options={{ label: 'Ledgers' }}
        />
        <Resource
          name="coupon_settings"
          list={CouponSettingsList}
          icon={CouponSettingsIcon}
          options={{ menu: 'coupon' }}
        />
        <Resource
          name="coupons"
          list={CouponList}
          icon={CouponsIcon}
          options={{ menu: 'coupon' }}
        />
        <Resource
          name="social_media"
          list={SocialMediaList}
          create={SocialMediaCreate}
          edit={SocialMediaEdit}
          icon={PhoneAndroidIcon}
          options={{ label: 'Social Media' }}
        />
        <Resource
          name="transactions"
          list={Transactions}
          icon={ViewListIcon}
          options={{ label: 'Transaction' }}
        />
        <Resource
          name="advertisements"
          list={AdvertisementList}
          create={AdvertisementCreate}
          edit={AdvertisementEdit}
          icon={PublicIcon}
          options={{ menu: 'advertisements' }}
        />
        <Resource
          name="popupads"
          list={PopupadsList}
          create={PopupadsCreate}
          edit={PopupadsEdit}
          icon={PopupadsIcon}
          options={{ menu: 'advertisements' }}
        />
        <Resource
          name="couponads"
          list={CouponAdsList}
          create={CouponAdsCreate}
          edit={CouponAdsEdit}
          icon={PublicIcon}
          options={{ menu: 'advertisements' }}
        />
        <Resource
          name="runningText"
          list={RunningTextList}
          create={RunningTextCreate}
          edit={RunningTextEdit}
          icon={PublicIcon}
          options={{ menu: 'advertisements' }}
        />
        <Resource name="wallets" list={WalletList} options={{ menu: 'poin' }} />
        <Resource
          name="promotions"
          list={PromotionList}
          create={PromotionCreate}
          edit={PromotionEdit}
          options={{ menu: 'products' }}
        />
        <Resource
          name="referral_benefits"
          list={ReferralBenefitsList}
          icon={ReferralBenefitIcon}
          options={{ menu: 'promotions' }}
        />
        <Resource
          name="flashsale"
          list={FlashsaleList}
          create={FlashsaleCreate}
          edit={FlashsaleEdit}
          icon={ViewListIcon}
          options={{ menu: 'promotions' }}
        />
        <Resource
          name="promo_double_poin"
          {...pageDoublePoin}
          icon={ControlPointDuplicateIcon}
          options={{ menu: 'poin' }}
        />
        <Resource
          name="messenger_msg_series"
          list={MessengerMsgSeriesList}
          create={MessengerMsgSeriesCreate}
          edit={MessengerMsgSeriesEdit}
          icon={SendIcon}
          options={{ label: 'Welcome Messages' }}
        />
        <Resource
          name="store_points"
          list={StorePointList}
          create={StorePointCreate}
          edit={StorePointEdit}
          icon={StorefrontIcon}
          options={{ label: 'Convenience Stores' }}
        />
        <Resource
          name="surveys"
          list={SurveysList}
          create={SurveysCreate}
          edit={SurveysEdit}
          icon={PollIcon}
          options={{ label: 'Survey' }}
        />
        <Resource
          name="redundancy_payment_gateway"
          list={PageList}
          create={PageCreate}
          edit={PageUpdate}
          icon={AssistantPhotoIcon}
          options={{ label: 'Redundancy Payment Gateway' }}
        />
        <Resource
          name="purchase-limit"
          list={PurchaseLimitList}
          icon={TimerIcon}
          options={{ menu: 'products' }}
          // options={{ label: 'Purchase Limit' }}
        />
        <Resource
          name="knowledge_base"
          list={KBaseList}
          create={KBaseCreate}
          edit={KBaseEdit}
          icon={KBaseIcon}
          options={{ label: 'Knowledge Base' }}
        />
        <Resource
          name="bonuses"
          list={BonusesList}
          edit={BonusesEdit}
          create={BonusesCreate}
          options={{ menu: 'promotions' }}
        />
        <Resource name="customers_list_data" options={{ menu: 'customers_list_data' }} />
      </Admin>
    </Suspense>
  )
}

export default App
