const { client } = require('./MbaasClient')
const _ = require('lodash')

let id = null
let email = null
let activeRole = null

const authProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const { data, error } = await client.auth.login('local', {
      email: username,
      password: password,
    })
    // check user roles
    const allowedRoles = ['Administrator', 'Ads_admin']
    const intersectArrays = _.intersection(allowedRoles, data.roles || [])
    //
    if (!error && intersectArrays.length > 0) {
      localStorage.setItem('username', username)
      // accept all username/password combinations
      return Promise.resolve()
    } else {
      return Promise.reject()
    }
  },
  // called when the user clicks on the logout button
  logout: async () => {
    client.user.logout()
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: async () => {
    const { error } = await client.user.get()
    if (error && (error.status === 401 || error.status === 403)) {
      localStorage.removeItem('username')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    const { data, error } = await client.user.get()
    const isLoggedIn = client.user.isLoggedIn
    // check user roles
    const allowedRoles = ['Administrator', 'Ads_admin']
    const intersectArrays = _.intersection(allowedRoles, data.roles || [])
    //
    return isLoggedIn && intersectArrays.length > 0 && !error ? Promise.resolve() : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => {
    const { data } = await client.user.get()
    id = data.id
    email = data.email
    activeRole = data.roles[0]
    return data ? Promise.resolve(data.roles) : Promise.reject()
  },
  id: () => id,
  email: () => email,
  activeRole: () => activeRole,
}
export default authProvider
