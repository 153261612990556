const { client } = require('../../MbaasClient')

export const funcGetOneRedudancyPaymentGateway = async (resource, params) => {
  const { data } = await client.getTable('pg_configurations').lookup(params.id)
  return {
    data: {
      ...data,
    },
  }
}
