import React from 'react'
import { useAuthProvider, useRedirect, useNotify } from 'react-admin'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Button as MuiButton,
  Paper,
  TextField as MuiTextField,
  CircularProgress,
} from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { Add as AddIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'
import dataProvider from '../../provider/DataProvider'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

export default function PageCreate(props) {
  const classes = useStyles()
  const redirect = useRedirect()
  const authP = useAuthProvider()
  const history = useHistory()
  const notify = useNotify()

  const [code, setCode] = React.useState('')
  const [remark, setRemark] = React.useState('')
  const [poin, setPoin] = React.useState(0)
  const [credit, setCredit] = React.useState(0)
  const [addCust, setAddCust] = React.useState(false)
  const [loyaltyType, setLoyaltyType] = React.useState('POIN')
  const [custsAll, setCustsAll] = React.useState([])
  const [custToBeAdded, setCustToBeAdded] = React.useState()
  const [isLoading, setIsLoading] = React.useState(false)
  const [creditType, setCreditType] = React.useState('')
  const [searchTerm, setSearchTerm] = React.useState('')

  React.useEffect(() => {
    const getCustList = async () => {
      const CustsData = await dataProvider.getList('customers_list_data', {
        pagination: { page: 1, perPage: 10 },
        sort: {
          field: 'name',
          order: 'ASC',
        },
        filter: {
          custName: searchTerm,
        },
      })

      setCustsAll(CustsData.data)
      setIsLoading(false)
    }

    getCustList()
  }, [searchTerm])

  React.useEffect(() => {
    setCode(nanoid(7))
  }, [])

  const custsAllFilter = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  })

  const addCustomer = async () => {
    let payload = {}
    if (poin) {
      payload = {
        poin: Number(poin),
        code: code,
        remark: remark,
        created_by: authP.id(),
        customer_id: custToBeAdded.id,
        created_at: new Date(),
        dpAction: 'createOne',
      }
    }

    if (credit) {
      payload = {
        credit: Number(credit),
        code: code,
        remark: remark,
        created_by: authP.id(),
        customer_id: custToBeAdded.id,
        created_at: new Date(),
        dpAction: 'createOne',
        credit_type: creditType,
      }
    }

    await dataProvider.create('poin_add_manually', {
      data: payload,
    })
    redirect(`/poin_add_manually/${code}`)
  }

  const handleChangeCreditType = (value) => {
    setCreditType(value)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell>
                <MuiTextField value={code} name="code" label="Code" fullWidth disabled />
              </TableCell>
              <TableCell>
                <MuiTextField
                  onChange={(e) => {
                    setRemark(e.target.value)
                    addCust && setAddCust(false)
                  }}
                  value={remark}
                  name="remark"
                  label="Remark"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <MuiTextField
                  onChange={(e) => {
                    setCredit(e.target.value)
                    addCust && setAddCust(false)
                  }}
                  value={credit}
                  label="Credit"
                  type="number"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <FormControl variant="filled" style={{ minWidth: '280px' }}>
                  <InputLabel id="demo-simple-select-label">Credit Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={creditType}
                    onChange={(e) => handleChangeCreditType(e.target.value)}
                    variant="standard"
                  >
                    <MenuItem value="GIMMICK">GIMMICK</MenuItem>
                    <MenuItem value="REFUND">REFUND</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}>
                <div className={classes.toolbar}>
                  <div></div>
                  <div>
                    <MuiButton
                      size="small"
                      color="primary"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        if (credit <= 0 || !credit)
                          return notify('Please set CREDIT first!', 'error')
                        if (!remark) return notify('Please set Remark first!', 'error')
                        if (!creditType) return notify('Please set credit type', 'error')
                        addCust ? setAddCust(false) : setAddCust(true)
                      }}
                    >
                      Add Customer
                    </MuiButton>
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Customer Name</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addCust && (poin || credit) > 0 && (poin || credit) && remark && (
                        <>
                          {!isLoading ? (
                            <TableRow>
                              <TableCell colSpan={3}>
                                <Autocomplete
                                  filterOptions={custsAllFilter}
                                  onChange={(e, newValue) => setCustToBeAdded(newValue)}
                                  options={custsAll}
                                  getOptionLabel={(option) =>
                                    option?.channel === 'PWA_APP'
                                      ? `${option?.name} - ${option?.channel} - ${option?.users_customer_id_list?.data[0]?.msisdn}`
                                      : `${option?.name} - ${option?.channel} - ${option?.channel_id}`
                                  }
                                  fullWidth
                                  renderInput={(params) => (
                                    <MuiTextField
                                      {...params}
                                      label="Type in customer name"
                                      variant="standard"
                                      autoFocus
                                      onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <MuiButton
                                  size="small"
                                  color="primary"
                                  startIcon={<AddIcon />}
                                  variant="contained"
                                  onClick={async () => {
                                    setAddCust(false)
                                    addCustomer()
                                  }}
                                >
                                  Add
                                </MuiButton>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={4}>
                                <CircularProgress />
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.toolbar}>
                  <div></div>
                  <div>
                    <MuiButton
                      size="medium"
                      color="primary"
                      variant="outlined"
                      onClick={() => history.push({ pathname: '/poin_add_manually' })}
                    >
                      Back
                    </MuiButton>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
    height: 350,
  },
  input: {
    marginBottom: '-20px',
  },
  text: {
    margin: 0,
  },
  detailsBox: {
    borderBottom: 'solid thin rgba(0, 0, 0, 0.12)',
    marginBottom: '20px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
})
