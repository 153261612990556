import React, { useState, useEffect, cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  required,
  useNotify,
  useRedirect,
  SimpleForm,
  Edit,
  EditButton,
  Toolbar,
  SaveButton,
  Create,
  TextInput,
  FunctionField,
  useInput,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  Button,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import AceEditor from 'react-ace'
import ReactJson from 'react-json-view'
import Grid from '@material-ui/core/Grid'
import ExportButton from '../shared-components/export-button'

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton filter={filterValues} resourceType="MESSENGER" />
    </TopToolbar>
  )
}

export const MessengerI18nList = (props) => (
  <List {...props} bulkActionButtons={false} actions={<ListActions />}>
    <Datagrid>
      <TextField source="bahasa" />
      <FunctionField
        label="Text"
        render={(record) => (
          <div>
            <label>
              <ReactJson
                src={record.text ? record.text : {}}
                name={false}
                shouldCollapse={() => {}}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            </label>
          </div>
        )}
      />
      <EditButton />
    </Datagrid>
  </List>
)

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const TranslationInput = (props) => {
  const [translationValue, setTranslationValue] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const translation = useInput(props)

  useEffect(() => {
    setTranslationValue(JSON.stringify(translation.input.value, null, '\t'))
    setIsChanged(false)
  }, [translation.input.value])

  function onChange(newValue) {
    setTranslationValue(newValue)
    setIsChanged(true)
  }

  function saveToForm() {
    const parsed = JSON.parse(translationValue)
    translation.input.onChange(parsed)
  }

  return (
    <div>
      <AceEditor
        name="translation"
        style={{ height: '200px', width: '100%' }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={translationValue}
      />
      {isChanged && (
        <Button variant="contained" size="small" color="primary" onClick={saveToForm}>
          <>Commit Changes</>
        </Button>
      )}
    </div>
  )
}

export const MessengerI18nCreate = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/messenger_i18n')
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                className={classes.input}
                source="bahasa"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <p>Text</p>
              <TranslationInput source="text" className={classes.input} />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}

export const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
)

const EditTitle = ({ record }) => {
  return <span>{record ? `"${record.key}"` : ''} Edit</span>
}

export const MessengerI18nEdit = (props) => {
  const classes = useStyles()
  return (
    <Edit {...props} title={<EditTitle />}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput source="id" />
              <TextInput
                fullWidth
                disabled
                className={classes.input}
                source="bahasa"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <p>Text</p>
              <TranslationInput source="text" className={classes.input} />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}
