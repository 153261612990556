const { client } = require('../../MbaasClient')

export const funcCreateOverride = async (resource, params) => {
  const data = await client.invokeApi('override-transaction', {
    id: params.data.id,
    token: params.data.token,
    target: params.data.newTarget,
    newStatus: params.data.newStatus,
    reason: params.data.reason,
  })
  if (data?.error) {
    throw new Error(data.error)
  }
  return { data: params.data }
}
