import React, { useState, useEffect } from 'react'
import {
  SimpleForm,
  TextInput,
  Edit,
  AutocompleteInput,
  FormDataConsumer,
  useInput,
  required,
} from 'react-admin'
import { client } from './../../provider/MbaasClient'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomToolbar } from '../CustomToolbar'
import { Button as MuiButton } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextFieldMui from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import { Add as AddIcon } from '@material-ui/icons'
import ChooseIcon from '@material-ui/icons/CheckCircleOutline'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: 'auto',
    },
  },
}))

const EditTitle = ({ record }) => {
  return <span>Knowledge Base &gt; {record ? `"${record.question}"` : ''} Edit</span>
}

export default function PageEdit(props) {
  const classes = useStyles()

  const [firstRender, setFirstRender] = useState(true)
  const [categoryList, setCategoryList] = useState([])
  const [createdCategory, setCreatedCategory] = useState('')
  const [isCreateCategory, setIsCreateCategory] = useState(false)
  const [tagList, setTagList] = useState([])
  const [createdTag, setCreatedTag] = useState('')
  const [selectedTag, setSelectedTag] = useState([])
  const [isCreateTag, setIsCreateTag] = useState(false)

  const handleCreateCategory = () => {
    if (createdCategory !== '') {
      const updatedCategories = categoryList
      updatedCategories.push({
        id: createdCategory,
        name: createdCategory,
      })
      setCategoryList(
        updatedCategories.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i)
      )
    }
    setIsCreateCategory(false)
  }

  const handleCreateTag = () => {
    if (createdTag !== '') {
      const updatedTags = tagList
      if (createdTag.includes(',')) {
        createdTag.split(',').forEach((tag) => {
          updatedTags.push({
            id: tag,
            name: tag,
          })
        })
      } else {
        updatedTags.push({
          id: createdTag,
          name: createdTag,
        })
      }
      setTagList(updatedTags.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i))
    }
    setIsCreateTag(false)
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await client.getTable('knowledge_base').read()
      setCategoryList(
        data
          .map((x) => {
            return {
              id: x.category,
              name: x.category,
            }
          })
          .filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i)
      )
      const tagArray = []
      data.forEach((x) => {
        if (x.tag) {
          if (x.tag.includes(',')) {
            x.tag.split(',').forEach((y) => {
              tagArray.push({
                id: y,
                name: y,
              })
            })
          } else {
            tagArray.push({
              id: x.tag,
              name: x.tag,
            })
          }
        }
      })
      setTagList(tagArray.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i))
    })()
  }, [])

  const transform = (data) => ({
    ...data,
    tag: Array.isArray(data.tag)
      ? data.tag
          .map((x) => {
            return x.name
          })
          .join(',')
      : data.tag,
  })
  return (
    <Edit title={<EditTitle />} transform={transform} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput className={classes.input} disabled fullWidth source="id" />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                className={classes.input}
                source="question"
                label="Question?"
                autoFocus={true}
                validate={required()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                className={classes.input}
                source="answer"
                label="Answer"
                validate={required()}
              />
            </Grid>
            <FormDataConsumer subscription={{ values: true }}>
              {() => {
                if (!isCreateCategory)
                  return (
                    <>
                      <Grid item xs={11}>
                        <AutocompleteInput
                          fullWidth
                          className={classes.input}
                          source="category"
                          choices={categoryList}
                          optionValue="name"
                          label="Current Category"
                          autoFocus={true}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <MuiButton
                          size="small"
                          color="primary"
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            setIsCreateCategory(true)
                          }}
                        >
                          Add New
                        </MuiButton>
                      </Grid>
                    </>
                  )
              }}
            </FormDataConsumer>
            <FormDataConsumer subscription={{ values: true }}>
              {() => {
                if (isCreateCategory)
                  return (
                    <>
                      <Grid item xs={11}>
                        <TextInput
                          fullWidth
                          className={classes.input}
                          source="create_category"
                          label="+ Add new category"
                          onKeyUp={(event) => {
                            setCreatedCategory(event.target.value)
                          }}
                          autoFocus={true}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <MuiButton
                          size="small"
                          color="primary"
                          variant="contained"
                          startIcon={<ChooseIcon />}
                          onClick={() => {
                            setIsCreateCategory(false)
                          }}
                        >
                          Choose
                        </MuiButton>
                      </Grid>
                      <Grid item xs={12}>
                        <MuiButton
                          size="small"
                          color="primary"
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            handleCreateCategory()
                          }}
                        >
                          Add Category
                        </MuiButton>
                      </Grid>
                    </>
                  )
              }}
            </FormDataConsumer>
            <FormDataConsumer subscription={{ values: true }}>
              {() => {
                if (!isCreateTag)
                  return (
                    <>
                      <Grid item xs={11}>
                        <AutocompleteTags
                          label="Current Tags"
                          source="tag"
                          validate={required()}
                          option={tagList}
                          selectedTag={selectedTag}
                          setSelectedTag={setSelectedTag}
                          firstRender={firstRender}
                          setFirstRender={setFirstRender}
                          isCreateTag={isCreateTag}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <MuiButton
                          size="small"
                          color="primary"
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            setIsCreateTag(true)
                          }}
                        >
                          Add New
                        </MuiButton>
                      </Grid>
                    </>
                  )
              }}
            </FormDataConsumer>
            <FormDataConsumer subscription={{ values: true }}>
              {() => {
                if (isCreateTag)
                  return (
                    <>
                      <Grid item xs={11}>
                        <TextInput
                          fullWidth
                          className={classes.input}
                          source="create_tag"
                          label="+ Add new tag"
                          onKeyUp={(event) => {
                            setCreatedTag(event.target.value)
                          }}
                          autoFocus={true}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <MuiButton
                          size="small"
                          color="primary"
                          variant="contained"
                          startIcon={<ChooseIcon />}
                          onClick={() => {
                            setIsCreateTag(false)
                          }}
                        >
                          Choose
                        </MuiButton>
                      </Grid>
                      <Grid item xs={12}>
                        <MuiButton
                          size="small"
                          color="primary"
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            handleCreateTag()
                          }}
                        >
                          Add Tag
                        </MuiButton>
                      </Grid>
                    </>
                  )
              }}
            </FormDataConsumer>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

const AutocompleteTags = (props) => {
  const classes = useStyles()
  const {
    input: { name, value, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)
  const record = value.includes(',') ? value.split(',') : [value]

  useEffect(() => {
    const tes = record?.map((v) => ({
      id: v,
      name: v,
    }))
    props.setSelectedTag(tes)
  }, [])

  useEffect(() => {
    !props.firstRender && onChange(props.selectedTag)
  }, [props.firstRender, props.selectedTag])

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option}
      getOptionLabel={(option) => option.name}
      value={
        props.firstRender
          ? props.option.filter((item) => {
              return record.includes('ALL') ? true : record.includes(item.id)
            })
          : props.selectedTag
      }
      style={{ marginBottom: '32px' }}
      onChange={(_, v) => {
        // onChange(v)
        props.setFirstRender(false)
        props.setSelectedTag(v)
      }}
      required={isRequired}
      PaperComponent={({ children }) => <Paper className={classes.test}>{children}</Paper>}
      renderInput={(params) => (
        <TextFieldMui
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select ${name.toLowerCase()}`}
          error={!!(touched && error)}
          helperText={touched && error && 'required'}
          {...rest}
        />
      )}
    />
  )
}
