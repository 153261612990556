const listPaymentChannel = {
  default: [
    {
      name: 'Hi-Life',
      id: 'HILIFETW',
    },
    {
      name: 'Family Mart',
      id: 'FAMY',
    },
    /////////////////////////////////////////
    {
      name: '7-Eleven',
      id: 'SEVENTW',
    },
    {
      name: 'FPX Online Banking',
      id: 'FPX',
    },
    {
      name: 'Touch ‘n Go eWallet',
      id: 'TNG',
    },
    {
      name: 'Grab Pay',
      id: 'GRABPAY',
    },
    {
      name: 'Boost',
      id: 'BOOST',
    },
    {
      name: 'Shopee Pay',
      id: 'SHOPEEPAY',
    },
    {
      name: 'Big Central',
      id: 'BIGC',
    },
    {
      name: 'Tesco Lotus',
      id: 'TESCO',
    },
    {
      name: 'Socash',
      id: 'SG_SOCASH_CASH',
    },
    {
      name: 'eNETS Debit',
      id: 'SG_ENETS',
    },
  ],
  ipay88: [
    {
      name: 'FPX Online Banking',
      id: 'FPX',
    },
    { id: 'IPAY88_BOOST', name: 'Boost' },
    { id: 'IPAY88_CCMY', name: 'Credit Card' },
    // { id: 'IPAY88_WEBC', name: 'Web Cash' },
    // { id: 'IPAY88_PAYP', name: 'PayPal(MYR)' },
    // { id: 'IPAY88_CCPA', name: 'Credit Card (MYR) Pre-Auth' },
    // { id: 'IPAY88_P4ME', name: 'Pay4Me (Delay payment)' },
    // { id: 'IPAY88_HLPE', name: 'Hong Leong PEx+ (QR Payment)' },
    // { id: 'IPAY88_CIMBVA', name: 'CIMB Virtual Account (Delay payment)' },
    // { id: 'IPAY88_BOOST', name: 'Boost Wallet' },
    // { id: 'IPAY88_VCASH', name: 'VCash' },
  ],
}
export default listPaymentChannel
