import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  autocomplete1: {
    width: 200,
    display: 'inline-block',
    transform: 'translateY(-4px)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  button1: {
    marginRight: '10px',
  },
  w_300: {
    maxWidth: 300,
    maxHeight: 230,
  },
}))
