import { client } from '../../MbaasClient'

export const funcUpdateSurveys = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  let updatedData = params.data
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  delete params.data.type
  const isProdOriginString = typeof params.data.product_origin[0] === 'string'
  const isCategoryString = typeof params.data.category[0] === 'string'
  const isProdCodeString = typeof params.data.product_code[0] === 'string'
  await client.getTable('surveys').update({
    id: params.id,
    data: {
      ...updatedData,
      product_origin: isProdOriginString
        ? params.data.product_origin.toString()
        : // @ts-ignore
          params.data.product_origin.map((v) => v.id).toString(),
      category: isCategoryString
        ? params.data.category.toString()
        : // @ts-ignore
          params.data.category.map((v) => v.id).toString(),
      product_code: isProdCodeString
        ? params.data.product_code.toString()
        : // @ts-ignore
          params.data.product_code.map((v) => v.id).toString(),
    },
  })

  return { data: updatedData }
}
