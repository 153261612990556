const { client } = require('../../MbaasClient')

export const funcGetOneFailedButtons = async (resource, params) => {
  const {
    data: { data },
  } = await client.invokeApi('get-failed-trx-button', {
    filter: {
      category: params.id || '',
    },
  })
  return {
    data: data.map((v) => ({
      ...v,
      category: v.id,
      id: v.id + JSON.stringify(v.failed_trx_button),
    }))[0],
  }
}
