import gql from 'graphql-tag'
// import moment from 'moment'
import { buildArgs } from '../shared-script/buildArgs'
const { client } = require('../../MbaasClient')

export async function isWallets(params, resource) {
  console.log(371092830123, 'params', params)
  const where = {}
  const filterArray = []

  let { field, order } = params.sort
  if (field === 'created_at') {
    field = 'updated_at'
    order = 'DESC'
  }

  if (params.filter.user_id) {
    filterArray.push({
      user_id: {
        _eq: params.filter.user_id,
      },
    })
  }

  if (params.filter.customer_id) {
    filterArray.push({
      customer_id: {
        _eq: params.filter.customer_id,
      },
    })
  }

  // filter by date range
  // if (params.filter.dateGte || params.filter.dateLte) {
  //   filterArray.push({
  //     created_at: {
  //       _gte: moment(params.filter.dateGte).startOf('day').toISOString(),
  //     },
  //   })
  //   filterArray.push({
  //     created_at: {
  //       _lte: moment(params.filter.dateLte).endOf('day').toISOString(),
  //     },
  //   })
  // }

  if (filterArray.length > 0) where._and = filterArray

  const args = buildArgs({
    order: order.toUpperCase(),
    size: params.pagination.perPage,
    pageNumber: params.pagination.page,
    sort: field,
  })

  const gqlResult = await client.gql.query({
    query: gql`query wallets ($where: WalletsWhereInput){
          allWalletsList(
            where:$where, ${args}
          ){
            data{
                id
                created_at
                updated_at
                user_id
                customer_id
                credit_type
                balance
                user_id_data{
                    name
                }
                customer_id_data{
                    name
                }
            }
            count
          }
        }`,
    variables: {
      where,
    },
  })

  const groupedData = gqlResult.allWalletsList.data.reduce((result, item) => {
    const { customer_id, balance, credit_type, id, created_at } = item

    if (!result[customer_id]) {
      result[customer_id] = {
        customer_id,
        balanceGimmick: 0,
        balanceRefund: 0,
        endingBalance: 0,
        id: id,
        created_at: created_at,
        custName: item.customer_id_data.name,
      }
    }

    const customer = result[customer_id]

    if (credit_type === 'GIMMICK') {
      customer.balanceGimmick += balance
    } else if (credit_type === 'REFUND') {
      customer.balanceRefund += balance
    }

    customer.endingBalance = customer.balanceGimmick + customer.balanceRefund

    return result
  }, {})

  const resultArray = Object.values(groupedData)

  console.log(371092830123, 'gqlResult', gqlResult)
  console.log(3801293, 'resultArray', resultArray)

  return {
    data: resultArray,
    total: Math.round(gqlResult.allWalletsList.count / 2) || 0,
  }
}
