import React from 'react'
import { SimpleForm, PasswordInput, Create, required, useAuthenticated } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    width: '98%',
    textAlign: 'center',
  },
}))

export const ProfileEdit = (props) => {
  useAuthenticated() // redirects to login if not authenticated
  const classes = useStyles()
  return (
    <Create
      title="Change Password"
      redirect={false}
      basePath="/my-profile"
      resource="profile"
      {...props}
    >
      <SimpleForm>
        <div className={classes.root}>
          <Grid item xs={6}>
            <PasswordInput
              className={classes.input}
              fullWidth
              source="oldPassword"
              validate={[required()]}
            />
          </Grid>
          <Grid item xs={6}>
            <PasswordInput
              className={classes.input}
              fullWidth
              source="newPassword"
              validate={[required()]}
            />
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}
