// in src/posts.js
import * as React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  required,
  AutocompleteInput,
  FormDataConsumer,
  useInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import TextFieldMui from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'
import { CustomToolbar } from './CustomToolbarCreate'
import { client } from './../../provider/MbaasClient'
import Autocomplete from '@material-ui/lab/Autocomplete'
import productOrigin from './../list-product-origin'
import AddIcon from '@material-ui/icons/Add'
import { countryList } from './../../constants'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    background: '#ecebeb',
    marginBottom: 10,
    padding: 16,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: 'auto',
    },
  },
  conditionDivLabel: {
    fontSize: '12px',
    marginTop: '8px',
  },
  conditionDiv: {
    padding: '16px',
    marginTop: '5px',
    border: '2px dashed rgba(0, 0, 0, 0.2)',
  },
  conditionItem: {
    display: 'flex',
  },
})

const OptionLinearScale = ({ option, setOption }) => {
  const handleChangeFromValue = (event) => {
    const val = event.target.value
    setOption((old) => {
      return {
        ...old,
        from: {
          ...old.from,
          value: val,
        },
      }
    })
  }

  const handleChangeToValue = (event) => {
    const val = event.target.value
    setOption((old) => {
      return {
        ...old,
        to: {
          ...old.to,
          value: val,
        },
      }
    })
  }

  const handleChangeFromLabel = (event) => {
    const val = event.target.value
    setOption((old) => {
      return {
        ...old,
        from: {
          ...old.from,
          label: val,
        },
      }
    })
  }

  const handleChangeToLabel = (event) => {
    const val = event.target.value
    setOption((old) => {
      return {
        ...old,
        to: {
          ...old.to,
          label: val,
        },
      }
    })
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <FormControl>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={option.from.value}
            onChange={handleChangeFromValue}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
        <h6 style={{ margin: '0 10px', lineHeight: '32px' }}>to</h6>
        <FormControl>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={option.to.value}
            onChange={handleChangeToValue}
          >
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex', marginTop: '8px' }}>
        <p style={{ lineHeight: '31px', marginRight: '10px' }}>{option.from.value}</p>
        <TextFieldMui value={option.from.label} onChange={handleChangeFromLabel} />
      </div>
      <div style={{ display: 'flex', marginTop: '8px' }}>
        <p style={{ lineHeight: '31px', marginRight: '10px' }}>{option.to.value}</p>
        <TextFieldMui value={option.to.label} onChange={handleChangeToLabel} />
      </div>
    </>
  )
}

const OptionCheckboxes = ({ option, setOption }) => {
  const [value, setValue] = React.useState('female')

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  function handleOnDragEnd(result) {
    if (!result.destination) return

    const items = Array.from(option)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setOption(items)
  }

  const addOption = () => {
    const tes = { id: 'id-' + Math.random(), value: 'Option Text' }
    const halo = [...option]
    halo.push(tes)

    setOption(halo)
  }

  const setDelete = (index) => {
    const list = [...option]
    list.splice(index, 1)
    setOption(list)
  }

  const handleChangeValue = (event, v) => {
    setOption((old) => {
      const tes = [...old].findIndex((obj) => obj.id === v.id)
      old[tes].value = event.target.value
      return old
    })
  }

  return (
    <FormControl component="fieldset" fullWidth>
      {/* <FormLabel component="legend">Gender</FormLabel> */}
      <FormGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                {option?.map((v, index) => {
                  return (
                    <Draggable key={v.id} draggableId={v.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FormControlLabel
                            // value="female"
                            disabled
                            control={<Checkbox />}
                            label={
                              <>
                                <TextFieldMui
                                  defaultValue={v.value}
                                  onChange={(e) => handleChangeValue(e, v)}
                                />
                                {option.length > 1 && (
                                  <IconButton aria-label="delete" onClick={() => setDelete(index)}>
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                )}
                              </>
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <FormControlLabel
          // value="female"
          disabled
          control={<Checkbox />}
          label={
            <Button color="primary" onClick={addOption}>
              Add Option
            </Button>
          }
        />
      </FormGroup>
    </FormControl>
  )
}

const OptionMultipleChoice = ({ option, setOption }) => {
  const [value, setValue] = React.useState('female')

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  function handleOnDragEnd(result) {
    if (!result.destination) return
    const items = Array.from(option)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setOption(items)
  }

  const addOption = () => {
    const tes = { id: 'id-' + Math.random(), value: 'Option Text' }
    const halo = [...option]
    halo.push(tes)

    setOption(halo)
  }

  const handleChangeValue = (event, v) => {
    setOption((old) => {
      const tes = [...old].findIndex((obj) => obj.id === v.id)
      old[tes].value = event.target.value
      return old
    })
  }

  const setDelete = (index) => {
    const list = [...option]
    list.splice(index, 1)
    setOption(list)
  }

  return (
    <FormControl component="fieldset" fullWidth>
      {/* <FormLabel component="legend">Gender</FormLabel> */}
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                {option.map((v, index) => {
                  return (
                    <Draggable key={v.id} draggableId={v.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FormControlLabel
                            // value="female"
                            disabled
                            control={<Radio />}
                            label={
                              <>
                                <TextFieldMui
                                  defaultValue={v.value}
                                  onChange={(e) => handleChangeValue(e, v)}
                                />
                                {option.length > 1 && (
                                  <IconButton aria-label="delete" onClick={() => setDelete(index)}>
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                )}
                              </>
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <FormControlLabel
          // value="female"
          disabled
          control={<Radio />}
          label={
            <Button color="primary" onClick={addOption}>
              Add Option
            </Button>
          }
        />
      </RadioGroup>
    </FormControl>
  )
}

const CardTest = ({ ref, index, setDelete, value, setValue, allValue }) => {
  const classes = useStyles()
  const [type, setType] = React.useState(value.type)
  const [option, setOption] = React.useState(value.option)
  const [conditions, setConditions] = React.useState(value.condition)

  const handleChangeType = (event) => {
    setValue((old) => {
      const index = [...old].findIndex((obj) => obj.id === value.id)
      old[index].type = event.target.value
      return old
    })
    setType(event.target.value)

    // reset option
    if (event.target.value === 'multiple_choice' || event.target.value === 'checkboxes') {
      setOption([{ id: 'id-' + Math.random(), value: 'Option' }])
    } else if (event.target.value === 'linear_scale') {
      setOption({
        from: {
          value: 1,
          label: '',
        },
        to: {
          value: 5,
          label: '',
        },
      })
    }
    // reset condition
    setConditions([])
  }

  const handleChangeQuestion = (event) => {
    setValue((old) => {
      const index = [...old].findIndex((obj) => obj.id === value.id)
      old[index].question = event.target.value
      return old
    })
  }

  const handleChangeRequired = () => {
    setValue((old) => {
      const index = [...old].findIndex((obj) => obj.id === value.id)
      old[index].required = !value.required
      return old
    })
  }

  const handleChangeOption = () => {
    setValue((old) => {
      const index = [...old].findIndex((obj) => obj.id === value.id)
      if (index !== -1) old[index].option = option
      return old
    })
  }

  const handleChangeConditions = () => {
    setValue((old) => {
      const index = [...old].findIndex((obj) => obj.id === value.id)
      if (index !== -1) old[index].condition = conditions
      return old
    })
  }

  const handleChangeLevel = (event) => {
    setValue((old) => {
      const index = [...old].findIndex((obj) => obj.id === value.id)
      old[index].level = event.target.value
      return old
    })
  }

  const handleChangeConditionEquation = (event, index) => {
    const copyConditions = [...conditions]
    const newCondition = { ...conditions[index], equation: event.target.value }
    copyConditions.splice(index, 1, newCondition)
    setConditions(copyConditions)
  }
  const handleChangeConditionValue = (event, index) => {
    const copyConditions = [...conditions]
    const newCondition = { ...conditions[index], value: event.target.value }
    copyConditions.splice(index, 1, newCondition)
    setConditions(copyConditions)
  }
  const handleChangeConditionLinkTo = (event, index) => {
    const copyConditions = [...conditions]
    const newCondition = { ...conditions[index], link_to: event.target.value }
    copyConditions.splice(index, 1, newCondition)
    setConditions(copyConditions)
  }

  const handleChangeAddCondition = () => {
    const condition = [
      {
        equation: 'eq',
        value: '',
        link_to: '',
      },
    ]
    setConditions((old) => old.concat(condition))
  }
  const handleChangeDeleteCondition = (index) => {
    const copyCondition = [...conditions]
    copyCondition.splice(index, 1)
    setConditions(copyCondition)
  }

  React.useEffect(() => {
    handleChangeOption()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option])

  React.useEffect(() => {
    handleChangeConditions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions])

  return (
    <Card ref={ref} className={classes.root}>
      <TextFieldMui disabled label="Id" fullWidth defaultValue={value.id} />
      <TextFieldMui
        label="Level"
        fullWidth
        defaultValue={value.level}
        onChange={handleChangeLevel}
      />
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel>Type</InputLabel>
        <Select value={type} onChange={handleChangeType}>
          <MenuItem value="short_answer">short_answer</MenuItem>
          <MenuItem value="paragraph">paragraph</MenuItem>
          <MenuItem value="multiple_choice">multiple_choice</MenuItem>
          <MenuItem value="checkboxes">checkboxes</MenuItem>
          <MenuItem value="linear_scale">linear_scale</MenuItem>
        </Select>
      </FormControl>
      <TextFieldMui
        label="Question"
        fullWidth
        defaultValue={value.question}
        onChange={handleChangeQuestion}
      />
      {type === 'multiple_choice' && <OptionMultipleChoice option={option} setOption={setOption} />}
      {type === 'checkboxes' && <OptionCheckboxes option={option} setOption={setOption} />}
      {type === 'linear_scale' && <OptionLinearScale option={option} setOption={setOption} />}

      {/* Condition */}
      {type !== 'checkboxes' && (
        <>
          <InputLabel className={classes.conditionDivLabel}>Condition</InputLabel>
          <div className={classes.conditionDiv}>
            {conditions.map((condition, index) => {
              return (
                <div key={index} className={classes.conditionItem}>
                  <FormControl className={classes.formControl} style={{ marginRight: '8px' }}>
                    <InputLabel>Operator</InputLabel>
                    <Select
                      value={condition.equation}
                      onChange={(e) => handleChangeConditionEquation(e, index)}
                    >
                      <MenuItem value="eq">equal</MenuItem>
                      <MenuItem value="lt">less than</MenuItem>
                      <MenuItem value="gt">greater than</MenuItem>
                      <MenuItem value="lte">less than equal</MenuItem>
                      <MenuItem value="gte">greater than equal</MenuItem>
                    </Select>
                  </FormControl>
                  <TextFieldMui
                    style={{ marginRight: '8px', flexGrow: 1 }}
                    label="Value"
                    value={condition.value}
                    onChange={(e) => handleChangeConditionValue(e, index)}
                  />
                  <TextFieldMui
                    style={{ marginRight: '8px' }}
                    label="Link To Id"
                    value={condition.link_to}
                    onChange={(e) => handleChangeConditionLinkTo(e, index)}
                  />
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleChangeDeleteCondition(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              )
            })}
            <Button color="primary" onClick={handleChangeAddCondition}>
              add condition
            </Button>
          </div>
        </>
      )}

      <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'end' }}>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={value.required}
              onChange={handleChangeRequired}
              color="primary"
            />
          }
          label="Required"
          labelPlacement="start"
        />
        {allValue.length > 1 && (
          <IconButton aria-label="delete" onClick={() => setDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </Card>
  )
}

const CustomersEdit = (props) => {
  const [dataForm, setDataForm] = React.useState([])
  const [characters, updateCharacters] = React.useState([])

  const [optionCategory, setOptionCategory] = React.useState([])
  const [optionProdCode, setOptionProdCode] = React.useState([])

  const [selectedProdOrigin, setSelectedProdOrigin] = React.useState([])
  const [selectedCategory, setSelectedCategory] = React.useState([])
  const [selectedProdCode, setSelectedProdCode] = React.useState([])

  const [firstRender, setFirstRender] = React.useState(true)

  React.useEffect(() => {
    let haloTimeout = setTimeout(() => {
      client
        .invokeApi('promo-flashsales-products', {
          product_origin: selectedProdOrigin.map((v) => v.id), //[String],
          // product_origin: ['MYS'], //[String],
          // category: formData.category || ['ALL'], //[String],
          // product_code: '', //[String],
          // denomination: '', //Int,
          invoked: 'category', //String! product_origin, category, product_code, denomination
        })
        .then((v) => {
          if (v.length !== 0) {
            const category = v.data?.data?.map((v) => ({ id: v.category, name: v.category }))
            setOptionCategory(category)
            setSelectedCategory(category)
          }
        })
    }, 2000)

    return () => clearTimeout(haloTimeout)
  }, [selectedProdOrigin])

  React.useEffect(() => {
    let haloTimeout = setTimeout(() => {
      client
        .invokeApi('promo-flashsales-products', {
          product_origin: selectedProdOrigin.map((v) => v.id), //[String],
          // product_origin: ['MYS'], //[String],
          category: selectedCategory.map((v) => v.id), //[String],
          // product_code: '', //[String],
          // denomination: '', //Int,
          invoked: 'product_code', //String! product_origin, category, product_code, denomination
        })
        .then((v) => {
          if (v.length !== 0) {
            const prodCode = v.data?.data?.map((v) => ({
              id: v.product_code,
              name: v.product_code,
            }))
            setOptionProdCode(prodCode)
            setSelectedProdCode(prodCode)
          }
        })
    }, 2000)

    return () => clearTimeout(haloTimeout)
  }, [selectedCategory, selectedProdOrigin])

  function handleOnDragEnd(result) {
    if (!result.destination) return
    const items = Array.from(characters)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    updateCharacters(items)
  }

  const setDelete = (index) => {
    const list = [...characters]
    list.splice(index, 1)
    updateCharacters(list)
  }

  const customValidation = (values) => {
    const errors = {}

    if (values.description?.length > 250) {
      errors.description = 'Max 250 characters'
    }
    return errors
  }

  return (
    <Create {...props}>
      <SimpleForm
        validate={customValidation}
        toolbar={<CustomToolbar dataForm={dataForm} option={characters} />}
      >
        <TextInput fullWidth source="name" validate={required()} />
        <TextInput fullWidth source="description" validate={required()} />
        <AutocompleteInput fullWidth source="country" choices={countryList} validate={required()} />
        <DateInput fullWidth source="start" validate={required()} />
        <DateInput fullWidth source="end" validate={required()} />

        <CustomAutocompleteProductOrigin
          label="Product Origin"
          source="product_origin"
          validate={required()}
          selectedProdOrigin={selectedProdOrigin}
          setSelectedProdOrigin={setSelectedProdOrigin}
          setSelectedCategory={setSelectedCategory}
          setSelectedProdCode={setSelectedProdCode}
          firstRender={firstRender}
          setFirstRender={setFirstRender}
          //
        />

        <CustomAutocompleteCategory
          label="Category"
          source="category"
          validate={required()}
          option={optionCategory}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setSelectedProdCode={setSelectedProdCode}
          firstRender={firstRender}
          setFirstRender={setFirstRender}

          //
        />
        <CustomAutocompleteProductCode
          label="Product Code"
          source="product_code"
          validate={required()}
          option={optionProdCode}
          selectedProdCode={selectedProdCode}
          setSelectedProdCode={setSelectedProdCode}
          firstRender={firstRender}
          setFirstRender={setFirstRender}
          //
        />

        <h3>Config</h3>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                {characters?.map((value, index) => {
                  return (
                    <Draggable key={value.id} draggableId={value.id} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <div {...provided.dragHandleProps}>
                            <DragIndicatorIcon
                              style={{
                                left: '50%',
                                position: 'relative',
                                transform: 'rotate(90deg) translateY(50%)',
                                opacity: '30%',
                              }}
                            />
                          </div>
                          <CardTest
                            index={index}
                            setDelete={setDelete}
                            value={value}
                            setValue={updateCharacters}
                            allValue={characters}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* <Button
          style={{ marginTop: '16px' }}
          color="primary"
          variant="contained"
          onClick={() => {
          }}
        >
          Log Surveys (dev)
        </Button> */}
        <Button
          style={{ marginTop: '16px' }}
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            const survey = {
              id: 'id-' + Math.random(),
              level: '0',
              type: 'short_answer',
              question: '',
              required: false,
              condition: [],
            }

            const surveys = [...characters]
            surveys.push(survey)

            updateCharacters(surveys)
          }}
        >
          Add
        </Button>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setDataForm(formData)
            if (characters?.length === 0 && formData.config) {
              updateCharacters(formData.config)
            } else if (characters?.length === 0) {
              updateCharacters([
                {
                  id: 'id-' + Math.random(),
                  level: '0',
                  type: 'short_answer',
                  question: 'question?',
                  option: [],
                  condition: [],
                },
              ])
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

const CustomAutocompleteProductCode = (props) => {
  const classes = useStyles()
  const [tes, setTes] = React.useState(true)
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const record = props.record[name]

  React.useEffect(() => {
    !props.firstRender && onChange(props.selectedProdCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.firstRender, props.selectedProdCode])

  React.useEffect(() => {
    if (record) {
      const tes = record.map((v) => ({
        id: v,
        name: v,
      }))
      props.setSelectedProdCode(tes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option}
      getOptionLabel={(option) => option.name}
      value={
        props.firstRender && tes
          ? props.option.filter((item) => record.includes(item.id))
          : props.selectedProdCode
      }
      style={{ marginBottom: '32px' }}
      onChange={(_, v) => {
        onChange(v)
        setTes(false)
        // props.setFirstRender(false)
        props.setSelectedProdCode(v)
      }}
      PaperComponent={({ children }) => <Paper className={classes.test}>{children}</Paper>}
      required={isRequired}
      renderInput={(params) => (
        <TextFieldMui
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select ${name.toLowerCase()}`}
          error={!!(touched && error)}
          helperText={touched && error && 'required'}
          {...rest}
        />
      )}
    />
  )
}

const CustomAutocompleteCategory = (props) => {
  const classes = useStyles()
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const record = props.record[name]

  React.useEffect(() => {
    if (record) {
      const tes = record.map((v) => ({
        id: v,
        name: v,
      }))
      props.setSelectedCategory(tes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    !props.firstRender && onChange(props.selectedCategory)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.firstRender, props.selectedCategory])

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={props.option}
      getOptionLabel={(option) => option.name}
      value={
        props.firstRender
          ? props.option.filter((item) => record.includes(item.id))
          : props.selectedCategory
      }
      style={{ marginBottom: '32px' }}
      onChange={(_, v) => {
        // onChange(v)
        props.setFirstRender(false)
        props.setSelectedCategory(v)
        props.setSelectedProdCode([])
      }}
      PaperComponent={({ children }) => <Paper className={classes.test}>{children}</Paper>}
      required={isRequired}
      renderInput={(params) => (
        <TextFieldMui
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select ${name.toLowerCase()}`}
          error={!!(touched && error)}
          helperText={touched && error && 'required'}
          {...rest}
        />
      )}
    />
  )
}

const CustomAutocompleteProductOrigin = (props) => {
  const classes = useStyles()
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const record = props.record[name]

  React.useEffect(() => {
    !props.firstRender && onChange(props.selectedProdOrigin)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.firstRender, props.selectedProdOrigin])

  React.useEffect(() => {
    if (record) {
      const tes = productOrigin.filter((item) => record.includes(item.id))
      props.setSelectedProdOrigin(tes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-standard"
      disableCloseOnSelect={true}
      options={productOrigin}
      getOptionLabel={(option) => option.name}
      value={props.selectedProdOrigin}
      style={{ marginBottom: '32px' }}
      onChange={(_, v) => {
        // onChange(v)
        props.setSelectedProdOrigin(v)
        props.setFirstRender(false)
        props.setSelectedCategory([])
        props.setSelectedProdCode([])
      }}
      PaperComponent={({ children }) => <Paper className={classes.test}>{children}</Paper>}
      required={isRequired}
      renderInput={(params) => (
        <TextFieldMui
          {...params}
          variant="filled"
          label={props.label}
          placeholder={`select ${name.toLowerCase()}`}
          error={!!(touched && error)}
          helperText={touched && error && 'required'}
          {...rest}
        />
      )}
    />
  )
}

export default CustomersEdit
