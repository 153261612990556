import { storePromotionCode } from './../../../requests'
const { client } = require('../../MbaasClient')

export const funcCreatePromotions = async (resource, params) => {
  console.log(91073124, 'params', params)

  const userData = await client.user.get()
  //
  const resultPaymentChannel = params?.data?.paymentChannel?.map((data) => {
    return data?.id
  })

  const resultPaymentChannelFpx = params?.data?.paymentChannelFPX?.map((data) => {
    return data?.id
  })

  let combinedPaymentChannel = []
  if (resultPaymentChannelFpx?.length > 0) {
    combinedPaymentChannel = resultPaymentChannel?.concat(resultPaymentChannelFpx)
  } else {
    combinedPaymentChannel = resultPaymentChannel
  }

  const joined = combinedPaymentChannel.join(',')
  //
  const [, data] = await storePromotionCode({
    category: params.data.category.map((v) => v.id),
    product_code: params.data.product_code.map((v) => v.id),
    product_origin: params.data.product_origin.map((v) => v.id),
    code: params.data.code,
    type: params.data.type,
    value: params.data.value,
    max_usage: params.data.max_usage,
    start: params.data.start,
    end: params.data.end,
    denomination:
      params.data.denom_type === 'ALL'
        ? params.data.denom_type
        : params.data.denom_value.map((v) => v.replace(',', '')).toString(),
    user_id: userData.data.id,
    max_usage_per_customer: params.data.max_usage_per_customer,
    specific_target: params.data.specific_target ? true : false,
    specific_customer_name: params.data.specific_customer_name ? true : false,
    specific_customer: params.data.specific_customer ? true : false,
    new_customer_only: params.data.new_customer_only ? true : false,
    customer_id: params.data.specific_customer_name ? params.data.customer_id : null,
    payment_channel: joined.length > 0 ? joined : null,
  })

  return { data: data }
}
