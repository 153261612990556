const { client } = require('../../MbaasClient')

export const funcCreatePoinAddManually = async (resource, params) => {
  console.log(74124214, 'params', params)
  const { poin, remark, customer_id, code, created_by, created_at, dpAction, credit, credit_type } =
    params.data

  let payload = {}

  if (poin) {
    payload = {
      poin,
      customer_id,
      code,
      remark,
      created_by,
      created_at,
      dpAction,
    }
  }

  if (credit) {
    payload = {
      credit: String(credit),
      customer_id,
      code,
      remark,
      created_by,
      created_at,
      dpAction,
      credit_type,
    }
  }

  const { data } = await client.invokeApi('credit-add-manually', payload)
  return { data: data }
}
