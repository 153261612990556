const { client } = require('./../provider/MbaasClient')

const storePromotionCode = async ({
  category, // array "DATAPACK,DONATION,ELOAD,EWALLET,GAME,PIN,PREDEFINED",
  product_code, // array "ALFAMART,AXIS,AXISDATA,BPJS,DANA,FREE_FIRE,GOPAY,INDOMARET,INFAQ,ISAT,LINKAJA,MOBILE_LEGEND,OVO,PLN,PLNBILL,TSEL,TSELDATA,WAKAF",
  product_origin, // array "IDN",
  code, // "ERY0TSU",
  type, // "absolute",
  value, // 123,
  max_usage, // 12,
  start, // "2022-04-01T03:47:00.000Z",
  end, // "2022-04-01T03:47:00.000Z",
  denomination, // array "5000,444,3333",
  user_id, // "73666e91-872f-4172-adad-d85f12579dec",
  max_usage_per_customer = 1, // 1
  specific_target,
  specific_customer_name,
  specific_customer,
  new_customer_only,
  customer_id,
  payment_channel,
}) => {
  try {
    category = category?.toString()
    product_code = product_code?.toString()
    product_origin = product_origin?.toString()
    code = code?.toUpperCase()
    denomination = denomination?.toString()

    const { data } = await client.getTable('promotions').insert({
      category,
      product_code,
      product_origin,
      code,
      type,
      value,
      max_usage,
      start,
      end,
      denomination,
      user_id,
      max_usage_per_customer,
      specific_target,
      specific_customer_name,
      specific_customer,
      new_customer_only,
      customer_id,
      payment_channel,
    })
    return [null, data]
  } catch (error) {
    return [error, null]
  }
}

export default storePromotionCode
