const productOrigin = [
  {
    id: 'IDN',
    name: 'Indonesia',
  },
  {
    id: 'MYS',
    name: 'Malaysia',
  },
  {
    id: 'BGD',
    name: 'Bangladesh',
  },
  {
    id: 'MMR',
    name: 'Myanmar',
  },
  {
    id: 'NPL',
    name: 'Nepal',
  },
  {
    id: 'PHL',
    name: 'Philippines',
  },
  {
    id: 'VNM',
    name: 'Vietnam',
  },
  {
    id: 'IND',
    name: 'India',
  },
  {
    id: 'PAK',
    name: 'Pakistan',
  },
  {
    id: 'CHN',
    name: 'China',
  },
]

export default productOrigin
