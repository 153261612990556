import gql from 'graphql-tag'
import moment from 'moment'
import { buildArgs } from '../shared-script/buildArgs'
const { client } = require('../../MbaasClient')

export async function isUsers(params, resource) {
  const where = {}
  const filterArray = []

  // filter by name
  if (params.filter.search_by_name) {
    filterArray.push({
      name: {
        _ilike: `%${params.filter.search_by_name}%`,
      },
    })
  }
  // filter by email
  if (params.filter.search_by_email) {
    filterArray.push({
      email: {
        _ilike: `%${params.filter.search_by_email}%`,
      },
    })
  }
  // filter by status
  if (params.filter.hasOwnProperty('status')) {
    filterArray.push({
      enable: {
        _eq: params.filter.status,
      },
    })
  }

  // filter by role
  if (params.filter.hasOwnProperty('roles')) {
    filterArray.push({
      roles: {
        _has_key: params.filter.roles,
      },
    })
  }

  // filter by date range
  if (params.filter.dateGte || params.filter.dateLte) {
    filterArray.push({
      created_at: {
        _gte: moment(params.filter.dateGte).startOf('day').toISOString(),
      },
    })
    filterArray.push({
      created_at: {
        _lte: moment(params.filter.dateLte).endOf('day').toISOString(),
      },
    })
  }

  if (filterArray.length > 0) {
    if (params.filter.search_by_name) {
      where._or = filterArray
    } else {
      where._and = filterArray
    }
  }

  const args = buildArgs({
    order: params.sort.order.toUpperCase(),
    size: params.pagination.perPage,
    pageNumber: params.pagination.page,
    sort: params.sort.field,
  })

  const gqlResult = await client.gql.query({
    query: gql`query userList ($where: UsersWhereInput){
          allUsersList(
            where:$where, ${args}
          ){
            data{
              id
              created_at
              updated_at
              roles
              email
              password
              social_ids
              verified
              fcm_tokens
              name
              deactivated_at
              level_id
              organization_id
              msisdn
              ref_number
              kyc_data
              kyc_status
              kyc_reason
              enable
              customer_id
              customer_id_data {
                  id
                  name
                  channel
                  enable
                  metadata
              }
            }
            count
          }
        }`,
    variables: {
      where,
    },
  })

  return {
    data: gqlResult.allUsersList.data,
    total: gqlResult.allUsersList.count || 0,
  }
}
