import React from 'react'
import {
  SimpleForm,
  TextInput,
  Edit,
  DateTimeInput,
  required,
  AutocompleteInput,
  FormDataConsumer,
  NumberInput,
  FileInput,
  FileField,
  useNotify,
  useRedirect,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { countryList, linkToList } from './../../constants'
import { checkUrlIsImage } from '../../utilities'
import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CheckboxLabels from './CheckBox'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  inputHidden: {
    display: 'none',
  },
  text: {
    color: '#0000008A',
    fontSize: '12px',
  },
  autocomplete1: {
    width: 200,
    display: 'inline-block',
    transform: 'translateY(-4px)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  button1: {
    marginRight: '10px',
  },
  w_300: {
    width: 300,
  },
}))

export default function PopupadsEdit(props) {
  const { source, ...rest } = props
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const PopupadsTitle = ({ record }) => {
    return <span>Edit Pop-Up Ads: {record ? `"${record.id}"` : ''}</span>
  }

  const PreviousImageOrVideo = ({ record }) => {
    return checkUrlIsImage(record.url) ? (
      // tested png, jpg, gif, mp4
      <>
        <p className={classes.text}>Previous Image</p>
        <img src={record.url} alt="Previous IMG" width="auto" />
      </>
    ) : (
      <>
        <p className={classes.text}>Previous Video (mp4)</p>
        <video className={classes.w_300} controls muted>
          <source src={record.url} type="video/mp4"></source>
          Your browser does not support the video tag.
        </video>
      </>
    )
  }

  return (
    <Edit
      title={<PopupadsTitle />}
      {...props}
      mutationMode="pessimistic"
      onSuccess={() => {
        redirect('/popupads')
        notify('Pop-Up Ads edited')
      }}
      onFailure={(error) => {
        notify(`Pop-Up Ads edit error: ${error.message}`, 'warning')
      }}
    >
      <SimpleForm>
        <TextInput source="id" disabled fullWidth />
        <PreviousImageOrVideo />
        <FileInput
          source="url"
          label="Image / Video (mp4)"
          accept="video/mp4, image/*"
          validate={required()}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="h6"
            style={{ color: 'black', marginBottom: '10px', fontSize: '14px' }}
          >
            Apply Popup Ads For?
          </Typography>
          <div>
            <CheckboxLabels type="checkbox" source="user_type" {...rest} />
          </div>
        </Grid>
        <AutocompleteInput
          source="description"
          label="Link to"
          choices={linkToList}
          className={classes.input}
          validate={required()}
          fullWidth
        />

        <FormDataConsumer>
          {({ formData, ...props }) =>
            formData.description === 'custom_url' && (
              <TextInput
                fullWidth
                source="ini_url"
                label="Custom Url"
                className={classes.input}
                validate={required()}
                {...props}
              />
            )
          }
        </FormDataConsumer>
        <NumberInput
          source="max_shown"
          label="Max. Shown / Customer"
          fullWidth
          className={classes.input}
          validate={required()}
        />
        <DateTimeInput
          fullWidth
          className={classes.input}
          source="start"
          label="Valid From"
          validate={required()}
          defaultValue={new Date()}
          parse={(v) => new Date(v)}
        />
        <DateTimeInput
          fullWidth
          className={classes.input}
          source="end"
          label="Valid Until"
          validate={required()}
          defaultValue={new Date()}
          parse={(v) => new Date(v)}
        />
        <AutocompleteInput
          fullWidth
          label="Country"
          source="country"
          validate={required()}
          className={classes.input}
          choices={countryList}
        />
        <TextInput source="ads_type" validate={required()} className={classes.inputHidden} />
      </SimpleForm>
    </Edit>
  )
}
