import { fetchDataInitTable } from '../shared-script/fetchDataInitTable'
import defaultSortParams from '../shared-script/defaultSortParams'
const { moco } = require('../../MbaasClient')

export async function isAdvertisements(params, resource) {
  console.log(6234958776, { method: 'getList', resource: resource, params: params })
  resource = 'advertisements'

  const { page, perPage } = params.pagination
  let { field: sortField, order: sortOrder } = params.sort

  // adjust sorting params, default sort DESC
  const [field, order] = defaultSortParams(sortField, sortOrder)

  let filterObject = moco.composeFilter()
  let includedTables = []

  if (params.filter.ads_type) {
    filterObject = filterObject.eq('ads_type', params.filter.ads_type)
    includedTables = ['coupon_settings']
  }
  if (params.filter.country) {
    filterObject = filterObject.eq('country', params.filter.country)
  }
  if (params.filter.status === 'active') {
    filterObject = filterObject.and((x) =>
      x.le('start', params.filter.date).ge('end', params.filter.date)
    )
  } else if (params.filter.status === 'inactive') {
    filterObject = filterObject.and((x) =>
      x.ge('start', params.filter.date).ge('end', params.filter.date)
    )
  } else if (params.filter.status === 'expired') {
    filterObject = filterObject.and((x) =>
      x.le('start', params.filter.date).le('end', params.filter.date)
    )
  }

  if (filterObject !== '') filterObject = filterObject.toString()

  const [data, total] = await fetchDataInitTable(
    includedTables,
    resource,
    filterObject,
    order,
    field,
    page,
    perPage
  )

  return { data: data, total: total }
}
