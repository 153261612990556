import reorder from './reorder'

export default function onDragEndTest(result, rows, setRows, setIsPriorityChange) {
  // dropped outside the list
  if (!result.destination) return

  const items = reorder(rows, result.source.index, result.destination.index)
  setRows((old) => ({ ...old, data: [...items] }))

  // detect if priority is change - for show button save order
  let listPriority = []
  Object.keys(items).forEach((v, i) => {
    if (items[v].priority !== i + 1) {
      listPriority.push(items[v].priority)
    }
  })
  listPriority.length > 0 ? setIsPriorityChange(true) : setIsPriorityChange(false)
}
