import React, { cloneElement } from 'react'
import {
  Filter,
  List,
  Datagrid,
  TextField,
  DateField,
  SimpleForm,
  SelectInput,
  TextInput,
  Edit,
  EditButton,
  required,
  BooleanField,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  Button,
  useUpdate,
  FunctionField,
} from 'react-admin'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomToolbar } from './CustomToolbar'
import { DateRange } from './DateRange'
import moment from 'moment'
import EnableIcon from '@material-ui/icons/VerifiedUser'
import DisableIcon from '@material-ui/icons/Block'
import ReactJson from 'react-json-view'
import ExportButton from '../shared-components/export-button'

const CustomerFilter = (props) => (
  <Filter {...props}>
    <DateRange alwaysOn />
    <TextInput label="Search" source="custName" alwaysOn />
    <SelectInput
      label="Referral Type"
      source="customer_type"
      choices={[
        { id: 'referrer', name: 'REFERRER' },
        { id: 'referee', name: 'REFEREE' },
      ]}
    />
    <SelectInput
      label="Channel"
      source="filter_channel"
      choices={[
        { id: 'facebook', name: 'FACEBOOK' },
        { id: 'pwa_app', name: 'PWA_APP' },
      ]}
    />
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton filter={filterValues} resourceType="CUSTOMER" />
    </TopToolbar>
  )
}

const Buttons = ({ record }) => {
  const diff = { enable: !record.enable }
  const [update, { loading, error }] = useUpdate('customers', record.id, diff, record)

  if (error) {
    alert(error)
  }

  return (
    <>
      <EditButton basePath="/customers" label="Edit" record={record} />
      <Button
        label={record.enable ? 'Disable' : 'Enable'}
        onClick={update}
        color="primary"
        disabled={loading}
      >
        {record.enable ? <DisableIcon /> : <EnableIcon />}
      </Button>
    </>
  )
}

const ExpandToLogs = ({ record }) => {
  const classes = useStyles()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)

  const handleChangePage = (_event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangePerPage = (event) => {
    setPerPage(event.target.value)
    setPage(1)
  }

  const data = record.metadata?.logs
  if (!data) {
    return (
      <Table>
        <TableBody>
          <TableRow key={0}>
            <TableCell>No data</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
  const total = data.length

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Updated by</TableCell>
            <TableCell>Before</TableCell>
            <TableCell>After</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((x) => (
            <TableRow key={x.id}>
              <TableCell>{moment(x.date).format('MM/DD/YYYY, hh:mm:ss A')}</TableCell>
              <TableCell>{x.updated_by.name}</TableCell>
              <TableCell>
                {
                  <ReactJson
                    src={x.before}
                    name={false}
                    shouldCollapse={
                      x.id === total - 1
                        ? false
                        : () => {
                            /* TODO document why this arrow function is empty */
                          }
                    }
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                }
              </TableCell>
              <TableCell>
                {
                  <ReactJson
                    src={x.after}
                    name={false}
                    shouldCollapse={
                      x.id === total - 1
                        ? false
                        : () => {
                            /* TODO document why this arrow function is empty */
                          }
                    }
                    enableClipboard={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 15, 20]}
        rowsPerPage={perPage}
        count={total}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangePerPage}
      />
    </>
  )
}

export const CustomerList = (props) => (
  <List
    {...props}
    filters={<CustomerFilter />}
    filterDefaultValues={{
      dateGte: moment().subtract(1, 'month').calendar(),
      dateLte: moment().format('YYYY/MM/DD'),
    }}
    actions={<ListActions />}
  >
    <Datagrid expand={<ExpandToLogs />}>
      <DateField source="created_at" label="Created" showTime />
      <TextField source="name" />
      <TextField source="channel_id" label="Channel ID" />
      <TextField source="channel" />
      <FunctionField
        label="Metadata"
        render={(record) => (
          <div>
            <label>
              <ReactJson
                src={record.metadata}
                name={false}
                collapsed={true}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            </label>
          </div>
        )}
      />
      <TextField source="language" />
      <BooleanField source="enable" />
      <DateField source="latest_chat_at" label="Latest Chat At" showTime />
      <Buttons />
    </Datagrid>
  </List>
)

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const EditTitle = ({ record }) => {
  return <span>{record ? `"${record.name}"` : ''} Edit</span>
}

export const CustomerEdit = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput fullWidth className={classes.input} source="id" disabled />
            </Grid>
            <Grid item xs={6}>
              <TextInput className={classes.input} fullWidth source="name" />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                fullWidth
                source="channel"
                choices={[
                  { id: 'FACEBOOK', name: 'Facebook' },
                  { id: 'LINE', name: 'Line' },
                  { id: 'PWA_APP', name: 'PWA App' },
                ]}
                validate={[required()]}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}
