const { client } = require('./../provider/MbaasClient')

const deleteOneHeaderImage = async (id, previousData) => {
  try {
    const { bucket, filepath } = previousData?.url
    await client.storage.removeFile({ filePath: filepath, bucket })
    await client.getTable('webview_headers').del({ id })

    return [null, null]
  } catch (e) {
    return [null, e]
  }
}
export default deleteOneHeaderImage
