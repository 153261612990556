import React, { useEffect, useMemo } from 'react'
import {
  DateTimeInput,
  required,
  SelectInput,
  useRedirect,
  FormDataConsumer,
  SaveButton,
  useAuthProvider,
  DeleteButton,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  minValue,
  useRecordContext,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import { fetchDistinctTableProduct } from '@/src/requests/fetch-distinct-table-product'
import { LIST_MAP_CHANNEL } from '@/src/constants/list-channel'
import { RaAutocompleteInput } from '@/src/shared-components/ra-autocomplete-input'
import { useQuery } from '@tanstack/react-query'
import { Button } from '@material-ui/core'
import { firstDateOfMonth } from '@/src/utilities/date/first-date-of-month'
import { lastDateOfMonth } from '@/src/utilities/date/last-date-of-month'

export const FormInput = ({ handleSubmitWithRedirect, saving, ...renderProps }: any) => {
  const authP = useAuthProvider()
  const redirect = useRedirect()
  const formState = useFormState()
  const [ccc, setFormValue] = React.useState<any>()
  const dataForm = formState.values
  const isDirty = formState.dirty
  const record = useRecordContext()
  const hasTransaction = record?.transactions_aggregate?.count >= 1

  const listMapChannel = useMemo(
    () =>
      LIST_MAP_CHANNEL.map((channel) => ({
        id: channel.id,
        name: channel.label,
      })),
    []
  )

  const listProductOrigin = dataForm?.items?.product_origin?.map((v: any) => v?.id || v) || []
  const listCategory = dataForm?.items?.category?.map((v: any) => v?.id || v) || []
  const listProductCode = dataForm?.items?.product_code?.map((v: any) => v?.id || v) || []

  const fetchProductOriginQuery = useQuery(
    ['fetchProductOriginQuery'],
    () => fetchDistinctTableProduct('country'),
    {
      staleTime: 1000 * 60 * 60 * 24,
    }
  )

  const fetchCategoryQuery = useQuery(
    ['fetchCategoryQuery', { product_origin: listProductOrigin }],
    () =>
      fetchDistinctTableProduct('category', {
        product_origin: listProductOrigin,
      }),
    { enabled: listProductOrigin?.length !== 0, staleTime: 1000 * 60 * 10 }
  )

  const fetchProductCodeQuery = useQuery(
    ['fetchProductCodeQuery', { product_origin: listProductOrigin, category: listCategory }],
    () =>
      fetchDistinctTableProduct('product_code', {
        product_origin: listProductOrigin,
        category: listCategory,
      }),
    {
      enabled: listProductOrigin?.length !== 0 && listCategory?.length !== 0,
      staleTime: 1000 * 60 * 10,
    }
  )

  const fetchDenominationQuery = useQuery(
    [
      'fetchDenominationQuery',
      {
        product_origin: listProductOrigin,
        category: listCategory,
        product_code: listProductCode,
      },
    ],
    () =>
      fetchDistinctTableProduct('denomination', {
        product_origin: listProductOrigin,
        category: listCategory,
        product_code: listProductCode,
      }),
    {
      enabled:
        listProductOrigin?.length !== 0 &&
        listCategory?.length !== 0 &&
        listProductCode?.length !== 0,
      staleTime: 1000 * 60 * 10,
    }
  )

  useEffect(() => {
    if (!isDirty) {
      // ccc.setValue('items.category', dataForm?.items?.category)
    } else if (
      (ccc && dataForm?.items?.product_origin?.length === 0) ||
      (ccc && fetchCategoryQuery.isLoading)
    ) {
      ccc.setValue('items.category', [])
    } else if (ccc && !!fetchCategoryQuery.data) {
      ccc.setValue(
        'items.category',
        fetchCategoryQuery.data?.data?.data?.map((v) => {
          return { id: v.category, name: v.category }
        })
      )
    }
  }, [
    ccc,
    fetchCategoryQuery.data,
    dataForm?.items?.product_origin?.length,
    fetchCategoryQuery.isLoading,
  ])

  useEffect(() => {
    if (!isDirty) {
      // ccc.setValue('items.product_code', dataForm?.items?.product_code)
    } else if (
      (ccc && dataForm?.items?.category?.length === 0) ||
      (ccc && fetchProductCodeQuery.isLoading)
    ) {
      ccc.setValue('items.product_code', [])
    } else if (ccc && fetchProductCodeQuery.data) {
      ccc.setValue(
        'items.product_code',
        fetchProductCodeQuery.data?.data?.data?.map((v) => {
          return { id: v.product_code, name: v.product_code }
        })
      )
    }
  }, [
    ccc,
    fetchProductCodeQuery.data,
    dataForm?.items?.category?.length,
    fetchProductCodeQuery.isLoading,
  ])

  useEffect(() => {
    if (!isDirty) {
      // ccc.setValue('items.denomination', dataForm?.items?.denomination)
    } else if (
      (ccc &&
        dataForm?.items?.product_origin?.length === 0 &&
        dataForm?.items?.category?.length === 0 &&
        dataForm?.items?.product_code?.length === 0) ||
      (ccc && fetchDenominationQuery.isLoading)
    ) {
      ccc.setValue('items.denomination', [])
    } else if (ccc && fetchDenominationQuery.data) {
      ccc.setValue(
        'items.denomination',
        fetchDenominationQuery.data?.data?.data?.map((v) => {
          return { id: v.denomination.toString(), name: v.denomination.toString() }
        })
      )
    }
  }, [
    ccc,
    fetchDenominationQuery.data,
    dataForm?.items?.product_origin?.length,
    dataForm?.items?.category?.length,
    dataForm?.items?.product_code?.length,
  ])

  const handleCancel = () => {
    redirect('/promo_double_poin')
  }

  const transformBody = (data: any) => ({
    ...data,
    user_id: authP.id(),
  })

  if (!ccc) {
    setFormValue(renderProps.form.mutators)
  }

  return (
    <>
      <div style={{ padding: '16px', paddingBottom: 0 }}>
        <Grid container>
          <Grid item xs={12}>
            <SelectInput
              label="Country"
              source="country"
              fullWidth
              validate={required()}
              choices={[
                { id: 'MY', name: 'Malaysia' },
                { id: 'ID', name: 'Indonesia' },
              ]}
              defaultValue="MY"
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={12}>
            <RaAutocompleteInput
              source="items.channel"
              options={listMapChannel}
              label="Channel"
              validate={required()}
              multiple
              fullWidth
              defaultValue={listMapChannel}
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={12}>
            <RaAutocompleteInput
              source="items.product_origin"
              options={fetchProductOriginQuery.data?.data?.data?.map((v) => {
                return { id: v.country, name: v.country }
              })}
              label="Product Origin"
              validate={required()}
              multiple
              fullWidth
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={12}>
            <RaAutocompleteInput
              source="items.category"
              options={fetchCategoryQuery.data?.data?.data?.map((v) => {
                return { id: v.category, name: v.category }
              })}
              label="Category"
              validate={required()}
              multiple
              fullWidth
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={12}>
            <RaAutocompleteInput
              source="items.product_code"
              options={fetchProductCodeQuery.data?.data?.data?.map((v) => {
                return { id: v.product_code, name: v.product_code }
              })}
              label="Product Code"
              validate={required()}
              multiple
              fullWidth
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={12}>
            <RaAutocompleteInput
              source="items.denomination"
              label="Denom Value"
              options={fetchDenominationQuery.data?.data?.data?.map((v) => {
                return { id: v.denomination.toString(), name: v.denomination.toString() }
              })}
              validate={required()}
              multiple
              fullWidth
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={12}>
            <NumberInput
              label="Multiplier"
              source="value"
              placeholder=""
              fullWidth
              validate={required()}
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={6}>
            <BooleanInput
              source="configs.new_customer_only"
              label="For New Customer / Target Only?"
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={6}>
            <BooleanInput
              source="configs.once_per_target"
              label="Is Specific Target?"
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={6}>
            <BooleanInput
              source="configs.specific_customer.status"
              label="Is for Specific Customer?"
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  formData?.configs?.specific_customer?.status && (
                    <ReferenceInput
                      source="configs.specific_customer.customer_id"
                      label="Specific For"
                      reference="customers"
                      validate={required()}
                      disabled={hasTransaction}
                    >
                      <AutocompleteInput fullWidth optionText="name" />
                    </ReferenceInput>
                  )
                )
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12}>
            <NumberInput
              source="configs.total_usage"
              label="Total Usage"
              fullWidth
              validate={[required(), minValue(1)]}
              defaultValue={1}
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={12}>
            <NumberInput
              source="configs.max_usage_per_customer"
              label="Max Usage Per Customer"
              fullWidth
              validate={[required(), minValue(1)]}
              defaultValue={1}
              disabled={hasTransaction}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ width: '100%', display: 'flex', gap: 16 }}>
              <DateTimeInput
                label="myroot.valid_from"
                source="start"
                fullWidth
                validate={[required()]}
                defaultValue={firstDateOfMonth}
              />
              <DateTimeInput
                label="Valid Until"
                source="end"
                fullWidth
                validate={[required()]}
                defaultValue={lastDateOfMonth}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          backgroundColor: '#f5f5f5',
          padding: '16px',
        }}
      >
        <SaveButton
          handleSubmitWithRedirect={handleSubmitWithRedirect}
          transform={transformBody}
          saving={saving}
          disabled={renderProps.submitting}
        />
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <DeleteButton
          style={{ marginLeft: 'auto' }}
          record={renderProps.record}
          disabled={hasTransaction}
          mutationMode="pessimistic"
        />
      </div>
    </>
  )
}
