import React, { useEffect, useState } from 'react'
import { useListContext } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { DateRangePicker } from 'rsuite'
import 'rsuite/dist/styles/rsuite-default.css'

const useStyles = makeStyles(() => ({
  date: {
    marginBottom: '12px',
  },
}))

export const DateRange = () => {
  const styleClass = useStyles()
  const { setFilters, filterValues } = useListContext()

  const [dateValue, setDateValue] = useState({
    dateEnd: filterValues ? filterValues.dateLte : null,
    dateBegin: filterValues ? filterValues.dateGte : null,
  })

  useEffect(() => {
    setFilters({
      dateGte: dateValue.dateBegin,
      dateLte: dateValue.dateEnd,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue])

  return (
    <DateRangePicker
      value={[dateValue.dateBegin, dateValue.dateEnd]}
      onChange={(value) => {
        setDateValue({
          dateBegin: new Date(value[0]),
          dateEnd: new Date(value[1]),
        })
      }}
      //if the value clean, the date will set to null
      onClean={() => {
        setDateValue({
          dateBegin: null,
          dateEnd: null,
        })
      }}
      className={styleClass.date}
      size="lg"
    />
  )
}
