import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  SelectInput,
  EditButton,
  SearchInput,
  Filter,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  ReferenceField,
  NumberField,
} from 'react-admin'
import ReactJson from 'react-json-view'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-textmate'
import ExportButton from '@/src/shared-components/export-button'

const ProductFilter = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="name" />
      <SelectInput
        source="country"
        choices={[
          { id: 'IDN', name: 'Indonesia' },
          { id: 'MYS', name: 'Malaysia' },
        ]}
      />
      <SearchInput source="denomination" />
      <SearchInput source="category" />
      <SearchInput source="product_code" />
    </Filter>
  )
}

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton filter={filterValues} resourceType="PRODUCT" />
    </TopToolbar>
  )
}

export default function PageList(props) {
  return (
    <List {...props} filters={<ProductFilter />} actions={<ListActions />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="product_code" />
        <ReferenceField label="Organization" reference="organizations" source="organization_id">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="category" />
        <TextField source="name" />
        <TextField source="country" />
        <NumberField source="denomination" />
        <TextField source="description" label="Usage Description" />
        <FunctionField
          label="Validator"
          render={(record) => (
            <div>
              <label>
                <ReactJson
                  src={record.validator ? record.validator : {}}
                  name={false}
                  shouldCollapse={() => {}}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
              </label>
            </div>
          )}
        />

        <EditButton />
      </Datagrid>
    </List>
  )
}
