import { funcUpdateUsers } from '@/src/provider/DataProvider/populate-update-resource/users'
import { funcUpdateEnableCustomerAndUser } from '@/src/provider/DataProvider/populate-update-resource/updateEnableCustomerAndUser'
import { funcUpdateTransactionsSync } from '@/src/provider/DataProvider/populate-update-resource/transactions-sync'
import { funcUpdateTransactions } from '@/src/provider/DataProvider/populate-update-resource/transactions'
import { funcUpdateSurveys } from '@/src/provider/DataProvider/populate-update-resource/surveys'
import { funcUpdateStorePoints } from '@/src/provider/DataProvider/populate-update-resource/store-points'
import { funcUpdateReferralBenefits } from '@/src/provider/DataProvider/populate-update-resource/referral-benefits'
import { funcUpdateRedundancyPaymentGateway } from '@/src/provider/DataProvider/populate-update-resource/redundancy-payment-gateway'
import { funcUpdatePromotions } from '@/src/provider/DataProvider/populate-update-resource/promotions'
import { funcUpdatePricings } from '@/src/provider/DataProvider/populate-update-resource/pricings'
import { funcUpdateOrganizations } from '@/src/provider/DataProvider/populate-update-resource/organizations'
import { funcUpdateMessengerMsgSeries } from '@/src/provider/DataProvider/populate-update-resource/messenger-msg-series'
import { funcUpdateKnowledgeBase } from '@/src/provider/DataProvider/populate-update-resource/knowledge-base'
import { funcUpdateHeaderImage } from '@/src/provider/DataProvider/populate-update-resource/header-image'
import { funcUpdateFlashsale } from '@/src/provider/DataProvider/populate-update-resource/flashsale'
import { funcUpdateFailedButtons } from '@/src/provider/DataProvider/populate-update-resource/failed-buttons'
import { funcUpdateDenomPricings } from '@/src/provider/DataProvider/populate-update-resource/denom-pricings'
import { funcUpdateDefault } from '@/src/provider/DataProvider/populate-update-resource/default'
import { funcUpdateCustomers } from '@/src/provider/DataProvider/populate-update-resource/customers'
import { funcUpdateCouponSettings } from '@/src/provider/DataProvider/populate-update-resource/coupon-settings'
import { funcUpdateBonuses } from '@/src/provider/DataProvider/populate-update-resource/bonuses'
import { funcUpdateBlastSms } from '@/src/provider/DataProvider/populate-update-resource/blast-sms'
import { funcUpdateBlacklistedTargets } from '@/src/provider/DataProvider/populate-update-resource/blacklisted-targets'
import { funcUpdateAdvertisements } from '@/src/provider/DataProvider/populate-update-resource/advertisements'
import { funcUpdatePromoDoublePoin } from '@/src/provider/DataProvider/populate-update-resource/promo-double-poin'

const funcUpdate = async (resource: string, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']

  if (params?.data?.['type'] === resource) {
    delete params.data['type']
  }

  if (resource === 'promo_double_poin') {
    return await funcUpdatePromoDoublePoin(resource, params)
  }

  if (
    resource === 'advertisements' ||
    resource === 'popupads' ||
    resource === 'couponads' ||
    resource === 'runningText'
  ) {
    return await funcUpdateAdvertisements(resource, params)
  }

  if (resource === 'transactions') {
    return await funcUpdateTransactions(resource, params)
  }

  if (resource === 'promotions') {
    return await funcUpdatePromotions(resource, params)
  }

  if (resource === 'pricings') {
    return await funcUpdatePricings(resource, params)
  }

  if (resource === 'messenger_msg_series') {
    return await funcUpdateMessengerMsgSeries(resource, params)
  }

  if (resource === 'transactions_sync') {
    return await funcUpdateTransactionsSync(resource, params)
  }

  if (resource === 'store_points') {
    return await funcUpdateStorePoints(resource, params)
  }

  if (resource === 'referral_benefits') {
    return await funcUpdateReferralBenefits(resource, params)
  }

  if (resource === 'coupon_settings') {
    return await funcUpdateCouponSettings(resource, params)
  }

  if (resource === 'failed_buttons') {
    return await funcUpdateFailedButtons(resource, params)
  }

  if (resource === 'organizations') {
    return await funcUpdateOrganizations(resource, params)
  }

  if (resource === 'customers') {
    return await funcUpdateCustomers(resource, params)
  }

  if (resource === 'flashsale') {
    return await funcUpdateFlashsale(resource, params)
  }

  if (resource === 'surveys') {
    return await funcUpdateSurveys(resource, params)
  }

  if (resource === 'users') {
    return await funcUpdateUsers(resource, params)
  }

  if (resource === 'redundancy_payment_gateway') {
    return await funcUpdateRedundancyPaymentGateway(resource, params)
  }

  if (resource === 'header-image') {
    return await funcUpdateHeaderImage(resource, params)
  }

  if (resource === 'denom_pricings') {
    return await funcUpdateDenomPricings(resource, params)
  }

  if (resource === 'knowledge_base') {
    return await funcUpdateKnowledgeBase(resource, params)
  }

  if (resource === 'blast_sms') {
    return await funcUpdateBlastSms(resource, params)
  }

  if (resource === 'blacklisted_targets') {
    return await funcUpdateBlacklistedTargets(resource, params)
  }

  if (resource === 'updateEnableCustomerAndUser') {
    return await funcUpdateEnableCustomerAndUser(resource, params)
  }

  if (resource === 'bonuses') {
    return await funcUpdateBonuses(resource, params)
  }

  return await funcUpdateDefault(resource, params)
}
export default funcUpdate
