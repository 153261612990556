import React from 'react'
import {
  required,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  Edit,
  DateInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { makeStyles } from '@material-ui/core/styles'
import { CustomToolbar } from './CustomToolbarForPageEdit'

import { countryList } from './../../constants'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  inputHidden: {
    display: 'none',
  },
  text: {
    color: '#0000008A',
    fontSize: '12px',
  },
  autocomplete1: {
    width: 200,
    display: 'inline-block',
    transform: 'translateY(-4px)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  button1: {
    marginRight: '10px',
  },
  w_300: {
    width: 300,
  },
}))

export default function AdvertisementEdit(props) {
  const classes = useStyles()
  const [dataForm, setDataForm] = React.useState({})
  const AdvertisementTitle = ({ record }) => {
    return <span>Edit Banner Ads: {record ? `"${record.id}"` : ''}</span>
  }

  const PreviousImage = ({ record }) => {
    return <img src={record.url} alt="Previous IMG" style={{ maxWidth: 300, maxHeight: 230 }} />
  }

  return (
    <Edit title={<AdvertisementTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar dataForm={dataForm} />}>
        <TextInput source="id" disabled fullWidth validate={required()} />
        <p className={classes.text}>Previous Image</p>
        <PreviousImage />
        <ImageInput source="url" label="Image" accept="image/*" validate={required()}>
          <ImageField source="src" />
        </ImageInput>

        <RichTextInput source="description" validate={required()} />
        <DateInput
          label="Start Date"
          source="start"
          parse={(v) => new Date(v)}
          validate={required()}
        />
        <DateInput label="End Date" source="end" parse={(v) => new Date(v)} validate={required()} />
        <TextInput source="ads_type" className={classes.inputHidden} />
        <AutocompleteInput
          fullWidth
          label="Country"
          source="country"
          className={classes.input}
          choices={countryList}
          validate={required()}
        />

        <ReferenceInput
          fullWidth
          defaultValue={dataForm?.coupon_setting_id}
          label="Link Coupon"
          source="coupon_setting_id"
          reference="coupon_settings"
          disabled
        >
          <AutocompleteInput fullWidth optionText="name" validate={required()} />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setDataForm(formData)
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}
