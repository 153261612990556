import React from 'react'
import { Create, FormWithRedirect, useNotify, useRedirect } from 'react-admin'
import { FormInput } from '@/src/component/Bonuses/form-input'

export const BonusesCreate = (props: any) => {
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/bonuses')
  }

  return (
    <Create title="Add A Bonus" {...props} onSuccess={onSuccess}>
      <FormWithRedirect
        mutators={{
          setValue: ([field, value]: any, state: any, { changeValue }: any) => {
            changeValue(state, field, () => value)
          },
        }}
        render={(renderProps) => {
          return <FormInput {...renderProps} />
        }}
      />
    </Create>
  )
}
