import { client } from '../provider/MbaasClient'

export default async function invokeApiEmitEventTransactionStatus({
  supplierRef, // string
  apiUsername, // string
  apiPassword, // string
}) {
  try {
    const result = await client.invokeApi('emit-event', {
      eventName: 'custom.transactionStatus',
      data: {
        supplier_ref: supplierRef,
        organization_data: {
          api_username: apiUsername,
          api_password: apiPassword,
        },
      },
    })

    return [null, result]
  } catch (error) {
    return [error, {}]
  }
}
