import gql from 'graphql-tag'
import moment from 'moment'
const { moco, client } = require('../../MbaasClient')

export async function isTransactions(params, resource) {
  console.log(30192873312, 'params', params)
  const { page, perPage } = params.pagination
  const { dateGte, dateLte } = params.filter || {}
  let { field, order } = params.sort
  if (field === 'id') {
    field = 'created_at'
    order = 'DESC'
  }

  let filterObject = moco.composeFilter()

  // for `blacklisted_targets`
  if (params.filter.q) {
    const currentlyBlacklistedData = await client
      .getTable('blacklisted_targets')
      .filter(moco.composeFilter().eq('status', true).toString())
      .sort(order === 'DESC' ? `-${field}` : field)
      .limit(999999)
      .offset((page - 1) * 999999)
      .read()
    const currentlyBlacklisted = currentlyBlacklistedData.data.map((y) => y.target)
    filterObject = filterObject.contains('target', params.filter.q)

    if (filterObject !== '') filterObject = filterObject.toString()
    const { data, total } = await client
      .getTable(resource)
      .filter(filterObject || 'true')
      .sort(order === 'DESC' ? `-${field}` : field)
      .limit(perPage)
      .offset((page - 1) * perPage)
      .read()

    return {
      data: data
        .filter(
          (x) => x.target !== '' && x.target !== 0 && !currentlyBlacklisted.includes(x.target)
        )
        .filter((v, i, a) => a.findIndex((t) => t.target === v.target) === i),
      total: total,
    }
  }
  const {
    order_id,
    customer_id,
    customer_id_select,
    user_id,
    target,
    latest_status,
    payment_id,
    promotion_code,
    tran_id,
    payment_channel,
  } = params.filter
  //
  const where = `{
        ${
          dateGte && dateLte
            ? `created_at: { _gte: "${moment(dateGte)
                .startOf('day')
                .toISOString()}", _lte: "${moment(dateLte).endOf('day').toISOString()}" }`
            : ''
        }
        ${order_id ? `order_id: { _ilike: "%${order_id}%"}` : ''}
        ${
          customer_id || customer_id_select
            ? `customer_id: { _eq: "${customer_id || customer_id_select}"}`
            : ''
        }
        ${user_id ? `user_id: { _eq: "${user_id}"}` : ''}
        ${target ? `target: { _ilike: "%${target}%"}` : ''}
        ${latest_status ? `latest_status: { _eq: "${latest_status}"}` : ''}
        ${payment_channel ? `pg_channel: { _eq: "${payment_channel}"}` : ''}
        ${payment_id ? `payment_id: { _ilike: "%${payment_id}%"}` : ''}
        ${
          promotion_code
            ? promotion_code.toLowerCase() === 'all'
              ? `promotion_code: { _is_null: false}`
              : `promotion_code: { _ilike: "%${promotion_code}%"}`
            : ''
        }
        
        ${
          tran_id
            ? ` _or: [{ pg_data: { _contains: $transId }}, { pg_data: { _contains: $transIdSpay }}]`
            : ''
        }
      }`
  // console.log(76631242142, params.filter, where, {
  //   transId: {
  //     payment_data: {
  //       tranID: tran_id,
  //     },
  //   },
  //   transIdSpay: {
  //     payment_data: {
  //       payment_info: {
  //         transaction_reference: tran_id,
  //       },
  //     },
  //   },
  // })
  try {
    const gqlResult = await client.gql.query({
      query: gql`query ${tran_id ? '($transId: JSON, $transIdSpay: JSON)' : ''}{
          allTransactionsList(
              where: ${where}
              sort: ${field}
              order: ${order}
              limit: ${perPage}
              offset: ${(page - 1) * perPage}
          ){
              data{
                  id
                  created_at
                  order_id
                  product_snapshot_id_data{
                      snapshot
                  }
                  customer_id_data{
                      id
                      created_at
                      name
                      organization_id_data{
                          name
                      }
                      channel
                  }
                  user_id_data{
                    msisdn
                    name
                    created_at
                  }
                  product_id_data{
                      description
                  }
                  target
                  store_data
                  allow_alter
                  payment_id
                  sell_price
                  pg_data
                  channel
                  pg_channel
                  pg_fee
                  metadata
                  cost
                  discount
                  profit
                  latest_status
                  promotion_code
                  discount_type
                  currency
                  poin
              }
          }
          aggregateTransactions(
              where: ${where}
          ){
              count
          }
      }`,
      variables: {
        transId: {
          payment_data: {
            tranID: tran_id,
          },
        },
        // transIdSpay: {
        //   payment_data: {
        //     payment_info: {
        //       transaction_reference: tran_id,
        //     },
        //   },
        // },
        transIdSpay: {
          payment_data: {
            transaction_id: tran_id,
          },
        },
      },
    })
    //
    const mappedData = (gqlResult.allTransactionsList?.data || []).map((x) =>
      Object.assign({
        ...x,
        customers: x.customer_id_data,
        organizations: x.customer_id_data.organization_id_data,
        products: x.product_id_data,
        reference_snapshots: x.product_snapshot_id_data,
      })
    )
    return {
      data: mappedData,
      total: gqlResult?.aggregateTransactions?.count || 0,
    }
  } catch (e) {
    console.log(333121212, e)
    return {
      data: [],
      total: 0,
    }
  }
}
