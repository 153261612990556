import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  ReferenceField,
  FileField,
  AutocompleteInput,
} from 'react-admin'
import moment from 'moment'

const DownloadFilter = (props) => (
  <Filter {...props}>
    <AutocompleteInput
      source="resource"
      optionValue="name"
      choices={[
        { name: 'USER' },
        { name: 'CUSTOMER' },
        { name: 'ADVERTISEMENT' },
        { name: 'ANNOUNCEMENT' },
        { name: 'CUSTOMER' },
        { name: 'DEPOSIT-LOG' },
        { name: 'DOCUMENT' },
        { name: 'LEVEL' },
        { name: 'LEDGER' },
        { name: 'LOCAL' },
        { name: 'LOG' },
        { name: 'MESSENGER' },
        { name: 'ORGANIZATION' },
        { name: 'POIN' },
        { name: 'PRICING' },
        { name: 'PRODUCT' },
        { name: 'PROMOTION' },
        { name: 'SOCIAL-MEDIA' },
        { name: 'TRANSACTION' },
        { name: 'REFERENCE-SNAPSHOT' },
        { name: 'POIN-LOG' },
        { name: 'REFERRAL-BENEFIT' },
        { name: 'COUPONS' },
        { name: 'COUPONS-SETTINGS' },
        { name: 'STORE-POINT' },
        { name: 'KBASE' },
        { name: 'BLACKLISTED-TARGET' },
        { name: 'Popupads' },
      ]}
      alwaysOn
    />
  </Filter>
)

export const DownloadList = (props) => (
  <List
    {...props}
    filters={<DownloadFilter />}
    filterDefaultValues={{
      dateGte: moment().subtract(1, 'month').calendar(),
      dateLte: moment().format('YYYY/MM/DD'),
    }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <DateField source="created_at" label="Created" showTime />
      <ReferenceField label="User" source="user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <FileField source="url" title="filename" label="File" />
      <DateField source="start" label="From" showTime />
      <DateField source="end" label="To" showTime />
      <TextField source="status" label="Status" />
    </Datagrid>
  </List>
)
