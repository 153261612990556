import React from 'react'
import { useInput, useTranslate, InputProps } from 'react-admin'
import { CustomAutocompleteInput } from './index'

type Props = {
  multiple?: any
  label: any
  options: any
  style?: any
} & InputProps

export const RaAutocompleteInput = ({
  multiple,
  label,
  options,
  style = { marginTop: '8px', marginBottom: '4px' },
  disabled,
  ...props
}: Props) => {
  const translate = useTranslate()

  const {
    input: { name, onChange, ref, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  return (
    // @ts-ignore
    <CustomAutocompleteInput
      label={label}
      multiple={multiple}
      options={options || []}
      disableCloseOnSelect={true}
      required={isRequired}
      onChange={(event: any, newValue: any) => {
        onChange(newValue)
      }}
      inputRef={ref}
      value={value || []}
      error={!!(touched && error)}
      helperText={touched && error ? translate(error) : <>&nbsp;</>}
      style={style}
      disabled={disabled}
      {...rest}
    />
  )
}
