// import { TDAGetManyFn } from '@/src/@types/data-provider/get-many'
import { getManyDefault } from '@/src/provider/DataProvider/populate-getMany-resource/default'
import { getManyPricing } from '@/src/provider/DataProvider/populate-getMany-resource/pricing'
import { getManyCustomer } from '@/src/provider/DataProvider/populate-getMany-resource/customer'

const funcGetMany: any = async (resource: any, params: any) => {
  console.log('get-many', { resource, params })

  if (resource === 'customers') {
    return await getManyCustomer(resource, params)
  }
  if (resource === 'pricings') {
    return await getManyPricing(resource, params)
  }

  return await getManyDefault(resource, params)
}

export default funcGetMany
