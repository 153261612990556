import polyglotI18nProvider from 'ra-i18n-polyglot'
import { en } from './en'
const translations = {
  en,
}

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  'en', // default locale
  [{ locale: 'en', name: 'English' }]
)
