import gql from 'graphql-tag'
import { client } from '@/src/provider/MbaasClient'

export const updateOne = async (
  id: string,
  {
    transactions_aggregate,
    ...variables
  }: {
    user_id: any
    start: any
    end: any
    country: any
    items: any
    configs: any
    transactions_aggregate: { count: number }
  }
) => {
  // @ts-ignore
  if (transactions_aggregate?.count >= 1) {
    throw new Error('Cannot update multiplier, because already have transaction')
  }

  const modifyVariable = {
    ...variables,
    items: {
      ...variables.items,
      channel: variables.items?.channel?.map((v: any) => v.id) || [],
      category: variables.items?.category?.map((v: any) => v.id) || [],
      denomination: variables.items?.denomination?.map((v: any) => v.id) || [],
      product_code: variables.items?.product_code?.map((v: any) => v.id) || [],
      product_origin: variables.items?.product_origin?.map((v: any) => v.id) || [],
    },
  }

  const data = await client.gql.mutation({
    mutation,
    variables: { ids: [id], input: modifyVariable },
  })

  if (data.error) throw data.error
  const res = data?.response?.[0]

  const dataModify = {
    ...(res || {}),
    items: {
      ...(res?.items || {}),
      channel: res?.items?.channel?.map((v: any) => ({ id: v, name: v })) || [],
      category: res?.items?.category?.map((v: any) => ({ id: v, name: v })) || [],
      denomination: res?.items?.denomination?.map((v: any) => ({ id: v, name: v })) || [],
      product_code: res?.items?.product_code?.map((v: any) => ({ id: v, name: v })) || [],
      product_origin: res?.items?.product_origin?.map((v: any) => ({ id: v, name: v })) || [],
    },
  }

  return { data: dataModify }
}

const mutation = gql`
  mutation ($ids: [UUID!]!, $input: PromoDoublePoinUpdateInput!) {
    response: updatePromoDoublePoinById(ids: $ids, input: $input) {
      id
      user_id
      created_at
      updated_at
      end
      start
      country
      value
      configs
      items
      transactions_aggregate: transactions_promo_double_poin_id_list_aggregate {
        count
      }
    }
  }
`
