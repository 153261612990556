import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  Filter,
  SelectInput,
  SearchInput,
  FunctionField,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin'
import ReactJson from 'react-json-view'
import moment from 'moment'
import { DateRange } from './DateRange'
import ExportButton from '../shared-components/export-button'

const DepositLogsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="user_id" reference="users">
      <SelectInput source="name" alwaysOn />
    </ReferenceInput>
    <SelectInput
      source="status"
      choices={[
        { id: 'paid', name: 'PAID' },
        { id: 'unpaid', name: 'UNPAID' },
      ]}
    />
    <SearchInput source="payment_id" />
    <DateRange alwaysOn />
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton filter={filterValues} resourceType="DEPOSIT-LOG" />
    </TopToolbar>
  )
}

export const DepositLogsList = (props) => (
  <List
    {...props}
    filters={<DepositLogsFilter />}
    filterDefaultValues={{
      dateGte: moment().subtract(1, 'month').calendar(),
      dateLte: moment().format('YYYY/MM/DD'),
    }}
    actions={<ListActions />}
  >
    <Datagrid>
      <FunctionField
        label="Created at"
        render={(record) => (
          <div>
            <label>{moment(record.created_at).format()}</label>
          </div>
        )}
      />
      <ReferenceField source="user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="payment_id" />
      <TextField source="amount" />
      <TextField source="status" />
      <FunctionField
        label="Detail"
        render={(record) => (
          <div>
            <label>
              <ReactJson
                src={record.data}
                shouldCollapse={() => {}}
                name={false}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            </label>
          </div>
        )}
      />
    </Datagrid>
  </List>
)
