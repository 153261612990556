import { updatePricings } from './../../../requests'

export const funcUpdatePricings = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  const {
    period_purchase_limit,
    limit_type_purchase_limit,
    limit_value_purchase_limit,
    ...newData
  } = params.data

  const [, data] = await updatePricings(params.id, {
    ...newData,
    purchase_limit: {
      period: period_purchase_limit.toLowerCase(),
      limit:
        limit_type_purchase_limit.toLowerCase() === 'unlimited'
          ? 'unlimited'
          : limit_value_purchase_limit,
    },
  } as any)

  return { data: data }
}
