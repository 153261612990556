import { client, moco } from '@/src/provider/MbaasClient'
// import { TDAGetManyFn } from '@/src/@types/data-provider/get-many'
// import { Record } from 'ra-core/src/types'

export const getManyPricing: any = async (resource: any, params: any) => {
  const { data } = (await client
    .getTable(resource)
    .include(['organizations', 'products'])
    .filter(moco.composeFilter().in('id', params.ids).toString())
    .limit(25)
    .read()) as unknown as { data: any[] }

  return { data }
}
