import React from 'react'
import {
  Datagrid,
  TextField,
  List,
  FunctionField,
  TextInput,
  Filter,
  SelectInput,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import { useAtom } from 'jotai'

import DrawerEdit from './drawer-edit'
import { countryList } from './../../constants'
import { recordAtom, isDrawerOpenAtom } from './atom'

export default function PageList(props) {
  return (
    <>
      <DrawerEdit />
      <List
        {...props}
        filters={<PostFilter />}
        filterDefaultValues={{
          country: 'MY',
        }}
        exporter={false}
        bulkActionButtons={false}
      >
        <Datagrid>
          <TextField source="product_code" />
          <TextField source="country" />
          <TextField source="channel_code" />
          <FunctionField
            sortable={true}
            label="Period purchase limit"
            render={(record) => record.purchase_limit?.period?.toUpperCase()}
          />
          <FunctionField
            sortable={true}
            label="Limit purchase limit"
            render={(record) => record.purchase_limit?.limit?.toUpperCase()}
          />

          <EditButton />
        </Datagrid>
      </List>
    </>
  )
}

const EditButton = (props) => {
  const [, setRecord] = useAtom(recordAtom)
  const [, setIsDrawerEditOpen] = useAtom(isDrawerOpenAtom)

  return (
    <Button
      onClick={() => {
        setRecord(props.record)
        setIsDrawerEditOpen(true)
      }}
      color="primary"
      startIcon={<EditIcon />}
    >
      Edit
    </Button>
  )
}

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Product Code" source="product_code" alwaysOn />
    <SelectInput source="country" choices={countryList} alwaysOn />
  </Filter>
)
