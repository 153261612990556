const { client } = require('../../MbaasClient')

export const funcGetOneDenomPricings = async (resource, params) => {
  const { data } = await client.getTable('denom_pricings').lookup(params.id)
  let currencySplit = data.currency.split('-')

  return {
    data: {
      ...data,
      currency_from: currencySplit[0],
      currency_to: currencySplit[1],
    },
  }
}
