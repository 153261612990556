import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  required,
  TextInput,
  Edit,
  ReferenceInput,
  SelectInput,
  EditButton,
  Filter,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomToolbar } from './CustomToolbar'
import { DateRange } from './DateRange'
import moment from 'moment'
import ExportButton from '../shared-components/export-button'

const DownloadFilter = (props) => (
  <Filter {...props}>
    <DateRange alwaysOn />
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton filter={filterValues} resourceType="DOCUMENT" />
    </TopToolbar>
  )
}

export const DocumentList = (props) => (
  <List
    {...props}
    filters={<DownloadFilter />}
    filterDefaultValues={{
      dateGte: moment().subtract(1, 'month').calendar(),
      dateLte: moment().format('YYYY/MM/DD'),
    }}
    actions={<ListActions />}
  >
    <Datagrid>
      <ReferenceField reference="organizations" source="organization_id" validate={[required()]}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="tag" />
      <TextField source="content" />
      <EditButton />
    </Datagrid>
  </List>
)

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
}))

export const DocumentCreate = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/documents')
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ReferenceInput
                fullWidth
                className={classes.input}
                reference="organizations"
                source="organization_id"
                validate={[required()]}
              >
                <SelectInput source="name" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <TextInput fullWidth className={classes.input} source="tag" />
            </Grid>
            <Grid item xs={12}>
              <TextInput fullWidth source="content" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}

const EditTitle = ({ record }) => {
  return <span>{record ? `"${record.tag}"` : ''} Edit</span>
}

export const DocumentEdit = (props) => {
  const classes = useStyles()
  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput source="id" disabled fullWidth className={classes.input} />
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                fullWidth
                className={classes.input}
                reference="organizations"
                source="organization_id"
                validate={[required()]}
              >
                <SelectInput source="name" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <TextInput fullWidth source="tag" />
            </Grid>
            <Grid item xs={6}>
              <TextInput fullWidth source="content" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}
