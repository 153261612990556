import { client } from '../../provider/MbaasClient'

export default async function gqlQuery({ query, variables = null }) {
  try {
    const data = await client.gql.query({ query, variables })
    return [null, data]
  } catch (error) {
    return [error, null]
  }
}
