import reqFailedButton from '@/src/requests/failed-button'

export const funcUpdateFailedButtons = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  const input: {
    failed_trx_button: {
      alter_number: boolean
      alter_product: boolean
      reprocess: boolean
    }
    ids: string[]
    product_codes: string[]
    category: string
  } = params.data

  try {
    const { data } = await reqFailedButton.updateOne(input.ids, input)
    return { data }
  } catch (e) {
    console.log(333444, e)
    throw e
  }
}
