import { fetchListPricings } from './../../../requests'

export async function isPricings(params, resource) {
  console.log(623495, { method: 'getList', resource: resource, params: params })
  const [, data] = await fetchListPricings({
    filter: params.filter,
    pagination: params.pagination,
    sort: params.sort,
  })
  return { data: data.rows, total: data.count }
}
