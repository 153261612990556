import React from 'react'
import { useRedirect, useListContext } from 'react-admin'
import {
  TableContainer,
  TablePagination,
  Paper,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button as MuiButton,
  Checkbox,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core'
import {
  Add as AddIcon,
  Edit as EditIcon,
  DragIndicator as DragIndicatorIcon,
  Save as SaveIcon,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import moment from 'moment'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import dataProvider from '../../provider/DataProvider'
import useStyles from './styles'
import { countryList } from './../../constants'
import ReactJson from 'react-json-view'
import ExportButton from '@/src/shared-components/export-button'

import onDragEndTest from '@/src/helper-functions/onDragEndTest'
import onClick_deleteRowsAndUpdatePriority_Test from '@/src/helper-functions/onClick_deleteRowsAndUpdatePriority_Test'
import onChange_TableBodyCheckBox from '@/src/helper-functions/onChange_TableBodyCheckBox'
import TabPanel from '@/src/helper-functions/TabPanel'
import buttonText from '@/src/helper-functions/buttonText'
import setDataStatus from '@/src/helper-functions/setDataStatus'
import { getTabStatus } from '../Advertisements/PageList'

const ExportBtnWrapper = () => {
  const { filterValues } = useListContext()

  return <ExportButton filter={filterValues} resourceType="ADVERTISEMENT" />
}

export default function PageList(props) {
  const classes = useStyles()
  const redirect = useRedirect()

  const [checkActive, setCheckActive] = React.useState({})
  const [checkInactive, setCheckInactive] = React.useState({})
  const [checkExpired, setCheckExpired] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)
  const [isPriorityChangeActive, setIsPriorityChangeActive] = React.useState(false)
  const [isPriorityChangeInactive, setIsPriorityChangeInactive] = React.useState(false)
  const [isPriorityChangeExpired, setIsPriorityChangeExpired] = React.useState(false)
  const [filterGetDataAds, setFilterGetDataAds] = React.useState({
    ads_type: 'running_text',
    country: 'MY',
  })
  const [value, setValue] = React.useState(0)

  const [advertisementsData, setAdvertisementsData] = React.useState({ data: [], count: 0 })
  const [paginationAdvertisements, setPaginationAdvertisements] = React.useState({
    page: 1,
    perPage: 5,
  })

  let AdsStatus = getTabStatus(value)

  React.useEffect(() => {
    getAdvertisementsData(AdsStatus, filterGetDataAds, paginationAdvertisements).then((data) =>
      setAdvertisementsData(data)
    )
  }, [filterGetDataAds, paginationAdvertisements, AdsStatus])

  const getAdvertisementsData = async (AdsStatus, filter = {}, pagination = {}) => {
    const { data, total } = await dataProvider.getList('runningText', {
      filter: { ...filter, status: AdsStatus, date: moment().format(), ads_type: 'running_text' },
      pagination: { ...pagination },
      sort: { field: 'priority', order: 'ASC' },
    })

    const dataRemap = data?.map((v) => {
      const country = countryList.filter((val) => val.id === v.country)[0].name
      const start = moment(v.start).toISOString()
      const end = moment(v.end).toISOString()
      let status = setDataStatus(start, end)

      return {
        id: v.id,
        created_at: moment(v.created_at).format('DD MMM YY h:mm A') || '-',
        priority: v.priority,
        start: moment(v.start).format('DD MMM YY h:mm A') || '-',
        end: moment(v.end).format('DD MMM YY h:mm A') || '-',
        max_shown: v.max_shown,
        running_text_config: v?.running_text_config?.text,
        country: country,
        description: v.description,
        status: status,
      }
    })
    return { data: dataRemap, count: total }
  }

  const rowDragnDrop = (rows, setRows, check, setCheck, setIsPriorityChange) => {
    return (
      <DragDropContext
        onDragEnd={(result) => onDragEndTest(result, rows, setRows, setIsPriorityChange)}
      >
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <TableBody {...provided.droppableProps} ref={provided.innerRef}>
              {rows.map((row, index) => (
                <Draggable key={row.id} draggableId={row.id} index={index}>
                  {(provided, snapshot) => (
                    <TableRow
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      style={{
                        ...provided.draggableProps.style,
                        background: snapshot.isDragging ? 'rgba(245,245,245, 0.75)' : 'none',
                      }}
                    >
                      <TableCell {...provided.dragHandleProps}>
                        <DragIndicatorIcon />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={check[row.id] || false}
                          onChange={(e) => onChange_TableBodyCheckBox(e, check, setCheck)}
                          name={row.id}
                        />
                      </TableCell>
                      <TableCell>{row.created_at}</TableCell>
                      <TableCell>
                        {
                          <ReactJson
                            src={row.running_text_config}
                            name={false}
                            collapsed={true}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                          />
                        }
                      </TableCell>
                      <TableCell>{row.start}</TableCell>
                      <TableCell>{row.end}</TableCell>
                      <TableCell>{row.max_shown}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        <MuiButton
                          size="small"
                          color="primary"
                          startIcon={<EditIcon />}
                          onClick={() => redirect('/runningText/' + row.id)}
                        >
                          Edit
                        </MuiButton>
                      </TableCell>
                    </TableRow>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  const rowNotDragnDrop = (rows, check, setCheck) => {
    return (
      <TableBody>
        {rows?.map((row, index) => (
          <TableRow>
            <TableCell>{/* <DragIndicatorIcon /> */}</TableCell>
            <TableCell>
              <Checkbox
                color="primary"
                checked={check[row.id] || false}
                onChange={(e) => onChange_TableBodyCheckBox(e, check, setCheck)}
                name={row.id}
              />
            </TableCell>
            <TableCell>{row.created_at}</TableCell>
            <TableCell>
              {
                <ReactJson
                  src={row.running_text_config}
                  name={false}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
              }
            </TableCell>
            <TableCell>{row.start}</TableCell>
            <TableCell>{row.end}</TableCell>
            <TableCell>{row.max_shown}</TableCell>
            <TableCell>{row.status}</TableCell>

            <TableCell>
              <MuiButton
                size="small"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => redirect('/runningText/' + row.id)}
              >
                Edit
              </MuiButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  const TableDataPagination = React.memo(
    ({
      rows,
      count,
      pagination,
      setPagination,
      setRows,
      check,
      setCheck,
      isPriorityChange,
      setIsPriorityChange,
    }) => {
      return (
        <>
          <div className={classes.toolbar}>
            {(isPriorityChange || Object.keys(check).length !== 0) && (
              <MuiButton
                className={classes.button1}
                disabled={isLoading}
                color="primary"
                size="small"
                variant="contained"
                startIcon={isLoading ? <CircularProgress size={20} /> : <SaveIcon />}
                onClick={() =>
                  onClick_deleteRowsAndUpdatePriority_Test(
                    rows,
                    check,
                    isPriorityChange,
                    pagination,
                    setIsLoading
                  )
                }
              >
                {buttonText(isPriorityChange, check)}
              </MuiButton>
            )}
          </div>

          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Text</TableCell>
                  <TableCell>Valid From</TableCell>
                  <TableCell>Valid Until</TableCell>
                  <TableCell>Max Shown</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {filterGetDataAds.country
                ? rowDragnDrop(rows, setRows, check, setCheck, setIsPriorityChange)
                : rowNotDragnDrop(rows, check, setCheck)}
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={pagination.perPage}
              page={pagination.page - 1}
              onPageChange={(_, value) => {
                setPagination({ ...pagination, page: value + 1 })
                // reset check / priority
                setIsPriorityChange(false)
                setCheckExpired({})
              }}
              onRowsPerPageChange={(e) => {
                setPagination({ page: 1, perPage: e.target.value })
                // reset check / priority
                setIsPriorityChange(false)
                setCheckExpired({})
              }}
            />
          </TableContainer>
        </>
      )
    },
    []
  )

  return (
    <>
      <div className={classes.toolbar}>
        <div>
          <Autocomplete
            options={countryList}
            getOptionLabel={(option) => option.name}
            defaultValue={countryList[9]}
            className={classes.autocomplete1}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Country" variant="filled" />
            )}
            onChange={(_, value) => {
              if (value) {
                setFilterGetDataAds({ ...filterGetDataAds, country: value.id })
                // reset check / priority
                setIsPriorityChangeActive(false)
                setIsPriorityChangeInactive(false)
                setIsPriorityChangeExpired(false)
                setCheckExpired({})
              } else {
                delete filterGetDataAds.country
                setFilterGetDataAds({ ...filterGetDataAds })
                // reset check / priority
                setIsPriorityChangeActive(false)
                setIsPriorityChangeInactive(false)
                setIsPriorityChangeExpired(false)
                setCheckExpired({})
              }
            }}
          />
        </div>
        <div>
          <MuiButton
            size="small"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => redirect('/runningText/create')}
          >
            Create
          </MuiButton>
          <ExportBtnWrapper />
        </div>
      </div>

      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={(e, newValue) => setValue(newValue)}
            aria-label="simple tabs example"
          >
            <Tab label="Active" />
            <Tab label="Inactive" />
            <Tab label="Expired" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <TableDataPagination
            count={advertisementsData?.count || 0}
            rows={advertisementsData?.data || []}
            setRows={setAdvertisementsData}
            pagination={paginationAdvertisements}
            setPagination={setPaginationAdvertisements}
            check={checkActive}
            setCheck={setCheckActive}
            isPriorityChange={isPriorityChangeActive}
            setIsPriorityChange={setIsPriorityChangeActive}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableDataPagination
            count={advertisementsData?.count || 0}
            rows={advertisementsData?.data || []}
            setRows={setAdvertisementsData}
            pagination={paginationAdvertisements}
            setPagination={setPaginationAdvertisements}
            check={checkInactive}
            setCheck={setCheckInactive}
            isPriorityChange={isPriorityChangeInactive}
            setIsPriorityChange={setIsPriorityChangeInactive}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TableDataPagination
            count={advertisementsData?.count || 0}
            rows={advertisementsData?.data || []}
            pagination={paginationAdvertisements}
            setPagination={setAdvertisementsData}
            setRows={setPaginationAdvertisements}
            check={checkExpired}
            setCheck={setCheckExpired}
            isPriorityChange={isPriorityChangeExpired}
            setIsPriorityChange={setIsPriorityChangeExpired}
          />
        </TabPanel>
      </div>
    </>
  )
}
