import { client } from '../provider/MbaasClient'

export const updateEnableCustomerAndUser = async ({
  customerId,
  userId,
  dataCustomer,
  dataUser,
}) => {
  try {
    const { data: resultUser, error: errorUser } = await client.getTable('users').update({
      id: userId,
      data: {
        enable: dataUser.enable,
      },
    })

    let resultCustomer
    let errorCustomer
    if (customerId) {
      const { data: _resultCustomer, error: _errorCustomer } = await client
        .getTable('customers')
        .update({
          id: customerId,
          data: {
            enable: dataCustomer.enable,
            metadata: dataCustomer.metadata,
          },
        })
      resultCustomer = _resultCustomer
      errorCustomer = _errorCustomer
    }
    // eslint-disable-next-line
    if (errorUser || errorCustomer) throw { errorUser, errorCustomer }
    return [null, { ...resultUser, customers: resultCustomer }]
  } catch (e) {
    return [e, null]
  }
}
