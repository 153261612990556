import moment from 'moment'

export default function setDataStatus(start, end) {
  if (moment(moment().toISOString()).isBetween(start, end)) {
    return 'ACTIVE'
  } else if (moment(moment().toISOString()).isBefore(end)) {
    return 'INACTIVE'
  }

  return 'EXPIRED'
}
