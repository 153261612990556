import gql from 'graphql-tag'
import { client } from '../provider/MbaasClient'

/* MAIN FUNCTION */
const fetchListPricings = async ({ pagination, sort, filter }) => {
  try {
    const { page, perPage } = pagination
    let { field, order } = sort

    const { available, organization_id, denom, currency, country, product_name } = filter

    const where = `{
        ${available ? `available: { _eq: ${available} }` : ''}
        ${organization_id ? `organization_id_data: { id: { _eq: "${organization_id}" } }` : ''}
        ${
          denom || product_name
            ? `product_id_data: { 
          ${denom ? `denomination: { _eq: ${denom} }` : ''} 
          ${product_name ? `name: { _ilike: "%${product_name}%" }` : ''} 
        }`
            : ''
        }
        ${currency ? `currency: { _eq: "${currency}" }` : ''}
        ${country ? `country: { _eq: "${country}" }` : ''}
      }`

    const mapOrder = {
      'product_id_data.name': `{ product_id_data: { name: ${order} } }`,
      id: `{ id: ${order} }`,
      'organization_id_data.name': `{ organization_id_data: { name: ${order} } }`,
      channel_code: `{ channel_code: ${order} }`,
      value: `{ value: ${order} }`,
      denom: `{ product_id_data: { denomination: ${order} } }`,
      reference: `{ reference: ${order} }`,
      available: `{ available: ${order} }`,
      currency: `{ currency: ${order} }`,
      country: `{ country: ${order} }`,
    }

    const gqlResult = await client.gql.query({
      query: gql`
        {
          allPricingsList(
            where: ${where}
            orderBy: ${mapOrder[field] || ''}
            limit: ${perPage}
            offset: ${(page - 1) * perPage}
          ) {
            data {
              id
              product_id_data {
                id
                name
                denomination
              }
              organization_id_data {
                id
                name
              }
              channel_code
              value
              reference
              available
              poin
              currency
              country
              method
              auto_update
              purchase_limit
            }
          }
          aggregatePricings(
            where: ${where}
          ) {
            count
          }
        }
      `,
    })

    return [
      null,
      {
        rows:
          gqlResult.allPricingsList?.data.map((pricing) => {
            return {
              ...pricing,
              period_purchase_limit: pricing.purchase_limit.period.toLowerCase(),
              limit_type_purchase_limit:
                pricing.purchase_limit.limit.toLowerCase() === 'unlimited' ? 'unlimited' : 'other',
              limit_value_purchase_limit:
                pricing.purchase_limit.limit.toLowerCase() === 'unlimited'
                  ? 'unlimited'
                  : pricing.purchase_limit.limit,
            }
          }) || [],
        count: gqlResult.aggregatePricings?.count || 0,
      },
    ]
  } catch (error) {
    return [error, { rows: [], count: 0 }]
  }
}

export default fetchListPricings
