import { client, bucket, StorageURL } from '../../MbaasClient'

export const funcUpdateMessengerMsgSeries = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  let updatedData = params.data
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  const prevContent = params.previousData.content
  const prevType = params.previousData.type
  let { content, type } = params.data

  if ((prevType === 'image' && params.data.imgUpload) || type !== 'image') {
    await client.storage.removeFile({
      filePath: prevContent.image_id,
      bucket: bucket['advertisements'],
    })
  }

  if (type === 'image' && params.data.imgUpload) {
    const { imgUpload } = params.data
    const uploadImage: any = await client.storage.upload({
      params: {
        filename: imgUpload.rawFile.name,
        bucket: bucket['advertisements'],
        expire: '60',
      },
      file: imgUpload.rawFile,
    })
    content = {
      url: `${StorageURL}/${bucket['advertisements']}/${uploadImage.data.id}`,
      image_id: uploadImage.data.id,
    }
  } else if (type === 'video') {
    content = {
      url: content.url,
      title: content.title,
      subtitle: content.subtitle,
    }
  } else if (type === 'text') {
    content = {
      text: content.text,
    }
  }

  const { data } = await client.getTable(resource).update(
    Object.assign({
      id: params.id,
      data: {
        ...updatedData,
        content: content,
      },
    })
  )

  return { data: data }
}
