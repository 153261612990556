import fetchFindActiveDefaultHeaderImage from './fetch-find-active-default-header-image'

const { client } = require('./../provider/MbaasClient')

const updateHeaderImage = async (
  id,
  { url, start, end, defaultImage, created_by, updated_by },
  previousData
) => {
  if (defaultImage) {
    const [, { hasActiveDefaultImage, listIdActiveDefaultImage }] =
      await fetchFindActiveDefaultHeaderImage()
    const isOwnActiveDefault = listIdActiveDefaultImage.some((v) => v.id === id)
    if (hasActiveDefaultImage && !isOwnActiveDefault)
      return Promise.reject('Error: has active default image')
  }

  let imageUrl = JSON.stringify(url)

  const hasNewImage = url?.rawFile
  if (hasNewImage) {
    const { bucket, filepath } = previousData?.url
    await client.storage.removeFile({ filePath: filepath, bucket })

    const filename = `${Date.now()}-${url?.rawFile?.name}`
    const { data: uploadedData } = await client.storage.upload({
      params: { filename, bucket: 'webview-configs' },
      file: url?.rawFile,
    })

    imageUrl = JSON.stringify({
      imageUrl: uploadedData?.attributes?.url.split('?')[0],
      filepath: filename,
      type: url?.rawFile?.type,
      bucket: 'webview-configs',
    })
  }

  try {
    const { data } = await client.getTable('webview_headers').update({
      id,
      data: {
        url: imageUrl,
        start,
        end,
        default: defaultImage,
        created_by,
        updated_by,
      },
    })

    return [null, data]
  } catch (error) {
    return [error, null]
  }
}
export default updateHeaderImage
