import React from 'react'
import { useInput } from 'react-admin'

import NumberFormatInput from './number-format-input'

const RaNumberFormatInput = ({ fullWidth, disabled, style, ...props }) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  return (
    <NumberFormatInput
      disabled={disabled}
      fullWidth={fullWidth}
      required={isRequired}
      onChange={onChange}
      label={props.label}
      error={!!(touched && error)}
      helperText={touched && error && 'required'}
      style={style}
      {...rest}
      //
    />
  )
}

export default RaNumberFormatInput
