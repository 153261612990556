import React from 'react'
import { useAuthProvider, linkToRecord } from 'react-admin'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Button as MuiButton,
  Checkbox,
  Paper,
  TextField as MuiTextField,
  CircularProgress,
} from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import {
  Add as AddIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  History as HistoryIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, Link } from 'react-router-dom'
import dataProvider from '../../provider/DataProvider'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import gql from 'graphql-tag'
import { buildArgs } from '../../../src/provider/DataProvider/shared-script/buildArgs'
import { client } from '../../../src/provider/MbaasClient'

export default function PageEdit(props) {
  const classes = useStyles()
  const authP = useAuthProvider()
  const history = useHistory()

  const [disabledApprove, setDisabledApprove] = React.useState(false)
  const [disabledReject, setDisabledReject] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [remark, setRemark] = React.useState('')
  const [poin, setPoin] = React.useState(0)
  const [creditType, setCreditType] = React.useState('')
  const [oldCreditType, setOldCreditType] = React.useState('')
  const [oldPoin, setOldPoin] = React.useState(0)
  const [credit, setCredit] = React.useState(0)
  const [oldCredit, setOldCredit] = React.useState(0)
  const [customers, setCustomers] = React.useState([])
  const [addCust, setAddCust] = React.useState(false)
  const [custsAll, setCustsAll] = React.useState([])
  const [custToBeAdded, setCustToBeAdded] = React.useState()
  const [checked, setChecked] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const isCustsHasStatus = customers.some((val) => ['approved', 'rejected'].includes(val.status))
  const isCustHasStatus = (custStatus) => ['approved', 'rejected'].includes(custStatus)
  const [searchTerm, setSearchTerm] = React.useState('')
  let debounceTimeout
  const isUserCanApprove = [
    'admin@telin.com.my',
    'secret9092@gmail.com',
    'elys@rocketmail.com',
    'devyps73@gmail.com',
    'muhammad.rahmahalim@gmail.com',
    'devyps@telin.my',
    'ellysa@telin.my',
  ].includes(authP.email())

  React.useEffect(() => {
    let where = {
      _and: [
        {
          name: {
            _ilike: `%${searchTerm}%`,
          },
        },
        {
          id: {
            _nin: customers.map((val) => val.customer_id),
          },
        },
      ],
    }
    // Define debounce function
    function debounce(func, delay) {
      clearTimeout(debounceTimeout)
      debounceTimeout = setTimeout(() => {
        func()
      }, delay)
    }

    // Fetch data function
    async function fetchData() {
      const args = buildArgs({
        order: 'DESC',
        size: 25,
        pageNumber: 0,
        sort: 'created_at',
      })

      const gqlResult = await client.gql.query({
        query: gql`
      query userList($where: CustomersWhereInput) {
        allCustomersList(
            where:$where, ${args}
          ) {
          data {
            id
            name
            channel_id
            channel
            users_customer_id_list {
              data {
                msisdn
              }
            }
          }
          count
        }
      }
    `,
        variables: {
          where,
        },
      })

      if (gqlResult && gqlResult.allCustomersList) {
        setCustsAll(gqlResult.allCustomersList.data)
      }
    }

    fetchData()
  }, [searchTerm, customers])

  React.useEffect(() => {
    setCode(props.id)
    getPamData(props.id)
  }, [props.id])

  const getPamData = async (id) => {
    setIsLoading(true)
    const pamData = await dataProvider.getOne('poin_add_manually', { id })
    console.log(789039123123, 'pamData', pamData)
    setPoin(pamData.data.data.poin)
    setOldPoin(pamData.data.data.poin)
    setCredit(pamData.data.data.credit)
    setOldCredit(pamData.data.data.credit)
    setCreditType(pamData.data.data.credit_type)
    setOldCreditType(pamData.data.data.credit_type)
    setCustomers(pamData.data.data.customers)
    setRemark(pamData.data.data.customers[0].remark)
    // setCustsAll(pamData.data.data.customerAll)
    setIsLoading(false)
  }

  const custsAllFilter = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  })

  const addCustomer = async () => {
    setAddCust(false)
    await dataProvider.create('poin_add_manually', {
      data: {
        credit: credit,
        credit_type: creditType,
        customer_id: custToBeAdded.id,
        code: code,
        remark: remark,
        created_by: customers[0].created_by,
        created_at: customers[0].created_at,
        dpAction: 'createOne',
      },
    })
    window.location.reload()
  }

  const updatePoin = async () => {
    const pamData = await dataProvider.getOne('poin_add_manually', { id: props.id })
    const arrPamId = pamData.data.data.customers.map((customer) => customer.pam_id)
    await dataProvider.updateMany('poin_add_manually', {
      ids: arrPamId,
      data: {
        poin: Number(poin),
        code: code,
        remark: remark,
        approved_by: null,
        status: null,
      },
    })
    window.location.reload()
  }

  const updateCredit = async () => {
    const pamData = await dataProvider.getOne('poin_add_manually', { id: props.id })
    const arrPamId = pamData.data.data.customers.map((customer) => customer.pam_id)
    await dataProvider.updateMany('poin_add_manually', {
      ids: arrPamId,
      data: {
        credit: Number(credit),
        credit_type: creditType,
        code: code,
        remark: remark,
        approved_by: null,
        status: null,
      },
    })
    window.location.reload()
  }

  const approveOne = async (pam_id, code) => {
    setDisabledApprove(true)
    setDisabledReject(true)
    await dataProvider.update('poin_add_manually', {
      id: pam_id,
      data: {
        code: code,
        remark: remark,
        approved_by: authP.id(),
        status: 'approved',
      },
    })
    window.location.reload()
  }

  const rejectOne = async (pam_id, code) => {
    setDisabledApprove(true)
    setDisabledReject(true)
    await dataProvider.update('poin_add_manually', {
      id: pam_id,
      data: {
        code: code,
        remark: remark,
        approved_by: null,
        status: 'rejected',
      },
    })
    window.location.reload()
  }

  const approveMany = async () => {
    setIsLoading(true)
    await dataProvider.updateMany('poin_add_manually', {
      ids: [...new Set(checked)],
      data: {
        code: code,
        remark: remark,
        approved_by: authP.id(),
        status: 'approved',
      },
    })
    window.location.reload()
  }

  const rejectMany = async () => {
    setIsLoading(true)
    await dataProvider.updateMany('poin_add_manually', {
      ids: [...new Set(checked)],
      data: {
        code: code,
        remark: remark,
        approved_by: null,
        status: 'rejected',
      },
    })
    window.location.reload()
  }

  const onChangeChecked = ({ pam_id, isChecked }) => {
    let newChecked = [...checked, pam_id]
    if (!isChecked) newChecked = checked.filter((val_pam_id) => val_pam_id !== pam_id)
    setChecked(newChecked)
  }

  const handleChangeCreditType = (value) => {
    setCreditType(value)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell>
                <MuiTextField value={code} name="code" label="Code" fullWidth disabled />
              </TableCell>
              <TableCell>
                <MuiTextField value={remark || ''} label="Remark" fullWidth disabled />
              </TableCell>
              <TableCell>
                <MuiTextField
                  onChange={(e) => setCredit(e.target.value)}
                  value={credit}
                  label="Credit"
                  type="number"
                  fullWidth
                  disabled={isCustsHasStatus}
                />
              </TableCell>
              <TableCell>
                <FormControl variant="filled" style={{ minWidth: '280px' }}>
                  <InputLabel id="demo-simple-select-label">Credit Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={creditType}
                    onChange={(e) => handleChangeCreditType(e.target.value)}
                    variant="standard"
                  >
                    <MenuItem value="GIMMICK">GIMMICK</MenuItem>
                    <MenuItem value="REFUND">REFUND</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}>
                <div className={classes.toolbar}>
                  <div></div>
                  <div>
                    <MuiButton
                      size="small"
                      color="primary"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => (addCust ? setAddCust(false) : setAddCust(true))}
                    >
                      Add Customer
                    </MuiButton>
                  </div>
                </div>
                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Customer Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addCust && (
                        <>
                          {!isLoading ? (
                            <TableRow>
                              <TableCell colSpan={3}>
                                <Autocomplete
                                  filterOptions={custsAllFilter}
                                  onChange={(e, newValue) => {
                                    setCustToBeAdded(newValue)
                                  }}
                                  options={custsAll}
                                  getOptionLabel={(option) =>
                                    option?.channel === 'PWA_APP'
                                      ? `${option?.name} - ${option?.channel} - ${option?.users_customer_id_list?.data[0]?.msisdn}`
                                      : `${option?.name} - ${option?.channel} - ${option?.channel_id}`
                                  }
                                  fullWidth
                                  renderInput={(params) => (
                                    <MuiTextField
                                      {...params}
                                      label="Type in customer name"
                                      variant="standard"
                                      autoFocus
                                      onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <MuiButton
                                  size="small"
                                  color="primary"
                                  startIcon={<AddIcon />}
                                  variant="contained"
                                  onClick={addCustomer}
                                >
                                  Add
                                </MuiButton>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={4}>
                                <CircularProgress size={20} />
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                      {customers.length > 0 && !isLoading ? (
                        customers.map((customer) => (
                          <TableRow key={customer.customer_id}>
                            <TableCell>
                              {!isCustHasStatus(customer.status) && (
                                <Checkbox
                                  color="primary"
                                  checked={checked.find((pam_id) => pam_id === customer.pam_id)}
                                  onChange={(e) =>
                                    onChangeChecked({
                                      pam_id: customer.pam_id,
                                      isChecked: e.target.checked,
                                    })
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              <CustUserName
                                dataType="customers"
                                id={customer.customer_id}
                                name={customer.customer_name}
                              />
                            </TableCell>

                            <TableCell>
                              {customer.status === 'approved' ? (
                                <>
                                  APPROVED BY&nbsp;
                                  <CustUserName
                                    dataType="users"
                                    id={customer.approved_by}
                                    name={customer.user_name}
                                  />
                                </>
                              ) : customer.status === 'rejected' ? (
                                'REJECTED'
                              ) : (
                                'AWAITING APPROVAL'
                              )}
                            </TableCell>
                            <TableCell>
                              {!isCustHasStatus(customer.status) && isUserCanApprove && (
                                <>
                                  <MuiButton
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<EditIcon />}
                                    onClick={() => approveOne(customer.pam_id, code)}
                                    disabled={disabledApprove}
                                  >
                                    Approve
                                  </MuiButton>
                                  <MuiButton
                                    size="small"
                                    style={{ color: '#f44336' }}
                                    startIcon={<HistoryIcon />}
                                    onClick={() => rejectOne(customer.pam_id, code)}
                                    disabled={disabledReject}
                                  >
                                    Reject
                                  </MuiButton>
                                </>
                              )}
                              {/* <MuiButton
                                size="small"
                                color="secondary"
                                style={{ color: '#f44336' }}
                                startIcon={<HistoryIcon />}
                                onClick={() => deleteOne(customer.pam_id)}
                              >
                                Delete
                              </MuiButton> */}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <CircularProgress size={20} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={classes.toolbar}>
                  <div>
                    {/* {!isCustsHasStatus && poin && (
                      <MuiButton
                        size="medium"
                        color="primary"
                        variant="contained"
                        style={{ marginRight: '10px' }}
                        startIcon={<SaveIcon />}
                        disabled={+oldPoin === +poin}
                        onClick={updatePoin}
                      >
                        Update Poin
                      </MuiButton>
                    )} */}

                    {!isCustsHasStatus && (
                      <MuiButton
                        size="medium"
                        color="primary"
                        variant="contained"
                        style={{ marginRight: '10px' }}
                        startIcon={<SaveIcon />}
                        disabled={+oldCredit === +credit || oldCreditType === creditType}
                        onClick={updateCredit}
                      >
                        Update Credit
                      </MuiButton>
                    )}

                    {Object.keys(checked).length !== 0 && (
                      <>
                        <MuiButton
                          className={classes.button1}
                          color="primary"
                          variant="contained"
                          startIcon={!isLoading ? <EditIcon /> : <CircularProgress size={20} />}
                          disabled={isLoading}
                          onClick={approveMany}
                        >
                          Approve Many
                        </MuiButton>
                        <MuiButton
                          className={classes.button1}
                          startIcon={<HistoryIcon />}
                          style={{ color: '#f44336' }}
                          onClick={rejectMany}
                        >
                          Reject Many
                        </MuiButton>
                      </>
                    )}
                  </div>
                  <div>
                    <MuiButton
                      size="medium"
                      color="primary"
                      variant="outlined"
                      onClick={() => history.push({ pathname: '/poin_add_manually' })}
                    >
                      Back
                    </MuiButton>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
    height: 350,
  },
  input: {
    marginBottom: '-20px',
  },
  text: {
    margin: 0,
  },
  detailsBox: {
    borderBottom: 'solid thin rgba(0, 0, 0, 0.12)',
    marginBottom: '20px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
})

const CustUserName = ({ dataType, id, name = '' }) => {
  const linkToUser = linkToRecord(`/${dataType}`, id, 'show')

  return <Link to={linkToUser}>{name}</Link>
}
