import React, { useState, useEffect, cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SearchInput,
  Filter,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  AutocompleteInput,
} from 'react-admin'
import { client } from './../../provider/MbaasClient'
import ExportButton from '@/src/shared-components/export-button'

const KBaseFilter = (props) => {
  const [categoryList, setCategoryList] = useState([])
  const [tagList, setTagList] = useState([])

  useEffect(() => {
    ;(async () => {
      const { data } = await client.getTable('knowledge_base').read()
      setCategoryList(
        data
          .map((x) => {
            return {
              id: x.category,
              name: x.category,
            }
          })
          .filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i)
      )
      const tagArray = []
      data.forEach((x) => {
        if (x.tag) {
          if (x.tag.includes(',')) {
            x.tag.split(',').forEach((y) => {
              tagArray.push({
                id: y,
                name: y,
              })
            })
          } else {
            tagArray.push({
              id: x.tag,
              name: x.tag,
            })
          }
        }
      })
      setTagList(tagArray.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i))
    })()
  }, [])
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <AutocompleteInput label="Category" source="category" choices={categoryList} />
      <AutocompleteInput label="Tag" source="tag" choices={tagList} />
    </Filter>
  )
}

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton filter={filterValues} resourceType="KBASE" />
    </TopToolbar>
  )
}

const ListTitle = ({ record }) => {
  return <span>Knowledge Base</span>
}

export default function PageList(props) {
  return (
    <List {...props} filters={<KBaseFilter />} actions={<ListActions />} title={<ListTitle />}>
      <Datagrid>
        <TextField source="question" />
        <TextField source="answer" />
        <TextField source="category" />
        <TextField source="tag" label="Tags" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
