const { client } = require('./../provider/MbaasClient')

const updateDenomPricingLookup = async (listProductId, denom_pricing_lookup) => {
  try {
    let arrPromise = []

    listProductId.forEach((productId) => {
      const promise = client.getTable('products').update({
        id: productId,
        data: { denom_pricing_lookup },
      })

      arrPromise.push(promise)
    })

    const result = await Promise.all(arrPromise)
    return [null, result]
  } catch (error) {
    return [error, null]
  }
}

export default updateDenomPricingLookup
