import { client } from '../../MbaasClient'

export const funcUpdateStorePoints = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  let updatedData = params.data
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  const { address } = updatedData
  const pruned_address = address.replace(/[^a-zA-Z0-9_]+/gm, '')

  let storeData = Object.assign({
    ...updatedData,
    affiliation: '711',
    pruned_address: pruned_address,
  })

  const { data } = await client.getTable(resource).update(
    Object.assign({
      id: params.id,
      data: storeData,
    })
  )
  return { data: data }
}
