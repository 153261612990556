import { deleteManyMessengerMsgSeries } from '@/src/provider/DataProvider/populate-deleteMany-resource/messenger-msg-series'
import { deleteManyHeaderImage_ } from '@/src/provider/DataProvider/populate-deleteMany-resource/header-image'
// import { TDADeleteManyFn } from '@/src/@types/data-provider/delete-many'
import { deleteManyDefault } from '@/src/provider/DataProvider/populate-deleteMany-resource/default'

const funcDeleteMany: any = async (resource: any, params: any) => {
  console.log('delete-many', { resource, params })

  if (resource === 'messenger_msg_series') {
    return await deleteManyMessengerMsgSeries(resource, params)
  }

  if (resource === 'header-image') {
    return await deleteManyHeaderImage_(resource, params)
  }

  return await deleteManyDefault(resource, params)
}

export default funcDeleteMany
