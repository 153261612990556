import { differenceWith, toPairs, isEqual, fromPairs } from 'lodash'

import { client } from '../../MbaasClient'

export const funcUpdateUsers = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  let updatedData = params.data
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  delete updatedData.metadata
  delete updatedData.customer_id
  delete updatedData.fcm_tokens

  // compare old data with new data and send the new one
  const changes = differenceWith(toPairs(params.data), toPairs(params.previousData), isEqual)

  const changes2 = fromPairs(changes)

  updatedData = { ...changes2 }

  const { data } = await client.getTable(resource).update(
    Object.assign({
      id: params.id,
      data: updatedData,
    })
  )

  return { data: data }
}
