import React, { useState, useEffect, cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  EmailField,
  BooleanField,
  PasswordInput,
  TextInput,
  Edit,
  EditButton,
  required,
  useNotify,
  useRedirect,
  FunctionField,
  ChipField,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  useInput,
  email,
  DateField,
  Filter,
  useListContext,
  TopToolbar,
  CreateButton,
  Button,
  sanitizeListRestProps,
  useAuthProvider,
  DateTimeInput,
  useUpdate,
  useRefresh,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AceEditor from 'react-ace'
import ReactJson from 'react-json-view'
import EnableIcon from '@material-ui/icons/VerifiedUser'
import DisableIcon from '@material-ui/icons/Block'

import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-textmate'
import { CustomToolbar } from './CustomToolbar'

import ExportButton from '../shared-components/export-button'

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="name" source="search_by_name" />
    <TextInput label="email" source="search_by_email" />
    <SelectInput
      label="Role"
      source="roles"
      optionText="label"
      choices={[
        { id: 'PWA_APP', label: 'PWA_APP' },
        { id: 'Administrator', label: 'Administrator' },
        { id: 'Ads_admin', label: 'Ads_admin' },
        { id: 'PWA_UPDATER', label: 'PWA_UPDATER' },
      ]}
    />
    <SelectInput
      label="Status"
      source="status"
      optionText="label"
      choices={[
        { id: true, label: 'ENABLE' },
        { id: false, label: 'DISABLE' },
      ]}
    />
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton resourceType="USER" />
    </TopToolbar>
  )
}

const Buttons = ({ record: { customers, ...users } }) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const [update, { loading, error }] = useUpdate(
    'updateEnableCustomerAndUser',
    {
      customerId: customers?.id || '',
      userId: users.id,
    },
    {
      dataCustomer: {
        enable: !users.enable,
      },
      dataUser: {
        enable: !users.enable,
      },
    },
    { ...users, customers },
    {
      onSuccess: (data) => {
        refresh()
        notify(`Success update data`)
      },
      onError: (error) => {
        notify(`Update data error: ${error.message}`, { type: 'warning' })
      },
    }
  )

  if (error) {
    notify(JSON.stringify(error), 'error')
  }

  return (
    <>
      <Button
        label={users.enable ? 'Disable' : 'Enable'}
        onClick={update}
        color="primary"
        disabled={loading}
      >
        {users.enable ? <DisableIcon /> : <EnableIcon />}
      </Button>
    </>
  )
}

export const UserList = (props) => {
  const authP = useAuthProvider()
  const redirect = useRedirect()
  if (authP.activeRole() === 'Ads_admin') redirect('/advertisements')

  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={<UserFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid>
        <DateField source="created_at" label="Registration time" showTime />
        <EmailField source="email" label="Email" />
        <TextField source="name" label="Name" />
        <ChipField source="roles" />
        <FunctionField
          sortable={true}
          label="Metadata"
          render={(record) => (
            <ReactJson
              src={record.metadata || {}}
              shouldCollapse={() => {}}
              name={false}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          )}
        />
        <BooleanField source="enable" label="Enable" />
        <EditButton />
        <Buttons />
      </Datagrid>
    </List>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },
  form: {
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px ',
  },
  text: {
    color: '#0000008A',
  },
}))

const RolesInput = (props) => {
  const [rolesValue, setRolesValue] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const roles = useInput(props)

  useEffect(() => {
    setRolesValue(JSON.stringify(roles.input.value, null, '\t'))
    setIsChanged(false)
  }, [roles.input.value])

  function onChange(newValue) {
    setRolesValue(newValue)
    setIsChanged(true)
  }

  function saveToForm() {
    const parsed = JSON.parse(rolesValue)
    roles.input.onChange(parsed)
  }

  return (
    <>
      <AceEditor
        name="roles"
        style={{ height: '200px', width: '100%' }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={rolesValue}
      />
      {isChanged && (
        <Button variant="contained" size="small" color="primary" onClick={saveToForm}>
          <>Commit Changes</>
        </Button>
      )}
    </>
  )
}

const FcmTokensInput = (props) => {
  const [FcmTokensValue, setFcmTokensValue] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const fcm_tokens = useInput(props)

  useEffect(() => {
    setFcmTokensValue(JSON.stringify(fcm_tokens.input.value, null, '\t'))
    setIsChanged(false)
  }, [fcm_tokens.input.value])

  function onChange(newValue) {
    setFcmTokensValue(newValue)
    setIsChanged(true)
  }

  function saveToForm() {
    const parsed = JSON.parse(FcmTokensValue)
    fcm_tokens.input.onChange(parsed)
  }

  return (
    <>
      <AceEditor
        name="fcm_tokens"
        style={{ height: '200px', width: '100%' }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={FcmTokensValue}
      />
      {isChanged && (
        <Button variant="contained" size="small" color="primary" onClick={saveToForm}>
          <>Commit Changes</>
        </Button>
      )}
    </>
  )
}

const KycDataInput = (props) => {
  const [KycDataValue, setKycDataValue] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const kyc_data = useInput(props)

  useEffect(() => {
    setKycDataValue(JSON.stringify(kyc_data.input.value, null, '\t'))
    setIsChanged(false)
  }, [kyc_data.input.value])

  function onChange(newValue) {
    setKycDataValue(newValue)
    setIsChanged(true)
  }

  function saveToForm() {
    const parsed = JSON.parse(KycDataValue)
    kyc_data.input.onChange(parsed)
  }

  return (
    <>
      <AceEditor
        name="kyc_data"
        style={{ height: '200px', width: '100%' }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={KycDataValue}
      />
      {isChanged && (
        <Button variant="contained" size="small" color="primary" onClick={saveToForm}>
          <>Commit Changes</>
        </Button>
      )}
    </>
  )
}

export const UserCreate = (props) => {
  const classes = useStyles()
  const validateEmail = [required(), email()]

  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/users')
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="email"
                  type="email"
                  validate={validateEmail}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="name"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={6}>
                <PasswordInput className={classes.input} fullWidth source="password" />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="twitter" />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="facebook" />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="google" />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput className={classes.input} fullWidth source="deactivated_at" />
              </Grid>
              <Grid item xs={6}>
                <ReferenceInput
                  fullWidth
                  className={classes.input}
                  label="Level"
                  source="level_id"
                  reference="kyc_levels"
                >
                  <SelectInput optionText="level" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={6}>
                <ReferenceInput
                  fullWidth
                  className={classes.input}
                  source="organization_id"
                  reference="organizations"
                >
                  <SelectInput source="name" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="msisdn" />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput className={classes.input} fullWidth source="verified" />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput className={classes.input} fullWidth source="enable" />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="ref_number" />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="kyc_status" />
              </Grid>
              <Grid item xs={12}>
                <TextInput className={classes.input} fullWidth source="kyc_reason" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.text}>KYC Data</p>
                <KycDataInput className={classes.input} fullWidth source="kyc_data" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.text}>FCM Tokens</p>
                <FcmTokensInput className={classes.input} fullWidth source="fcm_tokens" />
              </Grid>
              <Grid item xs={12}>
                <p className={classes.text}>Roles</p>
                <RolesInput fullWidth source="roles" />
              </Grid>
            </Grid>
          </div>
        </div>
      </SimpleForm>
    </Create>
  )
}

const EditTitle = ({ record }) => {
  return <span>{record ? `"${record.email}"` : ''} Edit</span>
}

export const UserEdit = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <div className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="id" disabled />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="email"
                  validate={[required()]}
                  type="email"
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  className={classes.input}
                  fullWidth
                  source="name"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="twitter" />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="facebook" />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="google" />
              </Grid>
              <Grid item xs={6}>
                <DateTimeInput className={classes.input} fullWidth source="deactivated_at" />
              </Grid>
              <Grid item xs={6}>
                <ReferenceInput
                  fullWidth
                  className={classes.input}
                  label="Level"
                  source="level_id"
                  reference="kyc_levels"
                >
                  <SelectInput optionText="level" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={6}>
                <ReferenceInput
                  fullWidth
                  className={classes.input}
                  source="organization_id"
                  reference="organizations"
                >
                  <SelectInput source="name" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="msisdn" />
              </Grid>
              <Grid item xs={3}>
                <BooleanInput className={classes.input} fullWidth source="verified" />
              </Grid>
              <Grid item xs={3}>
                {/*<BooleanInput className={classes.input} fullWidth source="enable" />*/}
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="kyc_reason" />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="ref_number" />
              </Grid>
              <Grid item xs={6}>
                <TextInput className={classes.input} fullWidth source="kyc_status" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.text}>KYC Data</p>
                <KycDataInput className={classes.input} fullWidth source="kyc_data" />
              </Grid>
              <Grid item xs={6}>
                <p className={classes.text}>FCM Tokens</p>
                <FcmTokensInput className={classes.input} fullWidth source="fcm_tokens" />
              </Grid>
              <Grid item xs={12}>
                <p className={classes.text}>Roles</p>
                <RolesInput fullWidth source="roles" />
              </Grid>
            </Grid>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  )
}
