import { listPaymentChannel, listPaymentChannelFPX } from '@/src/constants'
const { client } = require('../../MbaasClient')

export const funcGetOnePromotions = async (resource, params) => {
  const { data } = await client.getTable('promotions').lookup(params.id)

  console.log(8831794124, 'data', data)

  const splittedString = data?.payment_channel?.split(',')

  const filteredPaymentChannel = listPaymentChannel?.default?.filter((item) =>
    splittedString.includes(item.id)
  )
  const filteredPaymentChannelFPX = listPaymentChannelFPX?.default?.filter((item) =>
    splittedString.includes(item.id)
  )

  console.log(8831794124, { filteredPaymentChannel, filteredPaymentChannelFPX })

  let paymentChannel = filteredPaymentChannel?.map((item) => item.id).join(',')
  let paymentChannelFPX = filteredPaymentChannelFPX?.map((item) => item.id).join(',')

  return {
    data: {
      ...data,
      denom_type: data.denomination === 'ALL' ? 'ALL' : 'OTHER',
      denom_value: data.denomination === 'ALL' ? '' : data.denomination?.split(','),
      paymentChannel: paymentChannel ? paymentChannel : null,
      paymentChannelFPX: paymentChannelFPX ? paymentChannelFPX : null,
    },
  }
}
