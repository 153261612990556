const { client } = require('../../MbaasClient')

export const funcCreateCouponSettingsRecalc = async (resource, params) => {
  const { id } = params.data
  const recalc = await client.invokeApi('coupon-recalc-runner', {
    id: id,
  })
  let data
  data = recalc ? 'Recalculated' : 'Recalc Failed'
  return { data: data }
}
