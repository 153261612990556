import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  FunctionField,
  Filter,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin'
import ReactJson from 'react-json-view'
import moment from 'moment'
import { DateRange } from './DateRange'
import ExportButton from '../shared-components/export-button'

const ReferenceSnapshotFilter = (props) => (
  <Filter {...props}>
    <DateRange alwaysOn />
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton filter={filterValues} resourceType="REFERENCE-SNAPSHOT" />
    </TopToolbar>
  )
}

export const ReferenceSnapshotList = (props) => (
  <List
    {...props}
    filters={<ReferenceSnapshotFilter />}
    filterDefaultValues={{
      dateGte: moment().subtract(1, 'month').calendar(),
      dateLte: moment().format('YYYY/MM/DD'),
    }}
    actions={<ListActions />}
  >
    <Datagrid>
      <ReferenceField source="product_id" reference="products">
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="Snapshot"
        render={(record) => (
          <div>
            <label>
              <ReactJson
                src={record.snapshot}
                name={false}
                shouldCollapse={() => {}}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            </label>
          </div>
        )}
      />
      <FunctionField
        label="Created At"
        render={(record) => (
          <div>
            <label>{moment(record.created_at).format()}</label>
          </div>
        )}
      />
    </Datagrid>
  </List>
)
