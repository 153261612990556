const { client } = require('../../MbaasClient')

export const funcCreateStorePoints = async (resource, params) => {
  const { address } = params.data
  const pruned_address = address.replace(/[^a-zA-Z0-9_]+/gm, '')

  let storeData = Object.assign({
    ...params.data,
    affiliation: '711',
    pruned_address: pruned_address,
  })

  const { data } = await client.getTable(resource).insert(storeData)
  return { data: data }
}
