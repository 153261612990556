import defaultSortParams from '../shared-script/defaultSortParams'
const { moco, client } = require('../../MbaasClient')

export async function isSurveys(params, resource) {
  console.log(62349587, { method: 'getList', resource: resource, params: params })

  const { page, perPage } = params.pagination
  let { field: sortField, order: sortOrder } = params.sort

  // adjust sorting params, default sort DESC
  const [field, order] = defaultSortParams(sortField, sortOrder)

  let filterObject = moco.composeFilter()

  if (filterObject !== '') filterObject = filterObject.toString()
  const { data, total } = await client
    .getTable('surveys')
    .filter(filterObject || 'true')
    .sort(order === 'DESC' ? `-${field}` : field)
    .limit(perPage)
    .offset((page - 1) * perPage)
    .read()

  const dataMap = data.map((data) => {
    return {
      ...data,
      product_origin: data.product_origin?.split(','),
      category: data.category?.split(','),
      product_code: data.product_code?.split(','),
    }
  })
  return {
    data: dataMap,
    total: total,
  }
}
