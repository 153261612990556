import { client, bucket } from '../../MbaasClient'
// import { TDADeleteFn } from '@/src/@types/data-provider/delete'

export type TPreviousData = {
  id: string
  content?: {
    image_id?: string
  }
}
export const deleteMessengerMsgSeries: any = async (_: any, params: any) => {
  await client.storage.removeFile({
    filePath: params.previousData?.content?.image_id || '',
    bucket: bucket['advertisements'],
  })
  return { data: params }
}
