import React, { useState, useEffect } from 'react'
import { TextField, Typography, LinearProgress } from '@material-ui/core'
import { useInput } from 'react-admin'
import dataProvider from '../../provider/DataProvider'

const CharacterCounter = ({ pageProps, ...props }) => {
  const getOneData = async () => {
    const data = await dataProvider.getOne('advertisements', {
      id: props.id,
    })

    return data
  }

  // declare variables here
  const stringLegth = 108

  // declare hooks here
  const [data, setData] = useState([])
  const [inputText, setInputText] = useState('')
  const { input } = useInput({ source: props.source })
  useEffect(() => {
    ;(async () => {
      const res = await getOneData()
      setData(res?.data)
    })()
  }, [props.id])

  useEffect(() => {
    if (data?.running_text_config) {
      // Extract the locale code from the filter string
      const localeCode = props.source.split('_').pop()

      // Create a new object with the filtered key-value pair based on the locale code
      const filteredLocale = Object.keys(data?.running_text_config?.text)
        .filter((key) => key === localeCode)
        .reduce((obj, key) => {
          obj[key] = data?.running_text_config?.text[key]
          return obj
        }, {})

      setInputText(filteredLocale[localeCode])
    }
  }, [data?.running_text_config])

  const handleChange = (event) => {
    const inputValue = event.target.value
    if (inputValue.length <= stringLegth) {
      setInputText(inputValue)
    }
    input.onChange(inputValue)
  }

  const calculateRemainingCharacters = () => {
    const remainingChars = stringLegth - inputText.length
    return remainingChars >= 0 ? remainingChars : 0
  }

  const calculateIndicatorValue = () => {
    const percentage = (inputText.length / stringLegth) * 100
    return percentage >= 0 ? percentage : 0
  }

  return (
    <div>
      <TextField
        label="Input Running Text"
        multiline
        minRows={4}
        value={inputText}
        onChange={handleChange}
        variant="outlined"
        fullWidth
      />
      <Typography variant="caption" color={inputText.length === stringLegth ? 'error' : 'inherit'}>
        Characters remaining: {calculateRemainingCharacters()}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={calculateIndicatorValue()}
        color={inputText.length === stringLegth ? 'secondary' : 'primary'}
      />
    </div>
  )
}

export default CharacterCounter
