import React, { useEffect } from 'react'
import {
  DateTimeInput,
  required,
  SelectInput,
  useRedirect,
  FormDataConsumer,
  SaveButton,
  useAuthProvider,
  DeleteButton,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  minValue,
  FunctionField,
  Record,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import { fetchDistinctTableProduct } from '@/src/requests/fetch-distinct-table-product'
import { LIST_MAP_CHANNEL } from '@/src/constants/list-channel'
import { RaAutocompleteInput } from '@/src/shared-components/ra-autocomplete-input'
import { useQuery } from '@tanstack/react-query'
import { Button } from '@material-ui/core'
import { firstDateOfMonth } from '@/src/utilities/date/first-date-of-month'
import { lastDateOfMonth } from '@/src/utilities/date/last-date-of-month'
import Numeral from 'numeral'
import moment from 'moment'

export const FormInput = ({ handleSubmitWithRedirect, saving, ...renderProps }: any) => {
  const today = moment().toISOString()
  const useStyles = makeStyles(() => ({
    bonusAutocomplete: {
      margin: '18px 0 0 0',
    },
    title: {
      fontWeight: 'bold',
    },
    mainGridTitle: {
      border: '2px solid #68af2f',
      padding: '8px',
      fontWeight: 'bold',
    },
    bonusGridTitle: {
      border: '2px solid #7ec2f7',
      padding: '8px',
      fontWeight: 'bold',
    },
  }))
  const classes = useStyles()

  const authP = useAuthProvider()
  const redirect = useRedirect()
  const formState = useFormState()
  const [ccc, setFormValue] = React.useState<any>()
  const dataForm = formState.values

  const isDirty = formState.dirty

  const listProductOrigin = dataForm?.items?.product_origin?.map((v: any) => v?.id || v) || []
  const listCategory = dataForm?.items?.category?.map((v: any) => v?.id || v) || []
  const listProductCode = dataForm?.items?.product_code?.map((v: any) => v?.id || v) || []
  const listBonusProductOrigin = [dataForm?.items?.bonus?.product_origin] || []
  const listBonusCategory = [dataForm?.items?.bonus?.category] || []
  const listBonusProductCode = [dataForm?.items?.bonus?.product_code] || []

  const fetchProductOriginQuery = useQuery(
    ['fetchProductOriginQuery'],
    () => fetchDistinctTableProduct('country'),
    {
      staleTime: 1000 * 60 * 60 * 24,
    }
  )

  const fetchCategoryQuery = useQuery(
    ['fetchCategoryQuery', { product_origin: listProductOrigin }],
    () =>
      fetchDistinctTableProduct('category', {
        product_origin: listProductOrigin,
      }),
    { enabled: listProductOrigin.length !== 0, staleTime: 1000 * 60 * 10 }
  )

  const fetchBonusCategoryQuery = useQuery(
    ['fetchCategoryQuery', { product_origin: listBonusProductOrigin }],
    () =>
      fetchDistinctTableProduct('category', {
        // @ts-ignore
        product_origin: listBonusProductOrigin,
      }),
    { enabled: listBonusProductOrigin.length !== 0, staleTime: 1000 * 60 * 10 }
  )

  const fetchProductCodeQuery = useQuery(
    ['fetchProductCodeQuery', { product_origin: listProductOrigin, category: listCategory }],
    () =>
      fetchDistinctTableProduct('product_code', {
        product_origin: listProductOrigin,
        category: listCategory,
      }),
    {
      enabled: listProductOrigin.length !== 0 && listCategory.length !== 0,
      staleTime: 1000 * 60 * 10,
    }
  )

  const fetchBonusProductCodeQuery = useQuery(
    [
      'fetchProductCodeQuery',
      { product_origin: listBonusProductOrigin, category: listBonusCategory },
    ],
    () =>
      fetchDistinctTableProduct('product_code', {
        // @ts-ignore
        product_origin: listBonusProductOrigin,
        // @ts-ignore
        category: listBonusCategory,
      }),
    {
      enabled: listBonusProductOrigin.length !== 0 && listBonusCategory.length !== 0,
      staleTime: 1000 * 60 * 10,
    }
  )

  const fetchDenominationQuery = useQuery(
    [
      'fetchDenominationQuery',
      {
        product_origin: listProductOrigin,
        category: listCategory,
        product_code: listProductCode,
      },
    ],
    () =>
      fetchDistinctTableProduct('denomination', {
        product_origin: listProductOrigin,
        category: listCategory,
        product_code: listProductCode,
      }),
    {
      enabled:
        listProductOrigin.length !== 0 && listCategory.length !== 0 && listProductCode.length !== 0,
      staleTime: 1000 * 60 * 10,
    }
  )

  const fetchBonusDenominationQuery = useQuery(
    [
      'fetchDenominationQuery',
      {
        product_origin: listBonusProductOrigin,
        category: listBonusCategory,
        product_code: listBonusProductCode,
      },
    ],
    () =>
      fetchDistinctTableProduct('denomination', {
        // @ts-ignore
        product_origin: listBonusProductOrigin,
        // @ts-ignore
        category: listBonusCategory,
        // @ts-ignore
        product_code: listBonusProductCode,
      }),
    {
      enabled:
        listBonusProductOrigin.length !== 0 &&
        listBonusCategory.length !== 0 &&
        listBonusProductCode.length !== 0,
      staleTime: 1000 * 60 * 10,
    }
  )

  useEffect(() => {
    if (!isDirty) {
      // ccc.setValue('items.category', dataForm?.items?.category)
    } else if (
      (ccc && dataForm?.items?.product_origin?.length === 0) ||
      (ccc && fetchCategoryQuery.isLoading)
    ) {
      ccc.setValue('items.category', [])
    } else if (ccc && !!fetchCategoryQuery.data) {
      ccc.setValue(
        'items.category',
        fetchCategoryQuery.data?.data?.data?.map((v) => {
          return { id: v.category, name: v.category }
        })
      )
    }
  }, [
    ccc,
    fetchCategoryQuery.data,
    dataForm?.items?.product_origin?.length,
    fetchCategoryQuery.isLoading,
  ])

  useEffect(() => {
    if (!isDirty) {
      // ccc.setValue('items.product_code', dataForm?.items?.product_code)
    } else if (
      (ccc && dataForm?.items?.category?.length === 0) ||
      (ccc && fetchProductCodeQuery.isLoading)
    ) {
      ccc.setValue('items.product_code', [])
    } else if (ccc && fetchProductCodeQuery.data) {
      ccc.setValue(
        'items.product_code',
        fetchProductCodeQuery.data?.data?.data?.map((v) => {
          return { id: v.product_code, name: v.product_code }
        })
      )
    }
  }, [
    ccc,
    fetchProductCodeQuery.data,
    dataForm?.items?.category?.length,
    fetchProductCodeQuery.isLoading,
  ])

  useEffect(() => {
    if (!isDirty) {
      // ccc.setValue('items.denomination', dataForm?.items?.denomination)
    } else if (
      (ccc &&
        dataForm?.items?.product_origin?.length === 0 &&
        dataForm?.items?.category?.length === 0 &&
        dataForm?.items?.product_code?.length === 0) ||
      (ccc && fetchDenominationQuery.isLoading)
    ) {
      ccc.setValue('items.denomination', [])
    } else if (ccc && fetchDenominationQuery.data) {
      ccc.setValue(
        'items.denomination',
        fetchDenominationQuery.data?.data?.data?.map((v) => {
          return { id: v.denomination.toString(), name: v.denomination.toString() }
        })
      )
    }
  }, [
    ccc,
    fetchDenominationQuery.data,
    dataForm?.items?.product_origin?.length,
    dataForm?.items?.category?.length,
    dataForm?.items?.product_code?.length,
  ])

  const handleCancel = () => {
    redirect('/bonuses')
  }

  const transformBody = (data: any) => ({
    ...data,
    user_id: authP.id(),
  })

  if (!ccc) {
    setFormValue(renderProps.form.mutators)
  }

  return (
    <>
      <div style={{ padding: '16px', paddingBottom: 0 }}>
        <Grid container>
          <Grid item xs={12}>
            <FunctionField
              render={(record: Record | undefined) => (
                <SelectInput
                  label="Country"
                  source="country"
                  fullWidth
                  validate={required()}
                  choices={[
                    { id: 'MY', name: 'Malaysia' },
                    { id: 'ID', name: 'Indonesia' },
                  ]}
                  defaultValue="MY"
                  disabled={record?.start < today}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FunctionField
              render={(record: Record | undefined) => (
                <RaAutocompleteInput
                  source="items.channel"
                  options={LIST_MAP_CHANNEL.map((channel) => ({
                    id: channel.id,
                    name: channel.label,
                  }))}
                  label="Channel"
                  validate={required()}
                  multiple
                  fullWidth
                  disabled={record?.start < today}
                />
              )}
            />
          </Grid>
          <div style={{ display: 'flex', gap: '16px', width: '100%' }}>
            <Grid item xs={6} className={classes.title}>
              MAIN Product:
              <Grid item xs={12} className={classes.mainGridTitle}>
                <FunctionField
                  render={(record: Record | undefined) => (
                    <RaAutocompleteInput
                      source="items.product_origin"
                      options={fetchProductOriginQuery.data?.data?.data
                        ?.map((v) => {
                          return { id: v.country, name: v.country }
                        })
                        .sort(customSort)}
                      label="Product Origin"
                      validate={required()}
                      multiple
                      fullWidth
                      disabled={record?.start < today}
                    />
                  )}
                />
                <FunctionField
                  render={(record: Record | undefined) => (
                    <RaAutocompleteInput
                      source="items.category"
                      options={fetchCategoryQuery.data?.data?.data
                        ?.map((v) => {
                          return { id: v.category, name: v.category }
                        })
                        .sort(customSort)}
                      label="Category"
                      validate={required()}
                      multiple
                      fullWidth
                      disabled={record?.start < today}
                    />
                  )}
                />
                <FunctionField
                  render={(record: Record | undefined) => (
                    <RaAutocompleteInput
                      source="items.product_code"
                      options={fetchProductCodeQuery.data?.data?.data
                        ?.map((v) => {
                          return { id: v.product_code, name: v.product_code }
                        })
                        .sort(customSort)}
                      label="Product Code"
                      validate={required()}
                      multiple
                      fullWidth
                      disabled={record?.start < today}
                    />
                  )}
                />
                <FunctionField
                  render={(record: Record | undefined) => (
                    <RaAutocompleteInput
                      source="items.denomination"
                      label="Denom Value"
                      options={fetchDenominationQuery.data?.data?.data
                        ?.map((v) => {
                          return {
                            id: v.denomination.toString(),
                            name: Numeral(v.denomination.toString()).format('0,0'),
                          }
                        })
                        .sort(customSort)}
                      validate={required()}
                      multiple
                      fullWidth
                      disabled={record?.start < today}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.title}>
              BONUS Product:
              <Grid item xs={12} className={classes.bonusGridTitle}>
                <FunctionField
                  render={(record: Record | undefined) => (
                    <AutocompleteInput
                      source="items.bonus.product_origin"
                      choices={fetchProductOriginQuery.data?.data?.data
                        ?.map((v) => {
                          return { id: v.country, name: v.country }
                        })
                        .sort(customSort)}
                      label="Product Origin"
                      validate={required()}
                      fullWidth
                      className={classes.bonusAutocomplete}
                      disabled={record?.start < today}
                    />
                  )}
                />
                <FunctionField
                  render={(record: Record | undefined) => (
                    <AutocompleteInput
                      source="items.bonus.category"
                      choices={fetchBonusCategoryQuery.data?.data?.data
                        ?.map((v) => {
                          return { id: v.category, name: v.category }
                        })
                        .sort(customSort)}
                      label="Category"
                      validate={required()}
                      fullWidth
                      className={classes.bonusAutocomplete}
                      disabled={record?.start < today}
                    />
                  )}
                />
                <FunctionField
                  render={(record: Record | undefined) => (
                    <AutocompleteInput
                      source="items.bonus.product_code"
                      choices={fetchBonusProductCodeQuery.data?.data?.data
                        ?.map((v) => {
                          return { id: v.product_code, name: v.product_code }
                        })
                        .sort(customSort)}
                      label="Product Code"
                      validate={required()}
                      fullWidth
                      className={classes.bonusAutocomplete}
                      disabled={record?.start < today}
                    />
                  )}
                />
                <FunctionField
                  render={(record: Record | undefined) => (
                    <AutocompleteInput
                      source="items.bonus.denomination"
                      choices={fetchBonusDenominationQuery.data?.data?.data
                        ?.map((v) => {
                          return {
                            id: v.denomination.toString(),
                            name: Numeral(v.denomination.toString()).format('0,0'),
                          }
                        })
                        .sort(customSort)}
                      label="Denomination"
                      validate={required()}
                      fullWidth
                      className={classes.bonusAutocomplete}
                      disabled={record?.start < today}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
          <Grid item xs={6}>
            <FunctionField
              render={(record: Record | undefined) => (
                <BooleanInput
                  source="configs.new_customer_only"
                  label="For New Customer / Target Only?"
                  disabled={record?.start < today}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FunctionField
              render={(record: Record | undefined) => (
                <BooleanInput
                  source="configs.once_per_target"
                  label="Apply 1x / Target?"
                  disabled={record?.start < today}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FunctionField
              render={(record: Record | undefined) => (
                <BooleanInput
                  source="configs.specific_customer.status"
                  label="Is for Specific Customer?"
                  disabled={record?.start < today}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  formData?.configs?.specific_customer?.status && (
                    <FunctionField
                      render={(record: Record | undefined) => (
                        <ReferenceInput
                          source="configs.specific_customer.customer_id"
                          label="Specific For"
                          reference="customers"
                          validate={required()}
                          disabled={record?.start < today}
                        >
                          <AutocompleteInput fullWidth optionText="name" />
                        </ReferenceInput>
                      )}
                    />
                  )
                )
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={6}>
            <FunctionField
              fullWidth
              render={(record: Record | undefined) => (
                <NumberInput
                  source="configs.max_usage_per_customer"
                  label="Max Usage Per Customer"
                  fullWidth
                  validate={[required(), minValue(1)]}
                  defaultValue={1}
                  disabled={record?.start < today}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FunctionField
              render={(record: Record | undefined) => (
                <NumberInput
                  source="configs.total_usage"
                  label="Total Usage"
                  fullWidth
                  validate={[required(), minValue(1)]}
                  defaultValue={1}
                  disabled={record?.start < today}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimeInput
              label="myroot.valid_from"
              source="start"
              fullWidth
              validate={[required()]}
              defaultValue={firstDateOfMonth}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimeInput
              label="Valid Until"
              source="end"
              fullWidth
              validate={[required()]}
              defaultValue={lastDateOfMonth}
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          backgroundColor: '#f5f5f5',
          padding: '16px',
        }}
      >
        <SaveButton
          handleSubmitWithRedirect={handleSubmitWithRedirect}
          transform={transformBody}
          saving={saving}
          disabled={renderProps.submitting}
        />

        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <FunctionField
          style={{ marginLeft: 'auto' }}
          render={(record: Record | undefined) => (
            <DeleteButton
              style={{ marginLeft: 'auto' }}
              record={renderProps.record}
              disabled={record?.start < today}
            />
          )}
        />
      </div>
    </>
  )
}

const customSort = (a: any, b: any) => {
  if (a.id < b.id) {
    return -1
  }
  if (b.id > a.id) {
    return 1
  }
  return 0
}
