import React from 'react'
import NumberFormat from 'react-number-format'
import TextField from '@material-ui/core/TextField'

/* MAIN COMPONENT */
export default function NumberFormatInput({
  value,
  onChange,
  label,
  variant = 'filled',
  placeholder,
  fullWidth = false,
  error,
  helperText,
  required,
  size = 'small',
  style = {},
  ...props
}) {
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      variant={variant}
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      required={required}
      size={size}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      style={style}
      {...props}
    />
  )
}

/* OTHER COMPONENT */
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      // isNumericString
    />
  )
}
