import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  useNotify,
  useRedirect,
  SimpleForm,
  Edit,
  EditButton,
  Toolbar,
  SaveButton,
  Create,
  DeleteButton,
  SelectInput,
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  NumberField,
  required,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ExportButton from '../shared-components/export-button'

const StorePointPostFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="storePointId"
      reference="store_points"
      label="Store Name"
      filterToQuery={(searchText) => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="address" />
    <SelectInput
      source="state"
      label="State"
      choices={[
        { id: 'johor', name: 'Johor' },
        { id: 'kedah', name: 'Kedah' },
        { id: 'kelantan', name: 'Kelantan' },
        { id: 'malacca', name: 'Malacca' },
        { id: 'negeri sembilan', name: 'Negeri Sembilan' },
        { id: 'pahang', name: 'Pahang' },
        { id: 'penang', name: 'Penang' },
        { id: 'perak', name: 'Perak' },
        { id: 'perlis', name: 'Perlis' },
        { id: 'sabah', name: 'Sabah' },
        { id: 'sarawak', name: 'Sarawak' },
        { id: 'selangor', name: 'Selangor' },
        { id: 'terengganu', name: 'Terengganu' },
        { id: 'kuala lumpur', name: 'Kuala Lumpur' },
        { id: 'labuan', name: 'Labuan' },
        { id: 'putrajaya', name: 'Putrajaya' },
      ]}
    />
  </Filter>
)

const StorePointListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, showFilter, basePath } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton filter={filterValues} resourceType="STORE-POINT" />
    </TopToolbar>
  )
}

export const StorePointList = (props) => (
  <List
    {...props}
    title="Convenience Stores"
    sort={{ field: 'name', order: 'ASC' }}
    filters={<StorePointPostFilter />}
    actions={<StorePointListActions />}
  >
    <Datagrid>
      <TextField source="store_id" label="Store ID" />
      <TextField source="name" label="name" />
      <TextField source="address" />
      <TextField source="state" />
      <NumberField source="latitude" />
      <NumberField source="longitude" />
      <TextField source="affiliation" />
      <EditButton />
    </Datagrid>
  </List>
)

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export const StorePointCreate = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/store_points')
  }

  return (
    <Create {...props} onSuccess={onSuccess} title="Add a Convenience Store">
      <SimpleForm>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput label="Store ID" fullWidth className={classes.input} source="store_id" />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label="Store Name"
                fullWidth
                className={classes.input}
                source="name"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Store Address"
                fullWidth
                className={classes.input}
                source="address"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                source="state"
                label="State"
                fullWidth
                className={classes.input}
                choices={[
                  { id: 'Johor', name: 'Johor' },
                  { id: 'Kedah', name: 'Kedah' },
                  { id: 'Kelantan', name: 'Kelantan' },
                  { id: 'Malacca', name: 'Malacca' },
                  { id: 'Negeri Sembilan', name: 'Negeri Sembilan' },
                  { id: 'Pahang', name: 'Pahang' },
                  { id: 'Penang', name: 'Penang' },
                  { id: 'Perak', name: 'Perak' },
                  { id: 'Perlis', name: 'Perlis' },
                  { id: 'Sabah', name: 'Sabah' },
                  { id: 'Sarawak', name: 'Sarawak' },
                  { id: 'Selangor', name: 'Selangor' },
                  { id: 'Terengganu', name: 'Terengganu' },
                  { id: 'Kuala Lumpur', name: 'Kuala Lumpur' },
                  { id: 'Labuan', name: 'Labuan' },
                  { id: 'Putrajaya', name: 'Putrajaya' },
                ]}
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                label="Latitude"
                fullWidth
                className={classes.input}
                source="latitude"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                label="Longitude"
                fullWidth
                className={classes.input}
                source="longitude"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Affiliation"
                fullWidth
                className={classes.input}
                source="affiliation"
                value="711"
                disabled
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}

export const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
)

const EditTitle = ({ record }) => {
  return <span>Edit Convenience Store: {record ? `"${record.name}"` : ''}</span>
}

export const StorePointEdit = (props) => {
  const classes = useStyles()
  return (
    <Edit {...props} title={<EditTitle />}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput label="Store ID" fullWidth className={classes.input} source="store_id" />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label="Store Name"
                fullWidth
                className={classes.input}
                source="name"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Store Address"
                fullWidth
                className={classes.input}
                source="address"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                source="state"
                label="State"
                fullWidth
                className={classes.input}
                choices={[
                  { id: 'Johor', name: 'Johor' },
                  { id: 'Kedah', name: 'Kedah' },
                  { id: 'Kelantan', name: 'Kelantan' },
                  { id: 'Malacca', name: 'Malacca' },
                  { id: 'Negeri Sembilan', name: 'Negeri Sembilan' },
                  { id: 'Pahang', name: 'Pahang' },
                  { id: 'Penang', name: 'Penang' },
                  { id: 'Perak', name: 'Perak' },
                  { id: 'Perlis', name: 'Perlis' },
                  { id: 'Sabah', name: 'Sabah' },
                  { id: 'Sarawak', name: 'Sarawak' },
                  { id: 'Selangor', name: 'Selangor' },
                  { id: 'Terengganu', name: 'Terengganu' },
                  { id: 'Kuala Lumpur', name: 'Kuala Lumpur' },
                  { id: 'Labuan', name: 'Labuan' },
                  { id: 'Putrajaya', name: 'Putrajaya' },
                ]}
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                label="Latitude"
                fullWidth
                className={classes.input}
                source="latitude"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                label="Longitude"
                fullWidth
                className={classes.input}
                source="longitude"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Affiliation"
                fullWidth
                className={classes.input}
                source="affiliation"
                value="711"
                disabled
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}
