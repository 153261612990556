import React, { useEffect, useState } from 'react'
import {
  SelectInput,
  NumberInput,
  ReferenceInput,
  Create,
  SimpleForm,
  AutocompleteInput,
  required,
  useNotify,
  useRedirect,
  useInput,
  BooleanInput,
  Button,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-textmate'

import { countryList, currencyList } from '../../constants'

const PageCreate = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/pricings')
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ReferenceInput
                fullWidth
                reference="organizations"
                source="organization_id"
                className={classes.input}
                validate={[required()]}
              >
                <SelectInput source="name" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                fullWidth
                reference="products"
                source="product_id"
                validate={[required()]}
                className={classes.input}
              >
                <AutocompleteInput
                  optionText={(option) => option?.name || `(${option?.denomination || ''})`}
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                source="method"
                fullWidth
                validate={[required()]}
                className={classes.input}
                optionText="id"
                choices={[
                  { id: 'absolute', name: 'Absolute' },
                  { id: 'percent', name: 'Percent' },
                ]}
              />
            </Grid>

            <Grid item xs={6}>
              <NumberInput
                source="value"
                label="Sell price"
                fullWidth
                validate={[required()]}
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                fullWidth
                label="RRP"
                source="reference"
                validate={[required()]}
                className={classes.input}
              />
            </Grid>
            <Grid item xs={3}>
              <BooleanInput
                source="available"
                defaultValue={true}
                validate={[required()]}
                className={classes.input}
              />
            </Grid>
            <Grid item xs={3}>
              <BooleanInput
                source="auto_update"
                defaultValue={true}
                validate={[required()]}
                className={classes.input}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                fullWidth
                source="channel_code"
                validate={[required()]}
                className={classes.input}
                choices={[
                  { id: 'FACEBOOK', name: 'Facebook' },
                  { id: 'PWA_APP', name: 'PWA App' },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <p className={classes.text}>Poin</p>
              <PoinInput
                source="poin"
                label="Poin"
                defaultValue={[
                  {
                    poin: '1',
                    value: '0.02',
                    divider: '3',
                  },
                ]}
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteInput
                fullWidth
                label="Currency"
                source="currency"
                validate={[required()]}
                className={classes.input}
                choices={currencyList}
              />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteInput
                fullWidth
                label="Country"
                source="country"
                validate={[required()]}
                className={classes.input}
                choices={countryList}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <div style={{ display: 'flex', gap: '30px' }}>
                <SelectInput
                  source="period_purchase_limit"
                  fullWidth
                  validate={[required()]}
                  className={classes.input}
                  choices={[
                    { id: 'daily', name: 'daily' },
                    { id: 'weekly', name: 'weekly' },
                    { id: 'monthly', name: 'monthly' },
                  ]}
                  disabled
                />
                <SelectInput
                  source="limit_type_purchase_limit"
                  fullWidth
                  validate={[required()]}
                  className={classes.input}
                  choices={[
                    { id: 'unlimited', name: 'unlimited' },
                    { id: 'other', name: 'other' },
                  ]}
                  disabled
                />
                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    formData.limit_type_purchase_limit === 'other' && (
                      <NumberInput
                        source="limit_value_purchase_limit"
                        fullWidth
                        validate={[required()]}
                        className={classes.input}
                        {...rest}
                        disabled
                      />
                    )
                  }
                </FormDataConsumer>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}

export default PageCreate

/* OTHER COMPONENT */
const PoinInput = (props) => {
  const [poinValue, setPoinValue] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const poin = useInput(props)

  useEffect(() => {
    setPoinValue(JSON.stringify(poin.input.value, null, '\t'))
    setIsChanged(false)
  }, [poin.input.value])

  function onChange(newValue) {
    setPoinValue(newValue)
    setIsChanged(true)
  }

  function saveToForm() {
    const parsed = JSON.parse(poinValue)
    poin.input.onChange(parsed)
  }

  return (
    <div>
      <AceEditor
        name="poin"
        style={{ height: '200px', width: '100%' }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={poinValue}
      />
      {isChanged && (
        <Button variant="contained" size="small" color="primary" onClick={saveToForm}>
          <>Commit Changes</>
        </Button>
      )}
    </div>
  )
}

/* STYLING */
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  text: {
    color: '#0000008A',
  },
}))
