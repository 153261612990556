import React, { useState, useEffect } from 'react'
import { required, SimpleForm, TextInput, Edit, FormDataConsumer } from 'react-admin'
import { CustomToolbar } from './CustomToolbarEdit'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { makeStyles } from '@material-ui/core/styles'
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import TextFieldMui from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import ButtonMui from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const useStylesTree = makeStyles({
  root: {
    height: 'auto',
    flexGrow: 1,
    maxWidth: 400,
  },
})

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1, // display on the left rather than on the right of the list
      width: '30em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard)

const TreeSidebar = (props) => {
  const { ref, index, value, setValue, allValue } = props
  const classes = useStylesTree()
  const [product, setProduct] = React.useState(value.product)
  const [expanded, setExpanded] = React.useState([])
  const [selected, setSelected] = React.useState([])

  const onClickParent = (dataParent) => {
    props.setChangeView({ parent: true, child: false })
    props.setShowParent(true)
    props.setDataParent(dataParent)
  }
  const onClickChild = (dataProduct) => {
    props.setChangeView({ parent: false, child: true })
    props.setShowChild(true)
    props.setDataProduct(dataProduct)
  }

  const handleClickParent = (event) => {
    onClickParent(value)
    event.stopPropagation()
    event.preventDefault()
  }

  const handleClickChild = (event, product) => {
    onClickChild(product)
    event.stopPropagation()
    event.preventDefault()
  }

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds)
  }

  const handleChangeAddProduct = () => {
    const product = [
      {
        name: '',
        label: 'Add Child',
        icon: '',
        dataTestId: '',
        code: '',
        pathStr: '',
        pathReal: '',
      },
    ]
    setProduct((old) => old.concat(product))
    props.addProduct(index, product)
  }

  const handleChangeDeleteProduct = (index2) => {
    const copyProduct = [...product]
    copyProduct.splice(index2, 1)
    setProduct(copyProduct)
    const addData = [...allValue]
    addData[index].product.splice(index2, 1)
    setValue(addData)
  }

  function handleOnDragEndChild(result) {
    if (!result.destination) return
    const items = Array.from(allValue)
    const [reorderedItem] = items[index].product.splice(result.source.index, 1)
    items[index].product.splice(result.destination.index, 0, reorderedItem)
    setValue(items)
  }

  return (
    <Card ref={ref} key={index}>
      <CardContent>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          <TreeItem
            nodeId={value.section}
            label={value.section}
            onLabelClick={(event) => {
              handleClickParent(event)
            }}
          >
            <DragDropContext onDragEnd={handleOnDragEndChild}>
              <Droppable droppableId="product">
                {(provided) => (
                  <div className="product" {...provided.droppableProps} ref={provided.innerRef}>
                    {product.map((product, index2) => {
                      return (
                        <Draggable key={product.name} draggableId={product.name} index={index2}>
                          {(provided) => (
                            <div
                              key={product.name}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <TreeItem
                                indexParent={index}
                                indexChild={index2}
                                nodeId={product.name}
                                label={product.label}
                                icon={
                                  <>
                                    <div {...provided.dragHandleProps}>
                                      <DragIndicatorIcon
                                        style={{
                                          left: '50%',
                                          position: 'relative',
                                          transform: 'rotate(90deg) translateY(50%)',
                                          opacity: '30%',
                                        }}
                                      />
                                    </div>

                                    <CloseIcon
                                      fontSize="small"
                                      style={{ marginRight: '1em', opacity: '30%' }}
                                      onClick={() => handleChangeDeleteProduct(index2)}
                                    />
                                  </>
                                }
                                onLabelClick={(event) => {
                                  props.setIndexLayout({ parent: index, child: index2 })
                                  handleClickChild(event, product)
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <ButtonMui color="primary" onClick={handleChangeAddProduct}>
              Add Child
            </ButtonMui>
          </TreeItem>
        </TreeView>
      </CardContent>
    </Card>
  )
}

const ProductPrefParent = (props) => {
  const { value, setValue } = props

  const handleChangeSection = (event) => {
    setValue((old) => {
      const tes = [...old].findIndex((obj) => obj.section === value.section)
      old[tes].section = event.target.value
      return old
    })
  }

  const handleChangeLabel = (event) => {
    setValue((old) => {
      const tes = [...old].findIndex((obj) => obj.section === value.section)
      old[tes].label = event.target.value
      return old
    })
  }

  const handleChangePathStrAll = (event) => {
    setValue((old) => {
      const tes = [...old].findIndex((obj) => obj.section === value.section)
      old[tes].pathStrAll = event.target.value
      return old
    })
  }

  const handleChangePathRealAll = (event) => {
    setValue((old) => {
      const tes = [...old].findIndex((obj) => obj.section === value.section)
      old[tes].pathRealAll = event.target.value
      return old
    })
  }

  const handleChangeLimit = (event) => {
    setValue((old) => {
      const tes = [...old].findIndex((obj) => obj.section === value.section)
      old[tes].limit = event.target.value
      return old
    })
  }

  return (
    <div style={{ padding: '15px' }}>
      <Card {...props} key={value.section} style={{ width: '100%', background: '#ecebeb' }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h4>Edit Parent</h4>
            </Grid>
            <Grid item xs={6}>
              <TextFieldMui
                fullWidth
                defaultValue={value.section}
                label="Section"
                onChange={handleChangeSection}
                required={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldMui
                fullWidth
                defaultValue={value.label}
                label="Label"
                onChange={handleChangeLabel}
                required={true}
              />
            </Grid>
            {value.section === 'Malaysia' ? (
              <Grid item xs={6}>
                <TextFieldMui
                  fullWidth
                  defaultValue={value.limit}
                  label="Limit"
                  onChange={handleChangeLimit}
                />
              </Grid>
            ) : null}
            <Grid item xs={6}>
              <TextFieldMui
                fullWidth
                defaultValue={value.pathStrAll}
                label="Path All"
                onChange={handleChangePathStrAll}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldMui
                fullWidth
                defaultValue={value.pathRealAll}
                label="Path Real"
                onChange={handleChangePathRealAll}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

const ProductPrefChild = (props) => {
  const { value, setValue, allValue, indexLayout } = props
  const [products, setProducts] = React.useState(value || [])

  const handleChangeProductName = (event) => {
    const dt = [...allValue]
    const copyProducts = [products]
    dt[indexLayout.parent].product[indexLayout.child].name = event.target.value
    setProducts(copyProducts)
  }

  const handleChangeProductLabel = (event) => {
    const dt = [...allValue]
    const copyProducts = [products]
    dt[indexLayout.parent].product[indexLayout.child].label = event.target.value
    setProducts(copyProducts)
  }

  const handleChangeProductIcon = (event) => {
    const dt = [...allValue]
    const copyProducts = [products]
    dt[indexLayout.parent].product[indexLayout.child].icon = event.target.value
    setProducts(copyProducts)
  }

  const handleChangeProductDataTestId = (event) => {
    const dt = [...allValue]
    const copyProducts = [products]
    dt[indexLayout.parent].product[indexLayout.child].dataTestId = event.target.value
    setProducts(copyProducts)
  }

  const handleChangeProductCode = (event) => {
    const dt = [...allValue]
    const copyProducts = [products]
    dt[indexLayout.parent].product[indexLayout.child].code = event.target.value
    setProducts(copyProducts)
  }

  const handleChangeProductPathStr = (event) => {
    const dt = [...allValue]
    const copyProducts = [products]
    dt[indexLayout.parent].product[indexLayout.child].pathStr = event.target.value
    setProducts(copyProducts)
  }

  const handleChangeProductPathReal = (event) => {
    const dt = [...allValue]
    const copyProducts = [products]
    dt[indexLayout.parent].product[indexLayout.child].pathReal = event.target.value
    setProducts(copyProducts)
  }

  const handleChangeProducts = () => {
    setValue((old) => {
      const index = [...old].findIndex((obj) => obj.name === value.name)
      if (index !== -1) old[index] = products
      return old
    })
  }

  React.useEffect(() => {
    handleChangeProducts()
  }, [products])

  return (
    <div style={{ padding: '15px' }}>
      <Card {...props} key={value.name} style={{ width: '100%', background: '#ecebeb' }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h4>Edit Child</h4>
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui
                name="name"
                fullWidth
                value={value.name}
                label="Name"
                onChange={handleChangeProductName}
                required={true}
                autoFocus={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui
                name="label"
                fullWidth
                value={value.label}
                label="Label"
                onChange={handleChangeProductLabel}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui
                name="icon"
                fullWidth
                value={value.icon}
                label="Icon"
                onChange={handleChangeProductIcon}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui
                name="dataTestId"
                fullWidth
                value={value.dataTestId}
                label="Data Test Id"
                onChange={handleChangeProductDataTestId}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui
                name="code"
                fullWidth
                value={value.code}
                label="Code"
                onChange={handleChangeProductCode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui
                name="pathStr"
                fullWidth
                value={value.pathStr}
                label="Path Str"
                onChange={handleChangeProductPathStr}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui
                name="pathReal"
                fullWidth
                value={value.pathReal}
                label="Path Real"
                onChange={handleChangeProductPathReal}
                required={true}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export const ProductPrefEdit = (props) => {
  const [dataForm, setDataForm] = React.useState([])
  const [characters, updateCharacters] = React.useState([])
  const [showParent, setShowParent] = useState(false)
  const [showChild, setShowChild] = useState(false)
  const [dataProduct, setDataProduct] = useState({})
  const [dataParent, setDataParent] = useState({})
  const [indexLayout, setIndexLayout] = useState({ parent: null, child: null })
  const [child, setChild] = useState(null)
  const [parent, setParent] = useState(null)

  const [changeView, setChangeView] = useState({
    parent: false,
    child: false,
  })

  const classes = useStyles()

  function handleOnDragEnd(result) {
    if (!result.destination) return
    const items = Array.from(characters)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    updateCharacters(items)
  }

  useEffect(() => {
    if (showChild === true && dataProduct !== {}) {
      setChild(
        <ProductPrefChild
          {...props}
          indexLayout={indexLayout}
          value={dataProduct}
          setValue={updateCharacters}
          allValue={characters}
          setDataForm={setDataForm}
        />
      )
    }
  }, [setChild, dataProduct])

  useEffect(() => {
    if (showParent === true && dataParent !== {}) {
      setParent(
        <ProductPrefParent
          {...props}
          value={dataParent}
          setValue={updateCharacters}
          allValue={characters}
        />
      )
    }
  }, [setParent, dataParent])

  const addProduct = (indexParent, arrProduct) => {
    const addData = [...characters]
    addData[indexParent].product.push(...arrProduct)
    updateCharacters(addData)
  }

  return (
    <Edit
      {...props}
      title={<EditTitle />}
      aside={
        <Card style={{ width: '250px' }}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                  {characters?.map((value, index) => {
                    return (
                      <Draggable key={value.section} draggableId={value.section} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <div {...provided.dragHandleProps}>
                              <DragIndicatorIcon
                                style={{
                                  left: '50%',
                                  position: 'relative',
                                  transform: 'rotate(90deg) translateY(50%)',
                                  opacity: '30%',
                                }}
                              />
                            </div>

                            <TreeSidebar
                              index={index}
                              value={value}
                              setValue={updateCharacters}
                              allValue={characters}
                              setShowParent={setShowParent}
                              setShowChild={setShowChild}
                              setDataProduct={setDataProduct}
                              setDataParent={setDataParent}
                              setIndexLayout={setIndexLayout}
                              addProduct={addProduct}
                              indexLayout={indexLayout}
                              setChangeView={setChangeView}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ButtonMui
            style={{
              marginTop: '16px',
              width: '100%',
              backgroundColor: 'white',
              color: 'blue',
              border: '0px solid gainsboro',
            }}
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              const addParent = {
                section: 'Add Parent',
                label: '',
                pathStrAll: '',
                pathRealAll: '',
                product: [],
              }
              const addData = [...characters]
              addData.push(addParent)
              updateCharacters(addData)
            }}
          >
            Add Parent
          </ButtonMui>
        </Card>
      }
    >
      <SimpleForm toolbar={<CustomToolbar dataForm={dataForm} option={characters} />}>
        <div style={{ width: '750px' }}>
          <Grid container spacing={3}>
            {changeView.parent ? (
              parent
            ) : changeView.child ? (
              child
            ) : (
              <>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    source="name"
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    source="country"
                    validate={[required()]}
                  />
                </Grid>
              </>
            )}

            <FormDataConsumer>
              {({ formData }) => {
                setDataForm(formData)
                if (characters?.length === 0 && formData.layout) {
                  updateCharacters(formData.layout)
                } else if (characters?.length === 0) {
                  updateCharacters([
                    {
                      section: '',
                      label: '',
                      pathStrAll: '',
                      pathRealAll: '',
                      product: [],
                    },
                  ])
                }
              }}
            </FormDataConsumer>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

const EditTitle = ({ record }) => {
  return <span>{record ? `"${record.name}"` : ''} Edit</span>
}
