import { storeWebviewBackgroundHeader } from '../../../requests'
const { client } = require('../../MbaasClient')

export const funcCreateHeaderImage = async (resource, params) => {
  const authUserId = (await client.user.get())?.data?.id
  const [, data] = await storeWebviewBackgroundHeader({
    ...params.data,
    defaultImage: params.data.default,
    created_by: authUserId,
  })
  return { data: data }
}
