import { client } from '../provider/MbaasClient'

const fetchListPurchaseLimit = async ({
  product_code, // string
  country, // string
  channel_code, // FACEBOOK|PWA_APP
  order_by: {
    column, // product_code|country
    order, // asc|desc
  },
  page, // 0
  per_page, // 0
  //
}) => {
  try {
    const { error, data } = await client.invokeApi('purchase-limit-list', {
      product_code,
      country,
      channel_code,
      order_by: {
        column,
        order: order.toLowerCase(),
      },
      page,
      per_page,
    })
    if (error) return [error, {}]

    return [null, { rows: data.data, count: data.total }]
  } catch (error) {
    return [error, {}]
  }
}

export default fetchListPurchaseLimit
