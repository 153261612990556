import gql from 'graphql-tag'
import { client } from '../provider/MbaasClient'
import { checkIsJson } from '../utilities'

/* MAIN FUNCTION */
const fetchListHeaderImage = async ({ pagination, sort, filter }) => {
  try {
    const { page, perPage } = pagination
    let { field, order } = sort

    let orderBy = `{${field}: ${order} }`

    const gqlResult = await client.gql.query({
      query: gql`
          {
              aggregateWebviewHeaders {
                  count
              }
              allWebviewHeadersList(
                  orderBy: ${orderBy}
                  limit: ${perPage}
                  offset: ${(page - 1) * perPage}
              ) {
                  data {
                      id
                      url
                      start
                      end
                      default
                      created_by
                      updated_by
                      created_at
                  }
              }
          }

      `,
    })

    const resultMapping = gqlResult?.allWebviewHeadersList?.data?.map((v) => {
      return {
        ...v,
        url: {
          imageUrl: checkIsJson(v.url) ? JSON.parse(v.url)?.imageUrl : v.url,
          filepath: checkIsJson(v.url) ? JSON.parse(v.url)?.filepath : v.url,
          type: checkIsJson(v.url) ? JSON.parse(v.url)?.type : v.url,
          bucket: checkIsJson(v.url) ? JSON.parse(v.url)?.bucket : v.url,
        },
      }
    })

    return [
      null,
      {
        rows: resultMapping || [],
        count: gqlResult?.aggregateWebviewHeaders?.count || 0,
      },
    ]
  } catch (error) {
    return [error, { rows: [], count: 0 }]
  }
}

export default fetchListHeaderImage

/* QUERY GRAPHQL */
// const query = gql`
//   query ($orderBy: PgConfigurationsOrderByInput, $offset: Int, $limit: Int) {
//     allPgConfigurationsList(orderBy: $orderBy, offset: $offset, limit: $limit) {
//       data {
//         id
//         created_at
//         updated_at
//         country
//         default
//         additional_configuration
//         created_by
//         updated_by
//       }
//       count
//     }
//   }
// `
