export const funcGetOneTransactionMonitoringDashboard = async (resource, params) => {
  const jwt = require('jsonwebtoken')

  const payload = {
    resource: { dashboard: 34 },
    params: {},
    exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
  }
  const METABASE_SITE_URL = 'https://analytics.telin.com.my'
  const METABASE_SECRET_KEY = '61ae503ce4d3aef83d79513467bf5308280a3c6375c1446c8037b6c29b8bdabf'

  const token = jwt.sign(payload, METABASE_SECRET_KEY)

  const iframeUrl =
    METABASE_SITE_URL + '/embed/dashboard/' + token + '#bordered=false&titled=false&refresh=120'

  return { data: { id: '2133def5-594c-406a-997a-30dddb8e27da', metabaseUrl: iframeUrl } }
}
