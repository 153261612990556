import React from 'react'
import {
  Edit,
  SimpleForm,
  required,
  BooleanInput,
  DateInput,
  ImageField,
  ImageInput,
} from 'react-admin'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-textmate'

const PageEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ImageInput
        source="url"
        label="Image (recommended for using 7:3 aspect ratio)"
        accept="image/*"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
        <DateInput source="start" label="Begin" validate={[required()]} fullWidth />
        <DateInput source="end" validate={[required()]} fullWidth />
      </div>
      <BooleanInput label="Default Image" source="default" />
    </SimpleForm>
  </Edit>
)
export default PageEdit
