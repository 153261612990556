const listPaymentChannelFPX = {
  default: [
    ///////////////////////////////// FPX
    {
      name: 'FPX - Bank Islam',
      id: 'BIMB',
    },
    {
      name: 'FPX - Maybank',
      id: 'MB2U',
    },
    {
      name: 'FPX - Public Bank',
      id: 'PBB',
    },
    {
      name: 'FPX - CIMB Clicks',
      id: 'CIMBCLICKS',
    },
    {
      name: 'FPX - Am Online',
      id: 'AMOL',
    },
    {
      name: 'FPX - Hong Leong',
      id: 'HLB',
    },
    {
      name: 'FPX - RHB Bank',
      id: 'RHB',
    },
    {
      name: 'FPX - OCBC Bank',
      id: 'FPX_OCBC',
    },
    {
      name: 'FPX - Standard',
      id: 'FPX_SCB',
    },
    {
      name: 'FPX - AFFiN Bank',
      id: 'FPX_ABB',
    },
    {
      name: 'FPX - Bank Rakyat',
      id: 'BANKRAKYAT',
    },
    {
      name: 'FPX - Muamalat',
      id: 'BANKMUAMALAT',
    },
    {
      name: 'FPX - Kuwait Finance',
      id: 'FPX_KFH',
    },
    {
      name: 'FPX - BSN',
      id: 'FPX_BSN',
    },
    {
      name: 'FPX - Alliance Bank',
      id: 'FPX_ABMB',
    },
    {
      name: 'FPX - UOB',
      id: 'FPX_UOB',
    },
    {
      name: 'FPX - HSBC',
      id: 'FPX_HSBC',
    },
    /////////////////////////////
  ],
  ipay88: [
    ///////////////////////////////// FPX
    {
      name: 'FPX - Bank Islam',
      id: 'IPAY88_BIMB',
    },
    {
      name: 'FPX - Maybank',
      id: 'IPAY88_MB2U',
    },
    {
      name: 'FPX - Public Bank',
      id: 'IPAY88_PBOL',
    },
    {
      name: 'FPX - CIMB Clicks',
      id: 'IPAY88_CIMBCLICK',
    },
    {
      name: 'FPX - Am Online',
      id: 'IPAY88_AMOL',
    },
    {
      name: 'FPX - Hong Leong',
      id: 'IPAY88_HLOL',
    },
    {
      name: 'FPX - RHB Bank',
      id: 'IPAY88_RHBO',
    },
    {
      name: 'FPX - OCBC Bank',
      id: 'IPAY88_OCBC',
    },
    {
      name: 'FPX - Standard',
      id: 'IPAY88_SCB',
    },
    {
      name: 'FPX - AFFiN Bank',
      id: 'IPAY88_AFOL',
    },
    {
      name: 'FPX - Bank Rakyat',
      id: 'IPAY88_BRRB',
    },
    {
      name: 'FPX - Muamalat',
      id: 'IPAY88_BMMB',
    },
    {
      name: 'FPX - Kuwait Finance',
      id: 'IPAY88_KFH',
    },
    {
      name: 'FPX - BSN',
      id: 'IPAY88_BSNO',
    },
    {
      name: 'FPX - Alliance Bank',
      id: 'IPAY88_ALOL',
    },
    {
      name: 'FPX - UOB',
      id: 'IPAY88_UOB',
    },
    {
      name: 'FPX - HSBC',
      id: 'IPAY88_HSBC',
    },
    /////////////////////////////
  ],
}
export default listPaymentChannelFPX
