import deleteOneHeaderImage from '../../requests/delete-one-header-image'
// import { TDADeleteFn } from '@/src/@types/data-provider/delete'
import {
  deleteMessengerMsgSeries,
  TPreviousData,
} from '@/src/provider/DataProvider/populate-delete-resource/messenger-msg-series'
import {
  deleteAdvertisement,
  TPrevDataDeleteAdvertisement,
} from '@/src/provider/DataProvider/populate-delete-resource/advertisement'
import { deleteDefault } from '@/src/provider/DataProvider/populate-delete-resource/default'

const funcDelete: any = async (resource: any, params: any) => {
  console.log('delete', { resource, params })

  if (
    resource === 'advertisements' ||
    resource === 'popupads' ||
    resource === 'couponads' ||
    resource === 'runningText'
  ) {
    await deleteAdvertisement(resource, params as TPrevDataDeleteAdvertisement)
  }

  if (resource === 'messenger_msg_series') {
    await deleteMessengerMsgSeries(resource, params as TPreviousData)
  }

  if (resource === 'flashsale') {
    resource = 'promo_flashsales'
  }

  if (resource === 'denom_pricings') {
    resource = 'denom_pricings'
  }

  if (resource === 'redundancy_payment_gateway') {
    resource = 'pg_configurations'
  }

  if (resource === 'header-image') {
    await deleteOneHeaderImage(params.id, params.previousData)
    return { data: params }
  }

  return await deleteDefault(resource, params)
}

export default funcDelete
