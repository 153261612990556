import gql from 'graphql-tag'
import { client } from '@/src/provider/MbaasClient'

export const updateOne = async (
  ids: string[],
  {
    failed_trx_button,
  }: {
    failed_trx_button: {
      alter_number: boolean
      alter_product: boolean
      reprocess: boolean
    }
  }
): Promise<Record<any, any> & { error?: any }> => {
  const res = await client.gql.gqlRequest({
    mutation,
    variables: { ids, input: { failed_trx_button } },
  })
  if (res?.error) throw res?.error
  return { data: failed_trx_button }
}

const mutation = gql`
  mutation updateProductsById($ids: [UUID!]!, $input: ProductsUpdateInput!) {
    res: updateProductsById(ids: $ids, input: $input) {
      id
    }
  }
`
