import { funcUpdateManyPoinAddManually } from '@/src/provider/DataProvider/populate-updateMany-resource/poin-add-manually'
import { funcUpdateManyAdv } from '@/src/provider/DataProvider/populate-updateMany-resource/advertisements'

const funcUpdateMany = async (resource, params) => {
  console.log('update-many', { resource, params })

  if (resource === 'advertisements' || resource === 'popupads' || resource === 'couponads') {
    await funcUpdateManyAdv(resource, params)
  }

  if (resource === 'poin_add_manually') {
    await funcUpdateManyPoinAddManually(resource, params)
  }

  return { data: params }
}
export default funcUpdateMany
