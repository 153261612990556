import React from 'react'
import { useAuthProvider, useMutation, Button } from 'react-admin'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import GetAppIcon from '@material-ui/icons/GetApp'

export default function ExportButton(props) {
  const { resourceType } = props || ''
  const authP = useAuthProvider()
  const {
    dateLte,
    dateGte,
    user_id,
    custName,
    payment_id,
    status,
    storePointId,
    address,
    state,
    customer_id,
    q,
    category,
    tag,
    denomination,
    country,
    name,
    order_id,
    promotion_code,
    target,
    latest_status,
    type,
  } = props?.filter || {}
  const start = dateGte ? moment(dateGte).format('YYYY-MM-DD') : null
  const end = dateLte ? moment(dateLte).format('YYYY-MM-DD') : null
  const history = useHistory()

  let dataFil = {}

  function getAdditionalFilter(resourceType) {
    function isCoupons() {
      return {
        customer_id: props?.filter?.id,
        coupon_setting_id: props?.filter?.couponId,
      }
    }

    function isAnnoucement() {
      return {
        user: user_id,
      }
    }

    function isCustomer() {
      return {
        name: custName,
      }
    }

    function isDepositLog() {
      return {
        payment_id,
        status,
        user_id,
      }
    }

    function isStorePoint() {
      return {
        storePointId,
        address,
        state,
      }
    }

    function isPointLog() {
      return {
        user_id: authP.id(),
        user: user_id,
        customer: customer_id,
        type,
      }
    }

    function isPoin() {
      return {
        customer_id: props?.filter?.id,
      }
    }

    function isKbase() {
      return {
        q,
        category,
        tag,
      }
    }

    function isProduct() {
      return {
        denomination,
        name,
        country,
      }
    }

    function isTransaction() {
      return {
        orderId: order_id,
        status: latest_status,
        target,
        user: user_id,
        customer: customer_id,
        paymentId: payment_id,
        promotion_code,
      }
    }

    function isDefault() {
      return {}
    }

    let filters = {
      COUPONS: isCoupons(),
      ANNOUNCEMENT: isAnnoucement(),
      CUSTOMER: isCustomer(),
      'DEPOSIT-LOG': isDepositLog(),
      'STORE-POINT': isStorePoint(),
      'POIN-LOG': isPointLog(),
      POIN: isPoin(),
      KBASE: isKbase(),
      PRODUCT: isProduct(),
      TRANSACTION: isTransaction(),
      default: isDefault(),
    }

    return filters[resourceType] || filters?.default
  }

  dataFil = getAdditionalFilter(resourceType)

  const [exportData, { loading, loaded, error }] = useMutation({
    type: 'create',
    resource: 'downloads',
    payload: {
      data: {
        resource: resourceType,
        user_id: authP.id(),
        start,
        end,
        data: dataFil,
      },
    },
  })
  if (loaded && !error) {
    //
    history.push({
      pathname: '/downloads',
      search: encodeURI(`?filter={"resource":"${resourceType}"}`),
    })
  }
  return (
    <Button label="Export" onClick={exportData} disabled={loading}>
      <GetAppIcon />
    </Button>
  )
}
