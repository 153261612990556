const { client } = require('../../MbaasClient')

export const funcUpdateManyAdv = async (resource, params) => {
  delete params.data['created_at']
  delete params.data['updated_at']

  resource = 'advertisements'
  const index = params?.index

  for (let i = index; i < params.data.length + index; i++) {
    const { id } = params.data[i - index]
    const newPriority = i + 1
    await client.getTable(resource).update({
      id: id,
      data: { priority: newPriority },
    })
  }
}
