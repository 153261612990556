import { client } from '../../MbaasClient'

export const funcUpdatePromotions = async (resource: any, params: any) => {
  console.log(83970124124, { resource, params })
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  let updatedData = params.data
  //

  const isThereAnyFPX =
    typeof updatedData?.paymentChannel === 'string'
      ? updatedData?.paymentChannel
          ?.split(',')
          ?.map((v: any) => ({ id: v, name: v }))
          .some((v: any) => v.id === 'FPX')
      : updatedData?.paymentChannel?.some((v: any) => v.id === 'FPX')

  console.log(83970124124, 'isThereAnyFPX', isThereAnyFPX)

  const paymentChannelRemapped =
    typeof updatedData?.paymentChannel === 'string'
      ? updatedData?.paymentChannel
      : updatedData?.paymentChannel?.map((item: any) => item.id).join(',')

  const fpxRemapped =
    typeof updatedData?.paymentChannelFPX === 'string'
      ? updatedData?.paymentChannelFPX
      : updatedData?.paymentChannelFPX?.map((item: any) => item.id).join(',')

  console.log(83970124124, 'fpxRemapped', fpxRemapped)

  let savedPaymentChannel: any

  if (isThereAnyFPX) {
    savedPaymentChannel = `${paymentChannelRemapped},${fpxRemapped}`
  } else {
    savedPaymentChannel = paymentChannelRemapped
  }

  console.log(83970124124, 'savedPaymentChannelString', savedPaymentChannel)

  //
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  if (params.data['promoCode']) delete updatedData['promoCode']
  updatedData = Object.assign({
    // ...params.data,
    // @ts-ignore
    category: params.data.category.map((v) => v.id).toString(),
    // @ts-ignore
    product_code: params.data.product_code.map((v) => v.id).toString(),
    product_origin:
      typeof params.data.product_origin === 'string'
        ? params.data.product_origin
        : // @ts-ignore
          params.data.product_origin.map((v) => v.id).toString(),
    code: params.data.code,
    type: params.data.type,
    value: params.data.value,
    max_usage: params.data.max_usage,
    start: params.data.start,
    end: params.data.end,
    denomination:
      params.data.denom_type === 'ALL'
        ? params.data.denom_type
        : // @ts-ignore
          params.data.denom_value.map((v) => v.replace(',', '')).toString(),
    max_usage_per_customer: params.data.max_usage_per_customer,
    specific_target: params.data.specific_target,
    specific_customer_name: params.data.specific_customer_name,
    specific_customer: params.data.specific_customer,
    new_customer_only: params.data.new_customer_only,
    customer_id: params.data.specific_customer_name ? params.data.customer_id : null,
    payment_channel: savedPaymentChannel ?? null,
  })

  const { data } = await client.getTable(resource).update(
    Object.assign({
      id: params.id,
      data: updatedData,
    })
  )

  return { data: data }
}
