import React from 'react'
import {
  Datagrid,
  TextField,
  List,
  FunctionField,
  Filter,
  TextInput,
  SelectInput,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import { useAtom } from 'jotai'

import DrawerEdit from './drawer-edit'
import { recordAtom, isDrawerOpenAtom } from './atom'
import { client } from './../../provider/MbaasClient'

export default function PageList(props) {
  return (
    <>
      <DrawerEdit />
      <List
        {...props}
        filterDefaultValues={{
          product_origin: 'IDN',
        }}
        filters={<PromotionFilter />}
      >
        <Datagrid>
          <TextField source="category" label="Category" />
          <TextField source="country" label="Country" />

          <FunctionField
            label="Denom Pricing Lookup"
            source="denom_pricing_lookup"
            render={(record) => {
              let value = record.denom_pricing_lookup
              return (
                <label style={{ color: value ? 'green' : 'red' }}>{value ? 'Yes' : 'No'}</label>
              )
            }}
            sortable={false}
          />

          <EditButton />
        </Datagrid>
      </List>
    </>
  )
}

const EditButton = (props) => {
  const [, setRecord] = useAtom(recordAtom)
  const [, setIsDrawerEditOpen] = useAtom(isDrawerOpenAtom)

  return (
    <Button
      onClick={() => {
        setRecord(props.record)
        setIsDrawerEditOpen(true)
      }}
      color="primary"
      startIcon={<EditIcon />}
    >
      Edit
    </Button>
  )
}

const PromotionFilter = (props) => {
  const [countries, setCountries] = React.useState([])

  React.useEffect(() => {
    client
      .invokeApi('promo-flashsales-products', {
        product_origin: ['ALL'],
        invoked: 'country, category, denom_pricing_lookup',
      })
      .then((v) => {
        if (v.length !== 0) {
          const countries = v.data?.data?.map((v) => ({ id: v.country, name: v.country }))
          const key = 'name'
          const uniqueCountries = [...new Map(countries?.map((item) => [item[key], item])).values()]

          setCountries(uniqueCountries)
        }
      })
  }, [])

  return (
    <Filter {...props}>
      <TextInput label="Category" source="category" alwaysOn />
      <SelectInput source="product_origin" choices={countries} alwaysOn />
    </Filter>
  )
}
