import gql from 'graphql-tag'
import { client } from '../provider/MbaasClient'
import { checkIsJson } from '../utilities'

/* MAIN FUNCTION */
const fetchOneHeaderImage = async (WebviewHeadersId) => {
  try {
    const gqlResult = await client.gql.query({
      query: gql`
        query ($id: UUID) {
          getWebviewHeadersById(id: $id) {
            id
            url
            start
            end
            default
            created_by
            updated_by
          }
        }
      `,
    })

    const v = gqlResult?.getWebviewHeadersById

    return [
      null,
      {
        ...v,
        url: {
          imageUrl: checkIsJson(v.url) ? JSON.parse(v.url)?.imageUrl : v.url,
          filepath: checkIsJson(v.url) ? JSON.parse(v.url)?.filepath : v.url,
          type: checkIsJson(v.url) ? JSON.parse(v.url)?.type : v.url,
          bucket: checkIsJson(v.url) ? JSON.parse(v.url)?.bucket : v.url,
        },
      } || {},
    ]
  } catch (error) {
    return [error, {}]
  }
}
export default fetchOneHeaderImage
