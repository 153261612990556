import React, { useEffect, useState } from 'react'
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  AutocompleteInput,
  required,
  CheckboxGroupInput,
  NumberInput,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import CharacterCounter from './InputTextwithCounter'
import Typography from '@material-ui/core/Typography'
import MyColorPicker from './MyColorPicker'
import { fetchLocale } from '@/src/requests'

import { countryList } from './../../constants'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  inputHidden: {
    display: 'none',
  },
}))

// export
export default function RunningTextCreate(props) {
  const { source, ...rest } = props
  const [localeData, setLocaleData] = useState({})

  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/runningText')
  }

  useEffect(() => {
    const fetchData = async () => {
      const [, data] = await fetchLocale()

      setLocaleData({ data: data.rows, total: data.count })
    }
    fetchData()
  }, [])

  return (
    <Create {...props} onSuccess={onSuccess} title="Create Running Text">
      <SimpleForm>
        <div className={classes.root}>
          <Grid container spacing={3}>
            {localeData?.data?.map((data) => (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    color: 'black',
                    marginBottom: '10px',
                    fontSize: '14px',
                    fontWeight: '700',
                  }}
                >
                  {data?.locale}
                </Typography>
                <CharacterCounter source={`runText_locale_${data?.locale}`} {...rest} />
              </Grid>
            ))}

            <Grid item xs={12}>
              <RichTextInput source="description" validate={required()} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="h6"
                style={{ color: 'black', marginBottom: '10px', fontSize: '14px' }}
              >
                Pick a Color for the Running Text
              </Typography>
              <MyColorPicker source="textColor" {...rest} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="h6"
                style={{ color: 'black', marginBottom: '10px', fontSize: '14px' }}
              >
                Apply Running Text For?
              </Typography>
              <CheckboxGroupInput
                source="user_type"
                label={false}
                choices={[
                  { id: 'GUEST', name: 'GUEST' },
                  { id: 'UNPAYING', name: 'NEW USER' },
                  { id: 'PAYING', name: 'PAYING' },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <NumberInput
                fullWidth
                source="max_shown"
                label="Max. Shown / Customer"
                className={classes.input}
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', gap: 16 }}>
              <DateInput
                label="Start Date"
                source="start"
                defaultValue={new Date()}
                parse={(v) => new Date(v)}
                fullWidth
                validate={required()}
              />
              <DateInput
                label="End Date"
                source="end"
                defaultValue={new Date()}
                parse={(v) => new Date(v)}
                fullWidth
                validate={required()}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteInput
                fullWidth
                label="Country"
                source="country"
                className={classes.input}
                choices={countryList}
                validate={required()}
              />
            </Grid>
            <TextInput source="ads_type" className={classes.inputHidden} value="running_text" />
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}
