import * as React from 'react'
import {
  Edit,
  SimpleForm,
  Toolbar,
  useNotify,
  useMutation,
  useAuthProvider,
  DeleteButton,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import { useAtom } from 'jotai'
import Ajv from 'ajv'

import {
  countryList,
  listProductOrigin,
  listPaymentChannel,
  listPaymentChannelFPX,
} from './../../constants'
import { invokeApiPromoFlashsalesProducts } from './../../requests'
import { additionalConfiguration } from './atom'
import { CustomAutocompleteInput } from './../../shared-components'
import { schema } from './ajv-schema'
import RhfChipInputNumberFormat from '@/src/shared-components/react-hook-form/rhf-chip-input-number'

const ajv = new Ajv()

const useStyles = makeStyles(() => ({
  paper: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: 'auto',
    },
  },
}))

const ToolbarCustomButton = ({ ...rest }) => {
  const notify = useNotify()
  const history = useHistory()

  const [a] = useAtom(additionalConfiguration)
  const authP = useAuthProvider()

  const { id, updated_by = [], ...a2 } = a

  const [exportData, { loaded, error, loading }] = useMutation({
    type: 'update',
    resource: 'redundancy_payment_gateway',
    payload: {
      id: id,
      data: {
        ...a2,
        // country: a2?.country?.map((v) => v.id).toString(),
        updated_by: [...updated_by, { user_id: authP.id(), timestamp: new Date() }],
        country: a2?.country?.id,
        default: a2?.default?.id,
        additional_configuration: a?.additional_configuration?.map((v) => {
          return {
            product_origin: v?.productOrigin?.map((v2) => v2.id)?.toString(),
            category: v?.category?.map((v2) => v2.id)?.toString(),
            product_code: v?.productCode?.map((v2) => v2.id)?.toString(),
            denom: v?.denom?.map((v2) => v2.replace(/,/g, ''))?.toString(),
            payment_channel: [
              ...(v?.paymentChannel || [])?.map((v2) => v2.id),
              ...(v?.paymentChannelFPX || [])?.map((v2) => v2.id),
            ]?.toString(),
            pg_channel: v?.paymentGatewayChannel?.id,
          }
        }),
      },
    },
  })
  if (loaded && !error) {
    history.push({
      pathname: '/redundancy_payment_gateway',
    })
  }

  return (
    <Button
      disabled={loading}
      onClick={() => {
        const validate = ajv.compile(schema)
        const valid = validate(a)

        // Validation PG Channel and default cant use same value (cannot duplicate) - START
        let result = true // if duplicate return false
        if (a.additional_configuration.length > 0) {
          let tes = [
            { id: 'RAPYD', name: 'RAPYD' },
            { id: 'RAZER', name: 'RAZER' },
            { id: 'SPAY', name: 'SPAY' },
            { id: 'IPAY88', name: 'IPAY88' },
            { id: 'ASIAPAY_MY', name: 'ASIAPAY_MY' },
          ]
          const index = tes.findIndex((v) => v.id === a?.default?.id)
          if (index === -1) {
            result = false
          } else {
            tes.splice(index, 1)
          }

          ;(a?.additional_configuration || []).forEach((config) => {
            const index = tes.findIndex((v) => v.id === config?.paymentGatewayChannel?.id)
            if (index === -1) {
              result = false
            } else {
              tes.splice(index, 1)
            }
          })
        }
        // Validation PG Channel and default cant use same value (cannot duplicate) - END

        if (!valid) {
          notify('Form have some error, please check', 'error')
        } else if (!result) {
          notify('PG Channel and Default cant use same value', 'error')
        } else {
          exportData()
        }
      }}
      type="submit"
      form="myform"
      color="primary"
      variant="contained"
      startIcon={<SaveIcon />}
      size="small"
    >
      Save
    </Button>
  )
}

const PostEditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <ToolbarCustomButton />
      <DeleteButton />
    </Toolbar>
  )
}

const PageCreate = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<PostEditToolbar />}>
        <CustomForm source="customForm" />
      </SimpleForm>
    </Edit>
  )
}
export default PageCreate

/* OTHER COMPONENT */
const CustomForm = (props) => {
  const [a, seta] = useAtom(additionalConfiguration)

  const classes = useStyles()

  const [selectedProductOrigin0, setSelectedProductOrigin0] = React.useState('[]')

  const [listCategory0, setListCategory0] = React.useState([])
  const [listProductCode0, setListProductCode0] = React.useState([])
  const [selectedPgChannel, setSelectedPgChannel] = React.useState([])

  const additional_configuration = React.useMemo(() => {
    return props?.record?.additional_configuration.map((v, index) => {
      const paymentChannelFilter = v?.pg_channel === 'IPAY88' ? 'ipay88' : 'default'
      return {
        ...v,
        denom: v?.denom?.split(',').map((v2) => addCommasThousandSeparator(v2)),
        productOrigin: listProductOrigin.filter((v2) =>
          props?.record?.additional_configuration[index]?.product_origin.split(',').includes(v2.id)
        ),
        category: props?.record?.additional_configuration[index]?.category.split(',').map((v2) => ({
          id: v2,
          name: v2,
        })),
        productCode: props?.record?.additional_configuration[index]?.product_code
          .split(',')
          .map((v2) => ({
            id: v2,
            name: v2,
          })),
        paymentChannel: listPaymentChannel[paymentChannelFilter].filter((v2) =>
          props?.record?.additional_configuration[index]?.payment_channel
            ?.split(',')
            .includes(v2.id)
        ),
        paymentChannelFPX: listPaymentChannelFPX[paymentChannelFilter].filter((v2) =>
          props?.record?.additional_configuration[index]?.payment_channel
            ?.split(',')
            .includes(v2.id)
        ),
        paymentGatewayChannel: [
          { id: 'RAPYD', name: 'RAPYD' },
          { id: 'RAZER', name: 'RAZER' },
          { id: 'SPAY', name: 'SPAY' },
          { id: 'IPAY88', name: 'IPAY88' },
          { id: 'ASIAPAY_MY', name: 'ASIAPAY_MY' },
        ].filter((v2) =>
          props?.record?.additional_configuration[index]?.pg_channel?.split(',').includes(v2.id)
        )[0],
      }
    })
  }, [])

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      country: countryList.filter((country) =>
        props?.record?.country?.split(',').includes(country.id)
      )[0],
      default: [
        { id: 'RAPYD', name: 'RAPYD' },
        { id: 'RAZER', name: 'RAZER' },
        { id: 'SPAY', name: 'SPAY' },
        { id: 'IPAY88', name: 'IPAY88' },
        { id: 'ASIAPAY_MY', name: 'ASIAPAY_MY' },
      ].filter((paymentChannel) =>
        props?.record?.default?.split(',').includes(paymentChannel.id)
      )[0],
      additional_configuration: additional_configuration,
    },
  })

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      seta({ ...value, id: props?.record?.id, updated_by: props?.record?.updated_by })
    })
    return () => subscription.unsubscribe()
  }, [watch])

  React.useState(() => {
    watch('additional_configuration').forEach((v, index) => {
      setSelectedProductOrigin0((old) => {
        const tes = [...old]
        tes[index] = v?.productOrigin || []
        return tes
      })
      invokeApiPromoFlashsalesProducts({
        product_origin: v?.productOrigin?.map((v) => v.id),
        invoked: 'category',
      }).then(
        ([
          ,
          {
            data: { data: dataCategory },
          },
        ]) => {
          setListCategory0((old) => {
            const tes = [...old]
            tes[index] =
              dataCategory
                ?.filter((v) => v.category)
                ?.map((v) => ({ id: v.category, name: v.category })) || []
            return tes
          })
        }
      )

      invokeApiPromoFlashsalesProducts({
        product_origin: v?.productOrigin?.map((v) => v.id),
        category: v?.category?.map((v) => v.id),
        invoked: 'product_code',
      }).then(
        ([
          ,
          {
            data: { data: dataProductCode },
          },
        ]) => {
          setListProductCode0((old) => {
            const tes = [...old]
            tes[index] =
              dataProductCode?.map((v) => ({ id: v.product_code, name: v.product_code })) || []
            return tes
          })
        }
      )
      setSelectedPgChannel((old) => {
        const tes = [...old]
        tes[index] = v?.paymentGatewayChannel.id === 'IPAY88' ? 'ipay88' : 'default' || []
        return tes
      })
    })
  }, [])

  const { fields, append, remove, update } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'additional_configuration', // unique name for your Field Array
  })

  const onSubmit = (data) => {
    console.log('data')
  }

  const handleOnChangePaymentGatewayChannel = async ({ index, newValue }) => {
    setSelectedPgChannel((old) => {
      const tes = [...old]
      tes[index] = newValue.id === 'IPAY88' ? 'ipay88' : 'default' || []
      return tes
    })
    update(index, {
      ...a.additional_configuration[index],
      paymentGatewayChannel: newValue,
      paymentChannel: [],
      paymentChannelFPX: listPaymentChannelFPX[newValue.id === 'IPAY88' ? 'ipay88' : 'default'],
    })
  }

  const handleOnChangeProductOrigin = async ({ index, newValue }) => {
    setSelectedProductOrigin0((old) => {
      const tes = [...old]
      tes[index] = newValue || []
      return tes
    })

    update(index, {
      ...a.additional_configuration[index],
      productOrigin: newValue,
      category: [],
      productCode: [],
    })

    const [
      ,
      {
        data: { data: dataCategory },
      },
    ] = await invokeApiPromoFlashsalesProducts({
      product_origin: newValue?.map((v) => v.id),
      invoked: 'category',
    })

    setListCategory0((old) => {
      const tes = [...old]
      tes[index] =
        dataCategory
          ?.filter((v) => v.category)
          ?.map((v) => ({ id: v.category, name: v.category })) || []
      return tes
    })

    update(index, {
      ...a.additional_configuration[index],
      productOrigin: newValue,
      category:
        dataCategory
          ?.filter((v) => v.category)
          ?.map((v) => ({ id: v.category, name: v.category })) || [],
    })

    const [
      ,
      {
        data: { data: dataProductCode },
      },
    ] = await invokeApiPromoFlashsalesProducts({
      product_origin: newValue?.map((v) => v.id),
      category: dataCategory?.filter((v) => v.category)?.map((v) => v.category),
      invoked: 'product_code',
    })

    setListProductCode0((old) => {
      const tes = [...old]
      tes[index] = dataProductCode?.map((v) => ({ id: v.product_code, name: v.product_code })) || []
      return tes
    })

    update(index, {
      ...a.additional_configuration[index],
      productOrigin: newValue,
      category:
        dataCategory
          ?.filter((v) => v.category)
          ?.map((v) => ({ id: v.category, name: v.category })) || [],
      productCode:
        dataProductCode?.map((v) => ({ id: v.product_code, name: v.product_code })) || [],
    })
  }

  const handleOnChangeCategory = async ({ index, newValue }) => {
    update(index, {
      ...a.additional_configuration[index],
      productOrigin: selectedProductOrigin0[index] || [],
      category: newValue,
      productCode: [],
    })

    const [
      ,
      {
        data: { data: dataProductCode },
      },
    ] = await invokeApiPromoFlashsalesProducts({
      product_origin: selectedProductOrigin0[index]?.map((v) => v.id) || [],
      category: newValue?.map((v) => v.id),
      invoked: 'product_code',
    })

    setListProductCode0((old) => {
      const tes = [...old]
      tes[index] = dataProductCode?.map((v) => ({ id: v.product_code, name: v.product_code })) || []
      return tes
    })

    update(index, {
      ...a.additional_configuration[index],
      productOrigin: selectedProductOrigin0[index] || [],
      category: newValue,
      productCode:
        dataProductCode?.map((v) => ({ id: v.product_code, name: v.product_code })) || [],
    })
  }

  const showPaymentChannelFPXInput = (index) =>
    watch(`additional_configuration.${index}.paymentChannel`)?.some((v) => v.id === 'FPX')

  return (
    <>
      <form id="myform" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="country"
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Autocomplete
              options={countryList}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              disableCloseOnSelect={true}
              PaperComponent={({ children }) => <Paper className={classes.paper}>{children}</Paper>}
              value={value}
              onBlur={onBlur}
              onChange={(event, newValue) => {
                onChange(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Country"
                  size="small"
                  inputRef={ref}
                />
              )}
            />
          )}
        />

        <div style={{ height: '16px' }} />

        <Controller
          control={control}
          name="default"
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Autocomplete
              options={[
                { id: 'RAPYD', name: 'RAPYD' },
                { id: 'RAZER', name: 'RAZER' },
                { id: 'SPAY', name: 'SPAY' },
                { id: 'ASIAPAY_MY', name: 'ASIAPAY_MY' },
              ]}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              disableCloseOnSelect={true}
              PaperComponent={({ children }) => <Paper className={classes.paper}>{children}</Paper>}
              value={value}
              onBlur={onBlur}
              onChange={(event, newValue) => {
                onChange(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Default"
                  size="small"
                  inputRef={ref}
                />
              )}
            />
          )}
        />

        <div style={{ height: '16px' }} />

        <span
          style={{
            display: 'inline-block',
            marginBottom: '8px',
          }}
        >
          Additional Configuration
        </span>

        {fields.map((field, index) => (
          <div
            key={field.id}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
              //
            }}
          >
            <div
              style={{
                width: '100%',
                padding: '16px',
                border: '1px solid #00000026',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                marginBottom: '16px',
                //
              }}
            >
              <Controller
                control={control}
                name={`additional_configuration.${index}.paymentGatewayChannel`}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomAutocompleteInput
                    label="PG Channel"
                    options={[
                      { id: 'RAPYD', name: 'RAPYD' },
                      { id: 'RAZER', name: 'RAZER' },
                      { id: 'SPAY', name: 'SPAY' },
                      { id: 'ASIAPAY_MY', name: 'ASIAPAY_MY' },
                    ]}
                    disableCloseOnSelect={true}
                    PaperComponent={({ children }) => (
                      <Paper className={classes.paper}>{children}</Paper>
                    )}
                    value={value}
                    onBlur={onBlur}
                    onChange={(event, newValue) => {
                      onChange(newValue)
                      handleOnChangePaymentGatewayChannel({ index, newValue })
                    }}
                    inputRef={ref}
                  />
                )}
              />
              <Controller
                control={control}
                name={`additional_configuration.${index}.paymentChannel`}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomAutocompleteInput
                    label="Payment Channel"
                    multiple={true}
                    options={listPaymentChannel[selectedPgChannel[index]]}
                    disableCloseOnSelect={true}
                    PaperComponent={({ children }) => (
                      <Paper className={classes.paper}>{children}</Paper>
                    )}
                    value={value}
                    onBlur={onBlur}
                    onChange={(event, newValue) => {
                      onChange(newValue)
                    }}
                    inputRef={ref}
                  />
                )}
              />

              {showPaymentChannelFPXInput(index) && (
                <Controller
                  control={control}
                  name={`additional_configuration.${index}.paymentChannelFPX`}
                  rules={{ required: true }}
                  defaultValue={listPaymentChannelFPX[selectedPgChannel[index]]}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomAutocompleteInput
                      label="Payment Channel FPX"
                      multiple={true}
                      options={listPaymentChannelFPX[selectedPgChannel[index]]}
                      disableCloseOnSelect={true}
                      PaperComponent={({ children }) => (
                        <Paper className={classes.paper}>{children}</Paper>
                      )}
                      value={value}
                      onBlur={onBlur}
                      onChange={(event, newValue) => {
                        onChange(newValue)
                      }}
                      inputRef={ref}
                    />
                  )}
                />
              )}
              <Controller
                control={control}
                name={`additional_configuration.${index}.productOrigin`}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomAutocompleteInput
                    label="Product Origin"
                    multiple={true}
                    options={listProductOrigin}
                    disableCloseOnSelect={true}
                    PaperComponent={({ children }) => (
                      <Paper className={classes.paper}>{children}</Paper>
                    )}
                    value={value}
                    onBlur={onBlur}
                    onChange={(event, newValue) => {
                      onChange(newValue)
                      handleOnChangeProductOrigin({ index, newValue })
                    }}
                    inputRef={ref}
                  />
                )}
              />

              <Controller
                control={control}
                name={`additional_configuration.${index}.category`}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomAutocompleteInput
                    label="Category"
                    multiple={true}
                    options={listCategory0[index] || []}
                    disableCloseOnSelect={true}
                    PaperComponent={({ children }) => (
                      <Paper className={classes.paper}>{children}</Paper>
                    )}
                    value={value}
                    onBlur={onBlur}
                    onChange={(event, newValue) => {
                      onChange(newValue)
                      handleOnChangeCategory({ index, newValue })
                    }}
                    inputRef={ref}
                  />
                )}
              />

              <Controller
                control={control}
                name={`additional_configuration.${index}.productCode`}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomAutocompleteInput
                    label="Product Code"
                    multiple={true}
                    options={listProductCode0[index] || []}
                    disableCloseOnSelect={true}
                    PaperComponent={({ children }) => (
                      <Paper className={classes.paper}>{children}</Paper>
                    )}
                    value={value}
                    onBlur={onBlur}
                    onChange={(event, newValue) => {
                      onChange(newValue)
                    }}
                    inputRef={ref}
                  />
                )}
              />

              <RhfChipInputNumberFormat
                control={control}
                name={`additional_configuration.${index}.denom`}
                rules={{ required: true }}
                label="Denom"
              />
            </div>
            <IconButton
              onClick={(e) => {
                remove(index)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </form>

      <Button
        size="small"
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        disabled={fields.length >= 2}
        // disabled={fields >= 2}
        onClick={() => {
          append()
        }}
      >
        Add Additional Configuration
      </Button>
    </>
  )
}

/* OTHER FUNCTION */
function addCommasThousandSeparator(nStr) {
  nStr += ''
  let x = nStr.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  let rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2')
  }
  return x1 + x2
}
