import React, { useEffect } from 'react'
import { useInput, useTranslate } from 'react-admin'
import NumberFormat from 'react-number-format'
import ChipInput from 'material-ui-chip-input'
import Chip from '@material-ui/core/Chip'

const chipRenderer = (
  { value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, className, ...props },
  key,
  onChange,
  allValue
) => {
  const ttt2 = () => {
    onChange(allValue.filter((v) => v !== chip))
  }
  return (
    <Chip
      onFocus={isFocused}
      disabled={isDisabled}
      className={className}
      key={key}
      label={chip}
      onClick={handleClick}
      onDelete={ttt2}
      size="small"
      clickable={false}
    />
  )
}

export default function RaChipInputNumberFormat({
  variant = 'filled',
  label,
  size = 'small',
  fullWidth,
  style,
  disabled,
  ...props
}) {
  const translate = useTranslate()

  const {
    input: { name, onChange, value, ref, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  useEffect(() => {
    onChange(value || [])
  }, [value?.toString()])

  return (
    <ChipInput
      value={value || []}
      chipRenderer={(action, key) => chipRenderer(action, key, onChange, value)}
      inputRef={ref}
      variant={variant}
      label={label}
      size={size}
      required={isRequired}
      error={!!(touched && error)}
      helperText={touched && translate(error)}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      fullWidth={fullWidth}
      style={style}
      disabled={disabled}
      onAdd={(chip) => {
        onChange([...value, chip])
      }}
      {...rest}
    />
  )
}

/* OTHER COMPONENT */
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
    />
  )
}
