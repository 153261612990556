import React, { useState, useEffect } from 'react'
import { useInput } from 'react-admin'
import { ChromePicker } from '@hello-pangea/color-picker'
import dataProvider from '../../provider/DataProvider'

export default function MyColorPicker({ pageProps, ...props }) {
  const getOneData = async () => {
    const data = await dataProvider.getOne('advertisements', {
      id: props.id,
    })

    return data
  }
  // declare hooks here
  const [data, setData] = useState([])
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState({
    r: '1',
    g: '1',
    b: '1',
    a: '1',
  })
  const { input } = useInput({ source: props.source })

  useEffect(() => {
    ;(async () => {
      const res = await getOneData()
      setData(res?.data)
    })()
  }, [props.id])

  useEffect(() => {
    if (data?.running_text_config?.css) {
      setColor(data?.running_text_config?.css?.color)
      input.onChange(data?.running_text_config?.css?.color)
    }
  }, [data?.running_text_config?.css])

  function handleClick() {
    setDisplayColorPicker(!displayColorPicker)
  }

  function handleClose() {
    setDisplayColorPicker(false)
  }

  function handleChange(color) {
    setColor(color.rgb)
    input.onChange(color.rgb)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          marginRight: '17px',
        }}
      >
        <div
          style={{
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          }}
        />
      </div>
      <button onClick={handleClick}>Pick Color</button>
      {displayColorPicker ? (
        <div
          style={{
            position: 'absolute',
            zIndex: '2',
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleClose}
          />
          <ChromePicker onChangeComplete={handleChange} />
        </div>
      ) : null}
    </div>
  )
}
