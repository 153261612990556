import gql from 'graphql-tag'
import { client } from '../provider/MbaasClient'

/* MAIN FUNCTION */
export default async function fetchInfoForInvokeApiEmitEventTransactionStatus({
  authUserId,
  orderIdTransaction,
}) {
  try {
    const resultUser = await client.gql.query({
      query: queryUser,
      variables: { id: authUserId },
    })
    const resultTransaction = await client.gql.query({
      query: queryTransaction,
      variables: { orderId: orderIdTransaction },
    })

    return [
      null,
      {
        supplierRef: resultTransaction?.allTransactionsList?.data[0]?.supplier_reference_id || '',
        apiUsername: resultUser?.getUsersById?.organization_id_data?.api_username || '',
        apiPassword: resultUser?.getUsersById?.organization_id_data?.api_password || '',
      },
    ]
  } catch (error) {
    return [
      error,
      {
        supplierRef: '',
        apiUsername: '',
        apiPassword: '',
      },
    ]
  }
}

/* QUERY GRAPHQL */
const queryUser = gql`
  query user($id: UUID) {
    getUsersById(id: $id) {
      organization_id_data {
        api_username
        api_password
      }
    }
  }
`

const queryTransaction = gql`
  query transaction($orderId: String) {
    allTransactionsList(where: { order_id: { _eq: $orderId } }) {
      data {
        order_id
        supplier_reference_id
      }
    }
  }
`
