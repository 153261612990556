import React from 'react'
import { Create, FormWithRedirect, useNotify, useRedirect } from 'react-admin'
import { FormInput } from '@/src/component/doule-poin/form-input'

export const PageCreate = (props: any) => {
  const notify = useNotify()
  const redirect = useRedirect()

  return (
    <Create
      title="Create Multiplier"
      {...props}
      onSuccess={() => {
        notify('ra.notification.created', 'info', { smart_count: 1 })
        redirect('/promo_double_poin')
      }}
      onFailure={(error) => {
        notify(`Muliplier create error: ${error.message}`, 'warning')
      }}
    >
      <FormWithRedirect
        mutators={{
          setValue: ([field, value]: any, state: any, { changeValue }: any) => {
            changeValue(state, field, () => value)
          },
        }}
        render={(renderProps) => {
          return <FormInput {...renderProps} />
        }}
      />
    </Create>
  )
}
