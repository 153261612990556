import { fetchDataInitTable } from '../shared-script/fetchDataInitTable'
const { moco } = require('../../MbaasClient')

export async function isStorePoints(params, resource) {
  console.log(6234958776, { method: 'getList', resource: resource, params: params })
  const { storePointId, address, state, name } = params.filter || {}
  const { page, perPage } = params.pagination
  let { field, order } = params.sort

  let filterObject = moco.composeFilter()
  let includedTables = []

  switch (true) {
    case Boolean(storePointId):
      filterObject = filterObject.eq('id', storePointId)
      break
    case Boolean(name):
      filterObject = filterObject.contains((x) => x.toLower('name'), name.toLowerCase())
      break
    case Boolean(address):
      filterObject = filterObject.contains((x) => x.toLower('address'), address.toLowerCase())
      break
    case Boolean(state):
      filterObject = filterObject.contains((x) => x.toLower('state'), state.toLowerCase())
      break
    default:
      console.log('default')
      break
  }

  if (filterObject !== '') filterObject = filterObject.toString()

  const [data, total] = await fetchDataInitTable(
    includedTables,
    resource,
    filterObject,
    order,
    field,
    page,
    perPage
  )

  return { data: data, total: total }
}
