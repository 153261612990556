const { client } = require('../../MbaasClient')

export const funcCreateBlastSms = async (resource, params) => {
  const userData = await client.user.get()
  const { data } = await client.getTable('sms_blasts').insert({
    creator_id: userData.data.id,
    content: {
      title: params.data.title,
      content: params.data.content,
      type: 'blasts',
    },
    target: params.data.target,
    is_sent: true,
  })

  return { data: data }
}
