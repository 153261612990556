import { ClickAwayListener, Tooltip, Box } from '@material-ui/core'
import React from 'react'

import FileCopyIcon from '@material-ui/icons/FileCopy'

// type ClipboardProp = PropsWithChildren<{
//   value: string;
// }>;

export function ClipboardButton(props) {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const setClipboard = () => {
    navigator.clipboard.writeText(props.value)
    setOpen(true)
    setTimeout(() => handleTooltipClose(), 1500)
  }
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        placement="left"
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copied"
      >
        <Box display="flex" alignItems="center" role="button" onClick={setClipboard}>
          <Box sx={{ marginRight: 8 }}>{props.children}</Box>
          <FileCopyIcon fontSize="small" color="secondary" />
        </Box>
      </Tooltip>
    </ClickAwayListener>
  )
}
