import React, { useState, useEffect } from 'react'
import {
  TextInput,
  required,
  useNotify,
  useRedirect,
  useCreate,
  Confirm,
  Toolbar,
  SaveButton,
  FormWithRedirect,
} from 'react-admin'

import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { Importer, ImporterField } from 'react-csv-importer'
import 'react-csv-importer/dist/index.css'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TableNotRegistered from './TableNotRegistered'
import SendIcon from '@material-ui/icons/Send'

const { client } = require('./../../provider/MbaasClient')

const fetchMSISDNFromUser = async (setMsisdn) => {
  return await client.gql.query({
    query: gql`
      query msisdn($setMsisdn: [String]) {
        allUsersList(where: { msisdn: { _in: $setMsisdn } }) {
          data {
            id
            msisdn
          }
        }
      }
    `,
    variables: {
      setMsisdn,
    },
  })
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: 'auto',
    },
  },
}))

export default function PageCreate(props) {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()
  const [registeredUserMSISDN, setRegisteredUserMSISDN] = useState([])
  const [initialText] = useState('RM0 GoNilai: ')
  const [isImportFinished, setIsImportFinished] = useState(false)
  const [listTargetState, setListTargetState] = useState([])
  const [notRegisteredMSISDN, setNotRegisteredMSISDN] = useState([])
  const [verifiedMsisdn, setVerifiedMsisdn] = useState([])
  const [message, setMessage] = useState('RM0 GoNilai: ')
  const [title, setTitle] = useState('')
  const [openDialog, setOpenDialog] = useState(false)

  const [listTargetUnique, setListTargetUnique] = useState([])

  const handleRefresh = () => {
    window.location.reload()
  }

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  useEffect(() => {
    const listTargetSet = new Set(listTargetState)
    const newArr = [...listTargetSet]

    fetchMSISDNFromUser(newArr).then((data) => {
      const newFormat = data?.allUsersList?.data.map((dat) => {
        return dat?.msisdn
      })
      //-- msisdn from table user
      setRegisteredUserMSISDN(newFormat)
    })
    //-- unique msisdn from csv
    setListTargetUnique(newArr)
  }, [listTargetState])

  useEffect(() => {
    let intersection = []
    let difference = []

    if (registeredUserMSISDN.length === listTargetUnique.length) {
      // -- all verified
      intersection = listTargetUnique.filter((x) => registeredUserMSISDN.includes(x))

      setVerifiedMsisdn(intersection)
    }

    if (registeredUserMSISDN.length !== listTargetUnique.length) {
      // -- filter for verified msisdn
      intersection = listTargetUnique.filter((x) => registeredUserMSISDN.includes(x))
      // -- filter for not registered msisdn
      difference = listTargetUnique.filter((x) => !registeredUserMSISDN.includes(x))

      setNotRegisteredMSISDN(difference)
      setVerifiedMsisdn(intersection)
    }
    if (intersection.length > 0 || difference.length > 0) {
      handleClickOpen()
    }
  }, [isImportFinished])

  const inputHandler = (e) => {
    setMessage(e.target.value)
  }

  const inputTitleHandler = (e) => {
    setTitle(e.target.value)
  }

  const [create, { isLoading }] = useCreate()

  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = async () => {
    const newObj = { title: title, content: message, target: listTargetState }

    create('blast_sms', newObj, {
      onSuccess: () => {
        redirect('/blast_sms')
      },
      onFailure: ({ error }) => {
        notify(error.message, 'error')
      },
    })
    setOpen(false)
  }

  return (
    <Paper style={{ padding: '20px' }}>
      <FormWithRedirect
        resource="override"
        save={handleClick}
        render={({ handleSubmitWithRedirect, pristine, saving }) => (
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Importer
                  chunkSize={10000} // optional, internal parsing chunk size in bytes
                  assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
                  restartable={false} // optional, lets user choose to upload another file when import is complete
                  onStart={({ file, fields }) => {
                    // optional, invoked when user has mapped columns and started import
                    console.log('starting import of file', file, 'with fields', fields)
                  }}
                  processChunk={async (rows) => {
                    // required, receives a list of parsed objects based on defined fields and user column mapping;
                    // may be called several times if file is large
                    // (if this callback returns a promise, the widget will wait for it before parsing more data)
                    const newTar = rows.map((dat) => {
                      return dat.Target
                    })

                    await setListTargetState((oldArray) => [...oldArray, ...newTar])
                    // mock timeout to simulate processing
                    await new Promise((resolve) => setTimeout(resolve, 500))
                  }}
                  onComplete={() => {
                    // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                    setIsImportFinished(true)
                  }}
                  onClose={() => {
                    // optional, invoked when import is done and user clicked "Finish"
                    // (if this is not specified, the widget lets the user upload another file)
                    // if (notRegisteredMSISDN.length > 0 || didNotHaveCountryCode.length > 0) {
                    //   handleClickOpen()
                    // }
                  }}
                  delimiter={','}
                >
                  <ImporterField name="Target" label="Target" />
                </Importer>
                {/* DIALOG */}
                <Dialog
                  open={openDialog}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle id="alert-dialog-title"></DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description" component="div">
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            component="h6"
                            style={{ color: 'black', marginBottom: '10px' }}
                          >
                            Below is the result
                          </Typography>
                          <TableNotRegistered
                            notRegisteredMSISDN={notRegisteredMSISDN}
                            verifiedMsisdn={verifiedMsisdn}
                          />
                        </Grid>
                      </Grid>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleRefresh} color="primary">
                      Re-Upload csv
                    </Button>
                    <Button onClick={handleClose} color="primary">
                      Continue
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  className={classes.input}
                  source="title"
                  label="Topic"
                  autoFocus={true}
                  onChange={inputTitleHandler}
                  validate={required()}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="standard-multiline-flexible"
                  fullWidth
                  label="Message"
                  defaultValue={initialText}
                  onChange={inputHandler}
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 160 }}
                />
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: '5px',
                  }}
                >
                  <LinearProgress
                    className="charProgress"
                    variant="determinate"
                    value={message.length}
                  />
                  <span className="charLeft">{160 - message.length} characters left</span>
                </Grid>

                <Grid item xs={12}>
                  <Confirm
                    isOpen={open}
                    loading={isLoading}
                    title="Important"
                    content="By saving this record it will automatically send SMS message to the target list, are you sure want to proceed?"
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                  />
                  <Toolbar>
                    <SaveButton
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      pristine={pristine}
                      saving={saving}
                      disabled={isLoading || !isImportFinished}
                      label="Send"
                      icon={<SendIcon />}
                    />
                  </Toolbar>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      />
    </Paper>
  )
}
