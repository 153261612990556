function buildArguments(args) {
  let argsArray = []
  if (args.size) {
    argsArray.push(`limit:${args.size || 25}`)
  }
  if (args.pageNumber) {
    argsArray.push(`offset:${(args.pageNumber - 1) * (args.size || 25)}`)
  }
  if (args.sort) {
    argsArray.push(`sort:${args.sort}`)
  }
  if (args.order) {
    argsArray.push(`order:${args.order}`)
  }

  return argsArray.join(',')
}

export const buildArgs = buildArguments
