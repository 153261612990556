import React, { useState } from 'react'
import {
  List,
  Datagrid,
  TextField,
  required,
  useNotify,
  useRedirect,
  SimpleForm,
  Edit,
  EditButton,
  Toolbar,
  SaveButton,
  Create,
  FunctionField,
  NumberField,
  NumberInput,
  DeleteButton,
  SelectInput,
  ImageField,
  ImageInput,
  FormDataConsumer,
  Filter,
  TextInput,
  DateTimeInput,
  useGetOne,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { DateRange } from './DateRange'
import moment from 'moment'

const MessengerMsgSeriesPostFilter = (props) => (
  <Filter
    {...props}
    filterDefaultValues={{
      dateGte: moment().subtract(1, 'month').calendar(),
      dateLte: moment().format('YYYY/MM/DD'),
    }}
  >
    <DateRange alwaysOn />
    <SelectInput
      source="type"
      label="Message Type"
      choices={[
        { id: 'text', name: 'Text' },
        { id: 'image', name: 'Image' },
        { id: 'video', name: 'Video' },
      ]}
    />
  </Filter>
)

export const MessengerMsgSeriesList = (props) => (
  <List
    {...props}
    title="Welcome Messages"
    sort={{ field: 'delay', order: 'ASC' }}
    filters={<MessengerMsgSeriesPostFilter />}
  >
    <Datagrid>
      <TextField source="type" label="Message Type" />
      <NumberField source="delay" label="Delay (minutes)" />
      <TextField source="target" label="Target Customers" />
      <FunctionField
        source="start"
        label="Status"
        render={(record) => {
          const start = moment(record.start).toISOString()
          const end = moment(record.end).toISOString()
          if (moment(moment().toISOString()).isBetween(start, end)) {
            return 'ACTIVE'
          }
          return 'EXPIRED'
        }}
      />
      <FunctionField
        label="Content"
        render={(record) => {
          switch (record.type) {
            case 'image':
              return (
                <img
                  src={record.content.url}
                  alt={record.content.url}
                  width="auto"
                  style={{ maxWidth: 120 }}
                />
              )
            case 'video':
              let url = record.content.url
              let urlArr = url.split('?v=')
              let videoSrc = `https://www.youtube.com/embed/${urlArr['1']}?controls=0`
              return <iframe width="240" title="Preview" src={videoSrc}></iframe>
            case 'text':
              return record.content.text
            default:
              return <></>
          }
        }}
      />
      <EditButton />
    </Datagrid>
  </List>
)

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export const MessengerMsgSeriesCreate = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/messenger_msg_series')
  }

  return (
    <Create {...props} onSuccess={onSuccess} title="Create a Welcome Message">
      <SimpleForm>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                source="type"
                choices={[
                  { id: 'text', name: 'Text' },
                  { id: 'image', name: 'Image' },
                  { id: 'video', name: 'Video' },
                ]}
                label="Type of Message"
                fullWidth
                className={classes.input}
                validate={[required()]}
              />
              <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) =>
                  formData.type === 'image' && (
                    <ImageInput
                      className={classes.input}
                      source="imgUpload"
                      label="Upload image"
                      accept="image/*"
                      minSize={0}
                      maxSize={200000}
                      placeholder="Drop a picture to upload, or click to select it. (max. 200KB)"
                    >
                      <ImageField source="src" title="title" />
                    </ImageInput>
                  )
                }
              </FormDataConsumer>
            </Grid>
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                formData.type === 'text' && (
                  <Grid item xs={12}>
                    <TextInput
                      source="content.text"
                      className={classes.input}
                      label="Text"
                      fullWidth
                    />
                  </Grid>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, ...rest }) =>
                formData.type === 'video' && (
                  <Grid item xs={12}>
                    <TextInput
                      label="Video URI"
                      fullWidth
                      className={classes.input}
                      source="content.url"
                      validate={[required()]}
                    />
                    <TextInput
                      label="Title"
                      fullWidth
                      className={classes.input}
                      source="content.title"
                      validate={[required()]}
                    />
                    <TextInput
                      label="Subtitle"
                      fullWidth
                      className={classes.input}
                      source="content.subtitle"
                      validate={[required()]}
                    />
                  </Grid>
                )
              }
            </FormDataConsumer>
            <Grid item xs={12}>
              <NumberInput
                label="Delay (minutes)"
                fullWidth
                className={classes.input}
                source="delay"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                source="target"
                choices={[
                  { id: 'ALL', name: 'ALL Customers' },
                  { id: 'NEW', name: 'NEW Customers' },
                  { id: 'OLD', name: 'OLD Customers' },
                ]}
                label="Target"
                fullWidth
                className={classes.input}
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimeInput
                fullWidth
                className={classes.input}
                source="start"
                label="Valid From"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimeInput
                fullWidth
                className={classes.input}
                source="end"
                label="Valid Until"
                validate={[required()]}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}

export const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
)

const EditTitle = () => {
  return <span>Edit a Welcome Message</span>
}

const CurrentPreview = ({ record }) => {
  switch (record.type) {
    case 'image':
      return (
        <div id="current" style={{ marginBottom: 25 }}>
          <div className="MuiFormControl-root RaFileInput-root-57 RaImageInput-root-50 makeStyles-input-36 MuiFormControl-marginDense">
            <label class="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-50 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
              <span>Current&nbsp;image</span>
            </label>
          </div>
          <div
            className="MuiFormControl-root RaFileInput-root-57 RaImageInput-root-50 makeStyles-input-36 MuiFormControl-marginDense"
            style={{ marginTop: 25 }}
          >
            <img
              alt="Current"
              style={{ maxWidth: 240 }}
              src={record.content.url}
              title="Current Image"
            />
          </div>
        </div>
      )
    case 'video':
      let url = record.content.url
      let urlArr = url.split('?v=')
      let videoSrc = `https://www.youtube.com/embed/${urlArr['1']}?controls=0`
      return (
        <div id="current">
          <div className="MuiFormControl-root RaFileInput-root-57 RaImageInput-root-50 makeStyles-input-36 MuiFormControl-marginDense">
            <label class="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-50 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
              <span>Current&nbsp;video</span>
            </label>
          </div>
          <div
            className="MuiFormControl-root RaFileInput-root-57 RaImageInput-root-50 makeStyles-input-36 MuiFormControl-marginDense"
            style={{ marginTop: 25 }}
          >
            <iframe width="420" title="Current Video" height="315" src={videoSrc}></iframe>
          </div>
        </div>
      )
    default:
      return <></>
  }
}

export const MessengerMsgSeriesEdit = (props) => {
  const classes = useStyles()

  const { data } = useGetOne('messenger_msg_series', props.id)
  const currentData = Object.assign({
    ...data,
  })
  const currentDataContent = currentData.content
  const currentContent = Object.assign({
    ...currentDataContent,
  })
  const currentType = currentData.type
  const currentUrl = currentContent.url

  const [selectedType, setSelectedType] = useState(currentType)
  const [urlValue, setUrlValue] = useState(currentUrl)

  const onchange = (selected) => {
    setSelectedType(selected.target.value)
    const untoUrlValue = currentType === selected.target.value ? currentUrl : ''
    setUrlValue(untoUrlValue)
  }

  return (
    <Edit {...props} title={<EditTitle />}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <SelectInput
          source="type"
          choices={[
            { id: 'text', name: 'Text' },
            { id: 'image', name: 'Image' },
            { id: 'video', name: 'Video' },
          ]}
          label="Type of Message"
          fullWidth
          className={classes.input}
          validate={[required()]}
          onChange={onchange}
        />
        {selectedType === currentType && (
          <CurrentPreview label="Current Preview" className={classes.input} />
        )}
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.type === 'image' && (
              <ImageInput
                className={classes.input}
                source="imgUpload"
                label="Upload new image"
                accept="image/*"
                minSize={0}
                maxSize={200000}
                placeholder="Drop a picture to upload, or click to select it. (max. 200KB)"
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.type === 'text' && (
              <div>
                <TextInput label="Text" source="content.text" className={classes.input} fullWidth />
              </div>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.type === 'video' && (
              <Grid item xs={12}>
                <TextInput
                  label="Video URI"
                  fullWidth
                  className={classes.input}
                  source="content.url"
                  validate={[required()]}
                  value={urlValue}
                />
                <TextInput
                  label="Title"
                  fullWidth
                  className={classes.input}
                  source="content.title"
                  validate={[required()]}
                />
                <TextInput
                  label="Subtitle"
                  fullWidth
                  className={classes.input}
                  source="content.subtitle"
                  validate={[required()]}
                />
              </Grid>
            )
          }
        </FormDataConsumer>
        <NumberInput
          label="Delay (minutes)"
          fullWidth
          className={classes.input}
          source="delay"
          validate={[required()]}
        />
        <SelectInput
          source="target"
          choices={[
            { id: 'ALL', name: 'ALL Customers' },
            { id: 'NEW', name: 'NEW Customers' },
            { id: 'OLD', name: 'OLD Customers' },
          ]}
          label="Target"
          fullWidth
          className={classes.input}
          validate={[required()]}
        />
        <DateTimeInput
          fullWidth
          className={classes.input}
          source="start"
          label="Valid From"
          validate={[required()]}
        />
        <DateTimeInput
          fullWidth
          className={classes.input}
          source="end"
          label="Valid Until"
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  )
}
