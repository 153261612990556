import React, { cloneElement } from 'react'
import {
  TextField,
  ReferenceField,
  List,
  Datagrid,
  FunctionField,
  Filter,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin'
import ReactJson from 'react-json-view'
import { DateRange } from './DateRange'
import moment from 'moment'
import ExportButton from '../shared-components/export-button'

const LogsFilter = (props) => (
  <Filter {...props}>
    <DateRange alwaysOn />
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton filter={filterValues} resourceType="LOG" />
    </TopToolbar>
  )
}

export const LogsList = (props) => (
  <List
    {...props}
    filters={<LogsFilter />}
    filterDefaultValues={{
      dateGte: moment().subtract(1, 'month').calendar(),
      dateLte: moment().format('YYYY/MM/DD'),
    }}
    actions={<ListActions />}
  >
    <Datagrid>
      <ReferenceField reference="users" source="user_id">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="activity" />
      <FunctionField
        label="before"
        render={(record) => (
          <div>
            <label>
              <ReactJson
                name={false}
                src={record.before}
                shouldCollapse={() => {}}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            </label>
          </div>
        )}
      />
      <FunctionField
        label="After"
        render={(record) => (
          <div>
            <label>
              <ReactJson
                src={record.after}
                name={false}
                shouldCollapse={() => {}}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            </label>
          </div>
        )}
      />
    </Datagrid>
  </List>
)
