import React from 'react'
import { Edit, FormWithRedirect, useNotify, useRedirect } from 'react-admin'
import { FormInput } from '@/src/component/doule-poin/form-input'

const EditTitle = ({ record, pageName }: any): any => {
  return `Edit ${pageName}: ${record.id}`
}

export const PageEdit = (props: any) => {
  const notify = useNotify()
  const redirect = useRedirect()

  return (
    <Edit
      title={<EditTitle pageName="Multiplier" />}
      {...props}
      mutationMode="pessimistic"
      onSuccess={() => {
        redirect('/promo_double_poin')
        notify('Muliplier successfully edited')
      }}
      onFailure={(error) => {
        notify(`Muliplier edit error: ${error.message}`, 'warning')
      }}
    >
      <FormWithRedirect
        mutators={{
          setValue: ([field, value]: any, state: any, { changeValue }: any) => {
            changeValue(state, field, () => value)
          },
        }}
        render={(renderProps) => {
          return <FormInput {...renderProps} />
        }}
      />
    </Edit>
  )
}
