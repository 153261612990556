import { client } from '../provider/MbaasClient'

type TInvoked = 'country' | 'category' | 'product_code' | 'denomination'

type TReturn<T> = T extends 'country'
  ? {
      data?: {
        data?: {
          country: number
        }[]
      }
      error?: any
    }
  : T extends 'category'
  ? { data?: { data?: { category: number }[] }; error?: any }
  : T extends 'product_code'
  ? { data?: { data?: { product_code: number }[] }; error?: any }
  : T extends 'denomination'
  ? { data?: { data?: { denomination: number }[] }; error?: any }
  : never

export const fetchDistinctTableProduct = async <T extends TInvoked>(
  invoked: T,
  {
    product_origin = undefined,
    category = undefined,
    product_code = undefined,
    denomination = undefined,
  } = {}
): Promise<TReturn<T>> => {
  const res = await client.invokeApi('promo-flashsales-products', {
    product_origin,
    category,
    product_code,
    denomination,
    invoked,
  })
  return res as TReturn<T>
}
