import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  ReferenceField,
  required,
  FunctionField,
  Filter,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin'
import ReactJson from 'react-json-view'
import { DateRange } from './DateRange'
import moment from 'moment'
import ExportButton from '../shared-components/export-button'

const LedgersFilter = (props) => (
  <Filter {...props}>
    <DateRange alwaysOn />
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const {
    resource,
    displayedFilters,
    filterValues,
    // basePath,
    showFilter,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton filter={filterValues} resourceType="LEDGER" />
    </TopToolbar>
  )
}

export const LedgersList = (props) => (
  <List
    {...props}
    filters={<LedgersFilter />}
    filterDefaultValues={{
      dateGte: moment().subtract(1, 'month').calendar(),
      dateLte: moment().format('YYYY/MM/DD'),
    }}
    actions={<ListActions />}
  >
    <Datagrid>
      <ReferenceField source="user_id" reference="users" label="Users" validate={[required()]}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="reference" />
      <NumberField source="credit" />
      <NumberField source="debit" />
      <TextField source="remark" />
      <FunctionField
        label="Data"
        render={(record) => (
          <ReactJson
            src={record.data || {}}
            shouldCollapse={() => {}}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={true}
            name={false}
          />
        )}
      />
    </Datagrid>
  </List>
)
