import React from 'react'
import {
  TextInput,
  Edit,
  SimpleForm,
  BooleanInput,
  Toolbar,
  SaveButton,
  FormDataConsumer,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

export default function PricingEdit(props) {
  const classes = useStyles()
  const [dataForm, setDataForm] = React.useState()

  return (
    <Edit {...props} title="Product categories edit">
      <SimpleForm toolbar={<CustomToolbar dataForm={dataForm} />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                label="Category"
                source="category"
                disabled
                fullWidth
                className={classes.input}
              />
            </Grid>

            <Grid item xs={3}>
              <BooleanInput
                label="Alter Number"
                source="failed_trx_button.alter_number"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={3}>
              <BooleanInput
                label="Alter Product"
                source="failed_trx_button.alter_product"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={3}>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <BooleanInput
                      label="Reprocess"
                      source="failed_trx_button.reprocess"
                      className={classes.input}
                    />
                  )
                }}
              </FormDataConsumer>
            </Grid>
            <Grid style={{ paddingTop: 0, marginTop: -15 }} item xs={12}>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  setDataForm(formData)
                  let buttonEnable
                  if (dataForm) {
                    buttonEnable = Object.values(dataForm.failed_trx_button).filter(
                      (val) => val
                    ).length
                  }
                  if (buttonEnable > 2) {
                    return (
                      <p style={{ fontSize: 14, color: '#f44336' }}>
                        Error: button enable maximal 2
                      </p>
                    )
                  }
                }}
              </FormDataConsumer>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

const CustomToolbar = ({ dataForm, ...props }) => {
  let buttonEnable
  if (dataForm) {
    buttonEnable = Object.values(dataForm.failed_trx_button).filter((val) => val).length
  }
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton disabled={buttonEnable > 2} />
    </Toolbar>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  text: {
    color: '#0000008A',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))
