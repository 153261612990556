import gql from 'graphql-tag'
import moment from 'moment'
import { buildArgs } from '../shared-script/buildArgs'
const { client } = require('../../MbaasClient')

export async function isBonuses(params, resource) {
  console.log(623495, { method: 'getList', resource: resource, params: params })

  // varibles declaration here
  const where = {}
  const filterArray = []

  // filter by date range
  if (params.filter.dateGte || params.filter.dateLte) {
    filterArray.push({
      created_at: {
        _gte: moment(params.filter.dateGte).startOf('day').toISOString(),
      },
    })
    filterArray.push({
      created_at: {
        _lte: moment(params.filter.dateLte).endOf('day').toISOString(),
      },
    })
  }

  if (filterArray.length > 0) {
    where._and = filterArray
  }

  const args = buildArgs({
    order: params.sort.order.toUpperCase(),
    size: params.pagination.perPage,
    pageNumber: params.pagination.page,
    sort: params.sort.field,
  })

  const gqlResult = await client.gql.query({
    query: gql`
      query getAllBonuses($where: BonusesWhereInput) {
        allBonusesList(where: $where, ${args}) {
          data {
            id
            created_at
            author_id_data {
              name
            }
            country
            start
            end
            items
            configs
            transactions_bonus_id_list {
              count
            }
          }
          count
        }
      }
    `,
  })
  let returnedData = gqlResult.allBonusesList.data
  returnedData = returnedData.map(async (x) => {
    let specific_customer = x.configs.specific_customer
    if (x.configs.specific_customer.status) {
      const { getCustomersById: custData } = await client.gql.query({
        query: gql`
          query ($id: UUID) {
            getCustomersById(id: $id) {
              name
            }
          }
        `,
        variables: {
          id: x.configs.specific_customer.customer_id,
        },
      })
      specific_customer = {
        status: x.configs.specific_customer.status,
        customer_id: x.configs.specific_customer.customer_id,
        name: custData.name,
      }
    }
    return {
      ...x,
      items_main: {
        category: x.items.category,
        product_origin: x.items.product_origin,
        product_code: x.items.product_code,
        denomination: x.items.denomination,
      },
      items: {
        ...x.items,
        category: x.items.category.map((cat) => ({ id: cat, name: cat })),
        product_origin: x.items.product_origin.map((prod) => ({ id: prod, name: prod })),
        product_code: x.items.product_code.map((prod) => ({ id: prod, name: prod })),
        denomination: x.items.denomination.map((denom) => ({ id: denom, name: denom })),
        channel: x.items.channel.map((cha) => ({ id: cha, name: cha })),
      },
      configs: {
        ...x.configs,
        specific_customer,
      },
    }
  })
  returnedData = await Promise.all(returnedData)
  return {
    data: returnedData,
    total: gqlResult.allBonusesList.count || 0,
  }
}
