import gql from 'graphql-tag'
const { client } = require('../../MbaasClient')

export async function isCustomersListData(params, resource) {
  console.log(623495, { method: 'getList', resource: resource, params: params })
  const { q } = params.filter

  const where = {}
  const filterArray = []
  if (q) {
    filterArray.push({ name: { _ilike: `%${q}%` } })
  }

  if (parseInt(params.filter.custName)) {
    filterArray.push({
      _or: [
        {channel_id: { _ilike: `%${params.filter.custName}%` }},
        {users_customer_id_list: {
          msisdn: {
            _ilike: `%${params.filter.custName}%`,
          },
        }},
      ],
    })
  } else {
    filterArray.push({ name: { _ilike: `%${params.filter.custName}%` } })
  }
  if (filterArray.length > 0) where._and = filterArray

  const gqlResult = await client.gql.query({
    query: gql`
      query customerList($latest_chat_at: PaginationOrderEnum, $where: CustomersWhereInput) {
        allCustomersList(limit: 50, orderBy: { latest_chat_at: $latest_chat_at }, where: $where) {
          data {
            id
            created_at
            updated_at
            name
            channel
            channel_id
            latest_chat_at
            users_customer_id_list {
              data {
                id
                msisdn
              }
            }
          }
          count
        }
      }
    `,
    variables: {
      latest_chat_at: params.sort.order.toUpperCase(),
      where,
    },
  })

  return {
    data: gqlResult?.allCustomersList?.data || [],
    total: gqlResult?.allCustomersList?.count || 0,
  }
}
