// @ts-nocheck
import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ReferenceField,
  TopToolbar,
  CreateButton,
  Filter,
  BooleanField,
  NumberField,
  SelectField,
  FunctionField,
} from 'react-admin'
import { DateRange } from '@/src/component/DateRange'
import { RaJsonField } from '@/src/shared-components/ra-json-field'
import moment from 'moment/moment'

function CustomFilter(props: any) {
  return (
    <Filter {...props}>
      {/* @ts-ignore*/}
      <DateRange alwaysOn />
    </Filter>
  )
}

function CustomActions({ basePath }: any) {
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  )
}

function EditButtonCustom(props: any) {
  return <EditButton {...props} disabled={props.record?.transactions_aggregate?.count >= 1} />
}

export const PageList = (props: any) => {
  return (
    <List
      {...props}
      filters={<CustomFilter />}
      actions={<CustomActions />}
      filterDefaultValues={{
        dateGte: moment().subtract(1, 'month').calendar(),
        dateLte: moment().format('YYYY/MM/DD'),
      }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <SelectField
          source="country"
          choices={[
            { id: 'MY', name: 'Malaysia' },
            { id: 'ID', name: 'Indonesia' },
          ]}
        />
        <FunctionField
          source="items.channel"
          label="Channel"
          sortable={false}
          render={(record: any) => {
            return (
              <ul style={{ padding: 0 }}>
                {record?.items?.channel?.map((v: any) => {
                  return <li>{v?.id}</li>
                })}
              </ul>
            )
          }}
        />
        <RaJsonField source="items" label="Items" />
        <NumberField source="value" label="Multiplier" />
        <BooleanField
          source="configs.new_customer_only"
          label="For New Customer / Target Only?"
          sortable={false}
        />
        <BooleanField
          source="configs.once_per_target"
          label="Is Specific Target?"
          sortable={false}
        />
        <BooleanField
          source="configs.specific_customer.status"
          label="Is for Specific Customer?"
          sortable={false}
        />
        <ReferenceField
          source="configs.specific_customer.customer_id"
          reference="customers"
          label="Specific For"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="configs.total_usage" label="Total Usage" sortable={false} />
        <NumberField
          source="configs.max_usage_per_customer"
          label="Max Usage Per Customer"
          sortable={false}
        />
        <DateField source="start" label="myroot.valid_from" />
        <DateField source="end" label="Valid Until" />
        <ReferenceField source="user_id" reference="users" label="Created by">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_at" label="Created At" />
        <EditButtonCustom />
      </Datagrid>
    </List>
  )
}
