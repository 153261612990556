import { useRecordContext } from 'react-admin'
import ReactJson from 'react-json-view'
import React from 'react'

export const RaJsonField = ({ source }: { source: string; label?: string }): any => {
  const record = useRecordContext()
  let src = record[source]
  if (source.includes('.')) {
    const [main_source, sub_source] = source.split('.')
    src = record[main_source][sub_source]
  }
  return (
    // @ts-ignore
    <ReactJson
      src={src}
      // @ts-ignore
      shouldCollapse={(v) => v}
      name={false}
      enableClipboard={false}
      displayDataTypes={false}
      displayObjectSize={false}
    />
  )
}
