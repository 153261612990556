import moment from 'moment'
import { client } from '../../MbaasClient'

// @ts-ignore
export const funcUpdateCustomers = async (resource, params) => {
  const userData: any = await client.user.get()
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  let updatedData = params.data
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  const previousData = params.previousData
  let logId = 0
  let logs = params.previousData.metadata.logs
  if (logs && logs.length > 0) {
    logId = logs.length
  }
  let logArray = []
  logArray.push({
    id: logId,
    date: moment().toISOString(),
    updated_by: {
      user_id: userData.data.id,
      name: userData.data.name,
    },
    before: {
      name: previousData.name,
      channel: previousData.channel,
      enable: previousData.enable,
    },
    after: {
      name: updatedData.name,
      channel: updatedData.channel,
      enable: updatedData.enable,
    },
  })
  if (logs && logs.length > 0) {
    // @ts-ignore
    logArray = logArray.concat(logs).sort((a, b) => b.date - a.date)
  }

  updatedData = {
    name: updatedData.name,
    channel: updatedData.channel,
    enable: updatedData.enable,
    metadata: {
      ...previousData.metadata,
      logs: logArray,
    },
  }

  const { data } = await client.getTable(resource).update(
    Object.assign({
      id: params.id,
      data: updatedData,
    })
  )

  return { data: data }
}
