import { fetchDataInitTable } from '../shared-script/fetchDataInitTable'
const { moco } = require('../../MbaasClient')

export async function isTransactionHistories(params, resource) {
  const { page, perPage } = params.pagination
  let { field, order } = params.sort

  let filterObject = moco.composeFilter()
  let includedTables = []

  filterObject = filterObject.eq('transaction_id', params.filter.transaction_id)

  if (filterObject !== '') filterObject = filterObject.toString()

  const [data, total] = await fetchDataInitTable(
    includedTables,
    resource,
    filterObject,
    order,
    field,
    page,
    perPage
  )

  return { data: data, total: total }
}
