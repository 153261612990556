import React, { useState } from 'react'
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  FileInput,
  FileField,
  useRefresh,
} from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import PublishIcon from '@material-ui/icons/Publish'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

export const UploadCSV = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseCLick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    create('CSVPricing', values, {
      onSuccess: () => {
        notify('ra.notification.created', 'info', { smart_count: 1 })
        setShowDialog(false)
        refresh()
      },
      onFailure: (error) => {
        notify(error.message, 'error')
      },
    })
  }

  return (
    <>
      <Button onClick={handleClick} label="Update Price (CSV)">
        <PublishIcon />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseCLick} aria-label="Upload CSV">
        <DialogTitle>Upload CSV File</DialogTitle>
        <FormWithRedirect
          resource="CSVPricing"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <FileInput source="files" label="Related files" accept=".csv">
                  <FileField source="src" title="title" />
                </FileInput>
              </DialogContent>
              <DialogActions>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                  label="upload"
                />
                <Button label="ra.action.cancel" onClick={handleCloseCLick} disabled={loading}>
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}
