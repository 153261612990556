const { client } = require('../../MbaasClient')

export const funcCreateReferralBenefits = async (resource, params) => {
  const userData = await client.user.get()
  const { data } = await client.getTable(resource).insert({
    ...params.data,
    user_id: userData.data.id,
    product_origin: 'IDN',
  })
  return { data: data }
}
