import gql from 'graphql-tag'
import moment from 'moment'
import { buildArgs } from '../shared-script/buildArgs'
const { client } = require('../../MbaasClient')

export async function isPromotions(params, resource) {
  console.log(74723423, { method: 'getList', resource: resource, params: params })

  // varibles declaration here
  const where = {}
  const filterArray = []

  // filter by date range
  if (params.filter.dateGte || params.filter.dateLte) {
    filterArray.push({
      created_at: {
        _gte: moment(params.filter.dateGte).startOf('day').toISOString(),
      },
    })
    filterArray.push({
      created_at: {
        _lte: moment(params.filter.dateLte).endOf('day').toISOString(),
      },
    })
  }

  if (filterArray.length > 0) {
    where._and = filterArray
  }

  const args = buildArgs({
    order: params.sort.order.toUpperCase(),
    size: params.pagination.perPage,
    pageNumber: params.pagination.page,
    sort: params.sort.field,
  })

  const gqlResult = await client.gql.query({
    query: gql`
      query getAllPromotions($where: PromotionsWhereInput) {
        allPromotionsList(where: $where, ${args}) {
          data {
            id
            created_at
            updated_at
            user_id
            code
            start
            end
            value
            type
            max_usage
            product_origin
            specific_customer
            specific_target
            max_usage_per_customer
            new_customer_only
            country
            specific_customer_name
            customer_id
            denomination
            product_code
            category
          }
          count
        }
      }
    `,
    variables: {
      where,
    },
  })

  console.log(8380172124, gqlResult)

  const dataMap = gqlResult?.allPromotionsList?.data?.map((data) => {
    return {
      ...data,
      denom_type: data.denomination === 'ALL' ? 'ALL' : 'OTHER',
      denom_value: data.denomination === 'ALL' ? '' : data.denomination.split(','),
    }
  })

  return {
    data: dataMap,
    total: gqlResult?.allPromotionsList?.count || 0,
  }
}
