const { client } = require('./../provider/MbaasClient')

const updatePricings = async (
  pricingId,
  {
    organization_id,
    product_id,
    method,
    value,
    poin,
    available,
    reference,
    auto_update,
    channel_code,
    currency,
    country,
    purchase_limit,
  }
) => {
  try {
    const { data } = await client.getTable('pricings').update({
      id: pricingId,
      data: {
        organization_id,
        product_id,
        method,
        value,
        poin,
        available,
        reference,
        auto_update,
        channel_code,
        currency,
        country,
        purchase_limit,
      },
    })
    return [null, data]
  } catch (error) {
    return [error, null]
  }
}

export default updatePricings
