import React from 'react'
import clsx from 'clsx'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import SaveIcon from '@material-ui/icons/Save'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { useRefresh } from 'react-admin'

import { RhfMuiTextfield, RhfMuiSelectBoolean } from './../../shared-components'
import { recordAtom, isDrawerOpenAtom } from './atom'
import { updateDenomPricingLookup } from './../../requests'
const { client } = require('./../../provider/MbaasClient')

const useStyles = makeStyles({
  list: {
    width: '40vw',
  },
})

export default function DrawerEdit() {
  const refresh = useRefresh()

  const classes = useStyles()
  const [record] = useAtom(recordAtom)
  const [isDrawerEditOpen, setIsDrawerEditOpen] = useAtom(isDrawerOpenAtom)

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      category: '',
      country: '',
      denom_pricing_lookup: '',
    },
  })

  React.useEffect(() => {
    const country = record.country || ''
    const category = record.category || ''
    const denom_pricing_lookup = record.denom_pricing_lookup || false

    reset({
      category: category.toUpperCase(),
      country: country.toUpperCase(),
      denom_pricing_lookup: denom_pricing_lookup,
    })
  }, [record, record.country, record.category, record.denom_pricing_lookup])

  // get all product_id depends on category and country
  const getProductId = async (category, country) => {
    const gqlResult = await client.gql.query({
      query: gql`
        query ($category: String, $country: String) {
          allProductsList(
            limit: 99999999
            where: { category: { _eq: $category }, country: { _eq: $country } }
          ) {
            data {
              id
            }
          }
        }
      `,
      variables: {
        category,
        country,
      },
    })

    const result = gqlResult.allProductsList.data

    return result
  }

  const onSubmit = async (data) => {
    const listProdId = await getProductId(data.category, data.country)

    const arrListProdId = listProdId.map(function (obj) {
      return obj.id
    })

    await updateDenomPricingLookup(arrListProdId, data.denom_pricing_lookup)
    setIsDrawerEditOpen(false)
    refresh()
  }

  const handleCloseDrawer = () => {
    !isSubmitting && setIsDrawerEditOpen(false)
  }

  return (
    <div>
      <Drawer anchor="right" open={isDrawerEditOpen} onClose={handleCloseDrawer}>
        <div className={clsx(classes.list)} style={{ padding: '16px' }} role="presentation">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              <RhfMuiTextfield
                fullWidth
                control={control}
                name="category"
                rules={{ required: 'Required' }}
                label="Category"
                disabled
              />
              <RhfMuiTextfield
                fullWidth
                control={control}
                name="country"
                rules={{ required: 'Required' }}
                label="Country"
                disabled
              />

              <RhfMuiSelectBoolean
                fullWidth
                control={control}
                name="denom_pricing_lookup"
                rules={{ required: 'Required' }}
                label="Denom Pricing Lookup"
                options={[
                  { id: 'true', name: 'Yes' },
                  { id: 'false', name: 'No' },
                ]}
                disabled={isSubmitting}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={isSubmitting ? <CircularProgress size="20px" /> : <SaveIcon />}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  )
}
