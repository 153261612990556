import moment from 'moment'
const { client } = require('../../MbaasClient')

export const funcCreateCouponSettings = async (resource, params) => {
  const userData = await client.user.get()
  const { data } = await client.getTable(resource).insert({
    ...params.data,
    end: moment(params.data.end).add(59, 'seconds'),
    user_id: userData.data.id,
    product_origin: 'IDN',
  })
  return { data: data }
}
