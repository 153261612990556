import { updateWebviewBackgroundHeader } from '../../../requests'
import { client } from '../../MbaasClient'

export const funcUpdateHeaderImage = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  const authUserId = (await client.user.get())?.data?.id
  const [, data] = await updateWebviewBackgroundHeader(
    params.id,
    {
      ...params.data,
      defaultImage: params.data.default,
      updated_by: [...params.data.updated_by, { timestamp: new Date(), actor: authUserId }],
    },
    params.previousData
  )
  return { data }
}
