import { isPromoDoublePoin } from './populate-getList-resource/promo-double-poin'
import { isPurchaseLimit } from './populate-getList-resource/purchase-limit'
import { isCoupons } from './populate-getList-resource/coupons'
import { isHeaderImage } from './populate-getList-resource/header-image'
import { isBonuses } from './populate-getList-resource/bonuses'
import { isKnowledgeBase } from './populate-getList-resource/knowledge-base'
import { isPromotions } from './populate-getList-resource/promotions'
import { isSurveys } from './populate-getList-resource/surveys'
import { isFlashsale } from './populate-getList-resource/flashsale'
import { isDenomPricings } from './populate-getList-resource/denom-pricings'
import { isFailedButtons } from './populate-getList-resource/failed-buttons'
import { isRedundancyPaymentGateway } from './populate-getList-resource/redundancy-payment-gateway'
import { isBlastSms } from './populate-getList-resource/blast-sms'
import { isReferralBenefits } from './populate-getList-resource/referral-benefits'
import { isDownloads } from './populate-getList-resource/downloads'
import { isPoinLedgers } from './populate-getList-resource/poin-ledgers'
import { isStorePoints } from './populate-getList-resource/store-points'
import { isMessengerMsgSeries } from './populate-getList-resource/messenger-msg-series'
import { isAdvertisements } from './populate-getList-resource/advertisements'
import { isDenomPricingSetup } from './populate-getList-resource/denom-pricing-setup'
import { isDepositLogs } from './populate-getList-resource/deposit-logs'
import { isPricings } from './populate-getList-resource/pricings'
import { isLedgers } from './populate-getList-resource/ledgers'
import { isCustomersListData } from './populate-getList-resource/customers-list-data'
import { isTransactionHistories } from './populate-getList-resource/transaction-histories'
import { isUsers } from './populate-getList-resource/users'
import { isTransactions } from './populate-getList-resource/transactions'
import { isCustomers } from './populate-getList-resource/customers'
import { isAnnouncements } from './populate-getList-resource/announcements'
import { isPoin } from './populate-getList-resource/poin'
import { isPoinAddManually } from './populate-getList-resource/poin-add-manually'
import { isProducts } from './populate-getList-resource/products'
import { isDefault } from './populate-getList-resource/default'
import { isWallets } from './populate-getList-resource/wallets'

const funcGetList = async (resource, params) => {
  // -- add new resource here, object key on the left is resource name.
  let resourceMapping = {
    products: () => isProducts(params, resource),
    customers: () => isCustomers(params, resource),
    transactions: () => isTransactions(params, resource),
    users: () => isUsers(params, resource),
    promo_double_poin: () => isPromoDoublePoin(params, resource),
    'purchase-limit': () => isPurchaseLimit(params, resource),
    coupons: () => isCoupons(params, resource),
    'header-image': () => isHeaderImage(params, resource),
    bonuses: () => isBonuses(params, resource),
    knowledge_base: () => isKnowledgeBase(params, resource),
    promotions: () => isPromotions(params, resource),
    surveys: () => isSurveys(params, resource),
    flashsale: () => isFlashsale(params, resource),
    denom_pricings: () => isDenomPricings(params, resource),
    failed_buttons: () => isFailedButtons(params, resource),
    redundancy_payment_gateway: () => isRedundancyPaymentGateway(params, resource),
    blast_sms: () => isBlastSms(params, resource),
    referral_benefits: () => isReferralBenefits(params, resource),
    downloads: () => isDownloads(params, resource),
    poin_ledgers: () => isPoinLedgers(params, resource),
    store_points: () => isStorePoints(params, resource),
    messenger_msg_series: () => isMessengerMsgSeries(params, resource),
    denom_pricings_setup: () => isDenomPricingSetup(params, resource),
    deposit_logs: () => isDepositLogs(params, resource),
    pricings: () => isPricings(params, resource),
    ledgers: () => isLedgers(params, resource),
    customers_list_data: () => isCustomersListData(params, resource),
    transaction_histories: () => isTransactionHistories(params, resource),
    advertisements: () => isAdvertisements(params, resource),
    popupads: () => isAdvertisements(params, resource),
    couponads: () => isAdvertisements(params, resource),
    runningText: () => isAdvertisements(params, resource),
    announcements: () => isAnnouncements(params, resource),
    poin: () => isPoin(params, resource),
    poin_add_manually: () => isPoinAddManually(params, resource),
    wallets: () => isWallets(params, resource),
    default: () => isDefault(params, resource),
  }

  const getResourceMapping = resourceMapping[resource] || resourceMapping?.default

  const res = await getResourceMapping()

  console.log(623495, { resource: resource, res: res })
  return { data: res?.data || [], total: res?.total || 0 }
}

export default funcGetList
