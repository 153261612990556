import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  EditButton,
  SearchInput,
  Filter,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin'
import ReactJson from 'react-json-view'

export default function ProductList(props) {
  return (
    <List
      {...props}
      filters={<ProductFilter />}
      actions={<ListActions />}
      // filterDefaultValues={{
      //   dateGte: moment().subtract(1, "month").calendar(),
      //   dateLte: moment().format("YYYY/MM/DD"),
      // }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField label="Category" source="category" />
        <FunctionField
          label="Product Code"
          render={(record) => (
            <div>
              <label>
                <ReactJson
                  src={record.product_codes}
                  name={false}
                  shouldCollapse={() => {}}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
              </label>
            </div>
          )}
        />
        {/* <TextField label="Category" source="category" /> */}
        {/* <TextField source="country" />
        <TextField source="product_code" /> */}
        <FunctionField
          label="Alter Number"
          source="country"
          render={(record) => {
            let value = record.failed_trx_button
            return (
              <label style={{ color: value.alter_number ? 'green' : 'red' }}>
                {value.alter_number ? 'Yes' : 'No'}
              </label>
            )
          }}
          sortable={false}
        />
        <FunctionField
          label="Alter Product"
          source="country"
          render={(record) => {
            let value = record.failed_trx_button
            return (
              <label style={{ color: value.alter_product ? 'green' : 'red' }}>
                {value.alter_product ? 'Yes' : 'No'}
              </label>
            )
          }}
          sortable={false}
        />
        <FunctionField
          label="Reprocess"
          source="country"
          render={(record) => {
            let value = record.failed_trx_button
            return (
              <label style={{ color: value.reprocess ? 'green' : 'red' }}>
                {value.reprocess ? 'Yes' : 'No'}
              </label>
            )
          }}
          sortable={false}
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const ProductFilter = (props) => {
  return (
    <Filter {...props}>
      <SearchInput Label="Category" placeholder="Category" source="category" />
    </Filter>
  )
}

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const {
    resource,
    displayedFilters,
    filterValues,
    // basePath,
    showFilter,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {/* <CreateButton basePath={basePath} /> */}
      {/* <ExportButton filter={filterValues} /> */}
    </TopToolbar>
  )
}
