const { client } = require('../../MbaasClient')

export async function isFailedButtons(params, resource) {
  console.log(6234958776, { method: 'getList', resource: resource, params: params })
  const { page, perPage } = params.pagination
  let { field, order } = params.sort
  const {
    data: { data, total },
  } = await client.invokeApi('get-failed-trx-button', {
    field: field,
    order: order,
    filter: {
      category: params.filter.category || '',
    },
    limit: perPage,
    offset: (page - 1) * perPage,
  })

  return {
    data: data.map((v) => ({
      ...v,
      category: v.id,
      id: v.id + JSON.stringify(v.failed_trx_button),
    })),
    total: total,
  }
}
