import reqDoublePoin from '@/src/requests/double-poin'

export const funcUpdatePromoDoublePoin = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  try {
    const { data } = await reqDoublePoin.updateOne(params.id, { ...params.data })
    return { data }
  } catch (e) {
    console.log(333444, e)
    throw e
  }
}
