import gql from 'graphql-tag'
import { client } from '@/src/provider/MbaasClient'
import moment from 'moment/moment'
export const getList = async ({
  page,
  limit,
  orderBy,
  filter,
}: {
  page: number
  limit: number
  orderBy: { [key: string]: 'ASC' | 'DESC' }
  filter: { [key: string]: string }
}) => {
  const { dateGte, dateLte } = filter
  const whereBuilder = {
    ...(dateGte &&
      dateLte && {
        created_at: {
          _gte: moment(dateGte).startOf('day').toISOString(),
          _lte: moment(dateLte).endOf('day').toISOString(),
        },
      }),
  }

  const res = await client.gql.query({
    query,
    variables: {
      offset: (page - 1) * limit,
      limit,
      orderBy,
      whereBuilder,
    },
  })

  const dataModify = res.response.data?.map((v: any) => {
    return {
      ...(v || {}),
      product_origin: v.product_origin.split(','),
      category: v.category.split(','),
      product_code: v.product_code.split(','),
    }
  })

  return { data: dataModify, count: res.response.count }
}

const query = gql`
  query (
    $offset: Int
    $limit: Int
    $orderBy: PromoFlashsalesOrderByInput
    $whereBuilder: PromoFlashsalesWhereInput
  ) {
    response: allPromoFlashsalesList(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      where: $whereBuilder
    ) {
      data {
        id
        created_at
        updated_at
        user_id
        start
        end
        value
        country
        product_origin
        denomination
        product_code
        category
        total_usage
        apply_max_usage_per_customer
        max_usage_per_customer
        specific_target
        new_customer_only
        specific_customer
        customer_id
        transactions_aggregate: transactions_flashsale_id_list_aggregate {
          count
        }
      }
      count
    }
  }
`
