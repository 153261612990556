import { client } from '../provider/MbaasClient'

const invokeApiPromoFlashsalesProducts = async ({
  product_origin, // ? string[]
  category, // ? string[]
  product_code, // ? string[] | ["ALL"]
  denomination, // ? number
  invoked, // string : "product_origin" | "category" | "product_code" | "denomination"
  //
}) => {
  try {
    const result = await client.invokeApi('promo-flashsales-products', {
      product_origin,
      category,
      product_code,
      denomination,
      invoked,
    })

    return [null, result]
  } catch (error) {
    return [error, {}]
  }
}

export default invokeApiPromoFlashsalesProducts
