import { linkToList } from './../../../constants'

const { client } = require('../../MbaasClient')

export const funcGetOneAdvertisements = async (resource, params) => {
  resource = 'advertisements'

  const { data } = await client.getTable('advertisements').lookup(params.id)

  if (data.ads_type === 'popup') {
    const { data } = await client.getTable('advertisements').lookup(params.id)

    const ifValueExist = (value) => {
      return linkToList.some((el) => {
        return el.id === value
      })
    }

    let ini_url

    if (!ifValueExist(data.description)) {
      ini_url = data.description
      data.description = 'custom_url'
    }

    let retrievedUserType
    if (data?.user_type) {
      retrievedUserType = data?.user_type
    }

    const user_type = retrievedUserType?.split(',')

    return { data: { ...data, ini_url, user_type: user_type || [] } }
  }

  if (data.ads_type === 'banner') {
    const { data } = await client.getTable('advertisements').lookup(params.id)

    let retrievedUserType
    if (data?.user_type) {
      retrievedUserType = data?.user_type
    }

    const user_type = retrievedUserType?.split(',')

    return { data: { ...data, user_type: user_type || [] } }
  }

  if (data.ads_type === 'running_text') {
    const { data } = await client.getTable('advertisements').lookup(params.id)

    let retrievedUserType
    if (data?.user_type) {
      retrievedUserType = data?.user_type
    }

    function convertTextObjectToArray(textObj) {
      const result = []
      for (const locale in textObj) {
        const dynamicKey = `runText_locale_${locale}`
        const obj = { [dynamicKey]: textObj[locale] }
        result.push(obj)
      }
      return result
    }

    const runningTextConfig = data.running_text_config
    const transformedTextArray = convertTextObjectToArray(runningTextConfig.text)

    function convertTextObject(textObj) {
      const result = {}
      for (const locale in textObj) {
        const dynamicKey = `runText_locale_${locale}`
        result[dynamicKey] = textObj[locale]
      }
      return result
    }

    const runningTextConfig2 = data.running_text_config
    const transformedText = convertTextObject(runningTextConfig2.text)

    const user_type = retrievedUserType?.split(',')

    return {
      data: {
        ...data,
        ...transformedText,
        runningTextLocale: transformedTextArray,
        user_type: user_type,
      },
    }
  }
}
