import { client } from '../../MbaasClient'

export const funcUpdateOrganizations = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  let updatedData = params.data
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  if (params.data['user_level_configuration']) {
    delete updatedData['user_level_configuration']
  }

  const { data } = await client.getTable(resource).update(
    Object.assign({
      id: params.id,
      data: updatedData,
    })
  )

  return { data: data }
}
