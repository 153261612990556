const linkToList = [
  { id: 'referral', name: 'Ajak Kawan' },
  { id: 'prepaid/IDN', name: 'Isi Pulsa Indonesia' },
  { id: 'mobiledata/IDN', name: 'Paket Data Indonesia' },
  { id: 'pln', name: 'Token PLN Indonesia' },
  { id: 'game/IDN', name: 'Game Voucher Indonesia' },
  { id: 'game/MMR', name: 'Game Voucher Myanmar' },
  { id: 'emoney', name: 'E-Money Indonesia' },
  { id: 'prepaid/MYS/digi', name: 'Digi Malaysia' },
  { id: 'prepaid/MYS/hotlink', name: 'Hotlink Malaysia' },
  { id: 'prepaid/MYS/celcom', name: 'Celcom Malaysia' },
  { id: 'prepaid/MYS/as2in1mobile', name: 'As2in1 Mobile Malaysia' },
  { id: 'prepaid/MYS/umobile', name: 'U Mobile Malaysia' },
  { id: 'prepaid/MYS/altel', name: 'Altel Malaysia' },
  { id: 'prepaid/MYS/merchantrade', name: 'Merchantrade Malaysia' },
  { id: 'prepaid/MYS/tunetalk', name: 'Tune Talk Malaysia' },
  { id: 'prepaid/MYS/xox', name: 'XOX Malaysia' },
  { id: 'prepaid/MYS/yes', name: 'Yes Malaysia' },
  { id: 'purchase/BGD', name: 'Bangladesh - Internasional' },
  { id: 'prepaid/MMR', name: 'Myanmar Topup - Internasional' },
  { id: 'mobiledata/MMR', name: 'Myanmar Mobile Data - Internasional' },
  { id: 'prepaid/NPL', name: 'Nepal - Internasional' },
  { id: 'prepaid/PHL', name: 'Philippines - Internasional' },
  { id: 'purchase/VNM', name: 'Vietnam - Internasional' },
  { id: 'purchase/IND', name: 'India - Internasional' },
  { id: 'purchase/PAK', name: 'Pakistan - Internasional' },
  { id: 'prepaid/CHN', name: 'China - Internasional' },
  { id: 'profile', name: 'User Profile Page' },
  { id: '#', name: 'NO PAGE' },
  { id: 'custom_url', name: 'CUSTOM URL' },
]

export default linkToList
