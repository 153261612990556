import React from 'react'
import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  TextField,
  useQueryWithStore,
  Loading,
  Error,
} from 'react-admin'

export default function PageList(props) {
  const { loaded, error } = useQueryWithStore({
    type: 'getOne',
    resource: 'dashboard',
    payload: {},
  })
  // fallback rendering
  if (!loaded) {
    return <Loading />
  }
  if (error) {
    console.warn(error)
    return <Error></Error>
  }
  const newProps = Object.assign({ ...props, filter: 'transactions' })
  return (
    <Show {...newProps}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="code" />
        <ReferenceManyField reference="transactions" target="promotion_code" addLabel={false}>
          <Datagrid>
            <TextField source="id" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
}
