import React from 'react'
import {
  Toolbar,
  // DeleteButton,
  Button,
  useNotify,
  useRedirect,
  useDataProvider,
  useAuthProvider,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  // Button as MuiButton
} from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'
import { useFormState } from 'react-final-form'

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const SaveButton = ({ dataForm, option }) => {
  const formState = useFormState()
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const authP = useAuthProvider()

  const [isLoading, setIsLoading] = React.useState(false)

  let startIcon = <SaveIcon />
  if (isLoading) {
    startIcon = <CircularProgress size={20} />
  }

  const saveData = async () => {
    setIsLoading(true)
    delete dataForm.type
    await dataProvider
      .create('surveys', {
        data: {
          ...dataForm,
          user_id: authP.id(),
          config: option,
        },
      })
      .then((response) => {
        redirect('/surveys')
        notify('surveys created')
      })
      .catch((error) => {
        notify(`surveys create error: ${error.message}`, 'warning')
      })
    setIsLoading(false)
  }

  return (
    <Button
      startIcon={startIcon}
      label="Save"
      variant="contained"
      disabled={!formState.valid}
      onClick={saveData}
    />
  )
}

export const CustomToolbar = ({ dataForm, option, onCancel, ...props }) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton dataForm={dataForm} option={option} />
    </Toolbar>
  )
}
