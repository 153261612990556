const { client } = require('../../MbaasClient')

export const funcCreateUsers = async (resource, params) => {
  const {
    email,
    facebook,
    twitter,
    google,
    enable,
    name,
    password,
    deactivated_at,
    level_id,
    organization_id,
    msisdn,
    verified,
    ref_number,
    kyc_status,
    kyc_reason,
    kyc_data,
    fcm_tokens,
    roles,
  } = params.data
  const { data } = await client.invokeApi('create-user', {
    social_ids: Object.assign({
      facebook: facebook,
      twitter: twitter,
      google: google,
    }),
    email: email,
    enable: enable,
    name: name,
    password: password,
    deactivated_at: deactivated_at,
    level_id: level_id,
    organization_id: organization_id,
    msisdn: msisdn,
    verified: verified,
    ref_number: ref_number,
    kyc_status: kyc_status,
    kyc_reason: kyc_reason,
    kyc_data: kyc_data,
    fcm_tokens: fcm_tokens,
    roles: roles,
  })
  return { data: data }
}
