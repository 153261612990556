const { client } = require('../../MbaasClient')

export const funcCreatePromoDoublePoin = async (resource, params) => {
  const userData = await client.user.get()
  try {
    const { data } = await client.getTable('promo_double_poin').insert({
      ...params.data,
      items: {
        ...params.data.items,
        channel: params.data.items.channel.map((ori) => ori.id),
        product_origin: params.data.items.product_origin.map((ori) => ori.id),
        category: params.data.items.category.map((cat) => cat.id),
        product_code: params.data.items.product_code.map((prod) => prod.id),
        denomination: params.data.items.denomination.map((denom) => denom.id),
      },
      configs: {
        ...params.data.configs,
        once_per_target: params.data.configs.once_per_target
          ? params.data.configs.once_per_target
          : false,
        new_customer_only: params.data.configs.new_customer_only
          ? params.data.configs.new_customer_only
          : false,
        specific_customer: params.data.configs.specific_customer
          ? params.data.configs.specific_customer
          : {
              status: false,
            },
        max_usage_per_customer: Number(params.data.configs.max_usage_per_customer),
        total_usage: Number(params.data.configs.total_usage),
      },
      user_id: userData.data.id,
    })
    return { data: data }
  } catch (e) {
    throw e
  }
}
