import { client } from '../../MbaasClient'

export const funcUpdateBonuses = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  delete params.data.author_id_data
  delete params.data.configs.specific_customer.name
  delete params.data.user_id
  delete params.data.transactions_bonus_id_list
  delete params.data.items_main
  let updatedData = {
    ...params.data,
    items: {
      ...params.data.items,
      // @ts-ignore
      product_origin: params.data.items.product_origin.map((ori) => (ori.id ? ori.id : ori)),
      // @ts-ignore
      category: params.data.items.category.map((cat) => (cat.id ? cat.id : cat)),
      // @ts-ignore
      denomination: params.data.items.denomination.map((denom) => (denom.id ? denom.id : denom)),
      // @ts-ignore
      product_code: params.data.items.product_code.map((prod) => (prod.id ? prod.id : prod)),
      // @ts-ignore
      channel: params.data.items.channel.map((cha) => (cha.id ? cha.id : cha)),
    },
    configs: {
      ...params.data.configs,
      once_per_target: params.data.configs.once_per_target
        ? params.data.configs.once_per_target
        : false,
      new_customer_only: params.data.configs.new_customer_only
        ? params.data.configs.new_customer_only
        : false,
      specific_customer: params.data.configs.specific_customer
        ? params.data.configs.specific_customer
        : {
            status: false,
          },
      max_usage_per_customer: Number(params.data.configs.max_usage_per_customer),
      total_usage: Number(params.data.configs.total_usage),
    },
  }

  const { data } = await client.getTable(resource).update(
    Object.assign({
      id: params.id,
      data: updatedData,
    })
  )

  return { data: data }
}
