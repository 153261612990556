import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

import { useController } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  },
}))

export default function MuiSelectBoolean({
  control,
  name,
  rules, // react-hook-form prop

  variant = 'filled',
  size = 'small',
  label = '',
  options = [],
  fullWidth,
  ...muiProps // material-ui prop
}) {
  const classes = useStyles()

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: '',
  })

  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      error={error}
      className={classes.formControl}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputRef={ref}
        displayEmpty
        {...muiProps}
      >
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}
