import * as React from 'react'
import { useState } from 'react'
import { useLogin, useNotify, Notification } from 'react-admin'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [token, setToken] = useState('')
  const [isError, setIsError] = useState({ email: false, password: false })
  const login = useLogin()
  const notify = useNotify()

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    if (!data.get('email')) {
      setIsError((old) => ({ ...old, email: true }))
    }
    if (!data.get('password')) {
      setIsError((old) => ({ ...old, password: true }))
    }
    login({ username: data.get('email'), password: data.get('password') }).catch(() =>
      notify('Invalid email or password')
    )
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage:
          'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '300px',
          background: 'white',
          padding: '8px',
          borderRadius: '5px',
          marginTop: '-150px',
        }}
      >
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar style={{ margin: 'auto' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              error={isError.email}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => {
                isError.email && setIsError((old) => ({ ...old, email: false }))
                setEmail(e.target.value)
              }}
              helperText={isError.email && 'Required'}
            />
            <TextField
              error={isError.password}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => {
                isError.password && setIsError((old) => ({ ...old, password: false }))
                setPassword(e.target.value)
              }}
              helperText={isError.password && 'Required'}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: '8px' }}
              disabled={false}
              // disabled={!token}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </div>
      {/* <GoogleReCaptchaProvider reCaptchaKey="6LcrSUkeAAAAAFV0UM2LsYGfFKnHGH0mjjcE1CW2"> */}
      <GoogleReCaptchaProvider reCaptchaKey="6LfOlWEeAAAAAOTf7ij8tmirj73CE0CtURabwH1g">
        <GoogleReCaptcha
          onVerify={(v) => {
            if (!token) setToken(v)
          }}
        />
        {/* <YourReCaptchaComponent /> */}
      </GoogleReCaptchaProvider>
      <Notification />
    </div>
  )
}
