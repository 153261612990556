import React from 'react'
import {
  Create,
  SimpleForm,
  required,
  useNotify,
  useRedirect,
  BooleanInput,
  DateInput,
  ImageInput,
  ImageField,
} from 'react-admin'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-textmate'

const PageCreate = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/header-image')
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <ImageInput
          source="url"
          label="Image (recommended for using 7:3 aspect ratio)"
          accept="image/*"
          validate={[required()]}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
          <DateInput source="start" label="Begin" validate={[required()]} fullWidth />
          <DateInput source="end" validate={[required()]} fullWidth />
        </div>
        <BooleanInput label="Default Image" source="default" />
      </SimpleForm>
    </Create>
  )
}

export default PageCreate
