import React, { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  FunctionField,
  Filter,
  CreateButton,
  TopToolbar,
  useListContext,
  BooleanInput,
  SimpleForm,
  Edit,
  TextInput,
  required,
  NumberInput,
  Create,
  useNotify,
  useRedirect,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin'
import { DateRange } from './DateRange'
import moment from 'moment'
import { push } from 'react-router-redux'
import { withStyles } from '@material-ui/core'
import { flowRight as compose } from 'lodash'
import { connect } from 'react-redux'
import styles from 'ra-input-rich-text/lib/styles'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomToolbar } from './CustomToolbar'
import dataProvider from '../provider/DataProvider'
import ExportButton from '../shared-components/export-button'

const BlacklistedTargetsFilter = (props) => (
  <Filter {...props}>
    <DateRange alwaysOn />
  </Filter>
)

const BlacklistedTargetsActions = ({ basePath, resource }) => {
  const { filterValues } = useListContext()
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <ExportButton resource={resource} filter={filterValues} resourceType="BLACKLISTED-TARGET" />
    </TopToolbar>
  )
}

const BlacklistedTargetsList = (props) => {
  return (
    <Fragment>
      <List
        title="Blacklisted Targets"
        {...props}
        filters={<BlacklistedTargetsFilter />}
        actions={<BlacklistedTargetsActions />}
        filterDefaultValues={{
          dateGte: moment().subtract(1, 'month').calendar(),
          dateLte: moment().format('YYYY/MM/DD'),
        }}
      >
        <Datagrid>
          <TextField source="target" label="MSISDN" />
          <FunctionField
            source="status"
            label="Status"
            render={(record) => {
              if (record?.status === true) {
                return 'BLOCKED'
              }
              return 'WHITELISTED'
            }}
          />
          <FunctionField
            source="duration"
            render={(record) => {
              const start = moment(record?.start)
              const end = moment(record?.end)
              return `${end.diff(start, 'months')} month(s)`
            }}
          />
          <DateField
            source="start"
            label="Blocked From"
            showTime
            locales="my-MY"
            options={{
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }}
          />
          <DateField
            source="end"
            label="Blocked Until"
            showTime
            locales="my-MY"
            options={{
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }}
          />

          <EditButton />
        </Datagrid>
      </List>
    </Fragment>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
}))

export const BlacklistedTargetsCreate = ({ ...props }) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/blacklisted_targets')
  }

  const transformOnCreate = async (data) => {
    const trx = await dataProvider.getOne('transactions', {
      id: data?.target,
    })
    return {
      ...data,
      target: trx?.data?.target,
    }
  }

  return (
    <Create title=" &gt; Add" {...props} onSuccess={onSuccess} transform={transformOnCreate}>
      <SimpleForm>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ReferenceInput
                source="target"
                reference="transactions"
                className={classes.input}
                label="Target Number"
                enableGetChoices={({ q }) => q?.length >= 4}
              >
                <AutocompleteInput
                  fullWidth
                  source="target"
                  optionText="target"
                  suggestionLimit={10}
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                fullWidth
                className={classes.input}
                source="duration"
                label="Blacklist Duration (in month)"
                validate={[required()]}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}

const EditTitle = () => {
  return <span> &gt; Edit</span>
}

export const BlacklistedTargetsEdit = (props) => {
  const [editedDuration, setEditedDuration] = React.useState(0)

  React.useEffect(() => {
    ;(async () => {
      const record = await dataProvider.getOne(props?.resource, { id: props?.id })
      const { start, end } = record?.data
      setEditedDuration(moment(end).diff(moment(start), 'months'))
    })()
  }, [])

  const classes = useStyles()

  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput
                fullWidth
                className={classes.input}
                source="target"
                label="Target Number"
                validate={required()}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                fullWidth
                className={classes.input}
                source="duration"
                label="Blacklist Duration (in month)"
                value={editedDuration}
                onChange={(event) => {
                  setEditedDuration(event?.target?.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <BooleanInput
                fullWidth
                className={classes.input}
                source="status"
                label="Blacklist Status"
                validate={[required()]}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

export default compose(connect(undefined, { push }), withStyles(styles))(BlacklistedTargetsList)
