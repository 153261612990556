import gql from 'graphql-tag'
import { client } from '@/src/provider/MbaasClient'

export const getOne = async (id: any) => {
  const res = await client.gql.query({ query, variables: { id } })
  return {
    ...(res?.response || {}),
    product_origin: res?.response?.product_origin.split(','),
    category: res?.response?.category.split(','),
    product_code: res?.response?.product_code.split(','),
  }
}

const query = gql`
  query ($id: UUID!) {
    response: getPromoFlashsalesById(id: $id) {
      id
      created_at
      updated_at
      user_id
      start
      end
      value
      country
      product_origin
      denomination
      product_code
      category
      total_usage
      apply_max_usage_per_customer
      max_usage_per_customer
      specific_target
      new_customer_only
      specific_customer
      customer_id
      transactions_aggregate: transactions_flashsale_id_list_aggregate {
        count
      }
    }
  }
`
