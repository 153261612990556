import React from 'react'
import {
  SimpleForm,
  TextInput,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  required,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomToolbar } from '../CustomToolbar'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  test: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: 'auto',
    },
  },
}))

const EditTitle = () => {
  return <span>Blast SMS &gt; Edit</span>
}

export default function PageEdit(props) {
  const classes = useStyles()

  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                className={classes.input}
                source="content.title"
                label="title"
                autoFocus={true}
                validate={required()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                className={classes.input}
                source="content.content"
                label="Content"
                validate={required()}
              />
            </Grid>
            <Grid item xs={6}>
              <BooleanInput label="Is Sent" source="is_sent" />
            </Grid>
            <Grid item xs={6}>
              <ArrayInput source="target">
                <SimpleFormIterator>
                  <TextInput source="target" />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}
