import React from 'react'
import { Toolbar, DeleteButton, Button, useNotify, useRedirect, useDataProvider } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const SaveButton = ({ dataForm }) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()

  const [isLoading, setIsLoading] = React.useState(false)

  let startIcon = <SaveIcon />
  if (isLoading) {
    startIcon = <CircularProgress size={20} />
  }

  const saveData = async () => {
    setIsLoading(true)
    delete dataForm.type
    await dataProvider
      .update('couponads', {
        id: dataForm.id,
        data: { ...dataForm },
      })
      .then((response) => {
        redirect('/couponads')
        notify('Coupon Ads edited')
      })
      .catch((error) => {
        notify(`Coupon Ads edit error: ${error.message}`, 'warning')
      })
    setIsLoading(false)
  }

  return (
    <Button
      disabled={isLoading}
      startIcon={startIcon}
      label="Save"
      variant="contained"
      onClick={saveData}
    />
  )
}

export const CustomToolbar = (props) => {
  const classes = useStyles()
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton dataForm={props.dataForm} />
      <DeleteButton undoable={false} />
    </Toolbar>
  )
}
