import React from 'react'
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  Create,
  useNotify,
  useRedirect,
  FormDataConsumer,
} from 'react-admin'
import { CustomToolbar } from './CustomToolbarCreate.js'
import { view } from '@risingstack/react-easy-state'
import { currencyList } from '../../constants'
import { RaNumberFormatInput } from './../../shared-components'

export const FlashsaleCreate = view(({ ...props }) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const [dataForm, setDataForm] = React.useState([])

  const onCancel = () => {
    redirect('/denom_pricings')
  }

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/denom_pricings')
  }

  return (
    <Create title="Create denom_pricings" {...props} onSuccess={onSuccess}>
      <SimpleForm toolbar={<CustomToolbar onCancel={onCancel} dataForm={dataForm} />}>
        <SelectInput
          label="Currency From"
          source="currency_from"
          fullWidth
          validate={required()}
          choices={currencyList}
        />

        <SelectInput
          label="Currency To"
          source="currency_to"
          fullWidth
          validate={required()}
          choices={currencyList}
        />

        <FormDataConsumer style={{ width: '100%' }}>
          {({ formData, ...rest }) => {
            setDataForm(formData)
          }}
        </FormDataConsumer>

        <RaNumberFormatInput
          label="Denom"
          source="denom"
          placeholder=""
          fullWidth
          style={{ marginBottom: '27px' }}
        />

        <TextInput label="RRP" source="rrp" placeholder="" fullWidth />
      </SimpleForm>
    </Create>
  )
})
