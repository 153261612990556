const { client } = require('../../MbaasClient')

export const funcCreateSurveys = async (resource, params) => {
  const userData = await client.user.get()
  const isProdOriginString = typeof params.data.product_origin[0] === 'string'
  const isCategoryString = typeof params.data.category[0] === 'string'
  const isProdCodeString = typeof params.data.product_code[0] === 'string'
  const { data } = await client.getTable('surveys').insert({
    ...params.data,
    user_id: userData.data.id,
    product_origin: isProdOriginString
      ? params.data.product_origin.toString()
      : params.data.product_origin.map((v) => v.id).toString(),
    category: isCategoryString
      ? params.data.category.toString()
      : params.data.category.map((v) => v.id).toString(),
    product_code: isProdCodeString
      ? params.data.product_code.toString()
      : params.data.product_code.map((v) => v.id).toString(),
  })
  return { data: data }
}
