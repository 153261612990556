import { bucket, client } from '@/src/provider/MbaasClient'

export type TPrevDataDeleteAdvertisement = {
  id: string
  filename?: string
}
export const deleteAdvertisement: any = async (_: any, params: any) => {
  await client.storage.removeFile({
    filePath: params.previousData?.filename || '',
    bucket: bucket.advertisements,
  })
  return { data: params }
}
