import gql from 'graphql-tag'
import { client } from '../provider/MbaasClient'

/* MAIN FUNCTION */
const fetchOnePricings = async (id) => {
  try {
    const where = `{
        ${id ? `id: { _eq: "${id}" }` : ''}
      }`

    const gqlResult = await client.gql.query({
      query: gql`
        {
          allPricingsList(
            where: ${where}
          ) {
            data {
              id
              product_id_data {
                id
                name
                denomination
              }
              organization_id_data {
                id
                name
              }
              channel_code
              value
              reference
              available
              poin
              currency
              country
              method
              auto_update
              purchase_limit
            }
          }
        }
      `,
    })

    const pricing = gqlResult.allPricingsList?.data[0]
    const mappedRows = {
      ...pricing,
      period_purchase_limit: pricing.purchase_limit.period.toLowerCase(),
      limit_type_purchase_limit:
        pricing.purchase_limit.limit.toLowerCase() === 'unlimited' ? 'unlimited' : 'other',
      limit_value_purchase_limit:
        pricing.purchase_limit.limit.toLowerCase() === 'unlimited'
          ? 'unlimited'
          : pricing.purchase_limit.limit,
    }

    return [null, { rows: mappedRows || {} }]
  } catch (error) {
    return [error, { rows: {} }]
  }
}

export default fetchOnePricings
