import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  ReferenceInput,
  TextInput,
  EditButton,
  FunctionField,
  AutocompleteInput,
  Filter,
  BooleanField,
  useListContext,
  TopToolbar,
  CreateButton,
  Button,
  sanitizeListRestProps,
  useAuthProvider,
  useMutation,
  ExportButton,
  downloadCSV,
} from 'react-admin'
import ReactJson from 'react-json-view'
import 'ace-builds/src-noconflict/mode-json'
import Numeral from 'numeral'
import 'ace-builds/src-noconflict/theme-textmate'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useHistory } from 'react-router-dom'
import jsonExport from 'jsonexport/dist'

import { UploadCSV } from '../UploadCSV'
import { countryList, currencyList } from '../../constants'

const PageList = (props) => (
  <List {...props} filters={<PricingsFilter />} actions={<ListActions />}>
    <Datagrid>
      <TextField source="product_id_data.name" label="Product" />
      <TextField source="organization_id_data.name" label="Organizations" />
      <TextField source="channel_code" label="Channel" />
      <FunctionField
        sortable={true}
        label="Denom"
        source="denom"
        render={(record) =>
          record.product_id_data && Numeral(record.product_id_data.denomination).format('0,0')
        }
      />
      <TextField source="value" label="Sell Price" />
      <TextField source="reference" label="RRP" />
      <BooleanField source="available" label="Available " />
      <FunctionField
        sortable={true}
        label="Poin"
        render={(record) => (
          <div>
            <label>
              <ReactJson
                src={record.poin}
                shouldCollapse={() => {}}
                name={false}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            </label>
          </div>
        )}
      />
      <TextField source="currency" label="Currency" />
      <TextField source="country" label="Country" />
      <FunctionField
        sortable={true}
        label="Period purchase limit"
        render={(record) => record.purchase_limit?.period?.toUpperCase()}
      />
      <FunctionField
        sortable={true}
        label="Limit purchase limit"
        render={(record) => record.purchase_limit?.limit?.toUpperCase()}
      />
      <EditButton />
    </Datagrid>
  </List>
)

export default PageList

/* OTHER COMPONENT */
const PricingsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Product" source="product_name" />
    <ReferenceInput source="organization_id" reference="organizations">
      <AutocompleteInput source="name" alwaysOn />
    </ReferenceInput>
    <TextInput label="Denomination" source="denom" />
    <SelectInput source="available" optionText="id" choices={[{ id: 'true' }, { id: 'false' }]} />
    <AutocompleteInput source="currency" choices={currencyList} />
    <AutocompleteInput source="country" choices={countryList} />
  </Filter>
)

const ListActions = (props) => {
  const { className, filters, maxResults, record, data, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <DownloadButton filter={filterValues} />
      <UploadCSV />
      <ExportButton label="Download Master (Update)" maxResults={10} exporter={exporter} />
    </TopToolbar>
  )
}

const DownloadButton = (props) => {
  const authP = useAuthProvider()
  const history = useHistory()
  const { product_id, available, organization_id, denom } = props.filter
  const [exportData, { loading, loaded, error }] = useMutation({
    type: 'create',
    resource: 'downloads',
    payload: {
      data: {
        resource: 'PRICING',
        user_id: authP.id(),
        data: {
          product: product_id,
          available,
          organization: organization_id,
          denom,
        },
      },
    },
  })
  if (loaded && !error) {
    //
    history.push({
      pathname: '/downloads',
      search: encodeURI(`?filter={"resource":"PRICING"}`),
    })
  }
  return (
    <Button label="Export" onClick={exportData} disabled={loading}>
      <GetAppIcon />
    </Button>
  )
}

const exporter = async (records) => {
  const mappedData = records.map((record) => {
    return Object.assign({
      id: record.id,
      name: record.products.name,
      method: record.method,
      value: record.value,
      available: record.available,
      reference: record.reference,
      currency: record.currency,
      country: record.country,
    })
  })

  jsonExport(
    mappedData,
    {
      headers: ['id', 'name', 'method', 'value', 'available', 'reference', 'currency', 'country'],
    },
    (err, csv) => {
      downloadCSV(csv, 'pricings')
    }
  )
}
