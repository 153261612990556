import React from 'react'
import { Toolbar, DeleteButton, Button, useNotify, useRedirect, useDataProvider } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Button as MuiButton } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const SaveButton = ({ dataForm }) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()

  const [isLoading, setIsLoading] = React.useState(false)

  let startIcon = <SaveIcon />
  if (isLoading) {
    startIcon = <CircularProgress size={20} />
  }

  const saveData = async () => {
    setIsLoading(true)
    delete dataForm.type
    await dataProvider
      .update('denom_pricings', {
        id: dataForm.id,
        data: { ...dataForm },
      })
      .then((response) => {
        redirect('/denom_pricings')
        notify('denom_pricings edited')
      })
      .catch((error) => {
        notify(`denom_pricings edit error: ${error.message}`, 'warning')
      })
    setIsLoading(false)
  }

  return (
    <Button
      startIcon={startIcon}
      label="Save"
      variant="contained"
      disabled={
        !dataForm.currency_from || !dataForm.currency_to || !dataForm.denom || !dataForm.rrp
      }
      onClick={() => {
        saveData()
      }}
    />
  )
}

export const CustomToolbar = ({ dataForm, onCancel, ...props }) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton dataForm={dataForm} />

      <DeleteButton style={{ position: 'absolute', left: '50%' }} undoable={false} />
      <MuiButton onClick={onCancel}>cancel</MuiButton>
    </Toolbar>
  )
}
