const { client } = require('../../MbaasClient')

export async function isDenomPricingSetup(params, resource) {
  const { page, perPage } = params.pagination
  const { data } = await client.invokeApi('promo-flashsales-products', {
    category: [params?.filter?.category || 'ALL'],
    product_origin: [params?.filter?.product_origin || 'ALL'],
    order: 'DESC',
    field: 'category',
    limit: perPage,
    offset: (page - 1) * perPage,
    invoked: 'country, category, denom_pricing_lookup',
  })

  const dataMap = data?.data?.map((data) => {
    return {
      ...data,
      id: data.category + data.country + JSON.stringify(data.denom_pricing_lookup),
      category: data.category,
      country: data.country,
      denom_pricing_lookup: data.denom_pricing_lookup,
    }
  })

  return { data: dataMap, total: data?.total || 0 }
}
