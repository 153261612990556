import { useEffect, useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import { useInput } from 'react-admin'
import dataProvider from '../../provider/DataProvider'

export default function CheckboxLabels({ pageProps, ...props }) {
  const [data, setData] = useState()
  const [selectedOptions, setSelectedOptions] = useState([])

  const { input } = useInput({ source: props.source })

  const getOneData = async () => {
    const data = await dataProvider.getOne('advertisements', {
      id: props.id,
    })

    return data
  }

  console.log(3792184, data)

  const checkboxes = [
    { id: 'GUEST', label: 'GUEST' },
    { id: 'UNPAYING', label: 'NEW USER' },
    { id: 'PAYING', label: 'PAYING' },
  ]

  const handleChange = (event) => {
    const optionId = event.target.value
    const updatedOptions = event.target.checked
      ? [...selectedOptions, optionId]
      : selectedOptions.filter((id) => id !== optionId)

    setSelectedOptions(updatedOptions)

    // Update the input value whenever the selected options change
    input.onChange(updatedOptions)
  }

  useEffect(() => {
    if (data?.user_type) setSelectedOptions([...selectedOptions, ...data?.user_type])
  }, [data?.user_type])

  useEffect(() => {
    ;(async () => {
      const res = await getOneData()
      setData(res?.data)
    })()
  }, [props.id])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '17px',
      }}
    >
      {checkboxes.map((checkbox, index) => (
        <FormControlLabel
          key={checkbox.id}
          control={
            <Checkbox
              id={checkbox.id}
              value={checkbox.id}
              checked={selectedOptions.includes(checkbox.id)}
              onChange={handleChange}
            />
          }
          label={
            <InputLabel
              htmlFor={checkbox.id}
              variant="standard"
              style={{ color: 'black', width: index === 0 ? '-1px' : '95px' }}
            >
              {checkbox.label}
            </InputLabel>
          }
        />
      ))}
    </div>
  )
}
