const { client } = require('../../MbaasClient')

export const funcGetOneSurveys = async (resource, params) => {
  const { data } = await client.getTable('surveys').lookup(params.id)
  return {
    data: {
      ...data,
      product_origin: data.product_origin ? data.product_origin?.split(',') : [],
      category: data.category ? data.category?.split(',') : [],
      product_code: data.product_code ? data.product_code?.split(',') : [],
    },
  }
}
