// @ts-nocheck
import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TopToolbar,
  CreateButton,
  Filter,
  Record,
  FunctionField,
  Identifier,
} from 'react-admin'
import { DateRange } from '@/src/component/DateRange'
import moment from 'moment/moment'
import Numeral from 'numeral'
import { Button as MuiButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

const CustomFilter = (props: any) => (
  <Filter {...props}>
    {/* @ts-ignore*/}
    <DateRange alwaysOn />
  </Filter>
)

const CustomActions = ({ basePath }: any) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
)

export const BonusesList = (props: any) => {
  return (
    <List
      {...props}
      filters={<CustomFilter />}
      actions={<CustomActions />}
      filterDefaultValues={{
        dateGte: moment().subtract(1, 'month').calendar(),
        dateLte: moment().format('YYYY/MM/DD'),
      }}
      bulkActionButtons={false}
    >
      <Datagrid key={((id: Identifier) => id) as unknown as React.Key}>
        <DateField
          source="created_at"
          label="Date Added"
          showTime
          locales="en-MY"
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }}
        />
        <TextField source="country" label="Country" />
        <FunctionField
          sortable={false}
          label="Channel"
          render={(record: Record | undefined) => {
            return record!.items?.channel.map((cha: any) => cha.id).join(', ')
          }}
        />
        <FunctionField
          sortable={false}
          label="Main"
          render={(record: Record | undefined) => {
            return (
              <>
                <strong>Product Origin:</strong> {record!.items_main?.product_origin.join(', ')}
                <br />
                <strong>Category:</strong> {record!.items_main?.category.join(', ')}
                <br />
                <strong>Product Code:</strong> {record!.items_main?.product_code.join(', ')}
                <br />
                <strong>Denomination:</strong>&nbsp;
                {record!.items_main?.denomination
                  .map((x: string) => Numeral(x).format('0,0'))
                  .join('; ')}
                <br />
              </>
            )
          }}
        />
        <FunctionField
          sortable={false}
          label="Bonus"
          render={(record: Record | undefined) => {
            return (
              <>
                <strong>Product Origin:</strong> {record!.items.bonus.product_origin}
                <br />
                <strong>Category:</strong> {record!.items.bonus.category}
                <br />
                <strong>Product Code:</strong> {record!.items.bonus.product_code}
                <br />
                <strong>Denomination:</strong>&nbsp;
                {Numeral(record!.items.bonus.denomination).format('0,0')}
                <br />
              </>
            )
          }}
        />
        <FunctionField
          sortable={false}
          label="Configuration"
          render={(record: Record | undefined) => {
            return (
              <>
                {record!.configs.once_per_target && (
                  <>
                    <strong>Limit:</strong>
                    <br />
                    1x / Target
                    <br />
                  </>
                )}
                {record!.configs.new_customer_only && (
                  <>
                    <strong>For New Cust. Only</strong>
                    <br />
                  </>
                )}
                {record!.configs.specific_customer.status && (
                  <>
                    <strong>Specific for: </strong>
                    <br />
                    {record!.configs.specific_customer.name}
                    <br />
                  </>
                )}
                {record!.configs.max_usage_per_customer > 0 && (
                  <>
                    <strong>Max. Usage / Cust.: </strong>
                    <br />
                    {Numeral(record!.configs.max_usage_per_customer).format('0,0')}
                    <br />
                  </>
                )}
                <strong>Max. Total Usage: </strong>
                <br />
                {Numeral(record!.configs.total_usage).format('0,0')}
              </>
            )
          }}
        />
        <FunctionField
          label="Validity Period"
          render={() => {
            return (
              <>
                <strong>From:</strong> <DateField source="start" label="myroot.valid_from" />
                <br />
                <strong>Until:</strong> <DateField source="end" label="Valid Until" />
              </>
            )
          }}
        />
        <FunctionField
          label="Status"
          render={(record: Record | undefined) => {
            const start = moment(record!.start).toISOString()
            const end = moment(record!.end).toISOString()
            let status
            if (moment(moment().toISOString()).isBetween(start, end)) {
              status = 'ACTIVE'
            } else if (moment(moment().toISOString()).isBefore(end)) {
              status = 'INACTIVE'
            } else status = 'EXPIRED'
            return status
          }}
        />
        <FunctionField
          render={(record: Record | undefined) => {
            return (
              <MuiButton
                startIcon={<EditIcon />}
                color="primary"
                size="small"
                href={`#/bonuses/${record?.id}`}
              >
                Edit
              </MuiButton>
            )
          }}
        />
      </Datagrid>
    </List>
  )
}
