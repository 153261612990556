import React, { useState, useEffect } from 'react'
import {
  SimpleForm,
  SelectInput,
  NumberInput,
  ReferenceInput,
  TextInput,
  Edit,
  useInput,
  required,
  BooleanInput,
  Button,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-textmate'
import { CustomToolbar } from '../CustomToolbar'
import productOrigin from './../list-product-origin'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
}))

const ValidatorInput = (props) => {
  const [validatorValue, setValidatorValue] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const validator = useInput(props)

  useEffect(() => {
    setValidatorValue(JSON.stringify(validator.input.value, null, '\t'))
    setIsChanged(false)
  }, [validator.input.value])

  function onChange(newValue) {
    setValidatorValue(newValue)
    setIsChanged(true)
  }

  function saveToForm() {
    const parsed = JSON.parse(validatorValue)
    validator.input.onChange(parsed)
  }

  return (
    <div>
      <AceEditor
        name="validator"
        style={{ height: '200px', width: '100%' }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={validatorValue}
      />
      {isChanged && (
        <Button variant="contained" size="small" color="primary" onClick={saveToForm}>
          <>Commit Changes</>
        </Button>
      )}
    </div>
  )
}

const DataInput = (props) => {
  const [dataValue, setDataValue] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const data = useInput(props)

  useEffect(() => {
    setDataValue(JSON.stringify(data.input.value, null, '\t'))
    setIsChanged(false)
  }, [data.input.value])

  function onChange(newValue) {
    setDataValue(newValue)
    setIsChanged(true)
  }

  function saveToForm() {
    const parsed = JSON.parse(dataValue)
    data.input.onChange(parsed)
  }

  return (
    <div>
      <AceEditor
        name="data"
        style={{ height: '200px', width: '100%' }}
        mode="json"
        theme="textmate"
        onChange={onChange}
        value={dataValue}
      />
      {isChanged && (
        <Button variant="contained" size="small" color="primary" onClick={saveToForm}>
          <>Commit Changes</>
        </Button>
      )}
    </div>
  )
}

const EditTitle = ({ record }) => {
  return <span>{record ? `"${record.name}"` : ''} Edit</span>
}

export default function PageEdit(props) {
  const classes = useStyles()

  return (
    <Edit {...props} title={<EditTitle />}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput className={classes.input} disabled fullWidth source="id" />
            </Grid>
            <Grid item xs={6}>
              <TextInput fullWidth className={classes.input} source="product_code" label="Code" />
            </Grid>
            <Grid item xs={6}>
              <TextInput fullWidth className={classes.input} source="name" />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                source="country"
                fullWidth
                validate={[required()]}
                className={classes.input}
                choices={productOrigin}
              />
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                fullWidth
                className={classes.input}
                source="organization_id"
                reference="organizations"
                validate={[required()]}
              >
                <SelectInput source="name" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              {/* <BooleanInput
                fullWidth
                source="available"
                className={classes.input}
                validate={[required()]}
              /> */}
            </Grid>
            <Grid item xs={6}>
              <NumberInput className={classes.input} fullWidth source="denomination" />
            </Grid>
            <Grid item xs={6}>
              <NumberInput fullWidth className={classes.input} source="min_denomination" />
            </Grid>
            <Grid item xs={12}>
              <TextInput fullWidth className={classes.input} multiline source="description" />
            </Grid>
            <Grid item xs={6}>
              <p>Validator</p>
              <ValidatorInput source="validator" className={classes.input} />
            </Grid>
            <Grid item xs={6}>
              <p>Data</p>
              <DataInput source="data" />
            </Grid>
            <Grid item xs={6}>
              {/* <NumberInput source="value" fullWidth className={classes.input} /> */}
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                source="rpg_method"
                fullWidth
                validate={[required()]}
                className={classes.input}
                optionText="id"
                choices={[
                  { id: 'ABSOLUTE', name: 'Absolute' },
                  { id: 'PERCENT', name: 'Percent' },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="rpg_value" fullWidth className={classes.input} />
            </Grid>
            <Grid item xs={6}>
              <NumberInput fullWidth source="rpg_rrp" className={classes.input} />
            </Grid>
            <Grid item xs={3}>
              <BooleanInput source="rpg_inquiry" fullWidth className={classes.input} />
            </Grid>
            <Grid item xs={3}>
              <BooleanInput source="rpg_status" />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                className={classes.input}
                source="sub_id"
                validate={[required()]}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}
