const { client } = require('../../MbaasClient')

export async function fetchDataInitTable(
  includedTables,
  resource,
  filterObject,
  order,
  field,
  page,
  perPage
) {
  const initTable =
    includedTables.length > 0
      ? client.getTable(resource).include(includedTables)
      : client.getTable(resource)
  const { data, total } = await initTable
    .filter(filterObject || 'true')
    .sort(order === 'DESC' ? `-${field}` : field)
    .limit(perPage)
    .offset((page - 1) * perPage)
    .read()

  return [data, total]
}
