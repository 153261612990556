import { client } from '../../MbaasClient'

export const funcUpdateTransactionsSync = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  /**
   * how button button sync works:
   * 1. fetch latest status from transaction_histories
   * 2. take the latest status and save to transactions
   * */
  const trxId = params.id
  const trxLatestStatus = params.data.latest_status
  const table = client.getTable('transaction_histories')

  const { data: historicalData } = await table /* 1 */
    // @ts-ignore
    .filter(table.composeFilter().eq('transaction_id', trxId).toOData())
    .sort('-created_at')
    .limit(1)
    .read()
  const latestHist = historicalData[0]
  if (trxLatestStatus !== 'unpaid' && trxLatestStatus !== latestHist.status) {
    const { data } = await client /* 2 */
      .getTable('transactions')
      .update(
        Object.assign({
          id: params.id,
          data: { latest_status: latestHist.status },
        })
      )
    return { data: data }
  } else return { data: {} }
}
