import React, { useState } from 'react'
import {
  Button,
  SaveButton,
  useUpdate,
  useNotify,
  FormWithRedirect,
  useRefresh,
  useRedirect,
} from 'react-admin'
import MuiButton from '@material-ui/core/Button'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import gql from 'graphql-tag'
const { client } = require('./../provider/MbaasClient')

const fetchAllStore = async (search = null) => {
  return await client.gql.query({
    query: gql`
      query sdfsdf($search: String) {
        allStorePointsList(
          where: { _or: [{ store_id: { _ilike: $search } }, { name: { _ilike: $search } }] }
        ) {
          data {
            id
            store_id
            name
            address
            state
            pruned_address
          }
        }
      }
    `,
    variables: { search },
  })
}

export default function EditStore(record) {
  const [update, { loading, data }] = useUpdate()
  const [showDialog, setShowDialog] = useState(false)
  const [listStore, setListStore] = React.useState([])
  const [typingTimeout, setTypingTimeout] = React.useState([])
  const [search, setSearch] = React.useState(null)
  const [selected, setSelected] = React.useState({})
  const [isLoading, seIsLoading] = React.useState(false)

  React.useEffect(() => {
    fetchAllStore(search).then((data) => {
      const format = (v) => ({
        id: v.id,
        store_id: v.store_id,
        name: v.name,
        address: v.address,
        state: v.state,
        pruned_address: v.pruned_address,
      })
      const reformat = (data?.allStorePointsList?.data || []).map(format)
      setListStore(reformat)
      seIsLoading(false)
    })
  }, [search])

  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseCLick = () => {
    setShowDialog(false)
  }

  if (data) {
    redirect('/transactions')
    refresh()
    notify('ra.notification.updated', 'info', { smart_count: 1 })
  }

  const handleSubmit = async (values) => {
    update(
      'transactions',
      record.id,
      {
        store_data: {
          id: selected?.id,
          store_id: selected?.store_id,
          store_name: selected?.name,
          store_address: selected?.address,
          state: selected?.state,
          pruned_address: selected?.pruned_address,
        },
      },
      {
        onSuccess: ({ data }) => {
          redirect('/transactions')
          refresh()
          notify('ra.notification.updated', 'info', { smart_count: 1 })
        },
        onFailure: (error) => {
          notify(error.message, 'error')
        },
      }
    )
  }

  // -- function to check if store have ID, if yes display ID, if no display xxx
  function optionStoreID(option) {
    return option.store_id || 'xxx'
  }

  // -- function to display the no option text
  function noOptionTextFn(search, isLoading) {
    return !search?.length && !isLoading ? 'type a letters' : 'No options'
  }

  function valueFunc(selected, record) {
    return Object.keys(selected).length === 0
      ? record.store_data?.store_id || ''
      : selected.store_id || ''
  }

  function valueStoreName(selected, record) {
    return Object.keys(selected).length === 0
      ? record.store_data?.store_name || ''
      : selected.name || ''
  }

  function valueAddress(selected, record) {
    return Object.keys(selected).length === 0
      ? record.store_data?.store_address || ''
      : selected.address || ''
  }

  function valueState(selected, record) {
    return Object.keys(selected).length === 0
      ? record.store_data?.state || ''
      : selected.state || ''
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label="Edit Store"
        variant="contained"
        size="small"
        style={{ marginLeft: '180px' }}
      />
      <Dialog fullWidth open={showDialog} onClose={handleCloseCLick} aria-label="Edit Store">
        <DialogTitle>Edit Store</DialogTitle>
        <FormWithRedirect
          resource="transactions"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading}
                      fullWidth
                      options={listStore}
                      getOptionLabel={(option) => `${optionStoreID(option)} - ${option.name}`}
                      noOptionsText={noOptionTextFn(search, isLoading)}
                      loading={isLoading}
                      onChange={(_, value) => {
                        console.log('Autocomplete', { value })
                        setSelected(value || {})
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Store"
                          placeholder="input store id / name"
                          variant="filled"
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            seIsLoading(e.target.value.length > 0)
                            const text = e.target.value
                            clearTimeout(typingTimeout)
                            setTypingTimeout(null)
                            setTypingTimeout(
                              setTimeout(() => {
                                setSearch(text ? `%${text}%` : null)
                              }, 1300)
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={loading || Object.keys(selected).length === 0}
                      variant="standard"
                      label="Store ID"
                      fullWidth
                      value={valueFunc(selected, record)}
                      onChange={(e) => {
                        const data = { ...selected, store_id: e?.target?.value }
                        setSelected(data)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      variant="standard"
                      label="Store name"
                      fullWidth
                      value={valueStoreName(selected, record)}
                      onChange={(e) => {
                        const data = { ...selected, name: e?.target?.value }
                        setSelected(data)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      disabled
                      label="Store address"
                      variant="standard"
                      fullWidth
                      value={valueAddress(selected, record)}
                      onChange={(e) => {
                        const data = { ...selected, address: e?.target?.value }
                        setSelected(data)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      disabled
                      variant="standard"
                      label="State"
                      fullWidth
                      value={valueState(selected, record)}
                      onChange={(e) => {
                        const data = { ...selected, state: e?.target?.value }
                        setSelected(data)
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {!loading ? (
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    saving={saving}
                    disabled={loading || Object.keys(selected).length === 0}
                    label="Save"
                  />
                ) : (
                  <MuiButton
                    variant="contained"
                    color="primary"
                    disabled
                    startIcon={<CircularProgress style={{ color: 'white' }} size={'16px'} />}
                  >
                    Save
                  </MuiButton>
                )}
                <Button label="ra.action.cancel" onClick={handleCloseCLick} disabled={loading}>
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}
