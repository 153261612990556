import gql from 'graphql-tag'
import { client } from '../provider/MbaasClient'

/* MAIN FUNCTION */
const fetchFindActiveDefaultHeaderImage = async () => {
  try {
    const result = await client.gql.query({
      query: gql`
        query {
          allWebviewHeadersList(where: { default: { _eq: true } }) {
            data {
              id
            }
          }
        }
      `,
    })

    const hasActiveDefaultImage = result?.allWebviewHeadersList?.data?.length > 0
    return [
      null,
      {
        hasActiveDefaultImage,
        listIdActiveDefaultImage: result?.allWebviewHeadersList?.data,
      },
    ]
  } catch (error) {
    return [error, false]
  }
}
export default fetchFindActiveDefaultHeaderImage
