import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
  FunctionField,
  EditButton,
  ImageField,
} from 'react-admin'
import ReactJson from 'react-json-view'
import moment from 'moment'

const PageList = (props) => (
  <List {...props} sort={{ field: 'created_at', order: 'DESC' }}>
    <Datagrid>
      <DateField source="created_at" showTime />
      <FunctionField
        label="Status"
        render={(record) => {
          const start = moment(record.start).toISOString()
          const end = moment(record.end).toISOString()
          if (record.default) {
            return <p style={{ color: 'rgba(0,0,0,0.5)' }}>DEFAULT</p>
          } else if (moment(moment().toISOString()).isBetween(start, end)) {
            return <p style={{ color: 'green' }}>ACTIVE</p>
          } else if (moment(moment().toISOString()).isBefore(end)) {
            return <p style={{ color: 'orange' }}>INACTIVE</p>
          } else return <p style={{ color: 'red' }}>EXPIRED</p>
        }}
      />
      <ImageField label="Image" source="url.imageUrl" sortable={false} />
      <DateField source="start" label="Begin" />
      <DateField source="end" />
      <BooleanField source="default" label="Default Image" />
      <ReferenceField label="Created By" source="created_by" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="Updated By"
        render={({ updated_by }) => (
          <ReactJson
            src={updated_by || []}
            shouldCollapse={() => {}}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            name={false}
          />
        )}
      />
      <EditButton />
    </Datagrid>
  </List>
)
export default PageList
