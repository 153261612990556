// import { TDADeleteManyFn } from '@/src/@types/data-provider/delete-many'
import { client, bucket } from '../../MbaasClient'

export const deleteManyMessengerMsgSeries: any = async (resource: any, params: any) => {
  console.log('delete-many', { resource, params })

  for (const id of params.ids) {
    let getData = await client.getTable(resource).lookup(id as string)
    await client.storage.removeFile({
      filePath: getData.data.content.image_id,
      bucket: bucket['advertisements'],
    })
    await client.getTable(resource).del({ id: id as string })
  }
  return { data: params.ids }
}
