import moment from 'moment'
import { updateEnableCustomerAndUser } from '../../../requests/update-enable-customer-and-user'
import { client } from '../../MbaasClient'

export const funcUpdateEnableCustomerAndUser = async (resource: any, params: any) => {
  const userData: any = await client.user.get()
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  try {
    const { id, data, previousData } = params

    // @ts-ignore
    let logArray = []
    if (id.customerId) {
      let logId = 0
      let logs = previousData.customers.metadata.logs
      if (logs && logs.length > 0) {
        logId = logs.length
      }

      logArray.push({
        id: logId,
        date: moment().toISOString(),
        updated_by: {
          user_id: userData.data.id,
          name: userData.data.name,
        },
        before: {
          name: previousData.customers.name,
          channel: previousData.customers.channel,
          enable: previousData.customers.enable,
        },
        after: {
          name: previousData.customers.name,
          channel: previousData.customers.channel,
          enable: data.dataCustomer.enable,
        },
      })
      if (logs && logs.length > 0) {
        // @ts-ignore
        logArray = logArray.concat(logs).sort((a, b) => b.date - a.date)
      }
    }

    const [err, datas] = await updateEnableCustomerAndUser({
      customerId: id.customerId,
      userId: id.userId,
      dataCustomer: {
        enable: data.dataCustomer.enable,
        metadata: {
          ...(previousData?.customers?.metadata || {}),
          // @ts-ignore
          logs: logArray,
        },
      },
      dataUser: {
        enable: data.dataUser.enable,
      },
    })
    return { error: err, data: datas }
  } catch (e) {
    throw e
  }
}
