import React from 'react'
import { customAlphabet } from 'nanoid'
import { alphanumeric } from 'nanoid-dictionary'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { useInput } from 'react-admin'
import { Button } from '@material-ui/core'

export default function PromoCodeInputGenerator(props) {
  const classes = useStyles()

  const [promoCodeValue, setPromoCodeValue] = React.useState('')
  const promoCode = useInput(props)
  const promoCodeGen = () => {
    const nanoid = customAlphabet(alphanumeric, 7)
    const nanoPromoCode = nanoid().toUpperCase()
    promoCode.input.onChange(nanoPromoCode)
  }

  React.useEffect(() => {
    setPromoCodeValue(promoCode.input.value)
  }, [promoCode.input.value])

  return (
    <>
      <TextField
        required
        id="standard-required"
        name="promoCode"
        fullWidth
        className={classes.input}
        label="Promotion Code"
        value={promoCodeValue}
        onChange={(e) => {
          setPromoCodeValue(e.target.value)
          promoCode.input.onChange(e.target.value)
        }}
      />
      <Button
        color="primary"
        size="small"
        variant="contained"
        className={classes.button1}
        onClick={promoCodeGen}
        style={{
          marginTop: '25px',
        }}
      >
        Generate
      </Button>
    </>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  paper: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: 'auto',
    },
  },
}))
