const { client } = require('../../MbaasClient')

export const funcCreateKnowledgeBase = async (resource, params) => {
  const { data } = await client.getTable('knowledge_base').insert({
    question: params.data.question,
    answer: params.data.answer,
    category: params.data.category,
    tag: params.data.tag,
  })

  return { data: data }
}
