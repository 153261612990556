import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
} from 'react-admin'
import ReactJson from 'react-json-view'

/* 
id, 
created_by, 
updated_by (Object[] dengan Object berisi `user_id` dan `timestamp`), 
country, 
default, 
additional_configuration: 
  `additional_configuration` adalah Object[] dengan format : 
  [{ product_origin, 
    category, 
    product_code,
    denom, 
    payment_channel, 
    pg_channel }], 
created_at, 
updated_at
*/

export default function PageList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <ReferenceField link={false} source="created_by" reference="users">
          <TextField source="name" />
        </ReferenceField>

        <FunctionField
          source="updated_by"
          // label="Product Code"
          render={(record) => {
            return (
              <ReactJson
                src={record.updated_by || {}}
                shouldCollapse={() => {}}
                name={false}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            )
          }}
        />

        <TextField source="country" />
        <TextField source="default" />

        <FunctionField
          source="additional_configuration"
          // label="Product Code"
          render={(record) => {
            return (
              <ReactJson
                src={record.additional_configuration || []}
                shouldCollapse={() => {}}
                name={false}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            )
          }}
        />

        <DateField source="created_at" />

        <DateField source="updated_at" />

        <EditButton />
      </Datagrid>
    </List>
  )
}
