import moment from 'moment'
import { fetchDataInitTable } from '../shared-script/fetchDataInitTable'
const { moco, client } = require('../../MbaasClient')

export async function isPoin(params, resource) {
  const { page, perPage } = params.pagination
  const { dateGte, dateLte } = params.filter || {}
  let { field, order } = params.sort
  if (field === 'id') {
    field = 'created_at'
    order = 'DESC'
  }

  let filterObject = moco.composeFilter()
  let includedTables = []
  //poin list group by customer
  if (!params.filter.customer_id) {
    if (field === 'created_at') {
      field = 'customer_id'
    }
    const poin = await client.invokeApi('poin-group', {
      offset: (page - 1) * perPage,
      limit: perPage,
      filter: params.filter.id,
      field: field,
      order: order,
    })

    return poin.data
  }

  //detail poin
  if (params.filter.customer_id) {
    filterObject = filterObject.eq('customer_id', params.filter.customer_id)
  }

  if (dateGte && dateLte) {
    filterObject = filterObject.and((x) =>
      x
        .ge('created_at', moment(dateGte).startOf('day').toISOString())
        .le('created_at', moment(dateLte).endOf('day').toISOString())
    )
  }

  if (filterObject !== '') filterObject = filterObject.toString()

  const [data, total] = await fetchDataInitTable(
    includedTables,
    resource,
    filterObject,
    order,
    field,
    page,
    perPage
  )

  return { data: data, total: total }
}
