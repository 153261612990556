import React from 'react'
import {
  DateTimeInput,
  SimpleForm,
  required,
  NumberInput,
  SelectInput,
  Create,
  useNotify,
  useRedirect,
  BooleanInput,
  FormDataConsumer,
  useInput,
  ReferenceInput,
  AutocompleteInput,
  SaveButton,
  Toolbar,
  translate,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import { useAtom } from 'jotai'
import { listProductOrigin } from './../../constants'
import { invokeApiPromoFlashsalesProducts } from './../../requests'
import { CustomAutocompleteInput, RaChipInputNumberFormat } from './../../shared-components'
import { listCategoryAtom, listProductCodeAtom } from './atom'
import PromoCodeInputGenerator from './promo-code-input-generator'
import { listPaymentChannel, listPaymentChannelFPX } from './../../constants'

const PageCreate = (props) => {
  const [raFormDataProductOrigin, setRaFormDataProductOrigin] = React.useState('[]')
  const [raFormDataCategory, setRaFormDataCategory] = React.useState('[]')
  const [listCategory, setListCategory] = useAtom(listCategoryAtom)
  const [listProductCode, setListProductCode] = useAtom(listProductCodeAtom)
  const [listPaymentFPX, setListPaymentFPX] = React.useState()

  React.useEffect(() => {
    setListCategory([])
    setListProductCode([])

    if (JSON.parse(raFormDataProductOrigin)?.length > 0) {
      invokeApiPromoFlashsalesProducts({
        product_origin: JSON.parse(raFormDataProductOrigin)?.map((v) => v.id),
        invoked: 'category',
      }).then(
        ([
          ,
          {
            data: { data: dataCategory },
          },
        ]) => {
          setListCategory(dataCategory?.map((v) => ({ id: v.category, name: v.category })))
        }
      )
    }
  }, [raFormDataProductOrigin])

  React.useEffect(() => {
    setListProductCode([])
    if (JSON.parse(raFormDataCategory)?.length > 0) {
      invokeApiPromoFlashsalesProducts({
        product_origin: JSON.parse(raFormDataProductOrigin)?.map((v) => v.id),
        category: JSON.parse(raFormDataCategory)?.map((v) => v.id),
        invoked: 'product_code',
      }).then(
        ([
          ,
          {
            data: { data: dataProductCode },
          },
        ]) => {
          setListProductCode(
            dataProductCode?.map((v) => ({ id: v.product_code, name: v.product_code }))
          )
        }
      )
    }
  }, [raFormDataCategory])

  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()
  const [specCustWidth, setSpecCustWidth] = React.useState(12)
  const [custMaxUsageWidth, setCustMaxUsageWidth] = React.useState(12)
  const onCancel = () => {
    redirect('/promotions')
  }

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/promotions')
  }

  return (
    <Create title="Create Promotion" {...props} onSuccess={onSuccess}>
      <SimpleForm toolbar={<PromoCreateToolbar onCancel={onCancel} />}>
        <FormDataConsumer>
          {({ formData }) => {
            setRaFormDataProductOrigin(JSON.stringify(formData?.product_origin) || '[]')
            setRaFormDataCategory(JSON.stringify(formData?.category) || '[]')
          }}
        </FormDataConsumer>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PromoCodeInputGenerator source="code" />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                fullWidth
                source="type"
                validate={required()}
                choices={[
                  { id: 'absolute', name: 'ABSOLUTE' },
                  { id: 'percent', name: 'PERCENT' },
                  { id: 'final', name: 'FINAL' },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberInput
                fullWidth
                className={classes.input}
                source="value"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomProductOrigin
                source="product_origin"
                label="Product Origin"
                validate={required()}
                multiple
                options={[{ id: 'ALL', name: 'ALL' }, ...listProductOrigin]}
                style={{ marginBottom: '28px' }}
                //
              />
              <CustomCategory
                source="category"
                label="Category"
                validate={required()}
                multiple
                options={listCategory}
                style={{ marginBottom: '28px' }}
                //
              />
              <CustomProductCode
                source="product_code"
                label="Product Code"
                validate={required()}
                multiple
                options={listProductCode}
                style={{ marginBottom: '28px' }}
                //
              />
              <div style={{ display: 'flex', gap: '16px', width: '100%' }}>
                <SelectInput
                  source="denom_type"
                  label="Denom"
                  validate={required()}
                  choices={[
                    { id: 'ALL', name: 'All' },
                    { id: 'OTHER', name: 'Other' },
                  ]}
                  fullWidth
                />

                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    formData.denom_type === 'OTHER' && (
                      <RaChipInputNumberFormat
                        source="denom_value"
                        label="Denom Value"
                        validate={required()}
                        fullWidth
                        style={{ marginBottom: '23px' }}
                        {...rest}
                        //
                      />
                    )
                  }
                </FormDataConsumer>
              </div>
              <CustomPaymentChannel
                classes={classes}
                source="paymentChannel"
                label="Payment Channel"
                validate={required()}
                multiple
                options={listPaymentChannel?.default}
                style={{ marginBottom: '28px' }}
                listPaymentChannelFPX={listPaymentChannelFPX?.default}
                setListPaymentFPX={setListPaymentFPX}
              />
              {listPaymentFPX?.length > 0 && (
                <CustomPaymentChannelFPX
                  classes={classes}
                  source="paymentChannelFPX"
                  label="Payment Channel Fpx"
                  validate={required()}
                  multiple
                  options={listPaymentChannelFPX.default}
                  style={{ marginBottom: '28px' }}
                  listPaymentFPX={listPaymentFPX}
                />
              )}
            </Grid>
            <Grid item xs={custMaxUsageWidth}>
              <BooleanInput
                fullWidth
                source="specific_customer"
                label="Apply Cust. Max Usage?"
                title="Is Max Usage per Customer applied?"
              />
            </Grid>

            <FormDataConsumer subscription={{ values: true }}>
              {({ formData }) => {
                setCustMaxUsageWidth(12)
                if (formData.specific_customer) {
                  setCustMaxUsageWidth(6)
                  return (
                    <Grid item xs={6}>
                      <NumberInput
                        fullWidth
                        className={classes.input}
                        source="max_usage_per_customer"
                        label="Max Usage per Customer"
                      />
                    </Grid>
                  )
                }
              }}
            </FormDataConsumer>
            <Grid item xs={6}>
              <BooleanInput
                fullWidth
                source="new_customer_only"
                label="For New Customer / Target Only?"
                title="Customer / Target without success transactions before this promo is created"
              />
            </Grid>
            <Grid item xs={6}>
              <BooleanInput
                fullWidth
                source="specific_target"
                label="Is Specific Target?"
                title="Is it can only be used once per target?"
              />
            </Grid>
            <Grid item xs={specCustWidth}>
              <BooleanInput
                fullWidth
                source="specific_customer_name"
                label="Is for Specific Customer?"
                title="Is applied for Specific Customer?"
              />
            </Grid>
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData }) => {
                setSpecCustWidth(12)
                if (formData.specific_customer_name) {
                  setSpecCustWidth(6)
                  return (
                    <Grid item xs={6}>
                      <ReferenceInput
                        source="customer_id"
                        reference="customers_list_data"
                        fullWidth
                        className={classes.input}
                        sort={{ field: 'latest_chat_at', order: 'DESC' }}
                        label="Specific for"
                      >
                        <AutocompleteInput
                          source="id"
                          optionText={(choice) =>
                            choice?.channel === 'PWA_APP'
                              ? `${choice?.name} - ${choice?.channel} - ${choice?.users_customer_id_list?.data[0]?.msisdn}`
                              : `${choice?.name} - ${choice?.channel} - ${choice?.channel_id}`
                          }
                        />
                      </ReferenceInput>
                    </Grid>
                  )
                }
              }}
            </FormDataConsumer>
            <Grid item xs={12}>
              <NumberInput
                fullWidth
                className={classes.input}
                source="max_usage"
                step={1}
                label="Total Usage"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimeInput
                fullWidth
                className={classes.input}
                source="start"
                label="Valid From"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimeInput
                fullWidth
                className={classes.input}
                source="end"
                label="Valid Until"
                validate={[required()]}
              />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}
export default PageCreate

/* OTHER COMPONENT */

const CustomPaymentChannel = ({
  multiple,
  label,
  options,
  setListPaymentFPX,
  listPaymentChannelFPX,
  style,
  ...props
}) => {
  const {
    input: { name, onChange, ref, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  return (
    <CustomAutocompleteInput
      label={label}
      multiple={multiple}
      options={options || []}
      disableCloseOnSelect={true}
      required={isRequired}
      onChange={(event, newValue) => {
        console.log(8830713, 'newValue', newValue)
        if (newValue?.some((v) => v.id === 'FPX')) {
          setListPaymentFPX(newValue.length > 0 ? listPaymentChannelFPX : [])
          onChange(newValue)
          return
        }
        setListPaymentFPX([])
        onChange(newValue)
      }}
      inputRef={ref}
      value={value || []}
      error={!!(touched && error)}
      helperText={touched && error}
      style={style}
      {...rest}
    />
  )
}

const CustomPaymentChannelFPX = ({ multiple, label, options, listPaymentFPX, style, ...props }) => {
  const {
    input: { name, onChange, ref, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  React.useEffect(() => {
    onChange(listPaymentFPX)
  }, [JSON.stringify(listPaymentFPX)])

  return (
    <CustomAutocompleteInput
      label={label}
      multiple={multiple}
      options={options || []}
      disableCloseOnSelect={true}
      required={isRequired}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      inputRef={ref}
      value={value || []}
      error={!!(touched && error)}
      helperText={touched && error}
      style={style}
      {...rest}
    />
  )
}

const CustomProductOrigin = ({ multiple, label, options, style, ...props }) => {
  const {
    input: { name, onChange, ref, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  return (
    <CustomAutocompleteInput
      label={label}
      multiple={multiple}
      options={options || []}
      disableCloseOnSelect={true}
      required={isRequired}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      inputRef={ref}
      value={value || []}
      error={!!(touched && error)}
      helperText={touched && error}
      style={style}
      {...rest}
    />
  )
}

const CustomCategory = ({ multiple, label, options, style, ...props }) => {
  const [listCategory] = useAtom(listCategoryAtom)

  const {
    input: { name, onChange, ref, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  React.useEffect(() => {
    onChange(listCategory)
  }, [JSON.stringify(listCategory)])

  return (
    <CustomAutocompleteInput
      label={label}
      multiple={multiple}
      options={options || []}
      disableCloseOnSelect={true}
      required={isRequired}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      inputRef={ref}
      value={value || []}
      error={!!(touched && error)}
      helperText={touched && error}
      style={style}
      {...rest}
    />
  )
}

const CustomProductCode = ({ multiple, label, options, style, ...props }) => {
  const [listProductCode] = useAtom(listProductCodeAtom)

  const {
    input: { name, onChange, ref, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  React.useEffect(() => {
    onChange(listProductCode)
  }, [JSON.stringify(listProductCode)])

  return (
    <CustomAutocompleteInput
      label={label}
      multiple={multiple}
      options={options || []}
      disableCloseOnSelect={true}
      required={isRequired}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      inputRef={ref}
      value={value || []}
      error={!!(touched && error)}
      helperText={touched && error}
      style={style}
      {...rest}
    />
  )
}

const PromoCreateToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
))

/* STYLING */
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
  paper: {
    '& ul li[aria-selected="true"]:after': {
      content: "'\\2713'",
      marginLeft: 'auto',
    },
  },
}))
