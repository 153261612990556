const mapCountryCurrency = [
      { country: 'AUS', currency: 'AUD' },
      { country: 'BGD', currency: 'BDT' },
      { country: 'BRN', currency: 'BND' },
      { country: 'KHM', currency: 'KHR' },
      { country: 'TLS', currency: 'USD' },
      { country: 'HKG', currency: 'HKD' },
      { country: 'IDN', currency: 'IDR' },
      { country: 'LAO', currency: 'LAK' },
      { country: 'MOC', currency: 'MOP' },
      { country: 'MYS', currency: 'MYR' },
      { country: 'MMR', currency: 'MMK' },
      { country: 'NPL', currency: 'NPR' },
      { country: 'NZL', currency: 'NZD' },
      { country: 'PHL', currency: 'PHP' },
      { country: 'SGP', currency: 'SGD' },
      { country: 'TWN', currency: 'TWD' },
      { country: 'THA', currency: 'THB' },
      { country: 'USA', currency: 'USD' },
      { country: 'VNM', currency: 'VND' },
      { country: 'PAK', currency: 'PKR' },
      { country: 'CHN', currency: 'CNY' },
      { country: 'IND', currency: 'INR' },
]
    
export default mapCountryCurrency