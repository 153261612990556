import { cloneElement, useEffect } from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  Filter,
  useListContext,
  TopToolbar,
  ReferenceInput,
  AutocompleteInput,
  Pagination,
} from 'react-admin'
import { DateRange } from '../DateRange'
import moment from 'moment'
import { CustomerLedger } from './CustomerLedgers'
import { useCustomerId } from '@/src/zustand/customerIdStore'
import { TextField as MuiTextField, Button } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useFilter } from '@/src/zustand/filterStore'

const PaginationComp = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const WalletsPageFilter = (props) => (
  <Filter {...props}>
    <DateRange alwaysOn />
    <ReferenceInput source="user_id" reference="users">
      <AutocompleteInput source="name" alwaysOn />
    </ReferenceInput>
    <ReferenceInput source="customer_id" reference="customers" label="customer">
      <AutocompleteInput source="name" alwaysOn />
    </ReferenceInput>
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, showFilter } = useListContext()
  // filter from transaction page
  const customerID = useCustomerId((state) => state.customer_data)
  // this page filter
  const updateCustId = useFilter((state) => state?.updateCustId)
  const updateUserId = useFilter((state) => state?.updateUserId)

  useEffect(() => {
    if (filterValues?.customer_id) updateCustId(filterValues?.customer_id)
    if (filterValues?.user_id) updateUserId(filterValues?.user_id)
    const keys = Object.keys(filterValues)
    if (
      (keys.some((value) => ['dateGte', 'dateLte'].includes(value)) && keys.length === 2) ||
      keys.length === 0
    ) {
      updateCustId(null)
      updateUserId(null)
    }
  }, [filterValues])

  const styleUsed = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: customerID?.id ? 'space-between' : 'flex-end',
    width: '100%',
    height: '100%',
  }

  return (
    <div style={customerID?.id ? styleUsed : null}>
      {customerID?.id && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 2,
          }}
        >
          <MuiTextField
            label="customer name"
            style={{
              width: '11em',
            }}
            disabled
            defaultValue={customerID?.name}
          />
          <Button
            startIcon={<HighlightOffIcon />}
            onClick={() => useCustomerId.setState({ customer_data: null })}
          />
        </div>
      )}
      <TopToolbar>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
      </TopToolbar>
    </div>
  )
}

export const PageList = (props) => {
  // filter from transaction page
  const customerID = useCustomerId((state) => state.customer_data)
  // this page filter
  const customer_id = useFilter((state) => state.customer_id)
  const user_id = useFilter((state) => state.user_id)

  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={<WalletsPageFilter />}
      filter={{
        customer_id: customerID?.id ? customerID?.id : customer_id,
        user_id: user_id ?? '',
      }}
      filterDefaultValues={{
        dateGte: moment().startOf('month').format('YYYY/MM/DD'),
        dateLte: moment().endOf('month').format('YYYY/MM/DD'),
      }}
      perPage={25}
      pagination={<PaginationComp />}
      resource="wallets"
      title="Royalty Reward Logs"
      sort={{ field: 'created_at', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="expand" expand={<CustomerLedger />}>
        <DateField source="created_at" label="Created At" showTime />
        <TextField source="custName" label="Customer" />
        <NumberField source="balanceGimmick" label="Gimmick" />
        <NumberField source="balanceRefund" label="Refund" />
        <NumberField source="endingBalance" label="Total (Gimmick + Refund)" />
      </Datagrid>
    </List>
  )
}
