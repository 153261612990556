const { client } = require('./../provider/MbaasClient')

const updatePurchaseLimit = async (listPricingId, { purchase_limit }) => {
  try {
    let arrPromise = []

    listPricingId.forEach((pricingId) => {
      const promise = client.getTable('pricings').update({
        id: pricingId,
        data: { purchase_limit },
      })

      arrPromise.push(promise)
    })

    const result = await Promise.all(arrPromise)
    return [null, result]
  } catch (error) {
    return [error, null]
  }
}

export default updatePurchaseLimit
