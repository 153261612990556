import React, { cloneElement } from 'react'
import moment from 'moment'
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  DateField,
  FunctionField,
  Filter,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  Link,
  linkToRecord,
} from 'react-admin'
import ReactJson from 'react-json-view'
import { DateRange } from './DateRange'
import ExportButton from '../shared-components/export-button'

const PoinLedgersFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="user_id" reference="users">
      <AutocompleteInput source="name" alwaysOn />
    </ReferenceInput>
    <ReferenceInput source="customer_id" reference="customers">
      <AutocompleteInput source="name" alwaysOn />
    </ReferenceInput>
    <DateRange alwaysOn />
    <SelectInput
      label="Type"
      source="type"
      choices={[
        { id: 'POIN', name: 'POIN' },
        { id: 'CREDIT', name: 'CREDIT' },
      ]}
      alwaysOn
    />
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton filter={filterValues} resourceType="POIN-LOG" />
    </TopToolbar>
  )
}

export const PoinLedgersList = (props) => (
  <List
    {...props}
    filters={<PoinLedgersFilter />}
    actions={<ListActions />}
    sort={{ field: 'created_at', order: 'DESC' }}
    title="Royalty Reward Logs"
    filterDefaultValues={{
      dateGte: moment().startOf('month').format('YYYY/MM/DD'),
      dateLte: moment().endOf('month').format('YYYY/MM/DD'),
    }}
  >
    <Datagrid>
      <DateField source="created_at" label="Created" showTime />
      <FunctionField
        label="Users"
        render={(record) => {
          if (record.user_id) {
            const linkToUser = linkToRecord(`/users`, record.user_id)
            return <Link to={linkToUser}>{record.users.name}</Link>
          }
        }}
      />
      <FunctionField
        label="Customers"
        render={(record) => {
          const linkToCust = linkToRecord(`/customers`, record.customer_id)
          return <Link to={linkToCust}>{record.customers.name}</Link>
        }}
      />
      <FunctionField
        label="Reference"
        render={(record) => {
          if (record.transaction_ref) {
            const linkToRef = linkToRecord(`/transactions`, record.transaction_ref, 'show')
            return <Link to={linkToRef}>{record.transactions.order_id}</Link>
          }
        }}
      />
      <NumberField source="credit" />
      <NumberField source="debit" />
      <NumberField source="ending_balance" label="Ending balance" />
      <TextField source="remark" />
      <DateField source="expiry" label="Expired at" />
      <TextField source="type" />
      <FunctionField
        label="Data"
        render={(record) => (
          <ReactJson
            src={record.data || {}}
            shouldCollapse={() => {}}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={true}
            name={false}
          />
        )}
      />
    </Datagrid>
  </List>
)
