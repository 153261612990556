import React from 'react'
import {
  SimpleForm,
  TextInput,
  Edit,
  required,
  SelectInput,
  useRedirect,
  FormDataConsumer,
} from 'react-admin'
import { CustomToolbar } from './CustomToolbarEdit'
import { view } from '@risingstack/react-easy-state'
import { currencyList } from '../../constants'
import { RaNumberFormatInput } from './../../shared-components'

const EditTitle = ({ record }) => {
  return `Edit Flashsale: ${record.id}`
}

export const FlashsaleEdit = view((props) => {
  const redirect = useRedirect()
  const [dataForm, setDataForm] = React.useState([])

  const onCancel = () => {
    redirect('/denom_pricings')
  }

  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar onCancel={onCancel} dataForm={dataForm} />}>
        <FormDataConsumer style={{ width: '100%' }}>
          {({ formData, ...rest }) => {
            setDataForm(formData)
            let currencySplit = formData.currency.split('-')
            return (
              <>
                <SelectInput
                  label="Currency From"
                  source="currency_from"
                  defaultValue={currencySplit[0]}
                  fullWidth
                  validate={required()}
                  choices={currencyList}
                />

                <SelectInput
                  label="Currency To"
                  source="currency_to"
                  defaultValue={currencySplit[1]}
                  fullWidth
                  validate={required()}
                  choices={currencyList}
                />
              </>
            )
          }}
        </FormDataConsumer>
        <RaNumberFormatInput
          label="Denom"
          source="denom"
          placeholder=""
          fullWidth
          style={{ marginBottom: '27px' }}
        />
        <TextInput label="RRP" source="rrp" placeholder="" fullWidth />
      </SimpleForm>
    </Edit>
  )
})
