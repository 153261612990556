import { funcCreateUsers } from '@/src/provider/DataProvider/populate-create-resource/users'
import { funcCreateSurveys } from '@/src/provider/DataProvider/populate-create-resource/surveys'
import { funcCreateStorePoints } from '@/src/provider/DataProvider/populate-create-resource/store-points'
import { funcCreateReferralBenefits } from '@/src/provider/DataProvider/populate-create-resource/referral-benefits'
import { funcCreateRedundancyPaymentGateway } from '@/src/provider/DataProvider/populate-create-resource/redundancy-payment-gateway'
import { funcCreatePromotions } from '@/src/provider/DataProvider/populate-create-resource/promotions'
import { funcCreatePromoDoublePoin } from '@/src/provider/DataProvider/populate-create-resource/promo-double-poin'
import { funcCreateProfile } from '@/src/provider/DataProvider/populate-create-resource/profile'
import { funcCreatePricings } from '@/src/provider/DataProvider/populate-create-resource/pricings'
import { funcCreatePoinAddManually } from '@/src/provider/DataProvider/populate-create-resource/poin-add-manually'
import { funcCreateOverride } from '@/src/provider/DataProvider/populate-create-resource/override'
import { funcCreateMessengerMsgSeries } from '@/src/provider/DataProvider/populate-create-resource/messenger-msg-series'
import { funcCreateKnowledgeBase } from '@/src/provider/DataProvider/populate-create-resource/knowledge-base'
import { funcCreateHeaderImage } from '@/src/provider/DataProvider/populate-create-resource/header-image'
import { funcCreateFlashsale } from '@/src/provider/DataProvider/populate-create-resource/flashsale'
import { funcCreateDenomPricings } from '@/src/provider/DataProvider/populate-create-resource/denom-pricings'
import { funcCreateDefault } from '@/src/provider/DataProvider/populate-create-resource/default'
import { funcCreateCSVPricing } from '@/src/provider/DataProvider/populate-create-resource/csv-pricing'
import { funcCreateCouponSettingsRecalc } from '@/src/provider/DataProvider/populate-create-resource/coupon-settings-recalc'
import { funcCreateCouponSettings } from '@/src/provider/DataProvider/populate-create-resource/coupon-settings'
import { funcCreateBonuses } from '@/src/provider/DataProvider/populate-create-resource/bonuses'
import { funcCreateBlastSms } from '@/src/provider/DataProvider/populate-create-resource/blast-sms'
import { funcCreateBlacklistedTargets } from '@/src/provider/DataProvider/populate-create-resource/blacklisted-targets'
import { funcCreateAnnouncements } from '@/src/provider/DataProvider/populate-create-resource/announcements'
import { funcCreateAdvertisements } from '@/src/provider/DataProvider/populate-create-resource/advertisements'

const funcCreate = async (resource, params) => {
  console.log('create', { resource, params })

  if (resource === 'announcements') {
    return await funcCreateAnnouncements(resource, params)
  }

  if (resource === 'blast_sms') {
    return await funcCreateBlastSms(resource, params)
  }

  if (resource === 'users') {
    return await funcCreateUsers(resource, params)
  }

  if (resource === 'promotions') {
    return await funcCreatePromotions(resource, params)
  }

  if (resource === 'profile') {
    return await funcCreateProfile(resource, params)
  }

  if (resource === 'override') {
    return await funcCreateOverride(resource, params)
  }

  if (resource === 'CSVPricing') {
    return await funcCreateCSVPricing(resource, params)
  }

  if (
    resource === 'advertisements' ||
    resource === 'popupads' ||
    resource === 'couponads' ||
    resource === 'runningText'
  ) {
    return await funcCreateAdvertisements(resource, params)
  }

  if (resource === 'messenger_msg_series') {
    return await funcCreateMessengerMsgSeries(resource, params)
  }

  if (resource === 'store_points') {
    return await funcCreateStorePoints(resource, params)
  }

  if (resource === 'referral_benefits') {
    return await funcCreateReferralBenefits(resource, params)
  }

  if (resource === 'coupon_settings') {
    return await funcCreateCouponSettings(resource, params)
  }

  if (resource === 'poin_add_manually') {
    return await funcCreatePoinAddManually(resource, params)
  }

  if (resource === 'coupon_settings_recalc') {
    return await funcCreateCouponSettingsRecalc(resource, params)
  }

  if (resource === 'flashsale') {
    return await funcCreateFlashsale(resource, params)
  }

  if (resource === 'redundancy_payment_gateway') {
    return await funcCreateRedundancyPaymentGateway(resource, params)
  }

  if (resource === 'promo_double_poin') {
    return await funcCreatePromoDoublePoin(resource, params)
  }

  if (resource === 'denom_pricings') {
    return await funcCreateDenomPricings(resource, params)
  }

  if (resource === 'surveys') {
    return await funcCreateSurveys(resource, params)
  }

  if (resource === 'pricings') {
    return await funcCreatePricings(resource, params)
  }

  if (resource === 'header-image') {
    return await funcCreateHeaderImage(resource, params)
  }

  if (resource === 'knowledge_base') {
    return await funcCreateKnowledgeBase(resource, params)
  }

  if (resource === 'blacklisted_targets') {
    return await funcCreateBlacklistedTargets(resource, params)
  }

  if (resource === 'bonuses') {
    return await funcCreateBonuses(resource, params)
  }

  return await funcCreateDefault(resource, params)
}

export default funcCreate
