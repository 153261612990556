import React, { cloneElement } from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  required,
  PasswordInput,
  useNotify,
  useRedirect,
  CreateButton,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CustomToolbar } from './CustomToolbar'
import ExportButton from '../shared-components/export-button'

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { resource, displayedFilters, filterValues, basePath, showFilter } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton filter={filterValues} resourceType="ORGANIZATION" />
    </TopToolbar>
  )
}

export const OrganizationList = (props) => (
  <List {...props} actions={<ListActions />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" label="Company Name" />
      <TextField source="api_username" />
      <TextField source="api_password" />
      <EditButton />
    </Datagrid>
  </List>
)

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
  },
  input: {
    marginBottom: '-20px',
  },
}))

export const OrganizationCreate = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('ra.notification.created', 'info', { smart_count: 1 })
    redirect('/organizations')
  }

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput
                fullWidth
                className={classes.input}
                source="name"
                label="Company Name"
                validate={[required()]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput fullWidth className={classes.input} type="email" source="api_username" />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput fullWidth source="api_password" label="Password" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}

const EditTitle = ({ record }) => {
  return <span>{record ? `"${record.name}"` : ''} Edit</span>
}

export const OrganizationEdit = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<EditTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput className={classes.input} fullWidth disabled source="id" />
            </Grid>
            <Grid item xs={6}>
              <TextInput fullWidth source="name" label="Company Name" validate={[required()]} />
            </Grid>
            <Grid item xs={6}>
              <TextInput fullWidth type="email" source="api_username" />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput fullWidth source="api_password" label="Password" />
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}
