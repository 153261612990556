import moment from 'moment'
import { fetchDataInitTable } from '../shared-script/fetchDataInitTable'
import defaultSortParams from '../shared-script/defaultSortParams'
const { moco } = require('../../MbaasClient')

export async function isDownloads(params, resource) {
  console.log(6234958776, { method: 'getList', resource: resource, params: params })
  const { resource: resourcefilter, dateGte, dateLte } = params.filter || {}
  const { page, perPage } = params.pagination
  let { field: sortField, order: sortOrder } = params.sort

  // adjust sorting params, default sort DESC
  const [field, order] = defaultSortParams(sortField, sortOrder)

  let filterObject = moco.composeFilter()
  let includedTables = []

  if (resourcefilter) {
    filterObject = filterObject.eq('resource', resourcefilter)
  }

  if (dateGte && dateLte) {
    filterObject = filterObject.and((x) =>
      x
        .ge('created_at', moment(dateGte).startOf('day').toISOString())
        .le('created_at', moment(dateLte).endOf('day').toISOString())
    )
  }

  if (filterObject !== '') filterObject = filterObject.toString()

  const [data, total] = await fetchDataInitTable(
    includedTables,
    resource,
    filterObject,
    order,
    field,
    page,
    perPage
  )

  return { data: data, total: total }
}
