import React, { useEffect } from 'react'
import { useTranslate } from 'react-admin'
import NumberFormat from 'react-number-format'
import ChipInput from 'material-ui-chip-input'
import Chip from '@material-ui/core/Chip'
import { useController } from 'react-hook-form'

const chipRenderer = (
  { value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, className, ...props },
  key,
  onChange,
  allValue
) => {
  const ttt2 = () => {
    onChange(allValue.filter((v) => v !== chip))
  }
  return (
    <Chip
      onFocus={isFocused}
      disabled={isDisabled}
      className={className}
      key={key}
      label={chip}
      onClick={handleClick}
      onDelete={ttt2}
      size="small"
      clickable={false}
    />
  )
}

export default function RhfChipInputNumberFormat({
  name,
  control,
  rules, // react-hook-form prop
  variant = 'filled',
  label,
  size = 'small',
  fullWidth,
  style,
  disabled,
  ...props
}) {
  const translate = useTranslate()

  const {
    field: { onChange, value, ref },
    fieldState: { error, isTouched },
  } = useController({
    name,
    control,
    rules,
  })

  useEffect(() => {
    onChange(value || [])
  }, [value?.toString()])

  return (
    <ChipInput
      value={value || []}
      chipRenderer={(action, key) => chipRenderer(action, key, onChange, value)}
      inputRef={ref}
      variant={variant}
      label={label}
      size={size}
      error={!!(isTouched && error)}
      helperText={isTouched && translate(error)}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
      fullWidth={fullWidth}
      style={style}
      disabled={disabled}
      onAdd={(chip) => {
        onChange([...value, chip])
      }}
      {...props}
    />
  )
}

/* OTHER COMPONENT */
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
    />
  )
}
