import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import SaveIcon from '@material-ui/icons/Save'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { useRefresh } from 'react-admin'

import { RhfMuiTextfield, RhfMuiSelect } from './../../shared-components'
import { updatePurchaseLimit } from './../../requests'
import { recordAtom, isDrawerOpenAtom } from './atom'

const useStyles = makeStyles({
  list: {
    width: '40vw',
  },
})

export default function DrawerEdit() {
  const refresh = useRefresh()

  const classes = useStyles()
  const [record] = useAtom(recordAtom)
  const [isDrawerEditOpen, setIsDrawerEditOpen] = useAtom(isDrawerOpenAtom)

  const {
    handleSubmit,
    watch,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      product_code: '',
      country: '',
      channel_code: '',
      period_purchase_limit: '',
      limit_type_purchase_limit: '',
      limit_value_purchase_limit: '',
    },
  })

  React.useEffect(() => {
    const limit = record.purchase_limit?.limit || ''
    const period = record.purchase_limit?.period || ''
    const product_code = record.product_code || ''
    const country = record.country || ''
    const channel_code = record.channel_code || ''

    reset({
      product_code: product_code.toUpperCase(),
      country: country.toUpperCase(),
      channel_code: channel_code,
      period_purchase_limit: period.toLowerCase(),
      limit_type_purchase_limit: limit.toLowerCase() === 'unlimited' ? 'unlimited' : 'other',
      limit_value_purchase_limit: limit.toLowerCase() === 'unlimited' ? '' : limit,
    })
  }, [record])

  const onSubmit = async (data) => {
    // await sleep(1000)
    await updatePurchaseLimit(record.pricing_ids, {
      purchase_limit: {
        period: data.period_purchase_limit.toLowerCase(),
        limit:
          data.limit_type_purchase_limit.toLowerCase() === 'unlimited'
            ? 'unlimited'
            : data.limit_value_purchase_limit,
      },
    })
    setIsDrawerEditOpen(false)
    refresh()
  }

  const handleCloseDrawer = () => {
    !isSubmitting && setIsDrawerEditOpen(false)
  }

  return (
    <div>
      <Drawer anchor="right" open={isDrawerEditOpen} onClose={handleCloseDrawer}>
        <div className={clsx(classes.list)} style={{ padding: '16px' }} role="presentation">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              <RhfMuiTextfield
                fullWidth
                control={control}
                name="product_code"
                rules={{ required: 'Required' }}
                label="Product Code"
                disabled
              />
              <RhfMuiTextfield
                fullWidth
                control={control}
                name="country"
                rules={{ required: 'Required' }}
                label="Country"
                disabled
              />
              <RhfMuiTextfield
                fullWidth
                control={control}
                name="channel_code"
                rules={{ required: 'Required' }}
                label="Channel Code"
                disabled
              />
              <RhfMuiSelect
                fullWidth
                control={control}
                name="period_purchase_limit"
                rules={{ required: 'Required' }}
                label="Period purhase limit"
                type="number"
                options={[
                  { id: 'daily', name: 'DAILY' },
                  { id: 'weekly', name: 'WEEKLY' },
                  { id: 'monthly', name: 'MONTHLY' },
                ]}
                disabled={isSubmitting}
              />
              <RhfMuiSelect
                fullWidth
                control={control}
                name="limit_type_purchase_limit"
                rules={{ required: 'Required' }}
                label="Limit type purhase limit"
                type="number"
                options={[
                  { id: 'unlimited', name: 'UNLIMITED' },
                  { id: 'other', name: 'LIMITED' },
                ]}
                disabled={isSubmitting}
              />
              {watch('limit_type_purchase_limit')?.toUpperCase() === 'OTHER' && (
                <RhfMuiTextfield
                  fullWidth
                  control={control}
                  name="limit_value_purchase_limit"
                  rules={{ required: 'Required' }}
                  label="Limit value purhase limit"
                  type="number"
                  disabled={isSubmitting}
                />
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={isSubmitting ? <CircularProgress size="20px" /> : <SaveIcon />}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  )
}
