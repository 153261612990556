// @ts-nocheck
import { fetchLocale } from '@/src/requests'
import { client, bucket, StorageURL } from '../../MbaasClient'

// @ts-ignore
export const funcUpdateAdvertisements = async (resource, params) => {
  console.log(7742343, 'params', params)
  resource = 'advertisements'
  const {
    url,
    description,
    ini_url,
    start,
    end,
    ads_type,
    max_shown,
    country,
    filename: prevImage,
    coupon_setting_id,
    user_type,
    textColor,
  } = params.data

  let userTypeInserted
  if (user_type) {
    userTypeInserted = user_type.join(',')
  } else {
    userTypeInserted = 'GUEST'
  }

  let runningTextStyleProps
  if (textColor) {
    runningTextStyleProps = {
      css: {
        color: textColor,
      },
    }
  }

  // running text
  const [, data] = await fetchLocale()

  function extractRunText(data, locale) {
    const text = {}
    for (const item of locale) {
      const prop = `runText_locale_${item.locale}`
      if (data.hasOwnProperty(prop)) {
        text[item.locale] = data[prop]
      }
    }
    return { text }
  }

  const result = extractRunText(params.data, data?.rows)
  if (runningTextStyleProps) {
    Object.assign(result, runningTextStyleProps)
  }
  //

  if (params.data.url.rawFile) {
    // remove old file
    await client.storage.removeFile({
      filePath: prevImage,
      bucket: bucket['advertisements'],
    })
    // upload new file
    // @ts-ignore
    const { data: uploadedData } = await client.storage.upload({
      params: {
        filename: `${Date.now()}-${url?.rawFile?.name}`,
        bucket: bucket['advertisements'],
      },
      file: url.rawFile,
    })

    const parseUrl = new URL(uploadedData?.attributes?.url)
    const imageUrl = `${StorageURL}${parseUrl.pathname}`

    const { data } = await client.getTable(resource).update(
      Object.assign({
        id: params.id,
        data: {
          url: imageUrl,
          filename: uploadedData.id,
          description:
            description === null ? '#' : description === 'custom_url' ? ini_url : description,
          start: start,
          end: end,
          ads_type: ads_type,
          max_shown: max_shown ? max_shown : null,
          country: country,
          coupon_setting_id: coupon_setting_id,
          user_type: userTypeInserted,
        },
      })
    )

    return { data }
  } else {
    const { data } = await client.getTable(resource).update(
      Object.assign({
        id: params.id,
        data: {
          url: url,
          filename: prevImage,
          description:
            description === null ? '#' : description === 'custom_url' ? ini_url : description,
          start: start,
          end: end,
          ads_type: ads_type,
          max_shown: max_shown ? max_shown : null,
          country: country,
          coupon_setting_id: coupon_setting_id,
          user_type: userTypeInserted,
          running_text_config: result,
        },
      })
    )

    return { data }
  }
}
