import moment from 'moment'
import { client } from '../../MbaasClient'

export const funcUpdateBlacklistedTargets = async (resource: any, params: any) => {
  delete params.data['created_at']
  delete params.data['updated_at']
  delete params.data['id']
  let updatedData = params.data
  if (params.data['type'] && params.data['type'] === resource) delete params.data['type']

  let duration = updatedData.duration
  if (!duration) {
    const prevEnd = params.previousData.end
    duration = moment(prevEnd).diff(moment(updatedData.start), 'months')
  }
  const end = moment(updatedData.start).add(Number(duration), 'months').endOf('day').toISOString()
  delete updatedData.duration
  const { data } = await client.getTable(resource).update({
    id: params.id,
    data: { ...updatedData, end },
  })
  return { data: data }
}
