import moment from 'moment'
import { fetchDataInitTable } from '../shared-script/fetchDataInitTable'
const { moco } = require('../../MbaasClient')

export async function isMessengerMsgSeries(params, resource) {
  console.log(6234958776, { method: 'getList', resource: resource, params: params })
  const { type, dateGte, dateLte } = params.filter || {}
  const { page, perPage } = params.pagination
  let { field, order } = params.sort

  let filterObject = moco.composeFilter()
  let includedTables = []

  if (type) {
    filterObject = filterObject.eq('type', type)
  }
  if (dateGte && dateLte) {
    filterObject = filterObject.and((x) =>
      x
        .ge('start', moment(dateGte).startOf('day').toISOString())
        .le('end', moment(dateLte).endOf('day').toISOString())
    )
  }

  if (filterObject !== '') filterObject = filterObject.toString()

  const [data, total] = await fetchDataInitTable(
    includedTables,
    resource,
    filterObject,
    order,
    field,
    page,
    perPage
  )

  return { data: data, total: total }
}
