const { client } = require('../../MbaasClient')

export const funcCreateAnnouncements = async (resource, params) => {
  const { data } = await client.getTable(resource).insert({
    user_id: params.data.user_id,
    organization_id: params.data.organization_id,
    title: params.data.title,
    message: params.data.message,
    target: Object.assign({
      target: params.data.target,
      customers: params.data.customer_id,
    }),
  })
  return { data: data }
}
