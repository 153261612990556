import reqFlashsale from '@/src/requests/flashsale'
import defaultSortParams from '../shared-script/defaultSortParams'

export async function isFlashsale(params, resource) {
  console.log(6234958776, { method: 'getList', resource: resource, params: params })

  const { page, perPage } = params.pagination
  let { field: sortField, order: sortOrder } = params.sort

  // adjust sorting params, default sort DESC
  const [field, order] = defaultSortParams(sortField, sortOrder)

  const { data, count } = await reqFlashsale.getList({
    page,
    limit: perPage,
    orderBy: { [field]: order },
    filter: params.filter,
  })
  return { data: data, total: count }
}
