import gql from 'graphql-tag'
import { client } from '../provider/MbaasClient'

/* MAIN FUNCTION */
const fetchListRedundancyPaymentGateway = async ({ page, perPage }) => {
  try {
    const result = await client.gql.query({
      query,
      variables: {
        orderBy: { id: 'DESC' },
        offset: (page - 1) * perPage,
        limit: perPage,
      },
    })

    return [
      null,
      {
        rows: result?.allPgConfigurationsList?.data || [],
        count: result?.allPgConfigurationsList?.count || 0,
      },
    ]
  } catch (error) {
    return [error, { rows: [], count: 0 }]
  }
}

export default fetchListRedundancyPaymentGateway

/* QUERY GRAPHQL */
const query = gql`
  query ($orderBy: PgConfigurationsOrderByInput, $offset: Int, $limit: Int) {
    allPgConfigurationsList(orderBy: $orderBy, offset: $offset, limit: $limit) {
      data {
        id
        created_at
        updated_at
        country
        default
        additional_configuration
        created_by
        updated_by
      }
      count
    }
  }
`
